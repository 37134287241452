import {
  Button,
  FormField,
  Modal,
  Select,
  Variants,
} from "@sede-x/shell-ds-react-framework";
import {
  TCIQOption,
  TFilterSelectedSubmit,
  TTerminalsFilter,
} from "carbonIQ/carbonIQtypes";
import { useState } from "react";

const TerminalsSearch = ({
  onClose,
  onSubmit,
  masterData,
}: {
  onClose: () => void;
  onSubmit: (data: TFilterSelectedSubmit) => void;
  masterData: TTerminalsFilter[];
}) => {
  const [open, setOpen] = useState(true);
  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  const terminalNameList = masterData.map((elm: TTerminalsFilter) => {
    return {
      value: elm.gsapName,
      label: elm.gsapName,
      key: elm.id,
    };
  });

  const [selectedterminal, setSelectedterminal] = useState<TCIQOption[]>();

  return (
    <Modal
      title="Search Terminal"
      width={"700px"}
      height={"600px"}
      mask={true}
      open={open}
      onClose={() => {
        handleOnClose();
      }}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit(selectedterminal ?? null);
          handleOnClose();
        }}
      >
        <FormField
          size={"medium"}
          id="terminal-name-label"
          label="Terminal Name"
        >
          <Select
            options={terminalNameList}
            size={"medium"}
            id="terminal-name-select"
            placeholder="Select Terminal Name"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            mode="tags"
            labelInValue={true}
            onChange={(data) => {
              setSelectedterminal(
                data.filter(function (elm: TCIQOption) {
                  // used filter to remove undefined elements, which don't exists in list
                  return elm.key !== undefined;
                })
              );
            }}
          />
        </FormField>

        <div style={{ paddingBottom: "200px" }}></div>

        <div className="from-button-wrap">
          <Button
            variant={Variants.Outlined}
            onClick={() => {
              handleOnClose();
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Search</Button>
        </div>
      </form>
    </Modal>
  );
};

export default TerminalsSearch;
