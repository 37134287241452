import { useState } from "react";

import { Window } from "@progress/kendo-react-dialogs";
import "./movements.css";

import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab
} from "@progress/kendo-react-layout";
import { TMovement } from "ticketing/ticketing.types";
import { MovementComments } from "./MovementComments";
import { MovementDebugInfo } from "./MovementDebugInfo";
import { MovementInfoFields } from "./MovementInfoFields";

const MovementAdditionalDetailsContainer = ({
  movement,
  onClose
}: {
  movement: TMovement;
  onClose: () => void;
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const onActiveTabChanged = (e: TabStripSelectEventArguments) => {
    setActiveTab(e.selected);
  };

  const handleClose = () => onClose();

  return (
    <Window
      onClose={handleClose}
      title={`Delivery ID: ${movement?.enterpriseSystemCode}`}
      modal={true}
      width={600}
      height={400}>
      <TabStrip
        selected={activeTab}
        onSelect={onActiveTabChanged}
        keepTabsMounted={true}
        scrollable={false}
        renderAllContent={true}
        className="movement-aspect-container">
        <TabStripTab title="Info" contentClassName="tab-content-small">
          <MovementInfoFields movement={movement} onClose={onClose} />
        </TabStripTab>
        <TabStripTab title="Comments" contentClassName="tab-content-small">
          <MovementComments movement={movement} onClose={onClose} />
        </TabStripTab>
        <TabStripTab title="Debug Info" contentClassName="tab-content-large">
          <MovementDebugInfo movement={movement} onClose={onClose} />
        </TabStripTab>
      </TabStrip>
    </Window>
  );
};

export default MovementAdditionalDetailsContainer;
