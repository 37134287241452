import { ApolloError, useQuery } from "@apollo/client";
import {
  Badge,
  BaseTable,
  Button,
  Sentiments,
  Sizes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  AddSquare,
  CheckCircleSolid,
  CrossCircleSolid,
  EditOutlined
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  CellContext,
  ColumnDef,
  getPaginationRowModel,
  getSortedRowModel,
  RowData,
  SortingState
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { loader } from "graphql.macro";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import ColumnText from "shared/components/basetable/cells/ColumnText";
import NoDataTableImage from "shared/components/basetable/NoDataTableImage";
import GlobalHeader from "shared/components/GlobalHeader";
import LoadingPanel from "shared/components/LoadingPanel";
import {
  equalsIgnoreCase,
  SHELL_CHEM_LE,
  SOPUS_LE,
  SOPUS_SANDBOX_LE,
  STCAN_LE,
  STUSCO_LE
} from "ticketing/utils";
import { GqlResponse } from "types";
import { IMACkRole, IMACkRoleView, IMACkUser } from "../../auth";
import { TUserLegalEntity } from "../types";
import AddOrUpdateUser from "./AddOrUpdateUser";

const ROWS_PER_PAGE = 10;
const LEGAL_ENTITY_NAMES = [STCAN_LE, STUSCO_LE, SOPUS_LE, SOPUS_SANDBOX_LE, SHELL_CHEM_LE];

const GET_ALL_USERS = loader("../graphql/query-users.graphql");
const GET_ALL_ROLES = loader("../graphql/query-active-roles.graphql");
const GET_ALL_USER_LEGAL_ENTITIES = loader("../graphql/query-internal-legal-entities.graphql");

type TAllUsersData = GqlResponse<IMACkUser[], "allUsers">;
type TAllRolesData = GqlResponse<IMACkRoleView[], "allRoles">;
type TAllLegalEntities = GqlResponse<TUserLegalEntity[], "userLegalEntities">;
type TViewAddOrUpdateUser = { show: boolean; user?: IMACkUser };
type TButtonClickHandler<TData extends RowData> = (val: TData) => void;

const gridColumns: ColumnDef<IMACkUser>[] = [
  {
    header: "ID",
    accessorKey: "id",
    enableSorting: true,
    enableResizing: false,
    cell: arg => <ColumnText>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "First Name",
    accessorKey: "firstName",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Last Name",
    accessorKey: "lastName",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText>{arg.getValue() as string}</ColumnText>
  },
  {
    header: "Email",
    accessorKey: "email",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <p title={arg.getValue() as string}>{arg.getValue() as string}</p>
  },

  {
    header: "Status",
    accessorKey: "status",
    enableResizing: false,
    enableSorting: true,
    cell: arg => (
      <p>
        {equalsIgnoreCase(arg.getValue() as string, "active") ? (
          <CheckCircleSolid fill={"var(--forest-500)"} />
        ) : (
          <CrossCircleSolid fill={"var(--red-500)"} />
        )}
      </p>
    )
  },

  {
    header: "Role(s)",
    accessorKey: "roles",
    enableResizing: false,
    cell: arg => {
      const aggeragatedRoles = (arg.getValue() as IMACkRole[]).map(elm => elm.name).join(", ");
      return <p title={aggeragatedRoles}>{aggeragatedRoles}</p>;
    }
  },
  {
    header: "Last Sign-In At",
    accessorKey: "lastSignInAt",
    enableResizing: false,
    enableSorting: true,
    cell: arg => {
      const tempValue = arg.getValue() as string;
      return <p>{tempValue ? dayjs(tempValue).format("MM/DD/YYYY") : "N/A"}</p>;
    }
  }
];

const gridColumnsGenerate = (onEditUser: TButtonClickHandler<IMACkUser>) => {
  const additionalGridColumn = {
    header: "",
    accessorKey: "version",
    size: 50,
    enableSorting: false,
    cell: (arg: CellContext<IMACkUser, unknown>) => (
      <div className="cta-button-wrapper">
        <span>
          <Button title="Edit" size={Sizes.Small} onClick={() => onEditUser(arg.row.original)}>
            <EditOutlined />
            Edit
          </Button>
        </span>
      </div>
    )
  };

  return [...gridColumns, additionalGridColumn];
};

const PADDING = 80;
const createNewUser = () => ({
  id: "",
  version: 0,
  firstName: "",
  lastName: "",
  email: "",
  status: "ACTIVE",
  defaultLegalEntityId: null,
  roles: []
});

const ManageUsers = () => {
  const { loading, error: uError } = useQuery<TAllUsersData>(GET_ALL_USERS, {
    onCompleted: data => setUsers(data.allUsers)
  });

  const {
    loading: rLoading,
    data: rolesData,
    error: rError
  } = useQuery<TAllRolesData>(GET_ALL_ROLES);

  const {
    loading: lLoading,
    data: legalEntitiesData,
    error: lError
  } = useQuery<TAllLegalEntities>(GET_ALL_USER_LEGAL_ENTITIES, {
    variables: {
      legalEntityNames: LEGAL_ENTITY_NAMES
    }
  });

  const [users, setUsers] = useState<IMACkUser[]>([]);

  const [viewAddOrUpdateUser, setViewAddOrUpdateUser] = useState<TViewAddOrUpdateUser>({
    show: false
  });

  const onNewUser = useCallback(() => {
    setViewAddOrUpdateUser({ show: true, user: createNewUser() });
  }, []);

  const onEditUser = useCallback((user: IMACkUser) => {
    setViewAddOrUpdateUser({ show: true, user });
  }, []);

  const addUserBadge = useMemo(() => {
    return (
      <Badge
        key={1}
        icon={<AddSquare />}
        sentiment={Sentiments.Information}
        variant={Variants.Filled}
        onClick={onNewUser}>
        Add New User
      </Badge>
    );
  }, [onNewUser]);

  const onEditDone = useCallback(
    (result: IMACkUser | null) => {
      if (result != null) {
        if (users.some(u => u.id === result.id)) {
          setUsers(us => us.map(u => (u.id === result.id ? result : u))); //replace if found
        } else {
          setUsers(us => [...us, result]);
        }
      }
      setViewAddOrUpdateUser({ show: false });
    },
    [users]
  );

  const columns = useMemo(() => gridColumnsGenerate(user => onEditUser(user)), [onEditUser]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });

  const tableOptions = useMemo(
    () => ({
      getPaginationRowModel: getPaginationRowModel(),
      onPaginationChange: setPagination,
      state: {
        sorting,
        pagination
      },
      onSortingChange: setSorting,
      getSortedRowModel: getSortedRowModel(),
      autoResetPageIndex: false
    }),
    [sorting, pagination]
  );

  const errors = useMemo(
    () => [uError, rError, lError].filter((e): e is ApolloError => Boolean(e)),
    [uError, rError, lError]
  );

  const hasError = errors.length > 0;
  const isLoading = [loading, rLoading, lLoading].some(l => l);

  const globalHeaderRef = useRef<HTMLDivElement>(null);
  const [tableTop, setTableTop] = useState<number>(0);

  useEffect(() => {
    const { innerHeight } = window;
    setTableTop(
      innerHeight -
        (globalHeaderRef?.current?.getBoundingClientRect().top ?? 0) -
        (globalHeaderRef?.current?.getBoundingClientRect().height ?? 0) -
        PADDING
    );
  }, [globalHeaderRef]);

  return (
    <>
      <div ref={globalHeaderRef}>
        <GlobalHeader pageName="Manage Users" buttonContent={[addUserBadge]} />
      </div>

      {hasError && <ApolloErrorViewer error={errors}></ApolloErrorViewer>}

      {isLoading && <LoadingPanel />}

      <BaseTable
        className="carboniq-data-table"
        columns={columns}
        data={users}
        maxHeight={tableTop}
        noFooter={true}
        tableOptions={tableOptions}
        useColumnsSizing={true}
        stickyHeader={true}
        emptyStateProps={{ image: <NoDataTableImage /> }}
      />

      {viewAddOrUpdateUser.show && viewAddOrUpdateUser.user && (
        <AddOrUpdateUser
          onClose={() => setViewAddOrUpdateUser({ show: false })}
          user={viewAddOrUpdateUser.user}
          onEditDone={onEditDone}
          roles={rolesData?.allRoles ?? []}
          legalEntities={legalEntitiesData?.userLegalEntities ?? []}
        />
      )}
    </>
  );
};

export default ManageUsers;
