import { filterBy } from "@progress/kendo-data-query";
import "@progress/kendo-date-math/tz/America/Chicago";
import { Button } from "@progress/kendo-react-buttons";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  ComboBox,
  MultiSelect,
  MultiSelectFilterChangeEvent
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent
} from "@progress/kendo-react-form";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import LoadingPanel from "shared/components/LoadingPanel";
import { dateRangeValidator, requiredValidator } from "shared/validators";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import useSearchBatches from "ticketing/hooks/useSearchBatches";
import useSearchCarriers from "ticketing/hooks/useSearchCarriers";
import useSearchCounterParties from "ticketing/hooks/useSearchCounterParties";
import useSearchFacilities from "ticketing/hooks/useSearchFacilities";
import useSearchProducts from "ticketing/hooks/useSearchProducts";
import useSearchShipCodes from "ticketing/hooks/useSearchShipCodes";
import { TBatchSearchCriteria, TicketSource, TicketStatus } from "ticketing/ticketing.types";
import "./SearchBatches.css";

type TSearchMovementsOrTicketsProps = {
  searchCriteria?: TBatchSearchCriteria;
  onSearch: (criteria: TBatchSearchCriteria) => void;
  searchFor: "Movements" | "Tickets" | "AvailableTickets";
  onReset?: () => void;
};
const MIN_FILTER_LENGTH = 3;

/**
 *
 * @param props
 * @returns
 */
export const SearchMovementsOrTicketsForm = (props: TSearchMovementsOrTicketsProps) => {
  const { picklists, isLoading, isReady } = usePicklists();

  const onSubmitClick = (e: FormSubmitClickEvent) => {
    //shallow copy...to force search
    props.onSearch?.({ ...e.values } as TBatchSearchCriteria);
  };

  const onReset = (formRenderProps: FormRenderProps) => {
    props.onReset?.();
    formRenderProps.onFormReset();
  };

  const [searchBatches, batchSearchResults, bLoading] = useSearchBatches();
  const [searchProducts, productsSearchResults, pLoading] = useSearchProducts();
  const [searchCounterparties, cpSearchResults, cpLoading] = useSearchCounterParties();
  const [searchFacilities, facilitiesSearchResults, fLoading] = useSearchFacilities();
  const [searchCarriers, carrierSearchResults, cLoading] = useSearchCarriers();
  const [searchShipFromCodes, shipFromCodes, sfLoading] = useSearchShipCodes();

  const [searchShipToCodes, shipToCodes, stoLoading] = useSearchShipCodes();
  const [legalEntities, setLegalEntities] = useState(picklists?.legalEntities);

  const handleLegalEntitiesFilter = (e: MultiSelectFilterChangeEvent) => {
    if (picklists?.legalEntities) {
      setLegalEntities(filterBy(picklists?.legalEntities, e.filter));
    }
  };

  const handleFilterChange = (e: MultiSelectFilterChangeEvent) => {
    if (e.filter.value.length >= MIN_FILTER_LENGTH) {
      const value = e.filter.value;
      switch (e.target.name) {
        case "batches":
          searchBatches(value);
          break;
        case "products":
          searchProducts(value);
          break;
        case "counterParties":
          searchCounterparties(value);
          break;
        case "facilities":
          searchFacilities(value);
          break;
        case "carriers":
          searchCarriers(value);
          break;
        case "shipFromCodes":
          searchShipFromCodes({ shipCodeType: "SHIP_FROM", code: value });
          break;
        case "shipToCodes":
          searchShipToCodes({ shipCodeType: "SHIP_TO", code: value });
          break;
        default:
      }
    }
  };

  if (isLoading || !isReady) {
    return <LoadingPanel />;
  }

  return (
    <Form
      onSubmitClick={onSubmitClick}
      initialValues={props.searchCriteria}
      validator={dateRangeValidator}
      key={JSON.stringify(props.searchCriteria)}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement horizontal={true} className="parent">
          <Label>*Start&nbsp;Date</Label>
          <Field
            id={"startDate"}
            name={"startDate"}
            component={DatePicker}
            validator={requiredValidator}
            className={"theme-form-input"}
          />
          <Label>*End&nbsp;Date</Label>
          <Field
            id={"endDate"}
            name={"endDate"}
            component={DatePicker}
            validator={requiredValidator}
            className={"theme-form-input"}
          />

          <Label>Product</Label>
          <Field
            id={"products"}
            name={"products"}
            component={MultiSelect}
            popupSettings={{ popupClass: "m-popup" }}
            textField="name"
            dataItemKey="id"
            data={productsSearchResults}
            autoClose={false}
            filterable={true}
            onFilterChange={handleFilterChange}
            loading={pLoading}
            className={"theme-form-input"}
          />

          <span></span>
          <Label>Batch&nbsp;Name</Label>
          <Field
            id={"batches"}
            name={"batches"}
            component={MultiSelect}
            popupSettings={{ popupClass: "m-popup" }}
            textField="name"
            dataItemKey="id"
            data={batchSearchResults}
            autoClose={false}
            filterable={true}
            onFilterChange={handleFilterChange}
            loading={bLoading}
            className={"theme-form-input"}
          />
          {props.searchFor === "Movements" && (
            <>
              <Label>Counterparty</Label>
              <Field
                id={"counterParties"}
                name={"counterParties"}
                component={MultiSelect}
                popupSettings={{ popupClass: "m-popup" }}
                textField="name"
                dataItemKey="id"
                data={cpSearchResults}
                autoClose={false}
                filterable={true}
                onFilterChange={handleFilterChange}
                loading={cpLoading}
                className={"theme-form-input"}
              />
            </>
          )}
          {(props.searchFor === "Tickets" || props.searchFor === "AvailableTickets") && (
            <>
              <Label>Status</Label>
              <Field
                id={"ticketStatus"}
                name={"ticketStatus"}
                component={ComboBox}
                data={Object.values(TicketStatus).filter(v => isNaN)}
                className={"theme-form-input"}
              />
            </>
          )}
          <Label>Location</Label>
          <Field
            id={"facilities"}
            name={"facilities"}
            component={MultiSelect}
            popupSettings={{ popupClass: "m-popup" }}
            textField="name"
            dataItemKey="id"
            data={facilitiesSearchResults}
            autoClose={false}
            filterable={true}
            onFilterChange={handleFilterChange}
            loading={fLoading}
            className={"theme-form-input"}
          />
          <div></div>
          {props.searchFor === "AvailableTickets" && (
            <>
              <Label>Ship From</Label>
              <Field
                id={"shipFromCodes"}
                name={"shipFromCodes"}
                component={MultiSelect}
                popupSettings={{ popupClass: "m-popup" }}
                textField="code"
                dataItemKey="code"
                data={shipFromCodes}
                autoClose={false}
                filterable={true}
                onFilterChange={handleFilterChange}
                loading={sfLoading}
                className={"theme-form-input"}
              />
              <Label>Ship To</Label>
              <Field
                id={"shipToCodes"}
                name={"shipToCodes"}
                component={MultiSelect}
                popupSettings={{ popupClass: "m-popup" }}
                textField="code"
                dataItemKey="code"
                data={shipToCodes}
                autoClose={false}
                filterable={true}
                onFilterChange={handleFilterChange}
                loading={stoLoading}
                className={"theme-form-input"}
              />
              <span />
              <Label>Include nulls?</Label>
              <Field
                id={"includeNulls"}
                name={"includeNulls"}
                component={Switch}
                defaultChecked={props.searchCriteria?.includeNulls}
                onLabel={"Yes"}
                offLabel={"No"}
                className={"theme-form-input"}
              />
            </>
          )}
          {props.searchFor === "Movements" && (
            <>
              <Label>Ext&nbsp;Src&nbsp;Id</Label>
              <Field
                id={"deliveryIds"}
                name={"deliveryIds"}
                component={Input}
                placeholder="comma separated"
                className={"theme-form-input"}
              />
            </>
          )}
          {(props.searchFor === "Tickets" || props.searchFor === "AvailableTickets") && (
            <>
              <Label>Ticket#</Label>
              <Field
                id={"ticketNumbers"}
                name={"ticketNumbers"}
                component={Input}
                placeholder="comma separated"
                className={"theme-form-input"}
              />
            </>
          )}

          <Label>Logistics</Label>
          <Field
            id={"logisticsSystems"}
            name={"logisticsSystems"}
            component={MultiSelect}
            popupSettings={{ popupClass: "m-popup" }}
            textField="name"
            dataItemKey="id"
            data={picklists?.logisticsSystems}
            autoClose={false}
            filterable={true}
            className={"theme-form-input"}
          />

          <Label>Carrier</Label>
          <Field
            id={"carriers"}
            name={"carriers"}
            component={MultiSelect}
            popupSettings={{ popupClass: "m-popup" }}
            textField="displayName"
            dataItemKey="id"
            data={carrierSearchResults}
            autoClose={false}
            filterable={true}
            onFilterChange={handleFilterChange}
            loading={cLoading}
            className={"theme-form-input"}
          />

          <Button
            themeColor={"primary"}
            type={"submit"}
            disabled={!formRenderProps.valid}
            className={"theme-btn-yellow"}>
            Search
          </Button>
          {props.searchFor === "Movements" && (
            <>
              <Label>Legal&nbsp;Entity</Label>
              <Field
                id={"legalEntities"}
                name={"legalEntities"}
                component={MultiSelect}
                popupSettings={{ popupClass: "m-popup" }}
                textField="name"
                dataItemKey="id"
                data={legalEntities}
                className={"theme-form-input"}
                filterable={true}
                onFilterChange={handleLegalEntitiesFilter}
              />
            </>
          )}

          {(props.searchFor === "Tickets" || props.searchFor === "AvailableTickets") && (
            <>
              <Label>Source</Label>
              <Field
                id={"ticketSource"}
                name={"ticketSource"}
                component={MultiSelect}
                popupSettings={{ popupClass: "m-popup" }}
                data={Object.keys(TicketSource).filter(v => isNaN)}
                className={"theme-form-input"}
              />
            </>
          )}

          <Label>MoT</Label>
          <Field
            id={"modeOfTransports"}
            name={"modeOfTransports"}
            component={MultiSelect}
            popupSettings={{ popupClass: "m-popup" }}
            textField="name"
            dataItemKey="id"
            data={picklists?.modeOfTransports}
            className={"theme-form-input"}
          />

          <Label>Railcar&nbsp;#</Label>
          <Field
            id={"railcarNumbers"}
            name={"railcarNumbers"}
            component={Input}
            placeholder="comma separated"
            className={"theme-form-input"}
          />

          <Button
            className={"theme-btn-yellow"}
            onClick={_e => {
              onReset(formRenderProps);
            }}>
            Reset
          </Button>
        </FormElement>
      )}
    />
  );
};
