import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { process } from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuProps,
  GridItemChangeEvent,
  GridRowClickEvent,
} from "@progress/kendo-react-grid";

import GridActionBar from "./GridActionBar";

import { cellRender, baseProductRowRender } from "../../../forecast/util/gridRenderer";
import { onBaseProductRowSelect } from "../../../forecast/util/gridSelector";

import { IAppState, IForecastState } from "../../../_redux/IReduxState";
import { actionCreator } from "../../../_redux/actionCreator";
import {
  IForecastDailySummary,
  IForecastMonthlySummary,
  IForecastSummary,
} from "../../../forecast/models";
import { sumOfValues } from "../../algorithms";
import { FORECAST_APPROVED_VOLUME } from "shared/enums";
import {
  activeRow, validateValue, enterEdit as gridEditorEnterEdit,
  itemEdited, setApprovedPercentage, itemTouched
} from "../../../forecast/util/gridEditor";


interface IGridBaseProductProps {
  onDataStateChange: any;
  onClose: any;
}
const GridBaseProduct = (props: IGridBaseProductProps): JSX.Element => {
  const { onDataStateChange, onClose } = props;

  const dispatch = useDispatch();

  // redux store states
  const forecast: IForecastState = useSelector(
    (state: IAppState) => state.forecast
  );
  const isMonthly: boolean = forecast.isMonthlyDisplayMode;
  const summary: IForecastSummary = isMonthly
    ? forecast.monthlySummary
    : forecast.dailySummary;
// find base product of the active dailly/detai record
  const activeSummary = activeRow(summary);
  const activeDetails = isMonthly
    ? activeRow(activeSummary.details)
    : null;
    const baseProduct = isMonthly ? activeDetails?.base : activeSummary?.base;

  const data = baseProduct.data;
  const result = baseProduct.result ? baseProduct.result.data : data;
  
  const dataState = (baseProduct?.dataState) || {};
  
  const [, updateState] = React.useState();
  // @ts-ignore
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const enterEdit = (
    dataItem: IForecastMonthlySummary | IForecastDailySummary,
    field: string
  ) => {
    exitEdit(data);
    onBaseProductRowSelect(data, dataItem);

    if (field === FORECAST_APPROVED_VOLUME) {
      // set clicked data item inEdit and re-apply the dataState
      gridEditorEnterEdit(data, dataItem, field);
      baseProduct.result = process(data, dataState);
      dispatch(actionCreator("enterEdit", summary));
    }
  };

  const exitEdit = (
    dataItem: IForecastMonthlySummary | IForecastDailySummary
  ): void => {
    dataItem.inEdit = false;

    summary.inEdit = !!activeSummary.old;
    dispatch(actionCreator("exitEdit", summary));
  };

  const onRowSelect = (e: GridRowClickEvent): void => {
    onBaseProductRowSelect(data, e.dataItem);
    forceUpdate();
  };

  const onItemChange = (event: GridItemChangeEvent) => {
    const value = validateValue(event.value);

    // 1. update base item
    // 2. base - recalculate % for each base item
    // 3. calculate base product total
    // 4. update selected detail/daily volume = base total
    // 5. calculate details total
    // 6. update selected monthly volume = details total

    const field = event.dataItem.inEdit;

    // 1. set item with new volume
    itemEdited(event.dataItem, value);

    // 2. recalculate base product percentages
    setApprovedPercentage(data);

    // 3. calculate new base product volume total
    const newBaseVolumesTotal = sumOfValues(data, field);
    if (isMonthly) {
      // 4. selected detail row volume = new base total
      itemTouched(activeDetails, newBaseVolumesTotal);

      // 5. calculate new details volume total
      const detailsData = activeSummary.details.data;

      const newDetailsVolumeTotal = sumOfValues(detailsData, field);

      // 6. selected monthly volume = new detail total
      itemTouched(activeSummary, newDetailsVolumeTotal);
    } else {
      // 4. selected daily row volume = new base total
      itemTouched(activeSummary, newBaseVolumesTotal);
    }

    dispatch(actionCreator("editBaseData", summary.data));
  };

  const onCellRender = (
    tdElement: React.DetailedReactHTMLElement<any, HTMLElement>,
    cellProps: { dataItem: any; field: any }
  ) => {
    return cellRender(tdElement, cellProps, enterEdit, exitEdit);
  };

  const actions = [
    {
      icon: "k-icon k-i-close",
      title: "Close",
      click: onClose,
    },
  ];

  const ColumnMenuCheckboxFilter = (colMenuProps: GridColumnMenuProps) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter {...colMenuProps} data={data} expanded={true} />
      </div>
    );
  };

  return (
    <div className="card-container">
      <GridActionBar label="Base Product" actions={actions} />
      <Grid
        data={result}
        //data={ data }
        {...dataState}
        onDataStateChange={onDataStateChange}
        sortable
        resizable
        reorderable
        onRowClick={onRowSelect}
        onRowDoubleClick={onRowSelect}
        editField="inEdit"
        onItemChange={onItemChange}
        cellRender={onCellRender}
        rowRender={baseProductRowRender}
        style={{ minHeight: "100px" }}
      >
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="startDate"
          title="Start Date"
          width="120px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="endDate"
          title="End Date"
          width="120px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="baseProduct.name"
          title="Base Product"
          width="170px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="approvedVolumePercent"
          title="Approved Percentage"
          width="170px"
          format="{0:p2}"
          editable={true}
          editor="numeric"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="approvedVolume"
          title="Approved Volume"
          width="170px"
          format="{0:#,##.##}"
          editable={true}
          editor="numeric"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="forecastVolume"
          title="Forecast Volume"
          width="170px"
          format="{0:#,##.##}"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="unitOfMeasure.name"
          title="UoM"
          width="100px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="contractNum"
          title="Contract Num"
          width="150px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="supplier.name"
          title="Supplier"
          width="100px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="customer.name"
          title="Customer"
          width="250px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="terminal.name"
          title="Terminal"
          width="120px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="modeOfTransport.name"
          title="Mode of Transport"
          width="170px"
        />
      </Grid>
    </div>
  );
};
export default GridBaseProduct;
