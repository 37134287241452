import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { process } from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuProps,
  GridItemChangeEvent,
  GridRowClickEvent,
  GridSelectionChangeEvent,
} from "@progress/kendo-react-grid";

import GridActionBar from "./GridActionBar";

import { IAppState } from "../../../_redux/IReduxState";
import { actionCreator } from "../../../_redux/actionCreator";
import {
  IForecastDailySummary,
  IForecastMonthlySummary,
} from "../../../forecast/models";
import { FORECAST_SELECT_ALL_CHANGE } from "../../../forecast/state/forecastActionTypes";
import * as editor from "../../../forecast/util/gridEditor";
import * as renderer from "../../../forecast/util/gridRenderer";
import * as selector from "../../../forecast/util/gridSelector";
import { FORECAST_AGGREGATED_APPROVED_VOLUME_PERCENT, FORECAST_APPROVED_VOLUME, FORECAST_BASE_PERCENTAGE_MISSING } from "shared/enums";

const GridSummary = ({
  onDataStateChange,
  gridStyle,
}: {
  onDataStateChange: any;
  gridStyle: any;
}) => {
  const dispatch = useDispatch();
  const PAGE_SIZE_100 = 100;
  const PAGE_SIZE_200 = 200;
  const PAGE_SIZE_500 = 500;
  // redux store state
  const forecast = useSelector((state: IAppState) => state.forecast);
  const isMonthly = forecast.isMonthlyDisplayMode;

  const summary = isMonthly ? forecast.monthlySummary : forecast.dailySummary;
  const data = summary.data;
  const dataState = summary.dataState || {};
  const result = summary.result
    ? summary.result.data
    : summary.data.slice(dataState.skip, dataState.take + dataState.skip);

  // local state
  const [expand, setExpand] = React.useState(0);

  const onExpandChange = (event: any) => {
    // const onExpandChange = (event: GridExpandChangeEvent) => {
    //C:\Users\amit.a.kohli\mac-frontend\node_modules\@progress\kendo-react-grid\dist\npm\interfaces\events.d.ts
    event.dataItem[event.target.props.expandField] = event.value;
    setExpand(expand + 1);
  };

  const selectionChange = (event: GridSelectionChangeEvent) => {
    event.dataItem.selected = !event.dataItem.selected;
    dispatch(actionCreator("selectionChange", summary.data));
  };

  const headerSelectionChange = (event: any) => {
    const checked = event.syntheticEvent.target.checked;
    data.forEach((item: any) => {
      item.selected = checked;
    });
    dispatch(actionCreator(FORECAST_SELECT_ALL_CHANGE, summary.data));
  };

  const onRowSelect = (e: GridRowClickEvent) => {
    selector.onSummaryRowSelect(e.dataItem);
  };

  const enterEdit = (
    dataItem: IForecastMonthlySummary | IForecastDailySummary,
    field: string
  ) => {
    editor.exitEdit(data);

    if (field === FORECAST_APPROVED_VOLUME && !isMonthly) {
      // when showBaseProduct is enabled but base has not been fetched,
      // fetch base and reset active row
      if (summary.showBaseProduct) {
        selector.onDailyRowSelect(dataItem as IForecastDailySummary);
      }

      // set clicked data item inEdit and re-apply the dataState
      editor.enterEdit(data, dataItem, field);
      summary.result = process(data, dataState);
      dispatch(actionCreator("enterEdit", summary));
    }
  };

  const exitEdit = (
    dataItem: IForecastMonthlySummary | IForecastDailySummary
  ) => {
    dataItem.inEdit = "";
    dispatch(actionCreator("exitEdit", summary));
  };

  const onItemChange = (event: GridItemChangeEvent) => {
    const value = editor.validateValue(event.value);

    // base % total === 0
    if (
      event.dataItem[FORECAST_AGGREGATED_APPROVED_VOLUME_PERCENT] === 0
    ) {
      const msg = FORECAST_BASE_PERCENTAGE_MISSING;
      dispatch(actionCreator("logError", { message: msg }));
    } else {
      // 1. set new daily item volume
      // 2. set each base volume = new detail volume * %
      editor.itemOnChange(event.dataItem, value);
      dispatch(actionCreator("editDailyData", summary.data));
    }
  };

  const onCellRender = (
    tdElement: React.DetailedReactHTMLElement<any, HTMLElement>,
    cellProps: { dataItem: any; field: any }
  ) => {
    if (isMonthly) {
      return renderer.monthlyCellRender(tdElement, cellProps);
    }
    return renderer.cellRender(tdElement, cellProps, enterEdit, exitEdit);
  };

  const ColumnMenuCheckboxFilter = (props: GridColumnMenuProps) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter {...props} data={data} expanded={true} />
      </div>
    );
  };

  // Grid
  const SummaryGrid = () => {
    return (
      <Grid
        data={result}
        {...dataState}
        onDataStateChange={onDataStateChange}
        sortable
        resizable
        reorderable
        groupable
        pageable={{ pageSizes: [PAGE_SIZE_100, PAGE_SIZE_200, PAGE_SIZE_500] }}
        total={data.length}
        onExpandChange={onExpandChange}
        expandField="expanded"
        onRowClick={onRowSelect}
        onRowDoubleClick={onRowSelect}
        selectedField="selected"
        onSelectionChange={selectionChange}
        onHeaderSelectionChange={headerSelectionChange}
        editField="inEdit"
        onItemChange={onItemChange}
        cellRender={onCellRender}
        rowRender={renderer.rowRender}
        style={gridStyle}
      >
        {!isMonthly && (
          <GridColumn
            field="selected"
            width="50px"
            headerSelectionValue={data.every(
              (dataItem: any) => dataItem.selected === true
            )}
          />
        )}

        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="startDate"
          title="Start Date"
          width="120px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="endDate"
          title="End Date"
          width="120px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="product.name"
          title="Finished Product"
          width="170px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="approvedVolume"
          editable={true}
          editor="numeric"
          title="Approved Volume"
          width="170px"
          format="{0:#,##.##}"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="forecastVolume"
          title="Forecast Volume"
          width="170px"
          format="{0:#,##.##}"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="unitOfMeasure.name"
          title="UoM"
          width="100px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="contractNum"
          title="Contract Num"
          width="150px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="supplier.name"
          title="Supplier"
          width="100px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="customer.name"
          title="Customer"
          width="250px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="terminal.name"
          title="Terminal"
          width="120px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="modeOfTransport.name"
          title="Mode of Transport"
          width="170px"
        />
      </Grid>
    );
  };

  // Render
  return (
    <div className={"card-container"}>
      <GridActionBar
        label={
          forecast.isMonthlyDisplayMode ? "Monthly Summary" : "Daily Summary"
        }
      />
      <SummaryGrid />
    </div>
  );
};
export default GridSummary;
