import { ApolloError, useQuery } from "@apollo/client";
import {
  Badge,
  BaseTable,
  Button,
  Sentiments,
  Sizes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  AddSquare,
  CheckCircleSolid,
  EditOutlined,
  PersonSolid
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  CellContext,
  ColumnDef,
  RowData,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";

import { loader } from "graphql.macro";
import { useEffect, useMemo, useRef, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import ColumnText from "shared/components/basetable/cells/ColumnText";
import NoDataTableImage from "shared/components/basetable/NoDataTableImage";
import GlobalHeader from "shared/components/GlobalHeader";
import LoadingPanel from "shared/components/LoadingPanel";
import { GqlResponse } from "types";
import { IMACkPrivilege, IMACkRole } from "../../auth";
import AddOrUpdateRole from "./AddOrUpdateRole";
import UsersOfRole from "./UsersOfRole";

const GET_ALL_ROLES = loader("../graphql/query-roles.graphql");
const GET_ALL_PRIVILEGES = loader("../graphql/query-privileges.graphql");

const ROWS_PER_PAGE = 10;

type TRolesQueryResponse = GqlResponse<IMACkRole[], "roles">;
type TPrivilegesQueryResponse = GqlResponse<IMACkPrivilege[], "privileges">;
type TButtonClickHandler<TData extends RowData> = (val: TData) => void;
type TViewAddOrUpdateRole = { show: boolean; role?: IMACkRole };
type TShowUserOfRoleState = { show: boolean; role?: IMACkRole };

const gridColumns: ColumnDef<IMACkRole>[] = [
  {
    header: "Name",
    accessorKey: "name",
    enableSorting: true,
    enableResizing: false,
    cell: arg => <ColumnText>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Description",
    accessorKey: "description",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Status",
    accessorKey: "active",
    enableResizing: false,
    enableSorting: true,
    cell: arg => (
      <div>
        <CheckCircleSolid fill={arg.getValue() ? "var(--forest-500)" : "var(--red-500)"} />
      </div>
    )
  }
];

const gridColumnsGenerate = (
  editClickcallback: TButtonClickHandler<IMACkRole>,
  rowClickcallback: TButtonClickHandler<IMACkRole>
) => {
  const additionalGridColumn = {
    header: "",
    accessorKey: "id",
    size: 50,
    enableSorting: false,
    cell: (arg: CellContext<IMACkRole, unknown>) => (
      <div className="cta-button-wrapper">
        <span>
          <Button
            title="Edit"
            size={Sizes.Small}
            onClick={() => editClickcallback(arg.row.original)}>
            <EditOutlined />
            Edit
          </Button>
        </span>
        <span>
          <Button
            title="Users List"
            variant={Variants.Outlined}
            size={Sizes.Small}
            onClick={() => rowClickcallback(arg.row.original)}>
            <PersonSolid />
          </Button>
        </span>
      </div>
    )
  };

  return [...gridColumns, additionalGridColumn];
};

const PADDING = 80;
const createNewRole = () => ({
  id: 0,
  version: 0,
  name: "",
  description: "",
  active: true,
  privileges: []
});

const ManageRoles = () => {
  const { loading: rLoading, error: rError } = useQuery<TRolesQueryResponse>(GET_ALL_ROLES, {
    onCompleted: data => setRoles(data.roles)
  });

  const {
    loading: pLoading,
    error: pError,
    data: privielegesData
  } = useQuery<TPrivilegesQueryResponse>(GET_ALL_PRIVILEGES);

  const [roles, setRoles] = useState<IMACkRole[]>([]);
  const [viewAddOrUpdateRole, setViewAddOrUpdateRole] = useState<TViewAddOrUpdateRole>({
    show: false
  });
  const [viewUsersOfRole, setViewUsersOfRole] = useState<TShowUserOfRoleState>({
    show: false
  });

  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });
  const globalHeaderRef = useRef<HTMLDivElement>(null);
  const [tableTop, setTableTop] = useState<number>(0);

  const errors = useMemo(
    () => [rError, pError].filter((e): e is ApolloError => Boolean(e)),
    [rError, pError]
  );

  const onNewRole = () => {
    setViewAddOrUpdateRole({ show: true, role: createNewRole() });
  };

  const onEditRole = (role: IMACkRole) => {
    setViewAddOrUpdateRole({ show: true, role });
  };

  const onEditDone = (result: IMACkRole | null) => {
    if (result != null) {
      if (roles.findIndex(r => r.id === result.id) > -1) {
        setRoles(rs => rs.map(r => (r.id === result.id ? result : r))); //replace
      } else {
        setRoles(rs => [...rs, result]); //add
      }
    }
    setViewAddOrUpdateRole({ show: false });
  };

  const onViewUsersOfRole = (role: IMACkRole) => {
    setViewUsersOfRole({ show: true, role });
  };

  const columns = gridColumnsGenerate(onEditRole, onViewUsersOfRole);

  const tableOptions = {
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false
  };

  useEffect(() => {
    const { innerHeight } = window;
    setTableTop(
      innerHeight -
        (globalHeaderRef?.current?.getBoundingClientRect().top ?? 0) -
        (globalHeaderRef?.current?.getBoundingClientRect().height ?? 0) -
        PADDING
    );
  }, [globalHeaderRef]);

  const isLoading = [rLoading, pLoading].some(Boolean);
  const hasError = errors.length > 0;

  return (
    <>
      <div ref={globalHeaderRef}>
        <GlobalHeader
          pageName="Manage Roles"
          buttonContent={[
            <Badge
              key={1}
              icon={<AddSquare />}
              sentiment={Sentiments.Information}
              variant={Variants.Filled}
              onClick={onNewRole}>
              Add New Role
            </Badge>
          ]}
        />
      </div>

      {hasError && <ApolloErrorViewer error={errors} />}

      {isLoading && <LoadingPanel />}

      <BaseTable
        className="carboniq-data-table"
        columns={columns}
        data={roles}
        maxHeight={tableTop}
        noFooter={true}
        tableOptions={tableOptions}
        useColumnsSizing={true}
        stickyHeader={true}
        emptyStateProps={{ image: <NoDataTableImage /> }}
      />

      {viewAddOrUpdateRole.show && viewAddOrUpdateRole.role && (
        <AddOrUpdateRole
          role={viewAddOrUpdateRole.role}
          privileges={privielegesData?.privileges ?? []}
          onEditDone={onEditDone}
          onClose={() => setViewAddOrUpdateRole({ show: false })}
        />
      )}

      {viewUsersOfRole.show && viewUsersOfRole.role && (
        <UsersOfRole
          onClose={() => setViewUsersOfRole({ show: false })}
          role={viewUsersOfRole.role}
        />
      )}
    </>
  );
};
export default ManageRoles;
