import { Window } from "@progress/kendo-react-dialogs";
import GridActionBar from "shared/components/grid/GridActionBar";
import { TBatchSearchCriteria } from "ticketing/ticketing.types";
import { SearchMovementsOrTicketsForm } from "./SearchMovementsOrTicketsForm";

type TSearchAvailableTicketsContainerProps = {
  searchCriteria?: TBatchSearchCriteria;
  onSearch: (criteria: TBatchSearchCriteria) => void;
  onClose: () => void;
};
export default function SearchAvailableTicketsContainer(
  props: TSearchAvailableTicketsContainerProps
) {
  return (
    <Window
      onClose={props.onClose}
      title="Filter Available Tickets"
      className="tickets-search-filter"
      initialWidth={1024}
      initialHeight={340}
      modal
      resizable>
      <div className={"search-wrapper"}>
        <div className={"card-container"}>
          <GridActionBar label="Search Tickets" />
          {/**Only hide on collpase other wise the search form will be dismounted and the search criteria will be reset as the state is lost */}
          <div style={{ margin: "5px" }}>
            <div
              className={"form-container"}
              style={{
                maxWidth: "1024px",
                backgroundColor: "#444",
                padding: "5px",
                borderRadius: "5px"
              }}>
              <SearchMovementsOrTicketsForm
                searchCriteria={props.searchCriteria}
                onSearch={props.onSearch}
                searchFor={"AvailableTickets"}
              />
            </div>
          </div>
        </div>
      </div>
    </Window>
  );
}
