export const FORECAST_BASE_PRODUCTS = "forecastBaseProducts";
export const FORECAST_BULK_EDIT_DAILY_DATA = "bulkEditDailyData";
export const FORECAST_BULK_EDIT_DETAILS_DATA = "bulkEditDetailsData";
export const FORECAST_CLOSE_BASE_PRODUCT = "closeBaseProduct";
export const FORECAST_CLOSE_DETAILS = "closeDetails";
export const FORECAST_CRITERIA = "forecastCriteria";
export const FORECAST_DAILY = "forecastDaily";
export const FORECAST_DATASTATE = "forecastDataState";
export const FORECAST_DETAILS = "forecastDetails";
export const FORECAST_DONE_EDIT = "doneEdit";
export const FORECAST_EDIT_BASE_DATA = "editBaseData";
export const FORECAST_EDIT_DAILY_DATA = "editDailyData";
export const FORECAST_EDIT_DETAILS_DATA = "editDetailsData";
export const FORECAST_ENTER_EDIT = "enterEdit";
export const FORECAST_EXIT_EDIT = "exitEdit";
export const FORECAST_FAIL_EDIT = "failEdit";
export const FORECAST_FILTER_OPTIONS = "forecastFilterOptions";
export const FORECAST_MONTHLY = "forecastMonthly";
export const FORECAST_PICKLISTS = "forecastPicklists";
export const FORECAST_SELECT_ALL_CHANGE = "selectAllChange";
export const FORECAST_SELECT_DAILY_ROW = "selectDailyRow";
export const FORECAST_SELECT_DETAILS_ROW = "selectDetailsRow";
export const FORECAST_SELECT_MONTHLY_ROW = "selectMonthlyRow";
export const FORECAST_SELECTED_FILTER = "forecastSelectedFilter";
export const FORECAST_SELECTED_GROUP = "forecastSelectedGroup";
export const FORECAST_SELECTION_CHANGE = "selectionChange";
export const FORECAST_SUMMARY = "forecastSummary";
export const FORECAST_SUMMARY_DAILY = "forecastSummaryDaily";
export const FORECAST_SUMMARY_MONTHLY = "forecastSummaryMonthly";
export const FORECAST_TYPE = "forecastType";
export const FORECAST_UOM = "forecastUOM";
