import { useState } from "react";
import CronDialog from "../../shared/components/CronDialog";

const useCronDialog = () => {
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState("");
  const initDialog = () => {
    setVisible(true);
    setError("");
  };
  const onCloseDialog = () => setVisible(false);
  const handleError = (e: string) => {
    setError(e);
    setVisible(false);
  };

  return [
    () => initDialog(),
    <>
      {visible && <CronDialog onError={handleError} onClose={onCloseDialog} />}
      {!visible && error && (
        <div className={"card-message form-error"}>
          <span>{error}</span>
        </div>
      )}
    </>
  ] as const;
};
export default useCronDialog;
