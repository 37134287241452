import store from "../../store";
import * as forecastApi from "../api";

import { actionCreator } from "../../_redux/actionCreator";
import { convertToGridData } from "./dataConvertor";

import {
  IForecastBase,
  IForecastDailySummary,
  IForecastMonthlySummary,
  IForecastSearchCriteria,
} from "../models";
import {
  FORECAST_DAILY,
  FORECAST_MONTHLY,
  FORECAST_PICKLISTS,
} from "../state/forecastActionTypes";

const dispatch = store.dispatch;

// fetch forecast search criteria picklists
export const fetchForecastPicklists = async () => {
  try {
    dispatch(actionCreator("loading", true));
    const fetchedData = await forecastApi.fetchPicklists();
    dispatch(actionCreator(FORECAST_PICKLISTS, fetchedData));
  } catch (error) {
    dispatch(actionCreator("logError", error));
  } finally {
    dispatch(actionCreator("loading", false));
  }
};

// fetch forecast monthly or daily summary
export const fetchForecastSummary = async (
  isMonthly: boolean,
  criteria: IForecastSearchCriteria
) => {
  dispatch(actionCreator("loading", true));

  let original = null,
    data = null;
  try {
    if (isMonthly) {
      // monthly
      original = await forecastApi.fetchForecastMonthlyData(criteria);
    } else {
      // daily
      original = await forecastApi.fetchForecastDailyData(criteria);
    }

    if (original) {
      data = original.data && (await convertToGridData(original));
      dispatch(
        actionCreator(isMonthly ? FORECAST_MONTHLY : FORECAST_DAILY, { data })
      );
    }
  } catch (error) {
    dispatch(actionCreator("logError", error));
  } finally {
    dispatch(actionCreator("loading", false));
  }
};

// fetch forecast details
export const fetchForecastDetails = async (
  item: IForecastMonthlySummary,
  staleOnDate?: Date
) => {
  try {
    const original = await forecastApi.fetchForecastDetailsData(
      item,
      staleOnDate
    );
    if (original?.data) {
      const data = await convertToGridData(original);
      return { data };
    }
  } catch (error) {
    dispatch(actionCreator("logError", error));
    return error;
  }
};

// fetch base product
export const fetchForecastBaseProduct = async (item: IForecastDailySummary) => {
  let result;
  const original = await forecastApi.fetchForecastBaseProductData(item);
  if (original) {
    const data = await convertToGridData(original);
    result = { data };
  }
  return result;
};

// update base product approved volumes in DB
export const updateBaseVolume = async (editData: IForecastBase[][]) => {
  dispatch(actionCreator("loading", true));
  const result = await forecastApi.updateBaseVolume(editData);
  dispatch(actionCreator("loading", false));
  return result;
};

// update base product approved volumes in DB
export const updateDetailsVolume = async (
  editData: IForecastDailySummary[]
) => {
  dispatch(actionCreator("loading", true));
  const result = await forecastApi.updateDetailsVolume(editData);
  dispatch(actionCreator("loading", false));
  return result;
};
