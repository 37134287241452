import {
  Button,
  FormField,
  Modal,
  RangeDatePicker,
  Select,
  Variants
} from "@sede-x/shell-ds-react-framework";
import dayjs from "dayjs";
import { useState } from "react";

import { useQuery } from "@apollo/client";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { loader } from "graphql.macro";
import LoadingPanel from "shared/components/LoadingPanel";
import { GqlResponse } from "types";
import { CIQOption, TCICustomer, TSavingLetterSearchData } from "../carbonIQtypes";
const CUSTOMER_SEARCH = loader("../graphql/customerSearchFilterBy.graphql");
const FETCH_POLICY_NO_CACHE = {
  fetchPolicy: "no-cache" as MutationFetchPolicy
};

type CustomerSearchResponse = GqlResponse<TCICustomer[], "ciCustomersFilterBy">;

const CustomerSearch = ({
  onClose,
  onSubmit
}: {
  onClose: () => void;
  onSubmit: (elm: TSavingLetterSearchData) => void;
}) => {
  const [open, setOpen] = useState(true);
  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  dayjs.extend(weekday);
  dayjs.extend(localeData);

  const [customersList, setCustomersList] = useState<any[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<CIQOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { loading: customerSearchLoading, error: CustomerSearchError } =
    useQuery<CustomerSearchResponse>(CUSTOMER_SEARCH, {
      ...FETCH_POLICY_NO_CACHE,
      onCompleted: data => {
        setLoading(false);
        setCustomersList(
          data.ciCustomersFilterBy.map(cust => ({
            value: cust.displayName,
            label: cust.displayName,
            id: cust.id,
            key: cust.id
          }))
        );
      }
    });

  const isLoading = [loading, customerSearchLoading].some(elm => elm);
  const isError = [CustomerSearchError].some(e => e);

  return (
    <Modal
      title="Search a Customer"
      width={"700px"}
      height={"600px"}
      mask={true}
      open={open}
      onClose={() => {
        handleOnClose();
      }}>
      {isError && <CommonErrorComponent error={CustomerSearchError} />}

      {isLoading && <LoadingPanel />}

      <form
        onSubmit={(event: React.SyntheticEvent) => {
          event.preventDefault();
          const target = event.target as typeof event.target & {
            getElementsByClassName: (data: string) => {
              item: (data: number) => {
                getElementsByTagName: (data: string) => { value: Date }[];
              };
            };
          };
          const tempInpList = target
            .getElementsByClassName("month-range-wrapper")
            .item(0)
            .getElementsByTagName("input");
          const inpVal1 = tempInpList[0].value;
          const inpVal2 = tempInpList[1].value;

          onSubmit({
            selectedCustomerData: selectedCustomer,
            startMonth: inpVal1 ? dayjs(inpVal1).format("YYYY-MM-DD") : null,
            endMonth: inpVal2
              ? dayjs(inpVal2).format("YYYY-MM-") + dayjs(inpVal2).daysInMonth()
              : null
          });
          handleOnClose();
        }}>
        <FormField size={"medium"} id="customer-name-label" label="Customer name">
          <Select
            options={customersList}
            size={"medium"}
            id="customer-name"
            placeholder="Name, ID"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            mode="tags"
            labelInValue={true}
            onChange={data => {
              setSelectedCustomer(
                data.filter(function (elm: CIQOption) {
                  // used filter to remove undefined elements, which don't exists in list
                  return elm.key !== undefined;
                })
              );
            }}
          />
        </FormField>

        <FormField size={"medium"} id="month-range-label" label="Period">
          <div id="month-range-wrapper" className="month-range-wrapper">
            <RangeDatePicker
              placeholder={["Start Period", "End Period"]}
              picker="month"
              format="MMM YYYY"
              id="month-range"
            />
          </div>
        </FormField>
        <div style={{ paddingBottom: "200px" }}></div>
        <div className="from-button-wrap">
          <Button
            variant={Variants.Outlined}
            onClick={() => {
              handleOnClose();
            }}>
            Cancel
          </Button>
          <Button type="submit">Search</Button>
        </div>
      </form>
    </Modal>
  );
};

export default CustomerSearch;
