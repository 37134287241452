import GridActionBar from "shared/components/grid/GridActionBar";
import { TTicketSearchCriteria } from "ticketing/ticketing.types";
import { SearchTicketsForm } from "./SearchTicketsForm";

type TSearchTicketsContainerProps = {
  searchCriteria?: TTicketSearchCriteria;
  onSearch: (criteria: TTicketSearchCriteria) => void;
  open: boolean;
  onCollapsed: (collpased: boolean) => void;
  onReset: () => void;
  loading: boolean;
};

export default function SearchTicketsContainer({
  searchCriteria,
  onSearch,
  open,
  onCollapsed,
  onReset,
  loading
}: Readonly<TSearchTicketsContainerProps>) {
  // form style
  const icon = `k-icon ${
    open ? "k-i-arrow-double-60-up" : "k-i-arrow-double-60-down"
  } k-cursor-pointer k-pl-1 k-pr-1 k-color-primary`;

  const title = open ? "Collapse" : "Expand";

  // action bar
  const showHideAction = [{ icon, title, click: () => onCollapsed(!open) }];
  return (
    <div className={"search-wrapper"}>
      <div className={"card-container"}>
        <GridActionBar
          label={`Search Tickets`}
          actions={showHideAction}
          onClick={() => onCollapsed(!open)}
        />
        {/**Only hide on collpase other wise the search form will be dismounted and the search criteria will be reset as the state is lost */}
        <div style={{ padding: "5px" }} className={open ? "" : "k-hidden"}>
          <div className={"form-container"}>
            <SearchTicketsForm
              searchCriteria={searchCriteria}
              onSearch={onSearch}
              onReset={onReset}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
