import { Picklists, TMovementGroup, TTicket, TTicketInput, TVolume } from "ticketing/ticketing.types";
import { TEMPERATURE_MEASURE_NAME, equalsIgnoreCase, mapTempUOM } from "ticketing/utils";

const findMatchingVolumeFromVolumes = (
  volumes: TVolume[],
  movementGroup?: TMovementGroup | null,
  conversions?: Picklists.TUomConversion[]
): TVolume => {
  if (!movementGroup) {
    return volumes.at(0)!;
  }

  const activeMovement = movementGroup.activeMovement;
  const tempMeasure = activeMovement?.measures
    ?.find((m) => m?.measurementType?.name === TEMPERATURE_MEASURE_NAME);

  if (!tempMeasure) {
    return volumes.at(0)!;
  }
  const mackTempUom = mapTempUOM(tempMeasure.unitOfMeasure?.name);

  const fullyMatchedVolume = volumes.find((v) =>
    v.unitOfMeasure.id === activeMovement?.unitOfMeasure.id &&
    v.temperature === tempMeasure.value &&
    equalsIgnoreCase(v.temperatureUnitOfMeasure, mackTempUom)
  );

  if (fullyMatchedVolume) {
    return fullyMatchedVolume;
  }

  //find first matching volume by uom class
  const volumeMatchedByClass = volumes.find((v) =>
    v.unitOfMeasure?.unitOfMeasureClass?.name === activeMovement?.unitOfMeasure?.unitOfMeasureClass?.name &&
    v.temperature === tempMeasure.value &&
    equalsIgnoreCase(v.temperatureUnitOfMeasure, mackTempUom)
  );

  if (volumeMatchedByClass) {
    const converter = conversions?.find((c) =>
      c.srcUnitOfMeasure.id === volumeMatchedByClass?.unitOfMeasure.id &&
      c.destUnitOfMeasure.id === activeMovement?.unitOfMeasure.id
    );

    //should we skip this if a converter is not found???
    const factorByClass = converter?.factor ?? 1;
    return {
      ...volumeMatchedByClass,
      netVolume: volumeMatchedByClass.netVolume * factorByClass,
      grossVolume: (volumeMatchedByClass.grossVolume ?? 0) * factorByClass,
      unitOfMeasure: activeMovement?.unitOfMeasure,
    } as TVolume;
  }

  //find first matching volume by temperature or just the first volume
  const volumeMatchedByTemp = volumes.find(
    (v) =>
      v.temperature === tempMeasure.value &&
      equalsIgnoreCase(v.temperatureUnitOfMeasure, mackTempUom)
  ) ?? volumes.at(0)!

  const converterByTemp = conversions?.find((c) =>
    c.srcUnitOfMeasure.id === volumeMatchedByTemp?.unitOfMeasure.id &&
    c.destUnitOfMeasure.id === activeMovement?.unitOfMeasure.id
  );
  const factorByTemp = converterByTemp?.factor ?? 1;
  return {
    ...volumeMatchedByTemp,
    netVolume: volumeMatchedByTemp.netVolume * factorByTemp,
    grossVolume: (volumeMatchedByTemp.grossVolume ?? 0) * factorByTemp,
    unitOfMeasure: activeMovement?.unitOfMeasure,
  } as TVolume;
};


export const findMatchingVolumeInput = (
  ticket: TTicketInput,
  movementGroup?: TMovementGroup | null,
  conversions?: Picklists.TUomConversion[])
  : TVolume => {
  const ticketVolumes = ticket.volumes.map(v => ({
    id: v.id,
    sequence: v.sequence!,
    netVolume: v.netVolume!,
    unitOfMeasure: v.unitOfMeasure!,
    temperature: v.temperature!,
    temperatureUnitOfMeasure: v.temperatureUnitOfMeasure?.name!
  }));
  return findMatchingVolumeFromVolumes(ticketVolumes, movementGroup, conversions);
}

export const findMatchingVolume = (
  ticket: TTicket,
  movementGroup?: TMovementGroup | null,
  conversions?: Picklists.TUomConversion[]
): TVolume => {
  return findMatchingVolumeFromVolumes(ticket.volumes, movementGroup, conversions);
}