import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { FocusEvent, useContext, useState } from "react";

import { GridCellProps } from "@progress/kendo-react-grid";
import { BulkImportContext } from "../util";

export const TextCell = (props: GridCellProps) => {
  const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
  const { fieldConfig: allFieldConfig } = useContext(BulkImportContext);
  const fieldConfig = allFieldConfig
    ?.flatMap(f => [f, ...(f.children ?? [])])
    .find(f => f.name === field);
  const isInEdit = field === dataItem.inEdit;
  const initValue = field && dataItem[field] ? dataItem[field] : "";
  const [value, setValue] = useState(initValue);
  const navigationAttributes = useTableKeyboardNavigation(props.id);

  const onChange = (e: InputChangeEvent) => {
    setValue(e.value);
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        value,
        syntheticEvent: e
      });
    }
  };

  const defaultRendering = (
    <td
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      role={"gridcell"}
      {...navigationAttributes}>
      {isInEdit ? (
        <div>
          <Input
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className="bulk-import-cell-input"
          />
        </div>
      ) : (
        <span className="txt-preserve-whitespace">
          {fieldConfig?.isRequired && !value && (
            <span className="k-icon-xs k-icon bt-i-warning k-color-error"></span>
          )}
          <span>{value}</span>
        </span>
      )}
    </td>
  );

  return render?.(defaultRendering, props) ?? <td></td>;
};
