import { useState } from "react";
import Downloader, { IDownloadFile } from "../../shared/components/Downloader";

const useFileDownloader = () => {
  const [files, setFiles] = useState((): IDownloadFile[] => []);

  const download = (file: IDownloadFile) => {
    //just a fabricated Id to refer to download just in case we have multiple downloads
    const downloadId = Date.now();
    setFiles(fileList => [...fileList, { ...file, downloadId }]);
  };

  const remove = (removeId: number) =>
    setFiles(curr => [...curr.filter(file => file.downloadId !== removeId)]);

  return [
    (e: IDownloadFile) => download(e),
    files.length > 0 ? <Downloader files={files} remove={(e: number) => remove(e)} /> : <></>
  ] as const;
};

export default useFileDownloader;
