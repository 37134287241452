import { Button } from "@progress/kendo-react-buttons";
import {
  GridColumn as Column,
  Grid,
  GridDetailRowProps,
  GridExpandChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { useEffect, useState } from "react";
import { TTicketSaveResult } from "./types";

const KEY_FIELD = "id";
const EXPAND_FIELD = "expanded";

const DetailComponent = (props: GridDetailRowProps) => {
  const data = props.dataItem.volumes;
  if (data) {
    return (
      <div
        className="bulk-import-tickets-page"
        style={{ backgroundColor: "rgb(28, 31, 32)" }}
      >
        <Grid
          data={data}
          style={{
            width: "800px",
            fontSize: "0.75rem",
            border: "1px solid darkslategray",
          }}
          scrollable={"none"}
        >
          <Column field="netVolume" title="Net" width="120px" />
          <Column field="grossVolume" title="Gross" />
          <Column field="unitOfMeasure.name" title="Unit of measure" />
          <Column field="temperature" title="Temperature" />
          <Column
            field="temperatureUnitOfMeasure"
            title="Temperature Unit of measure"
          />
        </Grid>
      </div>
    );
  }
  return <></>;
};

export const TicketResultsGrid = (props: {
  results: TTicketSaveResult[] | undefined;
  onReset: () => void;
}) => {
  const [tickets, setTickets] = useState<TTicketSaveResult[]>();

  useEffect(() => {
    setTickets(props.results);
  }, [props.results]);

  const expandChange = (event: GridExpandChangeEvent) => {
    setTickets((ts) =>
      ts?.map((item: TTicketSaveResult) => {
        if (item[KEY_FIELD] === event.dataItem[KEY_FIELD]) {
          item[EXPAND_FIELD] = !event.dataItem[EXPAND_FIELD];
        }
        return item;
      })
    );
  };

  return (
    <div className={"content-wrapper"}>
      <div className={"grid-wrapper"}>
        <div className={"grid-container"}>
          <div className={"card-container"}>
            <Grid
              data={tickets}
              style={{ height: "calc(100vh - 112px)", width: "100%" }}
              expandField={EXPAND_FIELD}
              detail={DetailComponent}
              dataItemKey={KEY_FIELD}
              onExpandChange={expandChange}
            >
              <GridToolbar>
                <span className="k-color-primary">
                  Number of Records Saved:
                </span>
                <span style={{ color: "white", paddingLeft: "0" }}>
                  {props?.results?.length ?? 0}
                </span>
                <Button
                  title="Reset"
                  className="k-button-md k-rounded-md k-button-solid k-button-solid-primary bulk-tickets-cta-btn"
                  onClick={props.onReset}
                >
                  Reset
                </Button>
              </GridToolbar>
              <Column field={"id"} title="Ticket Id" width={"80px"} />
              <Column
                field={"ticketNumber"}
                title="Ticket Number"
                width={"120px"}
              />
              <Column
                field={"ticketDate"}
                title="Ticket Date"
                width={"120px"}
              />
              <Column field={"status"} title="Status" width={"120px"} />
              <Column
                field={"deliveryId"}
                title="Delivery Id"
                width={"120px"}
              />
              <Column
                field={"modeOfTransport.name"}
                title="Mode Of Transport"
                width={"120px"}
              />
              <Column field={"batch.name"} title="Batch" width={"144px"} />
              <Column field={"carrier.name"} title="Carrier" width={"144px"} />
              <Column field={"product.name"} title="Product" width={"160px"} />
              <Column
                field={"facility.name"}
                title="Facility"
                width={"160px"}
              />
              <Column field={"logisticsSystem.name"} title="Logistics System" />
              <Column
                field={"shipFromCode.code"}
                title="Ship From Code"
                width={"96px"}
              />
              <Column
                field={"shipToCode.code"}
                title="Ship To Code"
                width={"96px"}
              />
              <Column field={"poNumber"} title="PO Number" width={"96px"} />
              <Column
                field={"document.name"}
                title="PDF File Name"
                width={"144px"}
              />
              <Column
                field={"borderCrossingDate"}
                title="Border Crossing Date"
                width={"120px"}
              />
              <Column field={"invoiceComment"} title="Invoice Comment" />
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};
