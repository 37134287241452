import { INotificationState } from "../../_redux/IReduxState";
import { RESET_NOTIFICATION_BADGE } from "./notificationActionTypes";

export default function notificationsReducer(
  state: INotificationState = { resetBadge: false },
  action: { type: string; payload: { resetBadge: boolean } }
) {
  if (action.type === RESET_NOTIFICATION_BADGE) {
    return {
      ...state,
      resetBadge: action.payload.resetBadge,
    };
  }
  else {
    return state;
  }
}
