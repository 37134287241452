import { toString as toKString } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { TMovement } from "ticketing/ticketing.types";
import { TEMPERATURE_MEASURE_NAME, equalsIgnoreCase, mapTempUOM } from "ticketing/utils";
import RevisionsMovement from "./RevisionsMovement";

export const MovementChildHeader = ({
  childMovement
}: {
  childMovement: Partial<TMovement>;
}) => {
  const [showNomRevisions, setShowNomRevisions] = useState(false);
  const scheduledQuantity = childMovement?.scheduledQuantity ?? 0;
  const actualizedQuantity = childMovement.actualizedQuantity ?? 0;
  const remainingQuantityMax = Math.max(0, scheduledQuantity - actualizedQuantity);
  const temperatureMeasure = childMovement.measures?.find(measure =>
    equalsIgnoreCase(measure.measurementType.name, TEMPERATURE_MEASURE_NAME)
  );

  const temperatureData = `${temperatureMeasure?.value ?? "?"} ${mapTempUOM(
    temperatureMeasure?.unitOfMeasure?.name ?? "?"
  )?.charAt(0)}`;

  return (
    <div className="movement-group-header-container">
      <div style={{ display: "flex" }}>
        <div className="delivery-id-label-container" key={`${childMovement.id}`}>
          <span role="none" style={{ borderRadius: "0", userSelect: "none" }}>
            {`${childMovement.enterpriseSystemCode} ${childMovement.internalLegalEntity?.name}`}
          </span>
          <Button
            fillMode={"flat"}
            icon="copy"
            className="copy-btn"
            onClick={e => {
              navigator.clipboard.writeText(childMovement.enterpriseSystemCode ?? "");
              e.stopPropagation();
            }}
          />
        </div>
      </div>

      <div className="movement-group-label-container">
        <Label>
          <span className="k-white-space-pre">{"Scheduled: "}</span>
          <span style={{ color: "var(--primary-icon-color)" }}>
            {toKString(scheduledQuantity, "0.0###")}
          </span>
        </Label>

        <Label>
          <span className="k-white-space-pre">{"Actualized: "}</span>
          <span style={{ color: "limegreen" }}>{toKString(actualizedQuantity, "0.0###")}</span>
        </Label>

        {/* 1477660 - if actualization complete is true hide the remaining quantity */}
        {!childMovement?.actualizationComplete && (
          <Label>
            <span className="k-white-space-pre">{"Remaining: "}</span>
            <span style={{ color: "orangered" }}>
              {toKString(remainingQuantityMax, "0.0###")}
            </span>
          </Label>
        )}
        <Label>{childMovement.unitOfMeasure?.name}</Label>
        <Label>{temperatureData}</Label>
        <Label style={{ textDecoration: "underline", textTransform: "uppercase" }}>
          {childMovement.status?.name}
        </Label>
      </div>
      <div style={{ display: "flex" }}>
        {showNomRevisions && (
          <RevisionsMovement
            onClose={() => setShowNomRevisions(false)}
            enterpriseSystemCode={childMovement.enterpriseSystemCode!}
            movementId={childMovement.id!}></RevisionsMovement>
        )}
        <Button
          title="Movement Revisions"
          fillMode={"flat"}
          icon="clock"
          style={{ color: "var(--primary-icon-color)" }}
          onClick={() => setShowNomRevisions(true)}></Button>
      </div>
    </div>
  );
};
