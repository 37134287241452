import { Button, Flexbox, Variants } from "@sede-x/shell-ds-react-framework";
import {
  ArrowLeft,
  ArrowRight
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";

type TTrackerPageProps = {
  onPrevious: () => void;
  onNext: () => void;
};
export const TrackerPager = ({ onPrevious, onNext }: TTrackerPageProps) => {
  return (
    <Flexbox justifyContent={"space-between"}>
      <Button
        title="Previous Page"
        variant={Variants.Transparent}
        icon={<ArrowLeft />}
        iconPosition="left"
        onClick={onPrevious}>
        Previous
      </Button>
      <Button
        title="Next Page"
        variant={Variants.Transparent}
        icon={<ArrowRight />}
        iconPosition="right"
        onClick={onNext}>
        Next
      </Button>
    </Flexbox>
  );
};
