import "./Auth.css";

const LoggingIn = () => {
  return (
    <div className="stage">
      <div className="spinner"></div>
      <div>Please wait while you are being authenticated</div>
      <div className="dot-pulse"></div>
    </div>
  );
};
export default LoggingIn;
