import { NavBar } from "@sede-x/shell-ds-react-framework";
import {
  Calendar,
  ContentSolid,
  CopyFileSolid,
  CopySolid,
  Database
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  ItemRenderInfo,
  ItemType
} from "@sede-x/shell-ds-react-framework/build/esm/components/Menu/Menu.types";
import { Link } from "react-router-dom";

const menuItems: ItemType[] = [
  {
    label: "Oubound Movements",
    itemIcon: <Calendar />,
    key: "1-1",
    path: "/ticketing/trackers/outboundMovements"
  },
  {
    label: "Oubound Batches",
    itemIcon: <CopySolid />,
    key: "1-2",
    path: "/ticketing/trackers/outboundBatches"
  },
  {
    label: "Inbound Cargos",
    itemIcon: <CopyFileSolid />,
    key: "1-3",
    path: "/ticketing/trackers/inboundCargos"
  },
  {
    label: "Inbound Contracts",
    itemIcon: <ContentSolid />,
    key: "1-4",
    path: "/ticketing/trackers/inboundContracts"
  },
  {
    label: "Inbound Refdata",
    itemIcon: <Database />,
    key: "1-5",
    path: "/ticketing/trackers/inboundRefdata"
  }
];

const navbarItemRenderer = ({ path, children }: ItemRenderInfo) => (
  <Link to={path}>{children}</Link>
);

export const TrackerNavbar = ({ selectedPath }: { selectedPath: string }) => {
  return (
    <NavBar
      items={menuItems}
      itemRender={navbarItemRenderer}
      selectedPath={selectedPath}
      pectenArea={"none"}
    />
  );
};
