import { useSubscription } from "@apollo/client";
import { Sentiments } from "@sede-x/shell-ds-react-framework";
import { loader } from "graphql.macro";
import { useCallback, useState } from "react";
import { Notification, NotificationGroup } from "../../shared/components/Notification";
import { INotification } from "../models";

const NEW_NOTIFICATIONS_SUBSCRIPTION = loader(
  "../graphql/subscription-new-notifications.graphql"
);

interface NotificationData {
  onNewNotification: INotification;
}

const PushNotification = () => {
  useSubscription<NotificationData>(NEW_NOTIFICATIONS_SUBSCRIPTION, {
    onData: response => {
      if (response.data.data?.onNewNotification) {
        const notification = response.data.data?.onNewNotification;
        setNotifications(ns => ns.concat(notification));
      }
    }
  });

  const [notifications, setNotifications] = useState<INotification[]>([]);

  const onCloseNotification = useCallback((m: INotification) => {
    setNotifications(ns => ns.filter(n => n.id !== m.id));
  }, []);

  return (
    <NotificationGroup>
      {notifications.map(m => (
        <Notification
          message={m.title}
          sentiment={Sentiments.Information}
          onClose={() => onCloseNotification(m)}
          key={m.id}
        />
      ))}
    </NotificationGroup>
  );
};

export default PushNotification;
