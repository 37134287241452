import { useMutation } from "@apollo/client";
import {
  Alert,
  Button,
  FormField,
  Modal,
  Sentiments,
  TextInput,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { loader } from "graphql.macro";
import { useState } from "react";
import LoadingPanel from "shared/components/LoadingPanel";

const resendCarbonSavingsDocumentEmail = loader("../graphql/sendEmail.graphql");

const SendEmail = ({
  onClose,
  onSubmit,
  userData
}: {
  onClose: any;
  onSubmit: any;
  userData: any;
}) => {
  const [open, setOpen] = useState(true);
  const handleOnClose = () => {
    setLoading(false);
    if (onClose) onClose();
    setOpen(false);
  };

  const [reSendEmail, { loading: reSendEmailLoading, error: reSendEmailError }] = useMutation(
    resendCarbonSavingsDocumentEmail,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => handleOnClose(),
      onError: () => {
        handleOnClose();
      }
    }
  );

  const [toEmailList, setToEmailList] = useState([userData?.customer?.email ?? ""]);
  const [ccEmailList, setCcEmailList] = useState([""]);
  const [loading, setLoading] = useState(false);

  const isLoading = [loading, reSendEmailLoading].some(elm => elm);

  return (
    <Modal
      title="Send Email"
      width={"700px"}
      height={"600px"}
      mask={true}
      open={open}
      onClose={() => {
        handleOnClose();
      }}>
      {reSendEmailError && (
        <Alert solidBgColor={true} sentiment={Sentiments.Negative}>
          Error Occured. Please try again
        </Alert>
      )}
      <br />
      <form
        onSubmit={event => {
          event.preventDefault();
          setLoading(true);

          reSendEmail({
            variables: {
              documentId: userData?.id,
              toEmailAddress: toEmailList,
              ccEmailAddress: ccEmailList
            }
          });
        }}>
        <FormField size={"medium"} id="customer-name" label="To Email ID's">
          <TextInput
            size={"medium"}
            id="to-email"
            placeholder="Email ID"
            value={toEmailList[0]}
            onChange={event => setToEmailList([event.target.value.trim()])}
          />
        </FormField>

        <FormField size={"medium"} id="customer-name" label="CC Email ID's">
          <TextInput
            size={"medium"}
            id="cc-email"
            placeholder="Email ID"
            value={ccEmailList[0]}
            onChange={event => setCcEmailList([event.target.value.trim()])}
          />
        </FormField>
        <div className="from-button-wrap">
          <Button
            variant={Variants.Outlined}
            onClick={() => {
              handleOnClose();
            }}>
            Cancel
          </Button>
          <Button type="submit">Send</Button>
        </div>
      </form>

      {isLoading && <LoadingPanel />}
    </Modal>
  );
};

export default SendEmail;
