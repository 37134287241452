import { Button } from "@progress/kendo-react-buttons";
import { JsonView, collapseAllNested, darkStyles, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import { useTheme } from "shared/contexts/ThemeContext";

import { TMovement } from "ticketing/ticketing.types";

const removeTypeNameFromGQLResult = (result: Record<string, unknown>) => {
  return JSON.parse(
    JSON.stringify(result, (key, value) => {
      if (key === "__typename") {
        return undefined;
      }
      return value;
    })
  );
};

export const MovementDebugInfo = ({
  movement,
  onClose
}: {
  movement: TMovement;
  onClose: () => void;
}) => {
  const { theme } = useTheme();
  const copyContent = () => {
    navigator.clipboard.writeText(JSON.stringify(movement, null, 2));
  };

  const customStyles = {
    ...(theme === "light" ? defaultStyles : darkStyles),
    container: "movement-degug-info",
    basicChildStyle: "basic-element-style"
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        maxHeight: "minmax(296px, 100%)",
        margin: "2px",
        flexDirection: "column"
      }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "2px",
          backgroundColor: "var(--nav-selected-bg)"
        }}>
        <Button themeColor={"tertiary"} onClick={copyContent} icon="copy" fillMode={"flat"} />
        <Button themeColor={"primary"} onClick={onClose} icon="close" className="theme-btn-red">
          Close
        </Button>
      </div>
      <JsonView
        data={removeTypeNameFromGQLResult(movement)}
        shouldExpandNode={collapseAllNested}
        style={customStyles}
        clickToExpandNode={true}
      />
    </div>
  );
};
