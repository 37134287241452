import "@progress/kendo-date-math/tz/America/Chicago";
import { Button } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  ComboBox,
  MultiSelect,
  MultiSelectFilterChangeEvent
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  FieldRenderProps,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent
} from "@progress/kendo-react-form";
import { InputClearValue, TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { xIcon } from "@progress/kendo-svg-icons";
import { useRef, useState } from "react";
import LoadingPanel from "shared/components/LoadingPanel";
import { dateRangeValidator, requiredValidator } from "shared/validators";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import useSearchBatches from "ticketing/hooks/useSearchBatches";
import { TicketSource, TicketStatus, TTicketSearchCriteria } from "ticketing/ticketing.types";
import "./SearchTickets.css";

type TSearchTicketsProps = {
  searchCriteria?: TTicketSearchCriteria;
  onSearch: (criteria: TTicketSearchCriteria) => void;
  onReset?: () => void;
  loading: boolean;
};
const MIN_FILTER_LENGTH = 3;
const FORM_INPUT_THEME = "theme-form-input";

const MyCustomInputSuffix = ({
  fieldRenderProps,
  onClear
}: {
  fieldRenderProps: FieldRenderProps;
  onClear: () => void;
}) => {
  const { value } = fieldRenderProps;
  return (
    <InputClearValue onClick={onClear} hidden={!value}>
      <SvgIcon icon={xIcon} />
    </InputClearValue>
  );
};

const makeSuffix = ({
  fieldRenderProps,
  onClear
}: {
  fieldRenderProps: FieldRenderProps;
  onClear: () => void;
}) => <MyCustomInputSuffix fieldRenderProps={{ ...fieldRenderProps }} onClear={onClear} />;

const MyCustomInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, value, ...rest } = fieldRenderProps;
  const textRef = useRef(null);
  const onClear = () => fieldRenderProps?.onChange({ target: textRef, value: "" });
  return (
    <TextBox
      value={value ?? ""}
      ref={textRef}
      {...rest}
      suffix={() => makeSuffix({ fieldRenderProps, onClear })}
    />
  );
};

/**
 *
 * @param props
 * @returns
 */
export const SearchTicketsForm = (props: TSearchTicketsProps) => {
  const { picklists, isLoading, isReady } = usePicklists();
  const [deliveryIds, setDeliveryIds] = useState("");

  const onSubmitClick = (e: FormSubmitClickEvent) => {
    //shallow copy...to force search
    props.onSearch?.({ ...e.values } as TTicketSearchCriteria);
  };

  const onReset = (formRenderProps: FormRenderProps) => {
    props.onReset?.();
    formRenderProps.onFormReset();
  };

  const [searchBatches, batchSearchResults, bLoading] = useSearchBatches();

  const onFilterBatches = (e: MultiSelectFilterChangeEvent) => {
    if (e.filter.value.length >= MIN_FILTER_LENGTH) {
      const value = e.filter.value;
      searchBatches(value);
    }
  };

  const onDeliveryIdChange = (e: TextBoxChangeEvent) => {
    setDeliveryIds(e.value as string);
  };

  const hasDeliveryIds = (deliveryIds.trim()?.length ?? 0) > 0;

  if (isLoading || !isReady) {
    return <LoadingPanel />;
  }

  return (
    <Form
      onSubmitClick={onSubmitClick}
      initialValues={props.searchCriteria}
      validator={dateRangeValidator}
      key={JSON.stringify(props.searchCriteria)}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement horizontal={true} className="ticket-search-form">
          <Label editorId={"startDate"}>*Start&nbsp;Date</Label>
          <Field
            id={"startDate"}
            name={"startDate"}
            component={DatePicker}
            validator={requiredValidator}
            className={FORM_INPUT_THEME}
            disabled={hasDeliveryIds}
          />
          <Label>*End&nbsp;Date</Label>
          <Field
            id={"endDate"}
            name={"endDate"}
            component={DatePicker}
            validator={requiredValidator}
            className={FORM_INPUT_THEME}
            disabled={hasDeliveryIds}
          />
          <Label>Batch&nbsp;Name</Label>
          <Field
            id={"batches"}
            name={"batches"}
            component={MultiSelect}
            popupSettings={{ popupClass: "m-popup" }}
            textField="name"
            dataItemKey="id"
            data={batchSearchResults}
            autoClose={false}
            filterable={true}
            onFilterChange={onFilterBatches}
            loading={bLoading}
            className={FORM_INPUT_THEME}
            disabled={hasDeliveryIds}
          />
          <Label>MoT</Label>
          <Field
            id={"modeOfTransports"}
            name={"modeOfTransports"}
            component={MultiSelect}
            popupSettings={{ popupClass: "m-popup" }}
            textField="name"
            dataItemKey="id"
            data={picklists?.modeOfTransports}
            className={FORM_INPUT_THEME}
            disabled={hasDeliveryIds}
          />
          <Label>Source</Label>
          <Field
            id={"ticketSource"}
            name={"ticketSource"}
            component={MultiSelect}
            popupSettings={{ popupClass: "m-popup" }}
            data={Object.keys(TicketSource)}
            className={FORM_INPUT_THEME}
            disabled={hasDeliveryIds}
          />
          <Label>Status</Label>
          <Field
            id={"ticketStatus"}
            name={"ticketStatus"}
            component={ComboBox}
            data={Object.values(TicketStatus).filter(v => v !== TicketStatus.Cancelled)}
            className={FORM_INPUT_THEME}
            disabled={hasDeliveryIds}
          />
          <Label>Ticket#</Label>
          <Field
            id={"ticketNumbers"}
            name={"ticketNumbers"}
            component={MyCustomInput}
            placeholder="comma separated ticket numbers"
            className={FORM_INPUT_THEME}
            autoComplete={"off"}
          />
          <Label>Last Modified By</Label>
          <Field
            id={"lastModifiedBy"}
            name={"lastModifiedBy"}
            component={MyCustomInput}
            placeholder="comma separated user ids"
            className={FORM_INPUT_THEME}
            disabled={hasDeliveryIds}
            autoComplete={"off"}
          />
          <Label>Delivery Ids</Label>
          <Field
            id={"deliveryIds"}
            name={"deliveryIds"}
            component={MyCustomInput}
            placeholder="comma separated delivery ids"
            className={FORM_INPUT_THEME}
            autoComplete={"off"}
            onChange={onDeliveryIdChange}
          />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, .5fr)",
              gridColumnGap: "4px"
            }}>
            <Button
              themeColor={"primary"}
              icon="search"
              type={"submit"}
              disabled={!formRenderProps.valid || props.loading}
              className={"theme-btn-yellow"}>
              Search
            </Button>

            <Button
              className={"theme-btn-yellow"}
              icon="reset"
              disabled={props.loading}
              onClick={_e => {
                onReset(formRenderProps);
              }}>
              Reset
            </Button>
          </div>
        </FormElement>
      )}
    />
  );
};
