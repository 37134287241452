import { useQuery } from "@apollo/client";
import { BaseTable, Flexbox } from "@sede-x/shell-ds-react-framework";
import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import { loader } from "graphql.macro";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import NoDataTableImage from "shared/components/basetable/NoDataTableImage";
import GlobalHeader from "shared/components/GlobalHeader";
import LoadingPanel from "shared/components/LoadingPanel";
import { GqlResponse } from "ticketing/ticketing.types";
import { DateCell, makeButtonCell, TextCell } from "./cells";
import { InboundRefdataSearch } from "./InboundRefdataSearch";
import { RefdataJsonViewer } from "./RefdataJsonViewer";
import { SearchButton } from "./SearchButton";
import {
  PagedConnection,
  TInboundRefdataRequest,
  TInboundRefdataSearchCriteria
} from "./tracker.types";
import { TrackerNavbar } from "./TrackerNavbar";
import { TrackerPager } from "./TrackerPager";
import { FETCH_POLICY_NO_CACHE, toInboundRefdataRequestFilterInput } from "./util";

const inboundRefdataUpdateRequestsFilterBy = loader(
  "./graphql/refDataMessagesFilterBy.graphql"
);

type TInboundRefdataFilterByResponse = GqlResponse<
  PagedConnection<TInboundRefdataRequest>,
  "refDataMessagesFilterBy"
>;
type TSearchState = {
  showModal: boolean;
  criteria: TInboundRefdataSearchCriteria;
};

const pathName = "/ticketing/trackers/inboundRefdata";

const PADDING = 48;
const DEFULT_PAGE_SIZE = 25;
const INIT_SEARCH_CRITERIA = {
  startDate: dayjs().add(-1, "day").startOf("day").toISOString(),
  endDate: dayjs().endOf("day").toISOString()
};

export const InboundRefdataTracker = () => {
  const [searchModalState, setSearchModalState] = useState<TSearchState>({
    showModal: false,
    criteria: INIT_SEARCH_CRITERIA
  });

  const [showMessage, setShowMessage] = useState({ show: false, requestId: "" });

  //Fetching Carbon IQ  Product Blend data
  const { loading, error, data, refetch } = useQuery<TInboundRefdataFilterByResponse>(
    inboundRefdataUpdateRequestsFilterBy,
    {
      fetchPolicy: FETCH_POLICY_NO_CACHE,
      variables: {
        filter: toInboundRefdataRequestFilterInput({ ...INIT_SEARCH_CRITERIA }),
        first: DEFULT_PAGE_SIZE
      }
    }
  );
  const onShowMessage = useCallback((refdataRequest: TInboundRefdataRequest) => {
    setShowMessage({ show: true, requestId: refdataRequest.id });
  }, []);

  const gridColumns: ColumnDef<TInboundRefdataRequest>[] = useMemo(
    () => [
      {
        key: "1",
        header: "Message Id",
        accessorKey: "messageId",
        size: 120,
        cell: TextCell
      },
      {
        header: "Seq",
        accessorKey: "messageSeqNumber",
        size: 60,
        cell: TextCell
      },
      {
        header: "Entity Name",
        accessorKey: "entityName",
        size: 200,
        cell: TextCell
      },
      {
        header: "Queued At",
        accessorKey: "receivedAt",
        size: 160,
        cell: DateCell
      },
      {
        header: "Processed At",
        accessorKey: "processedAt",
        size: 160,
        cell: DateCell
      },
      {
        header: "Status",
        accessorKey: "status",
        size: 120,
        cell: TextCell
      },
      {
        header: "Reason",
        accessorKey: "statusReason",
        cell: TextCell
      },
      {
        header: "Json",
        accessorKey: "id",
        cell: makeButtonCell(onShowMessage)
      }
    ],
    [onShowMessage]
  );

  const searchFormRef = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<number>(0);
  useEffect(() => {
    const { innerHeight } = window;
    setTop(
      innerHeight -
        (searchFormRef?.current?.getBoundingClientRect().top ?? 0) -
        (searchFormRef?.current?.getBoundingClientRect().height ?? 0) -
        PADDING
    );
  }, [searchFormRef]);

  const onPrevious = useCallback(() => {
    const startCursor = data?.refDataMessagesFilterBy?.pageInfo?.startCursor;
    refetch({
      before: startCursor ?? null,
      after: null,
      last: startCursor ? DEFULT_PAGE_SIZE : null,
      first: !startCursor ? DEFULT_PAGE_SIZE : null
    });
  }, [refetch, data]);

  const onNext = useCallback(() => {
    const endCursor = data?.refDataMessagesFilterBy?.pageInfo?.endCursor;
    refetch({
      before: null,
      after: endCursor ?? null,
      first: DEFULT_PAGE_SIZE,
      last: null
    });
  }, [refetch, data]);

  return (
    <div>
      <GlobalHeader
        pageName="Inbound refdata updates"
        buttonContent={[
          <SearchButton
            key={"searchButton"}
            onClick={() => setSearchModalState({ ...searchModalState, showModal: true })}
          />
        ]}
      />
      <TrackerNavbar selectedPath={pathName} />
      {loading && <LoadingPanel />}
      {error && <ApolloErrorViewer error={error} />}
      <div ref={searchFormRef}>
        <InboundRefdataSearch
          open={searchModalState.showModal}
          searchCriteria={searchModalState.criteria}
          onClose={() => setSearchModalState({ ...searchModalState, showModal: false })}
          onSubmit={criteria => {
            setSearchModalState({ showModal: false, criteria });
            refetch({ filter: toInboundRefdataRequestFilterInput(criteria) });
          }}
        />
      </div>

      <Flexbox flexDirection="column" gap="8px">
        <BaseTable
          className="carboniq-data-table"
          size={"small"}
          useColumnsSizing={true}
          columns={gridColumns}
          maxHeight={top}
          data={data?.refDataMessagesFilterBy.edges.flatMap(e => e.node)}
          emptyStateProps={{ image: <NoDataTableImage /> }}
        />
        <TrackerPager onPrevious={onPrevious} onNext={onNext} />
      </Flexbox>
      {showMessage.show && (
        <RefdataJsonViewer
          requestId={showMessage.requestId}
          open={showMessage.show}
          onClose={() => setShowMessage({ show: false, requestId: "" })}
        />
      )}
    </div>
  );
};
