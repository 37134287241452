import { Badge, Sentiments, Variants } from "@sede-x/shell-ds-react-framework";
import { Search } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";

export const SearchButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Badge
      key={1}
      icon={<Search />}
      sentiment={Sentiments.Information}
      variant={Variants.Filled}
      onClick={onClick}
    />
  );
};
