import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import GlobalHeader from "shared/components/GlobalHeader";
import { IAppState } from "../../_redux/IReduxState";
import { actionCreator } from "../../_redux/actionCreator";
import Dialog from "../../shared/components/GridDialog";
import LoadingPanel from "../../shared/components/LoadingPanel";
import GridWrapper from "../../shared/components/grid/GridWrapper";
import { IForecastSearchCriteria, IForecastSummary } from "../models";
import { fetchForecastSummary } from "../util/apiHandler";
import { FORECAST_CRITERIA } from "./../state/forecastActionTypes";
import ForecastSearchForm from "./forecastSearch/ForecastSearchForm";

const Forecast = () => {
  const dispatch = useDispatch();
  const forecast = useSelector((state: IAppState) => state.forecast);

  const hasData = useCallback(() => {
    const hasMonthlyData =
      (forecast?.monthlySummary?.data?.length ?? 0) > 0 &&
      forecast?.monthlySummary?.data[0].monthName !== "Initial";
    const hasDailyData =
      (forecast?.dailySummary?.data?.length ?? 0) > 0 &&
      forecast?.dailySummary?.data[0].monthName !== "Initial";
    return hasMonthlyData || hasDailyData;
  }, [forecast.monthlySummary, forecast.dailySummary]);

  const hasError = () => forecast.error && !forecast.errorNotified;

  const [open, setOpen] = useState(!hasData());

  useEffect(() => {
    setOpen(!hasData());
  }, [hasData]);

  const [showDialog, setShowDialog] = useState(false);

  const onDialogClose = () => {
    dispatch(actionCreator("errorNotified"));
  };

  const onUnSavedDataDialogClose = () => {
    setShowDialog(false);
    setOpen(false);
  };

  const onSubmit = async (e: IForecastSearchCriteria) => {
    const summary = forecast.isMonthlyDisplayMode
      ? forecast.monthlySummary
      : forecast.dailySummary;
    const inEdit = !!summary?.inEdit;

    if (inEdit) {
      // grid in edit, unsaved data, block submit
      setShowDialog(true);
    } else {
      dispatch(actionCreator(FORECAST_CRITERIA, e));

      await fetchForecastSummary(e.isMonthlyDisplayMode, e);
    }
  };

  const onSearchOpen = async (e: boolean) => setOpen(e);

  return (
    <div id="Forecast" className="forecast-page">
      <GlobalHeader pageName="Forecast" />

      {hasError() && (
        <Dialog title="Error" text={forecast.error.message} onClose={onDialogClose} />
      )}

      {showDialog && (
        <Dialog
          title="Warning"
          text="You have unsaved data."
          onClose={onUnSavedDataDialogClose}
        />
      )}

      {forecast.loading && <LoadingPanel />}
      {/* <GridCriteria /> */}
      <ForecastSearchForm
        showMonthlyToggle={true}
        onSearch={onSubmit}
        isOpen={open}
        onOpen={onSearchOpen}
      />
      {!forecast.loading && <ErrorHandler />}
      {hasData() && <GridWrapper isSearchOpen={open} />}
    </div>
  );
};
export default Forecast;

//=============================
// Error Handler
//=============================
const ErrorHandler = () => {
  const forecast = useSelector((state: IAppState) => state.forecast);
  const { picklists } = forecast;
  const isMonthlyDisplayMode = forecast.isMonthlyDisplayMode;
  const summary: IForecastSummary = isMonthlyDisplayMode
    ? forecast.monthlySummary
    : forecast.dailySummary;

  const iconStyle = { padding: "0 10px 3px" };
  const warningStyle = { color: "#ff8100" };
  const errorStyle = { color: "red" };

  let icon = "k-icon ";
  let type = "";
  let errMsg = "";

  if (forecast.loading) {
    return <></>;
  }

  if (summary) {
    if (!summary.data) {
      errMsg = "Unable to fetch the forecast summary, please try again later.";
      icon += "k-i-error";
      type = "error";
    } else if (summary.data?.length === 0 && summary.data[0]?.monthName !== "Initial") {
      errMsg = "No data found, please try again.";
      icon += "k-i-info";
      type = "warning";
    }
  } else if (!picklists) {
    errMsg = "Unable to fetch forecast search options.";
    icon += "k-i-info";
    type = "warning";
  }

  if (errMsg !== "") {
    return (
      <div className={"form-error"} style={type === "warning" ? warningStyle : errorStyle}>
        <span className={icon} style={iconStyle}></span>
        {errMsg}
      </div>
    );
  } else {
    return <div />;
  }
};
