import { sumOfValues } from "../../shared/algorithms";
import * as enums from "../../shared/enums";
import {
  IForecastDailySummary,
  IForecastMonthlySummary,
  IForecastSummary,
} from "../models";

/** invoked  */
export const activeRow = (dataSet: IForecastSummary): any | null => {
  // export const activeRow = (dataSet: IForecastSummary) => {
  let result = null;
  if (dataSet) {
    result = dataSet.data!.filter((item: any) => item.active);
    return result[0];
  }
  return result;
};

//TODO: verify this is not used
// export const getActiveRow = (data: any[]) => {
//   //  debugger;
//     let result = null;
//     if (data) {
//         result = data.filter((item) => item.active);
//         return result[0];
//     }
//     return result;
// }

export const removeActiveRow = (data: any[]) => {
  //  debugger;
  data?.forEach((item) => {
    item.active = false;
  });
};

export const setActiveRow = (
  data: any[],
  dataItem: IForecastMonthlySummary | IForecastDailySummary
) => {
  //  debugger;
  removeActiveRow(data);
  dataItem.active = true;
};

export const enterEdit = (
  data: any[],
  dataItem: { Id: string | number },
  field: string
) => {
  ////  debugger;
  data.forEach(
    (item: any) => (item.inEdit = item.Id === dataItem.Id ? field : undefined)
  );
};

export const exitEdit = (data: any[]) => {
  data.forEach((item: { inEdit: string }) => (item.inEdit = ""));
};

export const itemEdited = (
  item: { [x: string]: any; old: null | undefined | Object },
  value: any
) => {
  // debugger;
  // let field = enums.FORECAST_APPROVED_VOLUME
  // debugger;
  if (item.old === undefined || item.old === null) {
    item.old = { ...item };
  }
  item["edited"] = true;
  item[enums.FORECAST_APPROVED_VOLUME] = value;
  return item;
};

export const itemTouched = (item: any, value: any) => {
  // export const itemTouched = (item: { [x: string]: any; old: null | undefined | Object; }, value: any) => {
  //  debugger;
  let field = enums.FORECAST_APPROVED_VOLUME;

  if (item.old === undefined || item.old === null) {
    item.old = { ...item };
  }
  item["touched"] = true;
  item[field] = value;
  return item;
};

// export const cancelEdit = (data: any) => {
export const cancelEdit = (
  data:
    | IForecastMonthlySummary[]
    | {
        [x: string]: any;
        base: { data: any };
        details: { data: any };
        old: { [x: string]: any } | null | undefined;
        edited: boolean;
        touched: boolean;
        selected: boolean;
        inEdit: string;
        error: null;
      }[]
) => {
  //  debugger;

  let vfield = enums.FORECAST_APPROVED_VOLUME;
  let pfield = enums.FORECAST_APPROVED_PERCENTAGE;

  data.forEach((item: any) => {
    // console.warn('cancelEdit ', item)
    // recuring base product
    if (item.base) {
      cancelEdit(item.base.data);
    }

    // recuring details
    if (item.details) {
      cancelEdit(item.details.data);
    }

    if (item.old !== undefined && item.old !== null) {
      item[vfield] = item.old[vfield];
      if (item[pfield] !== undefined) {
        item[pfield] = item.old[pfield];
      }
    }
    item.old = null;
    item.edited = false;
    item.touched = false;
    item.selected = false;
    item.inEdit = "";
    item.error = null;

    /* just reset item with the original ... NOT WORK YET
        if(item.old !== undefined && item.old !== null) {
            data[i] = item.old;
        }
        */
  });
};

const commitItem = (item: {
  old: null;
  edited: boolean;
  touched: boolean;
  selected: boolean;
  inEdit: string;
  error: null;
}) => {
  //  debugger;
  item.old = null;
  item.edited = false;
  item.touched = false;
  item.selected = false;
  item.inEdit = "";
  item.error = null;
};

const findFailed = (data: any[]) => {
  //  debugger;
  let failed = data.filter((item: any) => item.error);
  return failed && failed.length !== 0 ? true : false;
};

export const commitEdit = (data: any[]) => {
  //  debugger;
  data.forEach((item: any) => {
    if (item.old !== undefined && item.old !== null) {
      let isDetailsFailed = item.details
        ? findFailed(item.details.data)
        : false;

      if (!item.error && !isDetailsFailed) {
        commitItem(item);

        // recuring base
        if (item.base) {
          commitEdit(item.base.data);
        }
      } else {
        item.inEdit = "";
      }

      // recuring details
      if (item.details) {
        commitEdit(item.details.data);
      }
    }
  });
};

export const commitMonthlyTotal = (data: any[]) => {
  // when some detail items update failed,
  // only commit the succeed items to monthly record.
  //  debugger;
  data.forEach((item: any) => {
    if (item.details) {
      let detailsTotal = 0;
      item.details.data.forEach((item: { [x: string]: number; error: any }) => {
        if (!item.error) {
          detailsTotal += item[enums.FORECAST_APPROVED_VOLUME];
        }
      });
      item.old[enums.FORECAST_APPROVED_VOLUME] = detailsTotal;
    }
  });
};

// exit edit -> cancel
export const resetPercentage = (data: any[]) => {
  //  debugger;
  data.forEach((item) => {
    if (item.details) {
      resetPercentage(item.details.data); // recuring
    }
    if (item.base) {
      setApprovedPercentage(item.base.data);
    }
  });
};

// validate editing value (>=0, blank=0)
export const validateValue = (value: number) => {
  //  debugger;
  if (value < 0 || value === null) {
    return 0;
  } else {
    return value;
  }
};

// when daily or details volume on change
export const itemOnChange = (
  dataItem: { [x: string]: any; old: Object | null | undefined },
  value: number
) => {
  //  debugger;

  // update item's volume
  itemEdited(dataItem, value);

  // update each base product volume based on the %
  updateBaseProductVolumes(dataItem, value);
};

// parent onchange, update each base product volume based on its %
export const updateBaseProductVolumes = (
  dataItem: { [x: string]: any; old?: Object | null | undefined; base?: any },
  value: number
) => {
  //  debugger;
  let field = enums.FORECAST_APPROVED_PERCENTAGE;
  if (dataItem.base) {
    let base = dataItem.base.data;
    base.forEach((item: any) => {
      itemTouched(item, item[field] * value);
    });
  }
};

// when base volume on change, recalculate
// Approved Volume %
export const setApprovedPercentage = (data: any[]) => {
  //  debugger;

  let vfield = enums.FORECAST_APPROVED_VOLUME;
  let pfield = enums.FORECAST_APPROVED_PERCENTAGE;

  let approvedVolumeTotal = sumOfValues(data, vfield);
  let numOfItems = data.length;
  data.forEach((item: any) => {
    if (item.old === undefined || item.old === null) {
      itemTouched(item, item[vfield]);
    }
    approvedVolumeTotal !== 0
      ? (item[pfield] = item[vfield] / approvedVolumeTotal)
      : (item[pfield] = 1 / numOfItems);
  });
};

// activeRow could be the selected monthly, daily or detail.
// data is the children of the selected row (details or base)
export const updateActiveRowVolume = (
  activeRow: null | Object,
  data: any[]
) => {
  //  debugger;
  // export const updateActiveRowVolume = (activeRow: IForecastMonthlySummary | null | Object, data: any[]) => {
  let field = enums.FORECAST_APPROVED_VOLUME;
  let newTotal = sumOfValues(data, field);
  itemTouched(activeRow, newTotal);
};
