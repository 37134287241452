import { Icon } from "@progress/kendo-react-common";
import { Label } from "@progress/kendo-react-labels";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import { Picklists, TTicketInput, TVolumeInput } from "ticketing/ticketing.types";
import { TicketVolumeForm } from "./TicketVolumeForm";

const convertVolume = (
  volume: number | undefined,
  srcUom: Picklists.TUnitOfMeasure | undefined,
  destUom: Picklists.TUnitOfMeasure | undefined,
  uomConversions: Picklists.TUomConversion[]) => {
  if (uomConversions && srcUom && destUom && volume) {
    return (uomConversions
      .find(c => c.srcUnitOfMeasure.name === srcUom.name && c.destUnitOfMeasure.name === destUom.name)
      ?.factor ?? 1)
      * volume;
  }
  return volume;
}

const isMatchingTempeature = (srcVolume: TVolumeInput, destVolume: TVolumeInput) => {
  return srcVolume.temperatureUnitOfMeasure?.id === destVolume.temperatureUnitOfMeasure?.id
    && srcVolume.temperature === destVolume.temperature;
}

const isMatchingVolume = (srcVolume: TVolumeInput, destVolume: TVolumeInput) => {
  return isMatchingTempeature(srcVolume, destVolume)
    && srcVolume.unitOfMeasure?.id != null
    && srcVolume.unitOfMeasure.unitOfMeasureClass.name === destVolume.unitOfMeasure?.unitOfMeasureClass.name;
}

const convertVolumeUsingUOMClass = (
  uomConversions: Picklists.TUomConversion[],
  volumes: TVolumeInput[],
  currentVolume: TVolumeInput,
  destUom: Picklists.TUnitOfMeasure) => {

  //find a volume whose uom class is same as the current volume and the same temperature
  //if no row can be found, just use the first one that is not the current one and same temperature.
  const matchingVolumeRow = volumes.filter(v => !v.netVolume).find(v => isMatchingVolume(currentVolume, v))
    ?? volumes.find(v => v !== currentVolume && isMatchingTempeature(v, currentVolume) && v.netVolume && v.grossVolume);

  const netVolume = matchingVolumeRow
    ? convertVolume(matchingVolumeRow.netVolume, matchingVolumeRow.unitOfMeasure, destUom, uomConversions)
    : currentVolume.netVolume;

  const grossVolume = matchingVolumeRow
    ? convertVolume(matchingVolumeRow.grossVolume, matchingVolumeRow.unitOfMeasure, destUom, uomConversions)
    : currentVolume.grossVolume;

  return { netVolume, grossVolume };
}


const TicketVolumesWrapper = ({
  activeTicket,
  onTicketsChanged,
  showGrossVolumeWarning
}: {
  activeTicket: TTicketInput;
  onTicketsChanged: (ticket: TTicketInput, change: { [key: string]: unknown }) => void;
  showGrossVolumeWarning: boolean
}) => {
  const { picklists } = usePicklists();
  const copyTicketVolume = (volume: TVolumeInput) => {
    onTicketsChanged(activeTicket, {
      volumes: [
        ...activeTicket.volumes, {
          ...JSON.parse(JSON.stringify(volume)),
          id: `V:${new Date().getTime()}:0`,
          seq: null,
          isDefault: false,
          isNew: true,
          volumeType: null
        }]
    });
  }

  const deleteTicketVolume = (volume: TVolumeInput) => {
    onTicketsChanged(activeTicket, { volumes: activeTicket.volumes?.filter(v => v !== volume) });
  }

  //all volume conversion are done here...
  //If the user changes unit of measure - then convert ignoring the volume class if the current volume's net and/or gross has value
  //if net and/or gross has no value then look for a volume that has the same class and if not found simply return the first one
  const onVolumeChange = (destVolume: TVolumeInput, change: { [key: string]: number | Picklists.TUnitOfMeasure | Picklists.TTemperatureUOM }) => {
    if (Object.keys(change).length > 0 && Object.keys(change).at(0)) {
      const key = Object.keys(change).at(0);
      const value = change[key!];

      const updatedVolumes = activeTicket.volumes.map(currentVolume => {
        if (currentVolume === destVolume) {
          if (key === 'unitOfMeasure' && picklists?.uomConversions) {
            const finalVolume = convertVolumeUsingUOMClass(picklists.uomConversions, activeTicket.volumes, currentVolume, value as Picklists.TUnitOfMeasure);
            Object.assign(currentVolume, finalVolume);
          }
          Object.assign(currentVolume, change);
        }
        else if ((key === 'netVolume' || key === 'grossVolume') && isMatchingVolume(currentVolume, destVolume)
          && picklists?.uomConversions) {
          Object.assign(currentVolume, {
            [key]: convertVolume(value as number, destVolume.unitOfMeasure, currentVolume.unitOfMeasure, picklists?.uomConversions)
          });
        }
        return currentVolume;
      });
      onTicketsChanged(activeTicket, { volumes: updatedVolumes });
    }
  }

  if (!activeTicket.volumes || activeTicket.volumes.length === 0) {
    return <></>;
  }
  const canDeleteVolume = activeTicket.volumes.length > 1;

  return (
    <div className="ticket-volume-grid-wrapper">
      <Label>Unit Of Measure</Label>
      <Label>Temperature</Label>
      <Label>Net Volume</Label>
      <Label>Gross Volume
        {showGrossVolumeWarning && (
          <span className="warning-span" title="Pricing is based on gross volume">
            <Icon name="warning" themeColor="warning" />
          </span>
        )}
      </Label>
      <Label></Label>
      <Label></Label>
      {activeTicket.volumes.map(v =>
        <TicketVolumeForm
          key={v.id}
          activeTicket={activeTicket}
          volume={v}
          canDelete={canDeleteVolume}
          onVolumeChange={onVolumeChange}
          onCopyVolume={copyTicketVolume}
          onDeleteVolume={deleteTicketVolume} />
      )}
    </div>

  );
};

export default TicketVolumesWrapper;
