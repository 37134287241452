import { CSSProperties, useCallback, useMemo } from "react";
import { Accept, useDropzone } from "react-dropzone";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "var(--drag-n-drop-border)",
  borderStyle: "dashed",
  backgroundColor: "var(--drag-n-drop-bg)",
  color: "var(--drag-n-drop-txt)",
  outline: "none",
  transition: "border .24s ease-in-out"
};
const disabledStyle = {
  color: "lightgray"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};
const FileDropZone = ({
  accept,
  onAccept,
  disabled,
  maxFiles,
  multiple,
  displayText,
  style,
  maxSize
}: {
  accept?: Accept;
  onAccept: (e: File[]) => void;
  disabled?: boolean;
  maxFiles?: number;
  multiple?: boolean;
  displayText?: string;
  style?: CSSProperties;
  maxSize?: number;
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles?.length > 0) {
        onAccept(acceptedFiles);
      }
    },
    [onAccept]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections
  } = useDropzone({
    accept,
    onDrop,
    disabled,
    maxFiles,
    multiple,
    maxSize
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div className="error-message">
      <li key={file.name}>
        {file.name} - {file.size} bytes
        <ul className="file-too-large">
          {errors.map(e => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    </div>
  ));

  const componentStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(style ?? {}),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(disabled ? disabledStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept, style, disabled]
  );
  return (
    <div className="container">
      <div {...getRootProps({ style: componentStyle })}>
        <input {...getInputProps()} />
        <p>{displayText ?? "Drag 'n' drop some files here, or click to select files"}</p>
        {fileRejectionItems}
      </div>
    </div>
  );
};

export default FileDropZone;
