import { toString as toKString } from "@progress/kendo-intl";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { GridCellProps } from "@progress/kendo-react-grid";
import { NumericTextBox, NumericTextBoxBlurEvent } from "@progress/kendo-react-inputs";
import { useContext } from "react";
import { BulkImportContext } from "../util";

export const NumericCell = (props: GridCellProps) => {
  const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
  const { fieldConfig: allFieldConfig } = useContext(BulkImportContext);
  const fieldConfig = allFieldConfig
    ?.flatMap(f => [f, ...(f.children ?? [])])
    .find(f => f.name === field);
  const isInEdit = field === dataItem.inEdit;
  const initValue = field && dataItem[field] ? dataItem[field] : "";
  const navigationAttributes = useTableKeyboardNavigation(props.id);

  const onBlur = (e: NumericTextBoxBlurEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        value: e.target.value,
        syntheticEvent: e.syntheticEvent
      });
    }
  };

  const defaultRendering = (
    <td
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      role={"gridcell"}
      {...navigationAttributes}>
      {isInEdit ? (
        <div>
          <NumericTextBox defaultValue={Number(initValue)} onBlur={onBlur} />
        </div>
      ) : (
        <span>
          {fieldConfig?.isRequired && (!initValue || isNaN(initValue)) && (
            <span className="k-icon-xs k-icon bt-i-warning k-color-error"></span>
          )}
          <span>{toKString(initValue, "n3")}</span>
        </span>
      )}
    </td>
  );

  return render?.(defaultRendering, props) ?? <td></td>;
};
