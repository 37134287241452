import React from "react";
import { IForecastState } from "../../_redux/IReduxState";
import store from "../../store";
import { FORECAST_APPROVED_VOLUME } from "shared/enums";
import { activeRow } from "./gridEditor";


//TODO:
// monthly cell is not editable
export const monthlyCellRender = (
  tdElement: React.DetailedReactHTMLElement<any, HTMLElement>,
  cellProps: { dataItem: any; field: any }
) => {
  if (tdElement) {
    const { dataItem, field } = cellProps;
    const changed = dataItem.edited || dataItem.touched;
    const classname =
      field === FORECAST_APPROVED_VOLUME && changed ? "edited-text" : "";

    return React.cloneElement(tdElement, {
      ...tdElement.props,
      className: classname,
    });
  }
  return tdElement;
};

// custom cell render
export const cellRender = (
  tdElement: React.DetailedReactHTMLElement<any, HTMLElement>,
  cellProps: { dataItem: any; field: any },
  enterEdit: {
    (dataItem: any, field: any): void;
    (dataItem: any, field: any): void;
    (dataItem: any, field: any): void;
    (arg0: any, arg1: any): void;
  },
  exitEdit: {
    (dataItem: any): void;
    (dataItem: any): void;
    (dataItem: any): void;
    (arg0: any): void;
  }
) => {
  if (tdElement) {
    const { dataItem, field } = cellProps;
    const name = FORECAST_APPROVED_VOLUME;
    const id = dataItem.Id;
    const inEditField = dataItem["inEdit"];

    // styles
    const changed = dataItem.edited || dataItem.touched;
    const classname = field === name && changed ? "edited-text" : "";

    // icons
    const editIcon = <span className="k-icon k-i-edit" key={id} />;
    const errorIcon = () => {
      return (
        <span
          className="k-icon k-i-error"
          key={id}
          title={dataItem.error[0].errorMessage}
        />
      );
    };
    const icon =
      field === name && field !== inEditField ? (
        dataItem.error ? (
          errorIcon()
        ) : (
          editIcon
        )
      ) : (
        <div key={id} />
      );

    // inEdit field
    const additionalProps =
      field && field === inEditField
        ? {
          ref: (td: any) => {
            const input = td?.querySelector("input");
            const activeElement = document.activeElement;

            if (
              !input ||
              !activeElement ||
              input === activeElement ||
              !activeElement.contains(input)
            ) {
              return;
            }

            input.focus();

            input.addEventListener("keydown", (e: any) => {
              if (e.key === "Enter" || e.key === "Tab") {
                exitEdit(dataItem);
              }
            });
          },
        }
        : {
          onClick: () => {
            enterEdit(dataItem, field);
          },
          className: classname,
        };

    return React.cloneElement(
      tdElement,
      { ...tdElement.props, ...additionalProps },
      [icon, tdElement.props.children]
    );
  }

  return tdElement;
};

// custom row render
export const rowRender = (trElement: any, props: any) => {
  const forecast: IForecastState = store.getState().forecast;

  const activeMonthlyRow = activeRow(forecast.monthlySummary);
  const activeDailyRow = activeRow(forecast.dailySummary);
  const activeDetailsRow = activeMonthlyRow
    ? activeRow(activeMonthlyRow.details)
    : null;

  const bgcolor = { backgroundColor: "rgba(83, 140, 230, 0.44)" };
  let trProps = {};

  // highlight if row is selected
  if (
    props.dataItem === activeMonthlyRow ||
    props.dataItem === activeDailyRow ||
    props.dataItem === activeDetailsRow
  ) {
    trProps = { style: bgcolor };
  }

  return React.cloneElement(
    trElement,
    { ...trProps },
    trElement.props.children
  );
};

export const baseProductRowRender = (trElement: any, props: any) => {
  const bgcolor = { backgroundColor: "rgba(83, 140, 230, 0.44)" };
  let trProps = {};

  // highlight if row is active
  if (props.dataItem.active) {
    trProps = { style: bgcolor };
  }

  return React.cloneElement(
    trElement,
    { ...trProps },
    trElement.props.children
  );
};
