import { ApolloProvider } from "@apollo/client";
import "@progress/kendo-theme-default/dist/all.css";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import { Provider } from "react-redux";
import { ThemeProvider } from "shared/contexts/ThemeContext";
import { client } from "./ApolloClient";
import App from "./App";
import "./App.scss";
import { oidcConfig } from "./auth";
import { AuthenticationProvider } from "./auth/AuthenticationProvider";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <AuthProvider {...oidcConfig}>
        <AuthenticationProvider>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </AuthenticationProvider>
      </AuthProvider>
    </ApolloProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
