import { firstDayOfMonth, lastDayOfMonth } from "@progress/kendo-date-math";
import { Button } from "@progress/kendo-react-buttons";
import {
  MultiSelect as KendoMultiSelect,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../_redux/IReduxState";
import { actionCreator } from "../../../_redux/actionCreator";
import { DatePicker } from "../../../shared/components/form/DatePicker";
import { Input } from "../../../shared/components/form/Input";
import { MultiSelect } from "../../../shared/components/form/MultiSelect";
import { Switch } from "../../../shared/components/form/Switch";
import GridActionBar, {
  TGridAction,
} from "../../../shared/components/grid/GridActionBar";
import {
  dateRangeValidator,
  requiredValidator,
} from "../../../shared/validators";
import {
  ICustomer,
  IForecastSearchCriteria,
  IProduct,
  ITerminalGroup,
} from "../../models";
import { FORECAST_CRITERIA } from "../../state/forecastActionTypes";
import { fetchForecastPicklists } from "../../util/apiHandler";

const MIN_FILTER_LENGTH = 3;
// Forecast Search Criteria
const ForecastSearchForm = (props: {
  isOpen: boolean;
  onOpen?: (e: boolean) => void;
  showMonthlyToggle: boolean;
  onSearch?: (event: IForecastSearchCriteria) => void;
  actions?: TGridAction[];
}) => {
  const dispatch = useDispatch();

  // redux state
  const forecast = useSelector((state: IAppState) => state.forecast);
  const picklists = forecast?.picklists;

  const getFormCriteria = (c: IForecastSearchCriteria) => {
    return {
      startDate: c?.startDate ?? firstDayOfMonth(new Date()),
      endDate: c?.endDate ?? lastDayOfMonth(new Date()),
      customer: c?.customer ?? [],
      terminal: c?.terminal ?? [],
      terminalGroup: c?.terminalGroup ?? [],
      finishedProduct: c?.finishedProduct ?? [],
      planningCycle: c?.planningCycle ?? "",
      isMonthlyDisplayMode: c?.isMonthlyDisplayMode ?? false,
    };
  };

  const getTerminals = (selectedGroups: ITerminalGroup[] | undefined) => {
    if (selectedGroups && selectedGroups.length > 0) {
      return picklists.terminal.filter(
        (t) =>
          selectedGroups.findIndex(
            (g: ITerminalGroup) => g.id === t.group1?.id
          ) >= 0
      );
    }
    return picklists?.terminal ?? [];
  };

  // local state
  const [open, setOpen] = useState(props.isOpen);
  const [terminalGroups, setTerminalGroups] = useState<ITerminalGroup[]>(
    forecast.criteria?.terminalGroup ?? []
  );
  const [terminals, setTerminals] = useState(() =>
    getTerminals(forecast?.criteria?.terminalGroup)
  );

  const [formCriteria, setFormCriteria] = useState<IForecastSearchCriteria>(
    () => getFormCriteria(forecast?.criteria)
  );

  const [customers, setCustomers] = useState<ICustomer[]>(
    picklists?.customer ?? []
  );
  const [products, setProducts] = useState<IProduct[]>(
    picklists?.product ?? []
  );
  const [filteredTerminalGroups, setFilteredTerminalGroups] = useState<
    ITerminalGroup[]
  >(picklists?.terminalGroup ?? []);

  // fetch picklists
  useEffect(() => {
    if (picklists?.customer?.length === 0) {
      fetchForecastPicklists();
    }
  }, [picklists]);

  useEffect(() => {
    setCustomers(picklists?.customer);
    setProducts(picklists?.product);
    setFilteredTerminalGroups(picklists?.terminalGroup);
  }, [picklists]);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  // form style
  const icon = `k-icon ${
    open ? "k-i-arrow-double-60-up" : "k-i-arrow-double-60-down"
  } k-cursor-pointer k-pl-1 k-pr-1 k-color-primary`;
  const title = open ? "Collapse" : "Expand";

  // action bar
  const showHideAction = [
    {
      icon,
      title,
      click: () => {
        setOpen(!open);
        props.onOpen?.(!open);
      },
    },
  ];

  const barActions = [...(props.actions ?? []), ...showHideAction];

  const resetCriteria = async (_formRenderProps: FormRenderProps) => {
    setTerminalGroups([]);
    setTerminals(picklists?.terminal);
    setFilteredTerminalGroups(picklists?.terminalGroup);
    setCustomers(picklists?.customer);
    setProducts(picklists?.product);
    // @ts-ignore
    setFormCriteria(getFormCriteria({} as IForecastSearchCriteria));
    dispatch(actionCreator(FORECAST_CRITERIA, {}));
  };

  const onSubmitClick = (e: FormSubmitClickEvent) => {
    const searchState: IForecastSearchCriteria = {
      startDate: e.values.startDate,
      endDate: e.values.endDate,
      customer: e.values.customer,
      terminal: e.values.terminal,
      terminalGroup: terminalGroups,
      finishedProduct: e.values.finishedProduct,
      planningCycle: e.values.planningCycle,
      isMonthlyDisplayMode: e.values.isMonthlyDisplayMode ?? false,
      staleOnDate: e.values.staleOnDate,
    };
    props.onSearch?.(searchState);
  };

  const onTerminalGroupChange = (
    e: MultiSelectChangeEvent,
    formRenderProps: FormRenderProps
  ) => {
    const selectedTerminalGroups: ITerminalGroup[] = e.value;
    setTerminalGroups(selectedTerminalGroups); //save
    setTerminals(getTerminals(selectedTerminalGroups));
    //reset terminals for now, ideally we should loop thru and remove any that are not part of the group
    // const selectedTerminals:ITerminal[] = formRenderProps.valueGetter("terminal");
    formRenderProps.onChange("terminal", { value: [] });
  };

  const handleOrgClassFilterChange = (event: MultiSelectFilterChangeEvent) => {
    const filter = event.filter;
    const allData = picklists?.customer;
    const newData =
      filter.value.length >= MIN_FILTER_LENGTH
        ? picklists.customer.filter(
            (c) =>
              c.name.toLowerCase().indexOf(event.filter.value.toLowerCase()) >
              -1
          )
        : allData;

    setCustomers(newData);
  };

  const handleProductFilterChange = (event: MultiSelectFilterChangeEvent) => {
    const filter = event.filter;
    const allData = picklists?.product;
    const newData =
      filter.value.length >= MIN_FILTER_LENGTH
        ? picklists.product.filter(
            (p) =>
              p.name.toLowerCase().indexOf(event.filter.value.toLowerCase()) >
              -1
          )
        : allData;

    setProducts(newData);
  };

  const handleTerminalGroupFilterChange = (
    event: MultiSelectFilterChangeEvent
  ) => {
    const filter = event.filter;
    const allData = picklists?.terminalGroup;
    const newData =
      filter.value.length > 1
        ? picklists.terminalGroup.filter(
            (p) =>
              p.name.toLowerCase().indexOf(event.filter.value.toLowerCase()) >
              -1
          )
        : allData;

    setFilteredTerminalGroups(newData);
  };

  const handleTerminalFilterChange = (event: MultiSelectFilterChangeEvent) => {
    const filter = event.filter;
    const allData = picklists?.terminal;
    const newData =
      filter.value.length > MIN_FILTER_LENGTH
        ? picklists.terminal.filter(
            (p) =>
              p.name.toLowerCase().indexOf(event.filter.value.toLowerCase()) >
              -1
          )
        : allData;

    setTerminals(newData);
  };

  return (
    <div className={"search-wrapper"}>
      <div className={"card-container"}>
        <GridActionBar label="Search Criteria" actions={barActions} />
        <div className={"form-container" + (open ? "" : " k-hidden")}>
          <Form
            onSubmitClick={onSubmitClick}
            initialValues={formCriteria}
            key={JSON.stringify(formCriteria)}
            validator={dateRangeValidator}
            render={(formRenderProps) => {
              return (
                <FormElement horizontal={true}>
                  <Field
                    id={"customer"}
                    className={"theme-form-input"}
                    name={"customer"}
                    label={"Org Class"}
                    component={MultiSelect}
                    textField="name"
                    dataItemKey="id"
                    data={customers}
                    autoClose={false}
                    filterable={true}
                    onFilterChange={handleOrgClassFilterChange}
                  />
                  <div className={"k-form-field"}>
                    <label className={"k-label"}>Location</label>
                    <div
                      className="k-form-field-wrap k-gap-2"
                      style={{ display: "flex" }}
                    >
                      <div
                        className={"k-flex-grow"}
                        style={{ width: "calc(50% - 5px)" }}
                      >
                        <KendoMultiSelect
                          id={"terminalGroup"}
                          name={"terminalGroup"}
                          className={"theme-form-input"}
                          textField="name"
                          dataItemKey="id"
                          data={filteredTerminalGroups ?? []}
                          value={terminalGroups}
                          autoClose={false}
                          onChange={(e) =>
                            onTerminalGroupChange(e, formRenderProps)
                          }
                          filterable={true}
                          onFilterChange={handleTerminalGroupFilterChange}
                        />
                      </div>
                      <div
                        className={"k-flex-grow"}
                        style={{
                          display: "inline-block",
                          width: "calc(50% - 5px)",
                          marginLeft: "8px",
                        }}
                      >
                        <Field
                          id={"terminal"}
                          name={"terminal"}
                          component={KendoMultiSelect}
                          textField="name"
                          dataItemKey="id"
                          data={terminals}
                          autoClose={false}
                          filterable={true}
                          onFilterChange={handleTerminalFilterChange}
                          className={"theme-form-input"}
                        />
                      </div>
                    </div>
                  </div>
                  <Field
                    id={"finishedProduct"}
                    name={"finishedProduct"}
                    label={"Finished Product"}
                    component={MultiSelect}
                    textField="name"
                    dataItemKey="id"
                    data={products}
                    autoClose={false}
                    filterable={true}
                    onFilterChange={handleProductFilterChange}
                    className={"theme-form-input"}
                  />
                  <Field
                    id={"planningCycle"}
                    name={"planningCycle"}
                    label={"Planning Cycle"}
                    component={Input}
                    className={"theme-form-input"}
                  />
                  <Field
                    id={"startDate"}
                    name={"startDate"}
                    label={"*Start Date"}
                    component={DatePicker}
                    validator={requiredValidator}
                    defaultValue={formCriteria.startDate}
                  />
                  <Field
                    id={"endDate"}
                    name={"endDate"}
                    label={"*End Date"}
                    component={DatePicker}
                    validator={requiredValidator}
                    defaultValue={formCriteria.endDate}
                  />
                  {props.showMonthlyToggle && (
                    <Field
                      id={"staleOnDate"}
                      name={"staleOnDate"}
                      label={"Stale as of Date"}
                      max={new Date()}
                      component={DatePicker}
                      required={false}
                      placeholder="stale as of date"
                    />
                  )}

                  <div className={"k-form-field k-mb-0"}>
                    <label
                      className={
                        props.showMonthlyToggle ? "k-label" : "k-hidden"
                      }
                    >
                      Aggregated by Month?
                    </label>
                    <Field
                      id={"isMonthlyDisplayMode"}
                      name={"isMonthlyDisplayMode"}
                      component={Switch}
                      defaultChecked={formCriteria.isMonthlyDisplayMode}
                      className={props.showMonthlyToggle ? "" : "k-hidden"}
                    />

                    <div className={"k-form-button k-buttons-end k-pr-0"}>
                      <Button
                        className="form-action-button theme-btn-yellow"
                        type={"button"}
                        onClick={(_e) => {
                          resetCriteria(formRenderProps);
                        }}
                      >
                        Reset
                      </Button>
                      <Button
                        className="form-action-button theme-btn-yellow"
                        type={"submit"}
                        disabled={!formRenderProps.valid}
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                  {formRenderProps.visited &&
                    formRenderProps.errors &&
                    formRenderProps.errors.VALIDATION_SUMMARY && (
                      <div className={"k-messagebox k-messagebox-error"}>
                        {formRenderProps.errors.VALIDATION_SUMMARY}
                      </div>
                    )}
                </FormElement>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default ForecastSearchForm;
