import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid,
  GridColumn,
  GridItemChangeEvent,
  GridNoRecords,
  GridToolbar
} from "@progress/kendo-react-grid";
import { loader } from "graphql.macro";
import { useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import { GqlResponse, TMovement, TMovementComment } from "ticketing/ticketing.types";

const QUERY_MOVEMENT_COMMENTS = loader("../../ticketing-graphql/commentsOfMovement.graphql");
const UPDATE_MOVEMENT_COMMENTS = loader(
  "../../ticketing-graphql/updateCommentsOfMovements.graphql"
);

type Response = GqlResponse<Partial<TMovement>, "movement">;
type UpdateCommentsResponse = GqlResponse<Partial<TMovement>[], "movements">;
type MovementCommentsProps = {
  movement: TMovement;
  onClose: () => void;
};
type MovementCommentEdit = TMovementComment & {
  modifiedComment: string;
  inEdit?: boolean;
};

export const MovementComments = ({ movement, onClose }: MovementCommentsProps) => {
  const editField = "inEdit";
  const [movementComments, setMovementComments] = useState<MovementCommentEdit[]>([]);

  const { loading, error } = useQuery<Response>(QUERY_MOVEMENT_COMMENTS, {
    fetchPolicy: "no-cache",
    variables: { id: movement.id },
    onCompleted: data =>
      setMovementComments(
        data.movement.comments
          ?.map(c => ({ ...c, modifiedComment: c.comment, inEdit: true }))
          .sort((a, b) => a.seqNum - b.seqNum) ?? []
      )
  });

  const [updateComments, { loading: uLoading, error: uError }] =
    useMutation<UpdateCommentsResponse>(UPDATE_MOVEMENT_COMMENTS, {
      onCompleted: data => {
        setMovementComments(
          data.movements
            .filter(m => m.id === movement.id)
            .flatMap(m => m.comments ?? [])
            .map(c => ({ ...c, modifiedComment: c.comment, inEdit: true }))
            .sort((a, b) => a.seqNum - b.seqNum)
        );
      }
    });

  const hasModifiedComments = movementComments.some(c => c.comment !== c.modifiedComment);

  const onSave = () => {
    if (hasModifiedComments) {
      updateComments({
        variables: {
          comments: [
            {
              id: movement.id,
              version: movement.version,
              comments: movementComments
                .filter(c => c.comment !== c.modifiedComment)
                .map(({ seqNum, modifiedComment }) => ({
                  seqNum,
                  comment: modifiedComment
                }))
            }
          ]
        }
      });
    }
  };

  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field ?? "";
    const newData = movementComments?.map(item =>
      item.seqNum === event.dataItem.seqNum ? { ...item, [field]: event.value } : item
    );
    setMovementComments(newData);
  };

  const saveDisabled = loading || uLoading || !hasModifiedComments;

  if (error || uError) {
    return <ApolloErrorViewer error={[error, uError].filter((e): e is ApolloError => !!e)} />;
  }

  return (
    <div className={"content-wrapper"}>
      <div className={"grid-wrapper"}>
        <div className={"card-container"}>
          <Grid
            data={movementComments}
            style={{ maxHeight: "296px", overflowY: "auto", margin: "2px" }}
            dataItemKey="codeSequence"
            editField={editField}
            onItemChange={itemChange}>
            <GridToolbar size={"medium"}>
              {(loading || uLoading) && <InlineLoadingPanel />}
              <Button
                icon={"save"}
                onClick={onSave}
                disabled={saveDisabled}
                className="theme-btn-green">
                Save
              </Button>
              <Button
                icon="close"
                themeColor={"primary"}
                onClick={onClose}
                className="theme-btn-red"
                disabled={loading || uLoading}>
                Close
              </Button>
            </GridToolbar>
            <GridNoRecords>No comments available</GridNoRecords>
            <GridColumn title="Seq" field="seqNum" width={36} editable={false} />
            <GridColumn title="Comment" field="modifiedComment" editor={"text"} />
          </Grid>
        </div>
      </div>
    </div>
  );
};
