import { gql, useLazyQuery } from "@apollo/client";
import React, { useState, useCallback } from "react";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import { GqlResponse, TFacility } from "ticketing/ticketing.types";

// for type ahead
export const GQL = gql`
  query facilitiesFilterBy($enterpriseSystemId: Int!, $name: String!) {
    facilities: facilitiesFilterBy(
      filter: { enterpriseSystemId: $enterpriseSystemId, name: { regexIgnoreCase: $name } }
    ) {
      id
      name
    }
  }
`;

const delay = 500;
type Response = GqlResponse<TFacility[], "facilities">;

const useSearchFacilities = () => {
  const { picklists } = usePicklists();
  const [searchResults, setSearchResults] = useState<TFacility[]>([]);

  const [filter, { loading, error }] = useLazyQuery<Response>(GQL, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: (data) => setSearchResults(data.facilities),
  });

  const timeout = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  const search = (name: string) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      filter({ variables: { enterpriseSystemId: picklists?.enterpriseSystemId, name: `%${name}%` } });
    }, delay);
  };
  const clear = useCallback(() => {
    setSearchResults([]);
  }, [setSearchResults]);
  return [search, searchResults, loading, error, clear] as const;
};

export default useSearchFacilities;
