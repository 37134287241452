import { Sentiments } from "@sede-x/shell-ds-react-framework";
import { Notification, NotificationGroup } from "../../shared/components/Notification";

const SuccessNotification = ({
  success,
  hideAfterMs,
  onHide
}: {
  success: string;
  hideAfterMs?: number;
  onHide?: () => void;
}) => {
  return (
    <NotificationGroup>
      <Notification
        message={success}
        sentiment={Sentiments.Positive}
        onClose={onHide}
        autoCloseAfterMs={hideAfterMs}
      />
    </NotificationGroup>
  );
};

export default SuccessNotification;
