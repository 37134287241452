import { Flexbox, Loading } from "@sede-x/shell-ds-react-framework";

const InlineLoadingPanel = ({ message }: { message?: string }) => {
  return (
    <Flexbox alignItems="center" justifyContent="center" gap="8px">
      <span>{message}</span>
      <Loading size="small" />
    </Flexbox>
  );
};
export default InlineLoadingPanel;
