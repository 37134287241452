import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@progress/kendo-react-buttons";
import { Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";

import { actionCreator } from "../../../_redux/actionCreator";
import GridBulkEditor from "./GridBulkEditor";

import { IAppState } from "../../../_redux/IReduxState";
import { IForecastBase } from "../../../forecast/models";
import {
  FORECAST_BULK_EDIT_DAILY_DATA,
  FORECAST_BULK_EDIT_DETAILS_DATA,
  FORECAST_DONE_EDIT,
  FORECAST_FAIL_EDIT,
  FORECAST_UOM,
} from "../../../forecast/state/forecastActionTypes";
import { convertDataUom } from "../../../forecast/util/dataConvertor";
import { activeRow, cancelEdit, commitEdit, commitMonthlyTotal, itemOnChange, updateActiveRowVolume } from "../../../forecast/util/gridEditor";
import { flattenArray } from "../../algorithms";
import { FORECAST_APPROVED_VOLUME, FORECAST_UPDATE_FAILED, FORECAST_UPDATE_SUCCEEDED, 
  FORECAST_AGGREGATED_APPROVED_VOLUME_PERCENT, FORECAST_BULK_VOLUME_EDIT_MISSING_PERCENTAGE } from "../../enums";
import { fetchForecastSummary } from "forecast/util/apiHandler";
import { updateBaseVolume, updateDetailsVolume } from "forecast/api";

const GridToolBar = (_props: any) => {
  const dispatch = useDispatch();
const HTTP_SUCCESS_MIN = 200;
const HTTP_SUCCESS_MAX = 299;
  // redux store
  const forecast = useSelector((state: IAppState) => state.forecast);
  const isMonthly = forecast.isMonthlyDisplayMode;
  const criteria = forecast.criteria;
  const summary = isMonthly ? forecast.monthlySummary : forecast.dailySummary;
  const uom = forecast.unitOfMeasure;

  const activeMonthlyRow = activeRow(forecast.monthlySummary);
  const inEdit = summary.inEdit;
  const inSelect: boolean = isMonthly
    ? activeMonthlyRow?.details?.data?.some((item: any) => item.selected)
    : summary?.data?.some((item) => item.selected);


  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [err, setErr] = React.useState(false);
  const color = err ? { color: "#ff6656" } : { color: "#a6ffab" };

  const onDataToggle = async (e: SwitchChangeEvent) => {
    await fetchForecastSummary(e.target.value, criteria);
  };

  const onUOMChange = (e: SwitchChangeEvent) => {
    const updatedForecast = convertDataUom(e.target.value ? "GAL" : "BBL");
    updatedForecast.unitOfMeasure = e.target.value ? "GAL" : "BBL";
    dispatch(actionCreator(FORECAST_UOM, updatedForecast));
  };

  const onCancel = () => {
    cancelEdit(summary.data);
    dispatch(actionCreator(FORECAST_DONE_EDIT, summary.data));
    // clear message
    setMsg("");
  };

  const onSave = async () => {
    // clear message
    setMsg("");
    setErr(false);
    const details = getDetails();
    const detailsInEdit = getEditedDetails(details);
    const baseInEdit = getEditedBase(details);

    // console.log('### onSave', detailsInEdit, baseInEdit)

    if (baseInEdit && baseInEdit.length !== 0) { await saveBase(baseInEdit); }

    if (detailsInEdit && detailsInEdit.length !== 0) { await saveDetails(detailsInEdit); }

    commitSave();
  };

  const commitSave = () => {
    // commit edit
    commitEdit(summary.data);

    // update store
    if (!err) {
      dispatch(actionCreator(FORECAST_DONE_EDIT, summary.data));
    } else {
      // commit the succeeded items, keep the failed unchanged
      // commitEdit(summary.data);

      // recalculate monthly item original volume to reflect committed details
      if (isMonthly) {
        commitMonthlyTotal(summary.data);
      }
      dispatch(actionCreator(FORECAST_FAIL_EDIT, summary.data));
    }
  };

  const getDetails = () => {
    let detailsArray: any[] = [];
    if (isMonthly) {
      summary.data.forEach((m: any) => {
        if (m.details !== undefined) {
          detailsArray.push(m.details.data);
        }
      });
      detailsArray = flattenArray(detailsArray);
    } else {
      detailsArray = summary.data;
    }
    return detailsArray;
  };

  const getEditedDetails = (details: any[]) => {
    // filter the details that in edit && no base changes
    return details.filter((item) => item.edited && !item.base);
  };

  const getEditedBase = (details: any[]) => {
    const baseInEdit: any = [];
    details.forEach((d) => {
      if (d.base !== undefined) {
        const editedBase = d.base.data.filter(
          (i: { edited: boolean; touched: boolean }) => i.edited || i.touched
        );
        if (editedBase.length !== 0) { baseInEdit.push(d.base.data); }
      }
    });

    return baseInEdit;
  };

  // if any detail update failed, set error in the failed items
  const checkDetailsUpdateErrors = (data: any[], result: { data: never[] }) => {
    // check if there are any particular items failed updating
    const response = result.data || [];
    const failed = response.filter((item: any) => item.status === "FAILED");
    // console.log('@ details update result: ', result)
    // console.log('@ details update failed: ', failed)

    if (failed.length !== 0) {
      // set error in each failed detail item
      data.forEach((item) => {
        const groupId = item["Group Id"];
        const error = failed.filter(
          (i: any) => i.requestData.groupId === groupId
        );
        if (error.length !== 0) {
          item.error = error;
        }
      });
      return true;
    } else {
      return false;
    }
  };

  const saveDetails = async (data: any) => {
    // update DB
    const result = await updateDetailsVolume(data);

    if (result.status >= HTTP_SUCCESS_MIN || result.status <= HTTP_SUCCESS_MAX) {
      const hasError = checkDetailsUpdateErrors(data, result);

      if (!hasError) {
        setMsg(FORECAST_UPDATE_SUCCEEDED);
        setErr(false);
      } else {
        setMsg(FORECAST_UPDATE_FAILED);
        setErr(true);
      }
    } else {
      // general errors like: 400, 500, 401, ...
      setMsg(FORECAST_UPDATE_FAILED);
      setErr(true);
    }
  };

  const saveBase = async (data: IForecastBase[][]) => {
    // update DB
    const result = await updateBaseVolume(data);

    if (result.status >= HTTP_SUCCESS_MIN && result.status <= HTTP_SUCCESS_MAX) {
      // NOTE: backend does not provide itemized failure for base product update

      setMsg(FORECAST_UPDATE_SUCCEEDED);
      setErr(false);
    } else {
      // for general errors like 500, 400, 401 ...
      setMsg(FORECAST_UPDATE_FAILED);
      setErr(true);
    }
  };

  const openBulkEdit = () => {
    setOpen(true);
  };

  const closeBulkEdit = () => {
    setOpen(false);
  };

  const updateBulkEdit = (e: { approvedVolume: number }) => {
    const value = e.approvedVolume;
    let anySelected = false;

    if (isMonthly) {
      // update selected details & base products
      activeMonthlyRow.details.data.forEach((item: any) => {
        if (item.selected) {
          if (item[FORECAST_AGGREGATED_APPROVED_VOLUME_PERCENT] === 0) {
            dispatch(actionCreator("logError", { message: FORECAST_BULK_VOLUME_EDIT_MISSING_PERCENTAGE }));
          } else {
            itemOnChange(item, value);
            anySelected = true;
          }
        }
      });

      // update active monthly row volume
      if (anySelected) {
        updateActiveRowVolume(
          activeMonthlyRow,
          activeMonthlyRow.details.data
        );
        dispatch(actionCreator(FORECAST_BULK_EDIT_DETAILS_DATA, summary.data));
      }
    } else {
      // update selected daily & base products
      summary.data.forEach((item: any) => {
        if (item.selected) {
          if (item[FORECAST_AGGREGATED_APPROVED_VOLUME_PERCENT] === 0) {
            dispatch(actionCreator("logError", { message: FORECAST_BULK_VOLUME_EDIT_MISSING_PERCENTAGE }));
          } else {
            itemOnChange(item, value);
            anySelected = true;
          }
        }
      });

      if (anySelected) {
        dispatch(actionCreator(FORECAST_BULK_EDIT_DAILY_DATA, summary.data));
      }
    }
    setOpen(false);
  };

  const cancelBulkEdit = () => {
    setOpen(false);
  };

  return (
    <div className={"card-title"}>
      {open && (
        <GridBulkEditor
          onClose={closeBulkEdit}
          onUpdate={updateBulkEdit}
          onCancel={cancelBulkEdit}
        />
      )}

      <div className={"grid-tool-bar"}>
        <div className={"grid-tool-msg"} style={color}>
          {msg}
        </div>
        <div className={"grid-tool-buttons"}>
          <Button
            className={
              (inEdit ? "k-color-primary " : "") +
              "k-mr-1 k-pb-hair k-pt-hair theme-btn-yellow"
            }
            icon="save"
            disabled={!inEdit}
            onClick={onSave}
          >
            Save
          </Button>
          <Button
            className={
              (inEdit ? "k-color-primary " : "") +
              "k-mr-1 k-pb-hair k-pt-hair theme-btn-red"
            }
            icon="cancel"
            disabled={!inEdit}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className={
              "k-color-primary k-mr-1 k-pb-hair k-pt-hair theme-btn-yellow"
            }
            icon="table-properties"
            disabled={!inSelect}
            onClick={openBulkEdit}
          >
            Bulk Edit
          </Button>
          <Switch
            onLabel={"Monthly"}
            offLabel={"Daily"}
            onChange={onDataToggle}
            checked={isMonthly}
            disabled={inEdit}
          />
          <Switch
            onLabel={"Gallon"}
            offLabel={"Barrel"}
            onChange={onUOMChange}
            checked={uom === "GAL"}
          />
        </div>
      </div>
    </div>
  );
};
export default GridToolBar;
