import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { OnLoadProgressArgs } from "react-pdf/dist/cjs/shared/types";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const DEFAULT_ZOOM_PERCENTAGE = 100;
const DEFAULT_ZOOM_PERCENTAGE_CHANGE = 10;
const DEFAULT_ROTATE_CHANGE = 90;
const DEFAULT_ROTATE_RESET = 270;

const PdfComponent = ({
  pdfFileSelected,
  onDocumentLoaded
}: {
  pdfFileSelected: File | null;
  onDocumentLoaded: (numPages: number) => void;
}) => {
  const [, setLoadState] = useState<OnLoadProgressArgs>({
    loaded: 0,
    total: 0
  });
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomValue, setZoomValue] = useState(DEFAULT_ZOOM_PERCENTAGE);
  const [rotateValue, setRotateValue] = useState(0);

  if (pdfFileSelected == null) {
    return <></>;
  }

  //used for rerendring to calculate the height
  const onLoadProgress = (e: OnLoadProgressArgs) => setLoadState(e);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumberOfPages(numPages);
    onDocumentLoaded(numPages);
  };

  const nextPage = () =>
    setPageNumber(current => (current + 1 > numberOfPages ? 1 : current + 1));
  const previousPage = () =>
    setPageNumber(current => (current === 1 ? numberOfPages : current - 1));
  const lastPage = () => setPageNumber(numberOfPages);
  const firstPage = () => setPageNumber(1);

  const zoomOut = () => setZoomValue(prevValue => prevValue - DEFAULT_ZOOM_PERCENTAGE_CHANGE);
  const zoomIn = () => setZoomValue(prevValue => prevValue + DEFAULT_ZOOM_PERCENTAGE_CHANGE);

  const rotateLeft = () =>
    setRotateValue(prevValue =>
      prevValue === 0 ? DEFAULT_ROTATE_RESET : prevValue - DEFAULT_ROTATE_CHANGE
    );
  const rotateRight = () =>
    setRotateValue((prevValue: number) =>
      prevValue === DEFAULT_ROTATE_RESET ? 0 : prevValue + DEFAULT_ROTATE_CHANGE
    );

  return (
    <div className="pdf-component" style={{ height: "100%" }}>
      <div style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            height: "50px"
          }}>
          <span className="document-page-controls-container">
            <span className="document-page-controls">
              <Button
                size={"small"}
                fillMode={"flat"}
                rounded={"full"}
                icon="arrow-double-60-left k-icon-lg"
                onClick={firstPage}
                title="Go to first page"
              />

              <Button
                size={"small"}
                fillMode={"flat"}
                rounded={"full"}
                icon="arrow-60-left k-icon-lg"
                onClick={previousPage}
                title="Go to previous page"
              />
              <Label>{`Page ${pageNumber} of ${numberOfPages}`}</Label>
              <Button
                size={"small"}
                fillMode={"flat"}
                rounded={"full"}
                icon="arrow-60-right k-icon-lg"
                onClick={nextPage}
                title="Go to next page"
              />

              <Button
                size={"small"}
                fillMode={"flat"}
                rounded={"full"}
                icon="arrow-double-60-right k-icon-lg"
                onClick={lastPage}
                title="Go to last page"
              />
            </span>
            <span className="document-page-controls">
              <Button
                size={"small"}
                fillMode={"flat"}
                rounded={"full"}
                icon="zoom-out k-icon-lg"
                onClick={zoomOut}
                title="Zoom Out"
              />
              <span
                role="none"
                onClick={() =>
                  setZoomValue(DEFAULT_ZOOM_PERCENTAGE_CHANGE)
                }>{`${zoomValue.toFixed(0)}%`}</span>
              <Button
                size={"small"}
                fillMode={"flat"}
                rounded={"full"}
                icon="zoom-in k-icon-lg"
                onClick={zoomIn}
                title="Zoom In"
              />
            </span>
            <span className="document-page-controls">
              <Button
                size={"small"}
                fillMode={"flat"}
                rounded={"full"}
                icon="rotate-left k-icon-lg"
                onClick={rotateLeft}
                title="Rotate Left"
              />
              <Button
                size={"small"}
                fillMode={"flat"}
                rounded={"full"}
                icon="rotate-right k-icon-lg"
                onClick={rotateRight}
                title="Rotate Right"
              />
            </span>
          </span>
        </div>
        <div
          style={{
            height: "calc(100% - 50px)",
            overflow: "auto",
            textAlign: "center"
          }}>
          <Document
            file={pdfFileSelected}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadProgress={onLoadProgress}>
            <Page
              pageNumber={pageNumber}
              scale={zoomValue / DEFAULT_ZOOM_PERCENTAGE}
              rotate={rotateValue}
            />
          </Document>
        </div>
      </div>
    </div>
  );
};

export default PdfComponent;
