import { useQuery } from "@apollo/client";
import { BaseTable, Flexbox } from "@sede-x/shell-ds-react-framework";
import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import { loader } from "graphql.macro";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import NoDataTableImage from "shared/components/basetable/NoDataTableImage";
import GlobalHeader from "shared/components/GlobalHeader";
import LoadingPanel from "shared/components/LoadingPanel";
import { GqlResponse } from "ticketing/ticketing.types";
import { CargoJsonViewer } from "./CargoJsonViewer";
import { DateCell, makeButtonCell, TextCell } from "./cells";
import { InboundCargoSearch } from "./InboundCargoSearch";
import { SearchButton } from "./SearchButton";
import {
  PagedConnection,
  TInboundCargoRequest,
  TInboundCargoSearchCriteria
} from "./tracker.types";
import { TrackerNavbar } from "./TrackerNavbar";
import { TrackerPager } from "./TrackerPager";
import { FETCH_POLICY_NO_CACHE, toInboundCargoRequestFilterInput } from "./util";

const inboundCargoUpdateRequestsFilterBy = loader(
  "./graphql/cargoUpdateRequestsFilterBy.graphql"
);

type TInboundCargoFilterByResponse = GqlResponse<
  PagedConnection<TInboundCargoRequest>,
  "cargoUpdateRequestsFilterBy"
>;
type TSearchState = {
  showModal: boolean;
  criteria: TInboundCargoSearchCriteria;
};
const pathName = "/ticketing/trackers/inboundCargos";

const PADDING = 48;
const DEFULT_PAGE_SIZE = 25;
const INIT_SEARCH_CRITERIA = {
  startDate: dayjs().add(-1, "day").startOf("day").toISOString(),
  endDate: dayjs().endOf("day").toISOString()
};
const INIT_FILTER = toInboundCargoRequestFilterInput({ ...INIT_SEARCH_CRITERIA });

export const InboundCargoTracker = () => {
  const [searchModalState, setSearchModalState] = useState<TSearchState>({
    showModal: false,
    criteria: INIT_SEARCH_CRITERIA
  });

  const [showMessage, setShowMessage] = useState({ show: false, requestId: "" });

  const { loading, error, data, refetch } = useQuery<TInboundCargoFilterByResponse>(
    inboundCargoUpdateRequestsFilterBy,
    {
      fetchPolicy: FETCH_POLICY_NO_CACHE,
      variables: {
        filter: INIT_FILTER
      }
    }
  );
  const onShowMessage = useCallback((cargoRequest: TInboundCargoRequest) => {
    setShowMessage({ show: true, requestId: cargoRequest.id });
  }, []);

  const gridColumns: ColumnDef<TInboundCargoRequest>[] = useMemo(
    () => [
      {
        key: "1",
        header: "Cargo Id",
        accessorKey: "cargoId",
        size: 120,
        cell: TextCell
      },
      {
        header: "Cargo Name",
        accessorKey: "cargoName",
        cell: TextCell
      },
      {
        header: "Queued At",
        accessorKey: "receivedAt",
        size: 160,
        cell: DateCell
      },
      {
        header: "Transformed At",
        accessorKey: "transformedAt",
        size: 160,
        cell: DateCell
      },
      {
        header: "Processed At",
        accessorKey: "processedAt",
        size: 160,
        cell: DateCell
      },
      {
        header: "Status",
        accessorKey: "status",
        size: 120,
        cell: TextCell
      },
      {
        header: "Reason",
        accessorKey: "statusReason",
        cell: TextCell
      },
      {
        header: "Json",
        accessorKey: "id",
        cell: makeButtonCell(onShowMessage)
      }
    ],
    [onShowMessage]
  );

  const searchFormRef = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<number>(0);
  useEffect(() => {
    const { innerHeight } = window;
    setTop(
      innerHeight -
        (searchFormRef?.current?.getBoundingClientRect().top ?? 0) -
        (searchFormRef?.current?.getBoundingClientRect().height ?? 0) -
        PADDING
    );
  }, [searchFormRef]);

  const onPrevious = useCallback(() => {
    const startCursor = data?.cargoUpdateRequestsFilterBy?.pageInfo?.startCursor;
    refetch({
      before: startCursor ?? null,
      after: null,
      last: startCursor ? DEFULT_PAGE_SIZE : null,
      first: !startCursor ? DEFULT_PAGE_SIZE : null
    });
  }, [refetch, data]);

  const onNext = useCallback(() => {
    const endCursor = data?.cargoUpdateRequestsFilterBy?.pageInfo?.endCursor;
    refetch({
      before: null,
      after: endCursor ?? null,
      first: DEFULT_PAGE_SIZE,
      last: null
    });
  }, [refetch, data]);
  return (
    <div>
      <GlobalHeader
        pageName="Inbound cargo updates"
        buttonContent={[
          <SearchButton
            key={"searchButton"}
            onClick={() => setSearchModalState({ ...searchModalState, showModal: true })}
          />
        ]}
      />
      <TrackerNavbar selectedPath={pathName} />
      {loading && <LoadingPanel />}
      {error && <ApolloErrorViewer error={error} />}
      <div ref={searchFormRef}>
        <InboundCargoSearch
          open={searchModalState.showModal}
          searchCriteria={searchModalState.criteria}
          onClose={() => setSearchModalState({ ...searchModalState, showModal: false })}
          onSubmit={criteria => {
            setSearchModalState({ showModal: false, criteria });
            refetch({ filter: toInboundCargoRequestFilterInput(criteria) });
          }}
        />
      </div>

      <Flexbox flexDirection="column" gap="8px">
        <BaseTable
          className="carboniq-data-table"
          size={"small"}
          useColumnsSizing={true}
          columns={gridColumns}
          maxHeight={top}
          data={data?.cargoUpdateRequestsFilterBy.edges.flatMap(e => e.node)}
          emptyStateProps={{ image: <NoDataTableImage /> }}
        />
        <TrackerPager onPrevious={onPrevious} onNext={onNext} />
      </Flexbox>
      {showMessage.show && (
        <CargoJsonViewer
          requestId={showMessage.requestId}
          open={showMessage.show}
          onClose={() => setShowMessage({ show: false, requestId: "" })}
        />
      )}
    </div>
  );
};
