import { toString as toKString } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import { TLinkedTicket, TMovementGroup, TTicket, TVolume } from "ticketing/ticketing.types";
import { findMatchingVolume } from "./volume_util";

export const LinkedTicket = ({
  activeMovementGroup,
  ticket,
  disableLinks,
  onShowTicketRevisions,
  onUnlinkTicket,
  onEditTicket
}: {
  activeMovementGroup?: TMovementGroup | null;
  ticket: TLinkedTicket;
  disableLinks: boolean;
  onShowTicketRevisions: (ticketId: string) => void;
  onUnlinkTicket: (ticket: TLinkedTicket) => void;
  onEditTicket: (ticket: TTicket) => void;
}) => {
  const { picklists } = usePicklists();
  const matchingVolume: TVolume = findMatchingVolume(
    ticket,
    activeMovementGroup,
    picklists?.uomConversions
  );

  return (
    <div className="k-p-2">
      <div className="ticket-component">
        <div className="ticket-component-outter">
          <div style={{ display: "flex", flexWrap: "wrap", gap: "2px" }}>
            <span style={{ display: "flex", flexWrap: "nowrap", gap: "0" }}>
              <span style={{ color: "#09B0EC" }}>{`${ticket.ticketNumber}`}</span>
              {!!ticket.documentId && (
                <span
                  className="k-icon k-i-attachment k-icon-xs"
                  style={{
                    color: "limegreen",
                    fontSize: "xx-small",
                    verticalAlign: "super",
                    lineHeight: "1.0"
                  }}
                  title="Ticket has document attached"></span>
              )}
            </span>
            <span>{`(${toKString(ticket?.startDate, "yyyy-MM-dd")})`}</span>
          </div>
          <div style={{ display: "flex" }}>
            <Button
              disabled={disableLinks}
              title="Edit Ticket"
              fillMode={"flat"}
              icon={"edit"}
              onClick={() => onEditTicket(ticket)}
              style={{
                color: `${disableLinks ? "gray" : "#ff6358"}`,
                padding: 0
              }}></Button>
            <Button
              title="Ticket Revisions"
              fillMode={"flat"}
              icon={"clock"}
              onClick={() => onShowTicketRevisions(ticket.id)}
              style={{
                color: "var(--primary-icon-color)",
                padding: 0
              }}></Button>
            <Button
              title="Unlink Ticket"
              fillMode={"flat"}
              icon={"close-outline"}
              disabled={disableLinks}
              className={disableLinks ? "disabled" : "icon-red "}
              onClick={() => onUnlinkTicket(ticket)}
              style={{ padding: 0 }}></Button>
          </div>
        </div>
        <div className="ticket-component-label-wrapper">
          <Label>{`Net:${toKString(matchingVolume?.netVolume ?? 0.0, "0.0###")}`}</Label>
          <Label>{`Gross:${toKString(matchingVolume?.grossVolume ?? 0.0, "0.0###")}`}</Label>
          <Label>{matchingVolume?.unitOfMeasure?.name}</Label>
          <Label>{`${
            matchingVolume?.temperature
          }${matchingVolume?.temperatureUnitOfMeasure?.charAt(0)}`}</Label>
        </div>
      </div>
    </div>
  );
};
