import {
  DateQueryOperatorInput,
  IntegerQueryOperatorInput,
  LongQueryOperatorInput,
  QueryOperatorInput,
  StringQueryOperatorInput
} from "ticketing/ticketing.types";

export type TPageInfo = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
};

export type TNode<T> = {
  node: T;
};

export type PagedConnection<T> = {
  edges: [TNode<T>];
  pageInfo: TPageInfo;
};

export type TJsonObject = {
  [key: string]: unknown;
};

export enum MovementUpdateQueueStatus {
  NEW = "NEW",
  PROCESSING = "PROCESSING",
  DEFERED = "DEFERED",
  CANCELLED = "CANCELLED",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED"
}
export enum BatchUpdateQueueStatus {
  NEW = "NEW",
  PROCESSING = "PROCESSING",
  DEFERED = "DEFERED",
  CANCELLED = "CANCELLED",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED"
}

export enum CargoUpdateRequestStatus {
  NEW = "NEW",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS"
}

export enum ContractUpdateRequestStatus {
  NEW = "NEW",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS"
}

export enum RefdataMessageProcessStatus {
  NEW = "NEW",
  PROCESSING = "PROCESSING",
  DEFERED = "DEFERED",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED"
}

export type TOutboundMovementSearchCriteria = {
  startDate: string;
  endDate: string;
  endurDeliveryId?: string;
  endurBatchName?: string;
  status?: [MovementUpdateQueueStatus];
};

export type TOutboundBatchSearchCriteria = {
  startDate: string;
  endDate: string;
  name?: string;
  status?: [BatchUpdateQueueStatus];
};

export type TInboundCargoSearchCriteria = {
  startDate: string;
  endDate: string;
  cargoId?: string;
  cargoName?: string;
  status?: [CargoUpdateRequestStatus];
};

export type TInboundContractSearchCriteria = {
  startDate: string;
  endDate: string;
  contractId?: string;
  contractNumber?: string;
  status?: [ContractUpdateRequestStatus];
};

export type TInboundRefdataSearchCriteria = {
  startDate: string;
  endDate: string;
  entityName?: string;
  status?: [RefdataMessageProcessStatus];
};

export type TOutboundMovement = {
  id: string;
  movementId: string;
  version: number;
  batchId: string;
  movementGroupId: string;
  readyToInvoice: boolean;
  actualizationComplete: boolean;
  endurDeliveryId: string;
  endurBatchName: string;
  updatedBy: string;
  receivedAt: string;
  scheduledAt: string;
  processedAt: string;
  status: string;
  numRetries: number;
  nextRetryAt: string;
  statusReason: string;
  cargoMessageId: string;
  xmlMessageId: string;
};

export type TOutboundBatch = {
  id: string;
  batchId: string;
  cargoId: string;
  cargoMessageId: string;
  name: string;
  updatedBy: string;
  nextRetryAt: string;
  numRetries: number;
  processedAt: string;
  receivedAt: string;
  scheduledAt: string;
  status: BatchUpdateQueueStatus;
  statusReason: string;
  version: number;
  xmlMessageId: string;
};

export type TInboundCargoRequest = {
  id: string;
  cargoId: string;
  cargoName: string;
  receivedAt: string;
  transformedAt: string;
  processedAt: string;
  status: CargoUpdateRequestStatus;
  cargoResponse: TJsonObject;
  statusReason: string;
};

export type TInboundContractRequest = {
  id: string;
  contractId: number;
  contractNumber: string;
  receivedAt: string;
  transformedAt: string;
  processedAt: string;
  status: ContractUpdateRequestStatus;
  contractResponse: TJsonObject;
  statusReason: string;
};

export type TInboundRefdataRequest = {
  id: string;
  messageId: string;
  messageSeqNumber: string;
  entityName: string;
  receivedAt: string;
  processedAt: string;
  status: RefdataMessageProcessStatus;
  statusReason: string;
};

export type MovementUpdateQueueStatusInput = Omit<
  QueryOperatorInput<MovementUpdateQueueStatus>,
  "inOrNull" | "regex"
>;

export type BatchUpdateQueueStatusInput = Omit<
  QueryOperatorInput<BatchUpdateQueueStatus>,
  "inOrNull" | "regex"
>;

export type ContractUpdateRequestStatusInput = Omit<
  QueryOperatorInput<ContractUpdateRequestStatus>,
  "inOrNull" | "regex"
>;

export type CargoUpdateRequestStatusInput = Omit<
  QueryOperatorInput<CargoUpdateRequestStatus>,
  "inOrNull" | "regex"
>;

export type RefdataMessageProcessStatusInput = Omit<
  QueryOperatorInput<RefdataMessageProcessStatus>,
  "inOrNull" | "regex"
>;

export type TMovementUpdateQueueFilterInput = {
  receivedAt: DateQueryOperatorInput;
  movementId?: LongQueryOperatorInput;
  endurDeliveryId?: StringQueryOperatorInput;
  endurBatchName?: StringQueryOperatorInput;
  status?: MovementUpdateQueueStatusInput;
};

export type TBatchUpdateQueueFilterInput = {
  receivedAt: DateQueryOperatorInput;
  name?: StringQueryOperatorInput;
  status?: BatchUpdateQueueStatusInput;
};

export type TCargoUpdateRequestFilterInput = {
  receivedAt: DateQueryOperatorInput;
  cargoId?: StringQueryOperatorInput;
  cargoName?: StringQueryOperatorInput;
  status?: CargoUpdateRequestStatusInput;
};

export type TContractUpdateRequestFilterInput = {
  receivedAt: DateQueryOperatorInput;
  contractId?: IntegerQueryOperatorInput;
  contractNumber?: StringQueryOperatorInput;
  status?: ContractUpdateRequestStatusInput;
};

export type TRefDataMessageFilterInput = {
  receivedAt: DateQueryOperatorInput;
  entityName?: StringQueryOperatorInput;
  status?: RefdataMessageProcessStatusInput;
};
