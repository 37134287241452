import {
  GridColumn as Column,
  Grid,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { loader } from "graphql.macro";
import { useState } from "react";
import LoadingPanel from "../../../shared/components/LoadingPanel";
import { useQuery } from "@apollo/client";
import { GqlResponse, Picklists } from "ticketing/ticketing.types";

import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";


type TChildMovementTicketVolume = {
  id: string;
  netVolume: number;
  grossVolume: number;
  unitOfMeasure: Partial<Picklists.TUnitOfMeasure>;
  temperature: number;
  temperatureUnitOfMeasure: string;
};

type TChildMovementTicket = {
  id: string;
  startDate: string | Date;
  ticketNumber: string;
  volumes: TChildMovementTicketVolume[];
};

type TChildMovement = {
  id: string;
  enterpriseSystemCode: string;
  startDate: string | Date;
  tickets: TChildMovementTicket[];
};
type TSplitMovement = {
  children: TChildMovement[];
};

type Response = GqlResponse<TSplitMovement, "movement">;

const SETTLEMENT_SPLITS = loader(
  "../../ticketing-graphql/settlementSplits.graphql"
);

//TODO: use matching volume
const transformData = (movemement: TSplitMovement) => {
  return movemement.children?.map((c) => ({
    enterpriseSystemCode: c.enterpriseSystemCode,
    startDate: new Date(`${c.startDate}T00:00:00`),
    ticketNumber: c.tickets?.at(0)?.ticketNumber,
    netVolume: c.tickets?.at(0)?.volumes?.at(0)?.netVolume,
    grossVolume: c.tickets?.at(0)?.volumes?.at(0)?.grossVolume,
    unitOfMeasure: c.tickets?.at(0)?.volumes?.at(0)?.unitOfMeasure?.name,
    temperature: `${c.tickets?.at(0)?.volumes?.at(0)?.temperature} ${c.tickets?.at(0)?.volumes?.at(0)?.temperatureUnitOfMeasure
      }`,
  }));
};

type SettlementSplitsModalProps = {
  movementId: string;
  enterpriseSystemCode: string;
  handleCancel: () => void;
};

/***
 *
 */
const SettlementSplitsModal = ({ movementId }: SettlementSplitsModalProps) => {
  const { loading, error } = useQuery<Response>(SETTLEMENT_SPLITS, {
    fetchPolicy: "no-cache",
    variables: { id: movementId },
    onCompleted: (data) => setTransformedData(transformData(data.movement)),
  });

  const [transformedData, setTransformedData] = useState<Array<any>>([]);

  return (
    <>
      <div className={"content-wrapper"}>
        <div className={"grid-wrapper"}>
          <div className={"card-container"}>
            {loading && <LoadingPanel />}
            {error && <ApolloErrorViewer error={error} />}
            <Grid
              style={{ height: "calc(100vh - 600px)" }}
              data={transformedData}
              resizable
              dataItemKey="id"
            >
              <GridNoRecords>
                <div style={{ color: "gray" }}>There is no data available</div>
              </GridNoRecords>
              <Column
                title="Delivery Id"
                field="enterpriseSystemCode"
                width="140px"
              />
              <Column
                title="Movement Date"
                field="startDate"
                width="140px"
                format="{0:MM/dd/yy}"
              />
              <Column title="Ticket Id" field="ticketNumber" width="140px" />
              <Column title="Net Volume" field="netVolume" width="140px" />
              <Column title="Gross Volume" field="grossVolume" width="140px" />
              <Column
                title="Unit Of Measure"
                field="unitOfMeasure"
                width="140px"
              />
              <Column title="Temperature" field="temperature" width="140px" />
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettlementSplitsModal;
