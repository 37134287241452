import {
  BaseTable,
  Drawer,
  Heading,
  Section,
  Sizes,
  Text,
  TextTypes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { TValidationsConfig } from "ticketing/ticketing.types";

type TValidationsConfigDetail = { label: string; value?: string | number | boolean };
const columns: ColumnDef<TValidationsConfigDetail>[] = [
  {
    accessorKey: "label",
    cell: arg => (
      <Text size={Sizes.Small} color="light">
        {arg.getValue() as string}
      </Text>
    )
  },
  {
    accessorKey: "value",
    cell: arg => <Text size={Sizes.Small}>{(arg.getValue() as string) ?? "-"}</Text>
  }
];
const toBool = (val?: number | boolean) => (val ? "True" : "False");
const ShowOpsValidationDetails = ({
  details,
  onClose
}: {
  details: TValidationsConfig;
  onClose: () => void;
}) => {
  const data = useMemo(
    () => [
      { label: "Code", value: details.code },
      { label: "Business Unit Defined", value: details.businessUnitDefined },
      { label: "Legal Entity Id", value: details.legalEntityId },
      { label: "Run For Aggregated", value: toBool(details.runForAggregated) },
      { label: "Run For Cancelled Noms", value: toBool(details.runForCancelledNomIndicator) },
      { label: "Run For Converted Data", value: toBool(details.runForConvertedDataIndicator) },
      { label: "Run For Gain Loss Nom", value: toBool(details.runForGainLossNomIndicator) },
      { label: "Run For Non Transit", value: toBool(details.runForNonTransitIndicator) },
      { label: "Run For Transit", value: toBool(details.runForTransitIndicator) },
      { label: "Run For Paper Rins", value: toBool(details.runForPaperRinsNomIndicator) },
      { label: "Run For Planned Nom", value: toBool(details.runForPlannedNomIndicator) },
      { label: "Run For Rins Nom", value: toBool(details.runForRinsNomIndicator) },
      { label: "Run For Soft Delete", value: toBool(details.runForSoftDeleteIndicator) },
      { label: "Run For Zero Volume", value: toBool(details.runForZeroVolumeIndicator) },

      { label: "Validator Script", value: details.validatorScript }
    ],
    [details]
  );
  return (
    <Drawer
      header={
        <Heading className="setting-form-heading" type={TextTypes.H2}>
          {"OPS Validation Details"}
        </Heading>
      }
      borders={false}
      closeButton={true}
      sticky
      mask={true}
      open={true}
      size={Sizes.Medium}
      onClose={onClose}
      actions={[
        {
          label: "Close",
          action: onClose,
          props: {
            variant: Variants.Outlined
          }
        }
      ]}>
      <BaseTable size="small" noHeader noFooter data={data} columns={columns} disableRowHover />
      <Section>
        <Heading type={TextTypes.H3} gutter color="light">
          Message:
        </Heading>
        <Text color="normal">{details.messageText}</Text>
      </Section>
    </Drawer>
  );
};

export default ShowOpsValidationDetails;
