//======================================================
// DATE
//======================================================

interface IDateRange {
  firstDay: Date;
  lastDay: Date;
}
const DD_MM_YYYY_LENGTH = 10;
const MINUTES_PER_HOUR = 60;
const SECONDS_PER_MINUTES = 60;
const MILLISECONDS = 1000;
const HOURS_PER_DAY = 24;
const DAYS_PER_WEEK = 7;
const SATURDAY_IDX = 6;
const DAY_31 = 31;
const DECEMBER_IDX = 11;

// Convert Date to ISO format (yyyy-mm-dd)
export const convertToISODate = (fullDate: Date | null): string => {
  return new Date(fullDate!.toDateString())
    .toISOString()
    .slice(0, DD_MM_YYYY_LENGTH);
};
//-----------------------By Day------------------
// get tomorrow from a date
export const dayRangeTomorrow = (curr: Date | null): IDateRange => {
  const tomorrow = new Date();
  //tomorrow.setDate(curr!.getDate()+1);

  tomorrow.setTime(
    curr!.getTime() +
      HOURS_PER_DAY * MINUTES_PER_HOUR * SECONDS_PER_MINUTES * MILLISECONDS
  );

  const firstDay = tomorrow;
  const lastDay = tomorrow;
  return { firstDay, lastDay };
};
// get tomorrow from a date
export const dayRangeYesterday = (curr: Date | null): IDateRange => {
  const yesterday = new Date();
  //yesterday.setDate(curr!.getDate()-1);
  yesterday.setTime(
    curr!.getTime() -
      HOURS_PER_DAY * MINUTES_PER_HOUR * SECONDS_PER_MINUTES * MILLISECONDS
  );

  const firstDay = yesterday;
  const lastDay = yesterday;
  return { firstDay, lastDay };
};

//--------------------------By Week-----------
//0=sunday, ..., 6=saturday
export function getLastWeekDay(date: Date | null, weekday: number) {
  const d = new Date(date!);
  d.setDate(d.getDate() + weekday - d.getDay()); // move to last of given weekday
  return d;
}
// first/last day of current week
export const dayRangeInCurrentWeek = (curr: Date | null): IDateRange => {
  const firstDay = new Date(curr!.setDate(curr!.getDate() - curr!.getDay()));
  const lastDay = new Date(curr!.setDate(curr!.getDate() - curr!.getDay() + 6));
  return { firstDay, lastDay };
};
// first/last day of previous week
export const dayRangeInPreviousWeek = (curr: Date | null): IDateRange => {
  const lastDay = getLastWeekDay(curr, SATURDAY_IDX - DAYS_PER_WEEK);
  const firstDay = getLastWeekDay(curr, 0 - DAYS_PER_WEEK);

  return { firstDay, lastDay };
};
// first/last day of next week
export const dayRangeInNextWeek = (curr: Date | null): IDateRange => {
  const lastDay = getLastWeekDay(curr, SATURDAY_IDX + 7);
  const firstDay = getLastWeekDay(curr, 0 + 7);
  return { firstDay, lastDay };
};

//-----------------------By Month----------------
// first/last day of current month of a date
export const dateRangeInCurrentMonth = (date: Date | null): IDateRange => {
  const y = date!.getFullYear(),
    m = date!.getMonth();
  const firstDay = new Date(y, m, 1);
  const lastDay = new Date(y, m + 1, 0);
  return { firstDay, lastDay };
};
// first/last day of previous month
export const dayRangeInPreviousMonth = (date: Date | null): IDateRange => {
  const y = date!.getFullYear(),
    m = date!.getMonth();
  const firstDay = new Date(y, m - 1, 1);
  const lastDay = new Date(y, m + 1 - 1, 0);
  return { firstDay, lastDay };
};
// first/last day of next month
export const dayRangeInNextMonth = (date: Date | null): IDateRange => {
  const y = date!.getFullYear(),
    m = date!.getMonth();
  const firstDay = new Date(y, m + 1, 1);
  const lastDay = new Date(y, m + 1 + 1, 0);
  return { firstDay, lastDay };
};
//-----------------------By Year----------------
// first/last day of current year
export const dayRangeInCurrentYear = (date: Date | null): IDateRange => {
  const y = date!.getFullYear();
  const firstDay = new Date(y, 0, 1);
  const lastDay = new Date(y, DECEMBER_IDX, DAY_31);
  return { firstDay, lastDay };
};
// first/last day of previous year
export const dayRangeInPreviousYear = (date: Date | null): IDateRange => {
  const y = date!.getFullYear();
  const firstDay = new Date(y - 1, 0, 1);
  const lastDay = new Date(y - 1, DECEMBER_IDX, DAY_31);
  return { firstDay, lastDay };
};
// first/last day of next year
export const dayRangeInNextYear = (date: Date | null): IDateRange => {
  const y = date!.getFullYear();
  const firstDay = new Date(y + 1, 0, 1);
  const lastDay = new Date(y + 1, DECEMBER_IDX, DAY_31);
  return { firstDay, lastDay };
};
//======================================================
// NAME & NUMBER
//======================================================

// convert camel case
// export const convertCamelCase = (camelCase: string): string => {
//   return camelCase
//     .replace(/([A-Z])/g, (match: string) => ` ${match}`)
//     .replace(/^./, (match: string) => match.toUpperCase());
// };

// format number with comma
// export const numberWithComma = (num: number): number | string => {
//   return num
//     ? num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
//     : 0;
// };

//======================================================
// ARRAY
//======================================================

// flatten nested arrays
export const flattenArray = (arr: Array<any>): Array<any> => {
  let result: Array<any> = [];
  arr.forEach((subArr) => {
    result = [...result, ...subArr];
  });
  return result;
};

//======================================================
// JSON
//======================================================

// Filter unique values of a key (with original data type)
export const uniqueKeyValues = (
  jsonArray: Array<any>,
  key: string
): Array<any> => {
  const allElements: Array<any> = jsonArray.map((item: any) => {
    return item[key];
  });
  return [...new Set(allElements)];
};

// Filter unique values of a key and convert to string
export const uniqueKeyValueStrings = (
  jsonArray: any[],
  key: string
): Array<any> => {
  const allElements = jsonArray.map((item) => {
    return item[key] ? item[key].toString() : "";
  });
  return [...new Set(allElements)];
};

// Group JSON array by a list of keys (nested)
export const groupBy = (arr: any[], fields: string | any[]): Array<any> => {
  const field = fields[0];
  if (!field) {
    return arr;
  }
  const retArr = Object.values(
    arr.reduce((obj, current) => {
      if (!obj[current[field]]) {
        obj[current[field]] = { field, value: current[field], rows: [] };
      }
      obj[current[field]].rows.push(current);
      return obj;
    }, {})
  );

  // recurse for each child's rows if there are remaining fields
  if (fields.length) {
    retArr.forEach((obj: any) => {
      obj.count = obj.rows.length;
      obj.rows = groupBy(obj.rows, fields.slice(1));
    });
  }
  return retArr;
};

// Summarize values of a key
export const sumOfValues = (array: any[], key: string) =>
  array?.reduce((sum: number, currentValue) => {
    return sum + +currentValue[key];
  }, 0);

//pick subset fields from object
export const pick = (obj: { [x: string]: any }, keys: any[]) => {
  return Object.assign(
    {},
    ...keys.map((key) => ({
      [key]: obj[key],
    }))
  );
};
