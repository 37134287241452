//import React from 'react'

import { toString as toKString } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { TMovement } from "ticketing/ticketing.types";

export const MovementChildDetails = ({
  childMovement,
  isActiveChild,
  onActiveChildChanged
}: {
  childMovement: Partial<TMovement>;
  isActiveChild: boolean;
  onActiveChildChanged: (child: Partial<TMovement>) => void;
}) => {
  return (
    <div
      className="movement-detail-container"
      role="none"
      onClick={e => {
        onActiveChildChanged(childMovement);
        e.stopPropagation();
      }}>
      <Button
        icon="round-corners"
        fillMode={"flat"}
        className={isActiveChild ? "color-yellow" : "color-grey"}></Button>
      <Label className="detail-label">
        {toKString(childMovement?.startDate!, "yyyy-MM-dd")}
      </Label>
      <Label className="detail-label">{childMovement.product?.name}</Label>
      <Label className="prefix-text">at</Label>
      <Label className="detail-label-wide">{childMovement.titleTransferFacility?.name}</Label>
      <Label className="prefix-text">Via</Label>
      <Label className="detail-label">{childMovement.activityType?.name}</Label>
      <Label className="prefix-text">From</Label>
      <Label className="detail-label-narrow">{childMovement.recDeal?.contractNumber}</Label>
      <Label className="prefix-text">To</Label>
      <Label className="detail-label-narrow">{childMovement.delDeal?.contractNumber}</Label>
      <Label className="detail-label">{childMovement.incoterm?.name}</Label>
      <div style={{ display: "flex" }}></div>
    </div>
  );
};
