import { gql, useLazyQuery } from "@apollo/client";
import React, { useState, useCallback } from "react";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import { GqlResponse, TCarrier } from "ticketing/ticketing.types";

// for type ahead
const GQL = gql`
  query carriersFilterBy($enterpriseSystemId: Int!, $name: String!) {
    carriers: carriersFilterBy(
      filter: { enterpriseSystemId: $enterpriseSystemId, scacOrName: { regexIgnoreCase: $name } }
    ) {
      id
      name
      scac
    }
  }
`;

const delay = 500;
type Response = GqlResponse<TCarrier[], "carriers">;

const useSearchCarriers = () => {
  const { picklists } = usePicklists();
  const [searchResults, setSearchResults] = useState<TCarrier[]>([]);

  const [filter, { loading, error }] = useLazyQuery<Response>(GQL, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: (data) => setSearchResults(transform(data.carriers)),
  });

  const transform = (data: TCarrier[]) =>
    data
      .map((c) => ({
        ...c,
        displayName: `${c.scac} - ${c.name}`,
      }))
      .sort((a, b) => a.displayName.localeCompare(b.displayName));

  const timeout = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const search = (name: string) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      filter({ variables: { enterpriseSystemId: picklists?.enterpriseSystemId, name: `%${name}%` } });
    }, delay);
  };

  const clear = useCallback(() => {
    setSearchResults([]);
  }, [setSearchResults]);
  return [search, searchResults, loading, error, clear] as const;
};

export default useSearchCarriers;
