import { bool, string } from "prop-types";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Input as KendoInput } from "@progress/kendo-react-inputs";
import { Error as ErrorLabel, Hint, Label } from "@progress/kendo-react-labels";

export const Input = (fieldRenderProps: {
  [x: string]: any;
  validationMessage: any;
  touched: any;
  label: any;
  id: any;
  valid: any;
  disabled: any;
  hint: any;
  type: any;
  optional: any;
}) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper>
      <Label
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
      >
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <KendoInput
          valid={valid}
          type={type}
          id={id}
          disabled={disabled}
          ariaDescribedBy={`${hintId} ${errorId}`}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <ErrorLabel id={errorId}>{validationMessage}</ErrorLabel>
        )}
      </div>
    </FieldWrapper>
  );
};

Input.displayName = "Input";
Input.propTypes = {
  valid: bool,
  value: string,
  id: string,
  optional: bool,
  label: string,
  hint: string,
  validationMessage: string,
  visited: bool,
};
