import { Button, FormField, Modal, Select, Variants } from "@sede-x/shell-ds-react-framework";
import { useEffect, useState } from "react";

import { CIQOption, TCICustomer, TCustomerFilter } from "../carbonIQtypes";

const CustomerLookup = ({
  onClose,
  onSubmit,
  customerData
}: {
  onClose: () => void;
  onSubmit: (data: TCustomerFilter) => void;
  customerData: Partial<TCICustomer>[];
}) => {
  const [open, setOpen] = useState(true);
  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  const [customersList, setCustomersList] = useState<any[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<CIQOption[]>([]);

  useEffect(() => {
    setCustomersList(
      customerData.map(cust => ({
        value: cust?.displayName,
        label: cust?.displayName,
        id: cust?.id
      }))
    );
  }, [customerData]);

  return (
    <Modal
      title="Search for Customer(s)"
      width={"700px"}
      height={"600px"}
      mask={true}
      open={open}
      onClose={() => {
        handleOnClose();
      }}>
      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          onSubmit({
            customerIDs: selectedCustomer
          });
          handleOnClose();
        }}>
        <FormField size={"medium"} id="customer-name" label="Customer Name">
          <Select
            options={customersList}
            size={"medium"}
            id="customer-name"
            placeholder="Name, ID"
            optionLabelProp="label"
            mode="tags"
            filterOption={true}
            optionFilterProp="value"
            onChange={data => {
              setSelectedCustomer(
                data
                  .map((elm: CIQOption) => customersList.find(item => item.value === elm))
                  .filter(function (elm: CIQOption) {
                    // used filter to remove undefined elements, which don't exists in list
                    return elm !== undefined;
                  })
              );
            }}
          />
        </FormField>
        <div style={{ paddingBottom: "200px" }}></div>
        <div className="from-button-wrap">
          <Button
            variant={Variants.Outlined}
            onClick={() => {
              handleOnClose();
            }}>
            Cancel
          </Button>
          <Button type="submit">Search</Button>
        </div>
      </form>
    </Modal>
  );
};

export default CustomerLookup;
