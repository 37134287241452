import { ApolloError, useMutation, useQuery } from "@apollo/client";
import {
  Drawer,
  FormField,
  Heading,
  Select,
  Sentiments,
  Sizes,
  TextInput,
  TextTypes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import { loader } from "graphql.macro";
import { useCallback, useEffect, useState } from "react";
import LoadingPanel from "shared/components/LoadingPanel";
import { TCIWeightedAverage } from "../carbonIQtypes";

const ComponentsList = loader("../graphql/lookupComponents.graphql");

const TerminalsList = loader("../graphql/lookupTerminals.graphql");

const ciAddNewWeightedAverage = loader(
  "../graphql/mutation-CarbonAddNewWeightedAverage.graphql"
);

const ciUpdateWeightedAverage = loader(
  "../graphql/mutation-CarbonUpdateWeightedAverage.graphql"
);
const WeightedAverageCrud = ({
  details,
  onClose,
  onSubmit,
  weightedAverageData
}: {
  details: Partial<TCIWeightedAverage> | null;
  onClose: () => void;
  onSubmit: any;
  weightedAverageData: Partial<TCIWeightedAverage>[];
}) => {
  const [open, setOpen] = useState(true);
  const handleOnClose = (data: any = undefined) => {
    onSubmit(data);
    onClose();
    setOpen(false);
  };

  const numberInputOnWheelPreventChange = (e: any) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };
  const [components, setComponents] = useState([]);
  const [terminals, setTerminals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [preFillWeightedAverage, setPreFillWeightedAverage] =
    useState<Partial<TCIWeightedAverage>>();

  const isStateAdd: boolean = details === null || details === undefined;

  // for a year - a duplicate row is not allowed
  const validateForDuplicate = useCallback(
    (activeWeightedAverage: Partial<TCIWeightedAverage> | undefined) => {
      if (activeWeightedAverage === undefined || activeWeightedAverage?.year === undefined) {
        return;
      }
      let dataForYear = weightedAverageData.filter(
        (wa: Partial<TCIWeightedAverage>) => wa?.year === activeWeightedAverage?.year
      );
      if (!isStateAdd) {
        // in edit mode we don't want to compare the active record with itself
        dataForYear = dataForYear.filter(
          (d: Partial<TCIWeightedAverage>) => d.id !== activeWeightedAverage?.id
        );
      }
      const matchFound = dataForYear.filter(
        (data: Partial<TCIWeightedAverage>) =>
          data?.component?.id === preFillWeightedAverage?.component?.id &&
          data?.terminal?.id === preFillWeightedAverage?.terminal?.id &&
          data?.averageCI === preFillWeightedAverage?.averageCI &&
          data?.feedStock?.trim() === preFillWeightedAverage?.feedStock?.trim() &&
          data?.countryOfOrigin === preFillWeightedAverage?.countryOfOrigin
      );
      setIsDuplicate(matchFound?.length > 0);
    },
    [
      isStateAdd,
      preFillWeightedAverage?.averageCI,
      preFillWeightedAverage?.component?.id,
      preFillWeightedAverage?.countryOfOrigin,
      preFillWeightedAverage?.feedStock,
      preFillWeightedAverage?.terminal?.id,
      weightedAverageData
    ]
  );

  const disableSave =
    !preFillWeightedAverage?.component ||
    !preFillWeightedAverage?.terminal ||
    !preFillWeightedAverage?.year ||
    !preFillWeightedAverage?.averageCI ||
    !preFillWeightedAverage?.feedStock ||
    !preFillWeightedAverage?.countryOfOrigin ||
    isDuplicate;

  //Fetching Components List
  const { loading: ciqComponentLoading, error: eCIQComponent } = useQuery(ComponentsList, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      setComponents(
        data.ciComponentsFilterBy.map((component: any) => ({
          value: component.name,
          label: component.name,
          key: component.id
        }))
      );
    }
  });

  const { loading: ciqTerminalLoading, error: eCIQTerminal } = useQuery(TerminalsList, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      setTerminals(
        data.ciTerminalsFilterBy.map((terminal: any) => ({
          value: terminal.displayName,
          label: terminal.displayName,
          key: terminal.id
        }))
      );
    }
  });

  useEffect(() => {
    setPreFillWeightedAverage(details ?? {});
  }, [details]);

  const [
    addNewWeightedAverage,
    { loading: addNewWeightedAverageLoading, error: addNewWeightedAveragelError }
  ] = useMutation(ciAddNewWeightedAverage, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => handleOnClose(data),
    onError: e => {
      setLoading(false);
    }
  });

  useEffect(() => {
    validateForDuplicate(preFillWeightedAverage);
  }, [preFillWeightedAverage, validateForDuplicate]);

  // Update Weighted Average
  const [
    updateWeightedAverage,
    { loading: updateWeightedAverageLoading, error: updateWeightedAverageError }
  ] = useMutation(ciUpdateWeightedAverage, {
    fetchPolicy: "no-cache",
    onCompleted: data => handleOnClose(data),
    onError: () => {
      setLoading(false);
    }
  });

  const isLoading = [
    loading,
    updateWeightedAverageLoading,
    addNewWeightedAverageLoading,
    ciqTerminalLoading,
    ciqComponentLoading
  ].some(elm => elm);

  const onSubmitClick = () => {
    setLoading(true);
    const tempdata = {
      comments: preFillWeightedAverage?.comments || "",
      componentId: preFillWeightedAverage?.component?.id,
      terminalId: preFillWeightedAverage?.terminal?.id,
      year: preFillWeightedAverage?.year,
      averageCI: preFillWeightedAverage?.averageCI,
      feedStock: preFillWeightedAverage?.feedStock?.trim(),
      countryOfOrigin: preFillWeightedAverage?.countryOfOrigin?.trim()
    };
    isStateAdd
      ? addNewWeightedAverage({
          variables: {
            weightedAverage: tempdata
          }
        })
      : updateWeightedAverage({
          variables: {
            weightedAverage: {
              ...tempdata,
              id: preFillWeightedAverage?.id,
              version: preFillWeightedAverage?.version
            }
          }
        });
  };

  const isError = [
    addNewWeightedAveragelError,
    updateWeightedAverageError,
    eCIQComponent,
    eCIQTerminal
  ].some(e => e);
  const errors = [
    addNewWeightedAveragelError,
    updateWeightedAverageError,
    eCIQComponent,
    eCIQTerminal
  ].filter((e): e is ApolloError => Boolean(e));
  return (
    <Drawer
      header={
        <Heading className="setting-form-heading" type={TextTypes.H2}>
          {(isStateAdd ? "Add New" : "Update") + " Weighted Average"}
        </Heading>
      }
      borders={false}
      closeButton={false}
      sticky
      mask={true}
      open={open}
      size={Sizes.Medium}
      onClose={() => {
        handleOnClose();
      }}
      actions={[
        {
          label: "Cancel",
          action: () => {
            handleOnClose();
          },
          props: {
            variant: Variants.Outlined
          }
        },
        {
          label: "Save",
          action: () => {
            setLoading(true);
            onSubmitClick();
          },
          props: {
            disabled: disableSave
          }
        }
      ]}>
      {isError && (
        <CommonErrorComponent
          error={errors}
          customErrorMessage={isDuplicate ? "Duplicate record" : undefined}
        />
      )}
      <form>
        <FormField
          size={"medium"}
          id="component-name-label"
          label="Component"
          mandatory={true}
          bottomLeftHelper={{
            content: !preFillWeightedAverage?.component ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <Select
            options={components}
            size={"medium"}
            invalid={!preFillWeightedAverage?.component}
            id="component-name-input"
            placeholder="Select a Component"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            labelInValue={true}
            value={preFillWeightedAverage?.component?.name}
            onChange={data => {
              setPreFillWeightedAverage({
                ...preFillWeightedAverage,
                component: !data ? data : { id: data.key, name: data.value }
              });
            }}
          />
        </FormField>
        <FormField
          size={"medium"}
          id="terminal-name-label"
          label="Terminal"
          mandatory={true}
          bottomLeftHelper={{
            content: !preFillWeightedAverage?.terminal ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <Select
            options={terminals}
            size={"medium"}
            invalid={!preFillWeightedAverage?.terminal}
            id="terminal-name-input"
            placeholder="Select a Terminal"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            labelInValue={true}
            value={preFillWeightedAverage?.terminal?.displayName}
            onChange={data => {
              setPreFillWeightedAverage({
                ...preFillWeightedAverage,
                terminal: !data ? data : { id: data.key, displayName: data.value }
              });
            }}
          />
        </FormField>
        <FormField
          size={"medium"}
          id="year-label"
          mandatory={true}
          label="Year"
          bottomLeftHelper={{
            content: !preFillWeightedAverage?.year ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              setPreFillWeightedAverage({
                ...preFillWeightedAverage,
                year: +event.target.value.trim()
              });
            }}
            value={preFillWeightedAverage?.year ?? ""}
            invalid={!preFillWeightedAverage?.year}
          />
        </FormField>
        <FormField
          size={"medium"}
          id="average-ci-label"
          mandatory={true}
          label="Average CI"
          bottomLeftHelper={{
            content: !preFillWeightedAverage?.averageCI ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            type={"number"}
            step=".01"
            onWheel={numberInputOnWheelPreventChange}
            max={100.0}
            min={-100.0}
            onChange={event => {
              setPreFillWeightedAverage({
                ...preFillWeightedAverage,
                averageCI: +event.target.value.trim()
              });
            }}
            value={preFillWeightedAverage?.averageCI ?? ""}
            invalid={!preFillWeightedAverage?.averageCI}
          />
        </FormField>
        <FormField
          size={"medium"}
          id="feedstock-label"
          mandatory={true}
          label="Feedstock"
          bottomLeftHelper={{
            content: !preFillWeightedAverage?.feedStock ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              setPreFillWeightedAverage({
                ...preFillWeightedAverage,
                feedStock: event.target.value
              });
            }}
            value={preFillWeightedAverage?.feedStock ?? ""}
            invalid={!preFillWeightedAverage?.feedStock}
          />
        </FormField>

        <FormField
          size={"medium"}
          id="country-label"
          mandatory={true}
          label="Country of Origin"
          bottomLeftHelper={{
            content: !preFillWeightedAverage?.countryOfOrigin ? (
              <b>Field can't be blank.</b>
            ) : (
              ""
            ),
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              setPreFillWeightedAverage({
                ...preFillWeightedAverage,
                countryOfOrigin: event.target.value
              });
            }}
            value={preFillWeightedAverage?.countryOfOrigin ?? ""}
            invalid={!preFillWeightedAverage?.countryOfOrigin}
          />
        </FormField>

        <FormField size={"medium"} id="comments-label" label="Comments">
          <TextInput
            onChange={event => {
              setPreFillWeightedAverage({
                ...preFillWeightedAverage,
                comments: event.target.value
              });
            }}
            value={preFillWeightedAverage?.comments ?? ""}
          />
        </FormField>
      </form>

      {isLoading && <LoadingPanel />}
    </Drawer>
  );
};

export default WeightedAverageCrud;
