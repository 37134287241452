import { Button, FormField, Modal, Select, Variants } from "@sede-x/shell-ds-react-framework";
import { CIQOption, TProductBlendFetchData } from "carbonIQ/carbonIQtypes";
import { useState } from "react";

const ProductBlendSearch = ({
  onClose,
  onSubmit,
  masterData
}: {
  onClose: () => void;
  onSubmit: (data: CIQOption[]) => void;
  masterData: TProductBlendFetchData[] | undefined;
}) => {
  const [open, setOpen] = useState(true);
  const handleOnClose = () => {
    if (onClose) onClose();
    setOpen(false);
  };

  const customersList = masterData?.map((elem: TProductBlendFetchData) => {
    return {
      value: elem.name,
      label: elem.name,
      key: elem.id
    };
  });

  const [selectedCustomer, setSelectedCustomer] = useState<CIQOption[]>([]);

  return (
    <Modal
      title="Search Generic Product"
      width={"700px"}
      height={"600px"}
      mask={true}
      open={open}
      onClose={() => {
        handleOnClose();
      }}>
      <form
        onSubmit={event => {
          event.preventDefault();
          onSubmit(selectedCustomer);
          handleOnClose();
        }}>
        <FormField size={"medium"} id="customer-name-label" label="Generic Product name">
          <Select
            options={customersList}
            size={"medium"}
            id="product-name"
            placeholder="Select Generic Product name"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            mode="tags"
            labelInValue={true}
            onChange={(data: CIQOption[]) => {
              setSelectedCustomer(
                data.filter(function (elm: CIQOption) {
                  // used filter to remove undefined elements, which don't exists in list
                  return elm.key !== undefined;
                })
              );
            }}
          />
        </FormField>
        <div style={{ paddingBottom: "200px" }}></div>

        <div className="from-button-wrap">
          <Button
            variant={Variants.Outlined}
            onClick={() => {
              handleOnClose();
            }}>
            Cancel
          </Button>
          <Button type="submit">Search</Button>
        </div>
      </form>
    </Modal>
  );
};

export default ProductBlendSearch;
