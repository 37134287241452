import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { getDate } from "@progress/kendo-date-math";
import { Button, Chip } from "@progress/kendo-react-buttons";
import {
  Dialog,
  DialogActionsBar,
  Window,
  WindowMoveEvent
} from "@progress/kendo-react-dialogs";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { getCancelTokenSource, getFile, uploadTicketDocument } from "forecast/api";
import { loader } from "graphql.macro";
import React, { useCallback, useEffect, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import FileDropZone from "shared/components/FileDropZone";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";

import { AxiosProgressEvent, CancelTokenSource } from "axios";
import useValidationConfigs from "ticketing/hooks/useValidationConfigs";
import {
  GqlResponse,
  Picklists,
  TAllocationMovement,
  TMovement,
  TMovementGroup,
  TMovementMeasure,
  TPdfDocument,
  TPicklists,
  TTicket,
  TTicketInput,
  TVolumeInput
} from "ticketing/ticketing.types";
import { TEnterpriseSystem } from "types";
import {
  CELSIUS_TEMPERATURE_MEASURE,
  DEFAULT_TEMPERATURE_MEASURE,
  DEFAULT_UNIT_OF_MEASURE,
  ENDUR_CELSIUS_TEMPERATURE_MEASURE,
  ENDUR_FAHRENHEIT_TEMPERATURE_MEASURE,
  FAHRENHEIT_TEMPERATURE_MEASURE,
  MAX_FILE_SIZE,
  MODE_OF_TRANSPORT_TRUCK,
  NON_STCAN_DEFAULT_FAHRENHEIT_TEMPERATURE,
  STCAN_DEFAULT_CELSIUS_TEMPERATURE,
  buildMovementAllocationsPayload,
  equalsIgnoreCase,
  findPrimaryMovement,
  fromISODateTimeString,
  isMotRailOrTruck,
  isMovementDaily,
  isSTCan,
  isValidMOT,
  mapTempUOM,
  requireVolAndMassUnit,
  toTicketInputAdd,
  toTicketInputUpdate,
  truncateTo
} from "../../utils";
import VolumeAllocationModal from "../movements/VolumeAllocationModal";
import { findMatchingVolume } from "../movements/volume_util";
import "./../../../forecast/ForecastImport.css";
import PdfComponent from "./PdfComponent";
import SplitTicketModal from "./SplitTicketModal";
import TicketForm from "./TicketForm";
import "./TicketForm.css";
import { validateTicketInput } from "./validations";

//#region apollo GQL
const ADD_TICKETS = loader("../../ticketing-graphql/addTickets.graphql");

const UPDATE_TICKETS = loader("../../ticketing-graphql/updateTickets.graphql");

const UPDATE_MOVEMENTS = loader("../../ticketing-graphql/updateMovements.graphql");

const ALLOCATE_MOVEMENTS = loader("../../ticketing-graphql/allocateMovementVolume.graphql");

const LINK_TICKETS = loader("../../ticketing-graphql/linkTicketsToMovements.graphql");

const DELETE_TICKETS = loader("../../ticketing-graphql/deleteTicket.graphql");

const UPDATE_LINKED_TICKET = loader(
  "../../ticketing-graphql/updateLinkedTicketOfMovements.graphql"
);
const GET_TICKET_TO_EDIT = loader("../../ticketing-graphql/getTicketById.graphql");
//#endregion

//#region local state less functions
const getTEMPUom = (picklists: TPicklists, uom?: string) => {
  if (picklists?.temperatureUOMs && uom) {
    return picklists.temperatureUOMs.find(t => equalsIgnoreCase(t.name, uom));
  }
  return undefined;
};

const isFutureDate = (startDate?: Date) => {
  return startDate && getDate(startDate).getTime() > getDate(new Date()).getTime();
};
const isMovementScheduledAt60F = (measure: TMovementMeasure) => {
  return (
    equalsIgnoreCase(measure?.unitOfMeasure?.name, ENDUR_FAHRENHEIT_TEMPERATURE_MEASURE) &&
    measure.value === NON_STCAN_DEFAULT_FAHRENHEIT_TEMPERATURE
  );
};
const isMovementScheduledAt15C = (measure: TMovementMeasure) => {
  return (
    equalsIgnoreCase(measure?.unitOfMeasure?.name, ENDUR_CELSIUS_TEMPERATURE_MEASURE) &&
    measure.value === STCAN_DEFAULT_CELSIUS_TEMPERATURE
  );
};
const shouldAdd60FVolume = (movementGroup: TMovementGroup | null | undefined) => {
  /**This enhancement is to create a default volumetric row of UOM at 60F for users
   * to enter if the nomination was scheduled using mass at 60F.  */
  if (movementGroup != null) {
    const movementTEMPMeasure = movementGroup?.activeMovement?.measures?.find(m =>
      equalsIgnoreCase(m.measurementType.name, "Temperature")
    );
    return (
      movementTEMPMeasure &&
      isMovementScheduledAt60F(movementTEMPMeasure) &&
      equalsIgnoreCase(
        movementGroup?.activeMovement?.unitOfMeasure?.unitOfMeasureClass?.name,
        "Mass"
      )
    );
  }
  return false;
};
const shouldAdd15CVolume = (movementGroup: TMovementGroup | null | undefined) => {
  //if STCAN and movement is not scheduled at 15C
  // or if the Movement's Unit of mesure is not volumetric - Required for Tax purposes
  //then add 15C row with UOM class of Volume
  if (movementGroup != null && isSTCan(movementGroup)) {
    const movementTEMPMeasure = movementGroup?.activeMovement?.measures?.find(m =>
      equalsIgnoreCase(m.measurementType.name, "Temperature")
    );
    return (
      movementTEMPMeasure &&
      (!isMovementScheduledAt15C(movementTEMPMeasure) ||
        !equalsIgnoreCase(
          movementGroup?.activeMovement?.unitOfMeasure?.unitOfMeasureClass?.name,
          "Volume"
        ))
    );
  }
  return false;
};

const getFirstTicketDate = (movement: TMovement | undefined) => {
  if (movement) {
    return fromISODateTimeString(movement.tickets[0].startDate);
  }
  return undefined;
};

const getStartDate = (movementGroup: TMovementGroup | null | undefined) => {
  const isDaily = isMovementDaily(movementGroup);

  if (
    movementGroup?.activeMovement &&
    isFutureDate(movementGroup.activeMovement.startDate as Date)
  ) {
    return undefined;
  }

  if (
    movementGroup?.activeMovement &&
    movementGroup.activeMovement.tickets.length > 0 &&
    isDaily
  ) {
    return getFirstTicketDate(movementGroup.activeMovement);
  }
  return movementGroup?.activeMovement.startDate;
};

const createNewTicket = (
  movementGroup: TMovementGroup | null | undefined,
  picklists: TPicklists
): TTicketInput[] => {
  const defaultUoM = picklists?.unitOfMeasures?.find(u =>
    equalsIgnoreCase(u.name, DEFAULT_UNIT_OF_MEASURE)
  );

  const defuaultTempUoM = getTEMPUom(picklists, DEFAULT_TEMPERATURE_MEASURE);
  const celsiumTempUoM = picklists?.temperatureUOMs?.find(t =>
    equalsIgnoreCase(t.name, CELSIUS_TEMPERATURE_MEASURE)
  );
  const fahrenheitTempUoM = picklists?.temperatureUOMs?.find(t =>
    equalsIgnoreCase(t.name, FAHRENHEIT_TEMPERATURE_MEASURE)
  );

  const movement = movementGroup?.activeMovement;
  const movementTEMPMeasure = movement?.measures?.find(m =>
    equalsIgnoreCase(m.measurementType.name, "Temperature")
  );
  const movementScheduledTEMPMeasure =
    getTEMPUom(picklists, mapTempUOM(movementTEMPMeasure?.unitOfMeasure?.name)) ??
    defuaultTempUoM;

  const fabricatedId = new Date().getTime();
  let volumeIndex = 1;

  const defaultVolumes: TVolumeInput[] = [
    {
      id: `V${fabricatedId}:${volumeIndex}`,
      isNew: true,
      isDefault: !!movement,
      unitOfMeasure: movement?.unitOfMeasure ?? defaultUoM,
      volumeType:
        movement?.unitOfMeasure.unitOfMeasureClass.name ?? defaultUoM?.unitOfMeasureClass?.name,
      grossVolume: movement?.grossQuantity,
      netVolume: movement?.netQuantity,
      temperature: movementTEMPMeasure?.value,
      temperatureUnitOfMeasure: movementScheduledTEMPMeasure
    }
  ];

  if (shouldAdd15CVolume(movementGroup)) {
    volumeIndex++;
    defaultVolumes.push({
      id: `V${fabricatedId}:${volumeIndex}`,
      isNew: true,
      isDefault: true,
      temperature: 15,
      temperatureUnitOfMeasure: celsiumTempUoM,
      volumeType: "Volume"
    });
  }
  if (shouldAdd60FVolume(movementGroup)) {
    volumeIndex++;
    defaultVolumes.push({
      id: `V${fabricatedId}:${volumeIndex}`,
      isNew: true,
      isDefault: true,
      temperature: 60,
      temperatureUnitOfMeasure: fahrenheitTempUoM,
      volumeType: "Volume"
    });
  }

  if (requireVolAndMassUnit(movement)) {
    if (!defaultVolumes.find(v => equalsIgnoreCase(v.volumeType, "Volume"))) {
      volumeIndex++;
      defaultVolumes.push({
        id: `V${fabricatedId}:${volumeIndex}`,
        isNew: true,
        isDefault: true,
        temperature: 15,
        temperatureUnitOfMeasure: movementScheduledTEMPMeasure,
        volumeType: "Volume"
      });
    }

    if (!defaultVolumes.find(v => equalsIgnoreCase(v.volumeType, "Mass"))) {
      volumeIndex++;
      defaultVolumes.push({
        id: `V${fabricatedId}:${volumeIndex}`,
        isNew: true,
        isDefault: true,
        temperature: movementTEMPMeasure?.value ?? 0,
        temperatureUnitOfMeasure: movementScheduledTEMPMeasure,
        volumeType: "Mass"
      });
    }
  }
  const modeOfTransport = getDefaultMOT(null, movementGroup, picklists);

  return [
    {
      id: `T${fabricatedId}:1`,
      startDate: getStartDate(movementGroup),
      batch: movement?.batch,
      modeOfTransport,
      product: movement?.product,
      facility: movement?.titleTransferFacility,
      logisticsSystem:
        movement?.logisticsSystem ??
        picklists?.logisticsSystems?.find(l => equalsIgnoreCase(l.name, modeOfTransport?.name)),
      volumes: defaultVolumes,
      isNew: true,
      collapsed: false
    }
  ] as TTicketInput[];
};

const transformTicket = (
  ticket: TTicket,
  activeMovementGroup?: TMovementGroup | null
): TTicketInput[] => {
  const isDefaultRow = (id: string) => {
    const requireVolAndMass = requireVolAndMassUnit(activeMovementGroup?.activeMovement);
    const firstVolumeRow = ticket.volumes.find(v =>
      equalsIgnoreCase(v.unitOfMeasure?.unitOfMeasureClass.name, "Volume")
    );
    const firstMassRow = ticket.volumes.find(v =>
      equalsIgnoreCase(v.unitOfMeasure?.unitOfMeasureClass.name, "Mass")
    );
    const first15CRow = ticket.volumes.find(
      v =>
        v.temperature === STCAN_DEFAULT_CELSIUS_TEMPERATURE &&
        v.temperatureUnitOfMeasure === CELSIUS_TEMPERATURE_MEASURE
    );
    if (requireVolAndMass && (id === firstMassRow?.id || id === firstVolumeRow?.id)) {
      return true;
    }
    if (activeMovementGroup && isSTCan(activeMovementGroup) && id === first15CRow?.id) {
      return true;
    }
    return false;
  };

  return [
    {
      ...ticket,
      isNew: false,
      startDate: new Date(ticket.startDate),
      volumes: ticket.volumes.map(v => ({
        ...v,
        isNew: false,
        isDefault: isDefaultRow(v.id),
        temperatureUnitOfMeasure: {
          id: v.temperatureUnitOfMeasure,
          name: v.temperatureUnitOfMeasure,
          shortName: v.temperatureUnitOfMeasure.charAt(0)
        }
      })),
      shipFromCode: ticket.shipFromCode
        ? {
            ...ticket.shipFromCode,
            display: `${ticket.shipFromCode?.code}-${ticket.shipFromCode.description}`
          }
        : null,
      shipToCode: ticket.shipToCode
        ? {
            ...ticket.shipToCode,
            display: `${ticket.shipToCode?.code}-${ticket.shipToCode.description}`
          }
        : null,
      borderCrossingDate: ticket.borderCrossingDate
        ? new Date(ticket.borderCrossingDate)
        : null,
      railcars: ticket.railcars?.map(r => r.railcarNumber)?.join(","),
      carrierScacCode: ticket.carrierScacCode ?? ticket.carrier?.scac,
      collapsed: false
    }
  ];
};

const calcVolumeToAllocateOnLinkTicket = (
  ticketQuantity: number,
  activeMovement?: TMovement
) => {
  if (activeMovement && isValidMOT(activeMovement.batch.modeOfTransport.name)) {
    const { scheduledQuantity, actualizedQuantity } = activeMovement;
    const availabeQuantity = Math.max(scheduledQuantity - (actualizedQuantity ?? 0), 0);

    //if actualization is already complete,
    //then entire volume of the ticket should be allocated to another movement
    //else ticket volume - remaining quantity(scheduled - actualized)
    const calcVolume = activeMovement.actualizationComplete
      ? -1 * ticketQuantity
      : Math.min(availabeQuantity - ticketQuantity, 0);
    return truncateTo(calcVolume, 4);
  }
  return 0;
};

const getDefaultMOT = (
  activeTicket?: TTicket | null,
  activeMovementGroup?: TMovementGroup | null,
  picklists?: TPicklists
) =>
  activeTicket?.modeOfTransport ??
  activeMovementGroup?.activeMovement?.batch?.modeOfTransport ??
  picklists?.modeOfTransports?.find(m => equalsIgnoreCase(m.name, MODE_OF_TRANSPORT_TRUCK));

//#endregion

const INITIAL_HEIGHT = 550;
const INITIAL_WIDTH = 850;
const INITIAL_HEIGHT_DOCUMENT_PRESENT = 600;
const INITIAL_WIDTH_DOCUMENT_PRESENT = 1366;

const FETCH_POLICY_NO_CACHE = {
  fetchPolicy: "no-cache" as MutationFetchPolicy
};

type AddTicketsResponse = GqlResponse<TTicket[], "addTickets">;
type UpdateTicketsResponse = GqlResponse<TTicket[], "updateTickets">;
type UpdateMovementsResponse = GqlResponse<TMovement[], "updateMovements">;
type AllocateVolumeResponse = GqlResponse<TMovement[], "allocateMovementVolume">;
type LinkTicketResponse = GqlResponse<TMovement[], "movements">;
type UpdateLinkedTicketResponse = GqlResponse<TMovement[], "updateLinkedTicketOfMovements">;
type GetTicketByIdResponse = GqlResponse<TTicket, "ticket">;

type TLinkedTicketPayload = {
  ticketId: string;
  volume: number;
};

type TVolumeAllocationState = {
  showModal: boolean;
  tickets?: TLinkedTicketPayload[];
  qtyToAllocate?: number;
  reason?: string;
};

type TWidnowPosition = {
  left: number;
  top: number;
  width: number;
  height: number;
};

type AddOrUpdateTicketProps = {
  selectedPdfFile?: File;
  activeMovementGroup?: TMovementGroup | null;
  activeTicket?: TTicket;
  onCancel: () => void;
  onSuccess: (movements?: TMovement[], tickets?: TTicket[]) => void;
  onDelete?: (ticket: TTicket) => void;
};
enum UploadStatus {
  New,
  Uploading,
  Uploaded,
  Cancelled,
  Error
}
const AddOrUpdateTicket = ({
  activeMovementGroup,
  selectedPdfFile,
  activeTicket,
  onCancel,
  onSuccess,
  onDelete
}: AddOrUpdateTicketProps) => {
  //#region Apollo use hooks
  const [addTickets, { loading: addTicketsLoading, error: errorAddTickets }] =
    useMutation<AddTicketsResponse>(ADD_TICKETS, FETCH_POLICY_NO_CACHE);

  const [updateTickets, { loading: loadingUpdateTickets, error: errorUpdateTickets }] =
    useMutation<UpdateTicketsResponse>(UPDATE_TICKETS, FETCH_POLICY_NO_CACHE);

  const [deleteTickets, { loading: loadingDeleteTickets, error: errorDeleteTickets }] =
    useMutation(DELETE_TICKETS);

  const [linkTickets, { loading: linkTicketLoading, error: linkTicketError }] =
    useMutation<LinkTicketResponse>(LINK_TICKETS, FETCH_POLICY_NO_CACHE);

  const [updateLinkedTicket, { loading: updateLinkLoading, error: errorUpdateLinkedTicket }] =
    useMutation<UpdateLinkedTicketResponse>(UPDATE_LINKED_TICKET, FETCH_POLICY_NO_CACHE);

  const [updateMovements, { loading: loadingUpdateMovements, error: errorUpdateMovements }] =
    useMutation<UpdateMovementsResponse>(UPDATE_MOVEMENTS, FETCH_POLICY_NO_CACHE);

  const [allocateMovementVolume, { loading: allocateLoading, error: allocateError }] =
    useMutation<AllocateVolumeResponse>(ALLOCATE_MOVEMENTS, FETCH_POLICY_NO_CACHE);

  const {
    loading: vLoading,
    error: vError,
    validationConfigs,
    userQueryCodes
  } = useValidationConfigs();

  const [getTicketById, { loading: getTicketLoading, error: getTicketError }] =
    useLazyQuery<GetTicketByIdResponse>(GET_TICKET_TO_EDIT, {
      ...FETCH_POLICY_NO_CACHE,
      onCompleted: data => {
        setTickets(transformTicket(data.ticket, activeMovementGroup));
        if (data.ticket.documentId) {
          setPdfFile({
            docFile: null,
            documentId: data.ticket.documentId,
            numberOfPages: null,
            loading: true,
            showViewer: false
          });
          getFile(data.ticket.documentId)
            .then(response => {
              const fileName = response.headers["content-disposition"]?.replace(
                `attachment;filename=${data.ticket.documentId}-`,
                ""
              );
              const fileRef = new File([response.data], fileName, {
                type: "application/pdf"
              });
              setPdfFile(f => ({
                ...f,
                docFile: fileRef,
                loading: false,
                showViewer: true
              }));
            })
            .catch(_error => setPdfFile(f => ({ ...f, loading: false })));
        }
      }
    });

  //#endregion
  const { picklists, enterpriseSystems, selectedEnterpriseSystem, setEnterpriseSystem } =
    usePicklists();

  const [motSelected, setMotSelected] = useState<Picklists.TModeOfTransport | undefined>(() =>
    getDefaultMOT(activeTicket, activeMovementGroup, picklists)
  );

  const [pdfFile, setPdfFile] = useState<TPdfDocument>({
    documentId: null,
    docFile: selectedPdfFile ?? null,
    numberOfPages: null,
    loading: false,
    showViewer: !!selectedPdfFile
  });

  const [tickets, setTickets] = React.useState<TTicketInput[]>();
  const [volumeAllocationState, setVolumeAllocationState] = useState<TVolumeAllocationState>({
    showModal: false
  });

  const [showDeleteDocConfirmModal, setShowDeleteDocConfirmModal] = useState(false);
  const [showSplitModal, setShowSplitModal] = useState(false);
  const [showDeleteTicketConfirmation, setShowDeleteTicketConfirmation] = useState(false);
  const [splitTicket, setSplitTicket] = useState<TTicketInput | null>(null);
  const [showZeroOutTicketConfirmation, setShowZeroOutTicketConfirmation] = useState(false);

  const [windowPosition, setWindowPosition] = React.useState<TWidnowPosition>({
    left: 100,
    top: 100,
    width: pdfFile.docFile ? INITIAL_WIDTH_DOCUMENT_PRESENT : INITIAL_WIDTH,
    height: pdfFile.docFile ? INITIAL_HEIGHT_DOCUMENT_PRESENT : INITIAL_HEIGHT
  });

  const isNewTicket = activeTicket == null;
  const hasMovement = activeMovementGroup != null;

  const validateTicket = useCallback(
    (input: TTicketInput, ts?: TTicketInput[]) => {
      return {
        errors: validateTicketInput(
          input,
          activeMovementGroup ?? null,
          pdfFile.numberOfPages,
          validationConfigs,
          userQueryCodes,
          picklists?.shipFrom,
          picklists?.shipTo,
          ts?.filter(t => t.id !== input.id)
        )
      };
    },
    [validationConfigs, activeMovementGroup, picklists, userQueryCodes, pdfFile.numberOfPages]
  );

  useEffect(() => {
    if (!vLoading) {
      if (activeTicket == null) {
        const newTickets = createNewTicket(activeMovementGroup, picklists!);
        setTickets(newTickets.map(t => Object.assign(t, validateTicket(t))));
      } else {
        getTicketById({ variables: { ticketId: activeTicket.id } });
      }
    }
  }, [
    vLoading,
    validationConfigs,
    activeMovementGroup,
    activeTicket,
    picklists,
    getTicketById,
    userQueryCodes,
    validateTicket
  ]);

  const handlelocalTicketDelete = (ticketInput: TTicketInput) => {
    setTickets(ts => ts?.filter(t => t !== ticketInput));
    //validate after the state saved
    setTickets(ts => ts?.map(ticket => Object.assign(ticket, validateTicket(ticket, ts))));
  };

  const onSplitTicket = (ticket: TTicketInput) => {
    setSplitTicket(ticket);
    setShowSplitModal(true);
  };

  const onCancelSplit = () => {
    setSplitTicket(null);
    setShowSplitModal(false);
  };

  const onSplitSuccess = (copies: number) => {
    setShowSplitModal(false);
    setTickets(ts => {
      const index = ts?.findIndex(t => t === splitTicket) ?? -1;
      if (index > -1 && splitTicket) {
        const fakeId = new Date().getTime();

        const copyTickets = [...Array(copies).keys()].map(i => ({
          ...JSON.parse(JSON.stringify(splitTicket)),
          id: `T${fakeId}:${i}`,
          isNew: true,
          collapsed: true,
          startDate: splitTicket.startDate,
          borderCrossingDate: splitTicket.borderCrossingDate
        }));
        ts?.splice(index + 1, 0, ...copyTickets);
      }
      setSplitTicket(null);
      return ts;
    });
    spreadPageNumbersAcrossTickets(pdfFile.numberOfPages);
  };

  const handleEnterpriseSystemChange = (event: DropDownListChangeEvent) => {
    if (event.target.value) {
      setEnterpriseSystem?.(event.target.value as TEnterpriseSystem);
    }
  };

  const handleMotChange = (event: DropDownListChangeEvent) => {
    const logisticsSystem = isMotRailOrTruck(event.target.value.name)
      ? picklists?.logisticsSystems?.find(l => l.name === event.target.value.name)
      : undefined;

    setTickets(ts =>
      ts?.map(ticket => ({
        ...ticket,
        modeOfTransport: event.target.value,
        logisticsSystem
      }))
    );
    setMotSelected(event.target.value);
    setTickets(ts => ts?.map(ticket => Object.assign(ticket, validateTicket(ticket, ts))));
  };

  const handleTicketChanged = (
    ticketInput: TTicketInput,
    change: { [key: string]: unknown }
  ) => {
    setTickets(ts =>
      ts?.map(ticket => {
        if (ticket === ticketInput) {
          Object.assign(ticket, change);
        }
        return ticket;
      })
    );
    //validate after the state saved
    setTickets(ts => ts?.map(ticket => Object.assign(ticket, validateTicket(ticket, ts))));
  };

  const clearPageNumbers = () => {
    setTickets(ts => ts?.map(t => ({ ...t, pageNumbers: null })));
  };

  /**
   * onSave tickets
   * 1. Upload documents if any
   * 2. Add Tickets
   * 3. Link Tickets if movement is defined
   * 4. Allocate volume if required
   * 5. Update movement's actualization if required
   * @returns
   */
  const handleSave = () => {
    uploadDocument(documentId =>
      isNewTicket ? addNewTickets(documentId) : updateExistingTicket(documentId)
    );
  };

  //#region file upload

  const UNINDENT_TWO_PLACES = 100;

  let cancelTokenSource: CancelTokenSource;

  const [uploadInfo, setUploadInfo] = useState({
    status: UploadStatus.New,
    progress: 0,
    error: ""
  });
  const isUploading = () => uploadInfo.status === UploadStatus.Uploading;
  //#endregion
  const uploadDocument = (onDone: (documentId: string | null) => void) => {
    if (pdfFile.docFile != null && pdfFile.documentId == null) {
      onUpload(onDone);
    } else {
      onDone(pdfFile.documentId);
    }
  };

  const onUpload = async (onDone: (documentId: string | null) => void) => {
    try {
      cancelTokenSource = getCancelTokenSource();
      setUploadInfo({ status: UploadStatus.Uploading, progress: 0, error: "" });

      const options = {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const { loaded, total } = progressEvent;
          setUploadInfo({
            ...uploadInfo,
            progress: Math.floor((loaded * UNINDENT_TWO_PLACES) / (total ?? 1)),
            status: UploadStatus.Uploading,
            error: ""
          });
        },
        cancelToken: cancelTokenSource.token
      };

      const result = await uploadTicketDocument(pdfFile.docFile as File, options);
      if (result[0].id) {
        onDone(result[0].id);
      }
      setUploadInfo({ ...uploadInfo, status: UploadStatus.Uploaded });
    } catch (error) {
      const errorStatus = error.response
        ? error.response.data?.errors?.[0] ?? "Error: Unknown Error"
        : "Error: Network Error, please try later";
      setUploadInfo({
        ...uploadInfo,
        status: UploadStatus.Error,
        error: errorStatus
      });
    }
  };

  const addNewTickets = (documentId: string | null) => {
    const inputs = tickets?.map(t => toTicketInputAdd(t, documentId));
    addTickets({
      variables: { tickets: inputs },
      onCompleted: data =>
        !hasMovement
          ? onSuccess([]) //no link required
          : linkTicketsToMovement(data.addTickets)
    });
  };

  const updateExistingTicket = (documentId: string | null) => {
    const inputs = tickets?.map(t =>
      toTicketInputUpdate(t, documentId, showZeroOutTicketConfirmation)
    );
    updateTickets({
      variables: { tickets: inputs },
      onCompleted: data =>
        !hasMovement
          ? onSuccess([]) //no link required
          : updateExistingLink(data.updateTickets)
    });
  };

  const updateExistingLink = (updatedTickets: TTicket[]) => {
    const updateLinkTicketPayload = updatedTickets.map(t => ({
      ticketId: t.id,
      volume: findMatchingVolume(t, activeMovementGroup, picklists?.uomConversions).netVolume
    }));

    //show the volume allocation modal here if needed
    const originalVolume =
      activeMovementGroup?.activeMovement.tickets.find(t => t.id === updatedTickets?.at(0)?.id)
        ?.actualizedVolume ?? 0;

    //find the matching volume of the current unsaved ticket
    const updatedVolume = updateLinkTicketPayload.at(0)!.volume;

    //effective increase or decrease of volume between saved and unsaved ticket
    const effectiveVolume = updatedVolume - originalVolume;

    const volumeToAllocate = calcVolumeToAllocateOnLinkTicket(
      effectiveVolume,
      activeMovementGroup?.activeMovement
    );

    if (volumeToAllocate !== 0) {
      setVolumeAllocationState({
        showModal: true,
        qtyToAllocate: volumeToAllocate,
        tickets: updateLinkTicketPayload,
        reason: "Update existing ticket causing the acutalized volume go over/under scheduled"
      });
    } else {
      doUpdateLink(updateLinkTicketPayload, movements => onSuccess(movements));
    }
  };

  const linkTicketsToMovement = (addedTickets: TTicket[]) => {
    const linkTicketPayload = addedTickets.map(t => ({
      ticketId: t.id,
      volume: findMatchingVolume(t, activeMovementGroup, picklists?.uomConversions).netVolume
    }));
    const totalVolume = linkTicketPayload.reduce((a, v) => a + v.volume, 0);
    const volumeToAllocate = calcVolumeToAllocateOnLinkTicket(
      totalVolume,
      activeMovementGroup?.activeMovement
    );
    if (volumeToAllocate !== 0) {
      setVolumeAllocationState({
        showModal: true,
        qtyToAllocate: volumeToAllocate,
        tickets: linkTicketPayload,
        reason: "Adding new Ticket causing the actualized volume to go over scheduled"
      });
    } else {
      doLinkTickets(linkTicketPayload, movements => onSuccess(movements));
    }
  };

  const doLinkTickets = (
    ticketsPayload: TLinkedTicketPayload[],
    onSaveCompleted: (movements?: TMovement[]) => void
  ) => {
    linkTickets({
      variables: {
        movements: activeMovementGroup?.movements.map(m => ({
          movementId: m.id,
          version: m.version
        })),
        tickets: ticketsPayload
      },
      onCompleted: data => onSaveCompleted(data.movements)
    });
  };

  const doUpdateLink = (
    ticketsPayload: TLinkedTicketPayload[],
    onSaveCompleted: (movements?: TMovement[]) => void
  ) => {
    updateLinkedTicket({
      variables: {
        movements: activeMovementGroup?.movements.map(m => ({
          movementId: m.id,
          version: m.version
        })),
        ticket: ticketsPayload.at(0)
      },
      onCompleted: data => onSaveCompleted(data.updateLinkedTicketOfMovements)
    });
  };

  const onAllocationSave = (allocationMovements: TAllocationMovement[] | null) => {
    setVolumeAllocationState(st => ({ ...st, showModal: false }));
    const saveAndUpdateMovements = (movements?: TMovement[]) => {
      saveAllocations(allocationMovements, volumeAllocationState.reason!, alcMovements => {
        if (!activeMovementGroup?.activeMovement.actualizationComplete) {
          updateMovements({
            variables: {
              movements: activeMovementGroup?.movements.map(m => ({
                id: m.id,
                version: m.version,
                actualizationComplete: true
              }))
            },
            onCompleted: data => onSuccess([...data.updateMovements, ...(alcMovements ?? [])])
          });
        } else {
          onSuccess([...movements!, ...(alcMovements ?? [])]);
        }
      });
    };

    if (isNewTicket) {
      doLinkTickets(volumeAllocationState.tickets!, saveAndUpdateMovements);
    } else {
      doUpdateLink(volumeAllocationState.tickets!, saveAndUpdateMovements);
    }
  };

  const saveAllocations = (
    allocationMovements: TAllocationMovement[] | null,
    reason: string,
    onSaveCompleted: (movements?: TMovement[]) => void
  ) => {
    if (activeMovementGroup) {
      const allocateVolumeInput = buildMovementAllocationsPayload(
        activeMovementGroup?.activeMovement,
        allocationMovements,
        reason
      );
      allocateMovementVolume({
        ...allocateVolumeInput,
        onCompleted: data => onSaveCompleted(data.allocateMovementVolume)
      });
    } else {
      onSaveCompleted([]);
    }
  };

  const handleZeroOutTicket = () => setShowZeroOutTicketConfirmation(true);
  const onCancelZeroOutTicket = () => setShowZeroOutTicketConfirmation(false);

  const handleDeleteTickets = () => setShowDeleteTicketConfirmation(true);
  const onCancelDeleteDocument = () => setShowDeleteDocConfirmModal(false);
  const onCancelDeleteTicket = () => setShowDeleteTicketConfirmation(false);
  const onCancelAllocationModal = () => {
    setVolumeAllocationState({ showModal: false });
    onCancel();
  };

  const onDeleteTicketConfirmed = () => {
    setShowDeleteTicketConfirmation(false);
    if (activeTicket?.id) {
      deleteTickets({
        variables: { ids: activeTicket?.id },
        onCompleted: () => onDelete?.(activeTicket)
      });
    }
  };

  const onDeleteDocumentConfirmed = () => {
    setShowDeleteDocConfirmModal(false);
    setPdfFile({
      docFile: null,
      documentId: null,
      numberOfPages: null,
      showViewer: true,
      loading: false
    });
    clearPageNumbers();
  };

  //will set all the volumes to 0.0000001 PBI#2320223
  const onZeroOutTicketConfirmed = () => {
    setTickets(ts =>
      ts?.map(t => ({
        ...t,
        volumes: t.volumes.map(v => ({
          ...v,
          grossVolume: 0.0000001,
          netVolume: 0.0000001
        }))
      }))
    );
    setTimeout(() => handleSave(), 1000);
  };

  const spreadPageNumbersAcrossTickets = (numPages?: number | null) => {
    if (numPages) {
      setTickets(ts => {
        let numTickets = ts?.length ?? 0;
        let pageNumbers = numPages;
        let lastPageNumber = 0;
        //spread page numbers across tickets...
        return ts?.map(t => {
          if (pageNumbers > 0) {
            const a = Math.ceil((pageNumbers === 0 ? 1 : pageNumbers) / numTickets);
            pageNumbers -= a;
            Object.assign(t, {
              pageNumbers: `${lastPageNumber + 1}-${lastPageNumber + a}`
            });
            lastPageNumber += a;
          } else {
            Object.assign(t, {
              pageNumbers: `${lastPageNumber}-${lastPageNumber}`
            });
          }
          numTickets--;
          return t;
        });
      });
    }
  };

  const onDocumentLoaded = (numPages: number) => {
    setPdfFile(p => ({ ...p, numberOfPages: numPages }));
    if (pdfFile.documentId == null) {
      spreadPageNumbersAcrossTickets(numPages);
    }
  };

  const onAcceptFiles = (acceptedFiles: File[]) => {
    if (acceptedFiles?.length) {
      setPdfFile({
        docFile: acceptedFiles[0],
        documentId: null,
        numberOfPages: null,
        loading: false,
        showViewer: true
      });
      clearPageNumbers();
    }
  };

  const motDisabled = activeTicket != null || activeMovementGroup != null;

  //only a linked ticket can be zero'ed out
  const canZeroOutTicket =
    activeTicket != null && activeMovementGroup != null && !getTicketLoading;

  //only unlinked ticket can be deleted
  const canDeleteTicket =
    activeTicket != null && activeMovementGroup == null && !getTicketLoading;

  const canSave =
    (tickets?.length ?? 0) > 0 &&
    (tickets?.flatMap(t => t.errors).filter(e => e?.error)?.length ?? 0) === 0;

  const showHideDocumentViewer = () => {
    setPdfFile(f => ({ ...f, showViewer: !f.showViewer }));
  };
  const getAddDocumentButtonText = () => {
    if (pdfFile.showViewer) {
      return "Hide Document";
    }
    if (pdfFile.docFile) {
      return "Show Document";
    }
    return "Add Document";
  };

  const handleMove = (event: WindowMoveEvent) => {
    setWindowPosition({ ...windowPosition, left: event.left, top: event.top });
  };

  const handleResize = (event: WindowMoveEvent) => {
    setWindowPosition({
      left: event.left,
      top: event.top,
      width: Math.max(
        event.width,
        pdfFile.showViewer ? INITIAL_WIDTH_DOCUMENT_PRESENT : INITIAL_WIDTH
      ),
      height: Math.max(
        event.height,
        pdfFile.showViewer ? INITIAL_HEIGHT_DOCUMENT_PRESENT : INITIAL_HEIGHT
      )
    });
  };

  useEffect(() => {
    setWindowPosition(ws => ({
      left: ws.left,
      top: ws.top,
      width: pdfFile.showViewer ? INITIAL_WIDTH_DOCUMENT_PRESENT : INITIAL_WIDTH,
      height: pdfFile.showViewer ? INITIAL_HEIGHT_DOCUMENT_PRESENT : INITIAL_HEIGHT
    }));
  }, [pdfFile.showViewer]);

  const isLoading = [
    addTicketsLoading,
    getTicketLoading,
    loadingDeleteTickets,
    updateLinkLoading,
    loadingUpdateTickets,
    loadingUpdateMovements,
    allocateLoading,
    linkTicketLoading,
    vLoading
  ].some(l => l);

  const errors = [
    errorAddTickets,
    errorUpdateTickets,
    errorUpdateLinkedTicket,
    errorUpdateMovements,
    errorDeleteTickets,
    allocateError,
    linkTicketError,
    getTicketError,
    vError
  ].filter((e): e is ApolloError => Boolean(e));

  return (
    <Window
      height={windowPosition.height}
      width={windowPosition.width}
      top={windowPosition.top}
      left={windowPosition.left}
      onMove={handleMove}
      onResize={handleResize}
      modal
      onClose={onCancel}
      className="edit-ticket-modal"
      title={isNewTicket ? "Add ticket" : "Edit ticket"}>
      <div className={"card-container"}>
        <div style={{ height: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ height: "100%" }}>
              <div className="action-bar">
                <Button
                  icon="cancel"
                  disabled={isLoading}
                  themeColor={"warning"}
                  onClick={() => onCancel()}
                  className={"theme-btn-red"}>
                  Cancel
                </Button>
                {pdfFile.docFile && (
                  <Chip
                    icon="file-pdf k-color-warning"
                    removable={true}
                    text={pdfFile.docFile?.name}
                    className="k-color-tertiary"
                    size={"small"}
                    style={{
                      backgroundColor: "transparent",
                      color: "var(--modal-title-txt)",
                      fontWeight: "normal"
                    }}
                    removeIcon="delete k-icon-md k-color-error"
                    onRemove={() => setShowDeleteDocConfirmModal(true)}
                  />
                )}
                <div>{(isLoading || isUploading()) && <InlineLoadingPanel />}</div>
                <div style={{ display: "flex", gap: "8px" }}>
                  {canDeleteTicket && (
                    <Button
                      icon="delete"
                      disabled={isLoading}
                      title="Delete Ticket"
                      themeColor={"primary"}
                      onClick={handleDeleteTickets}>
                      Delete Ticket
                    </Button>
                  )}
                  {canZeroOutTicket && (
                    <Button
                      icon="reset"
                      disabled={isLoading}
                      title="Sets all volumes to 0.0000001"
                      themeColor={"primary"}
                      onClick={handleZeroOutTicket}>
                      Zero out
                    </Button>
                  )}
                  <Button
                    icon="save"
                    fillMode={"solid"}
                    themeColor={"success"}
                    disabled={!canSave || isLoading}
                    onClick={handleSave}
                    className={"theme-btn-green"}>
                    Save
                  </Button>
                </div>
              </div>
              <div>{errors && errors.length > 0 && <ApolloErrorViewer error={errors} />}</div>
              {uploadInfo.error && (
                <div className={"card-message form-error"}>
                  <span>{uploadInfo.error}</span>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  height: "calc(100% - 40px)"
                }}>
                <div
                  style={{
                    height: "100%"
                  }}>
                  <div className="ticket-header">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem"
                      }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: ".5rem"
                        }}>
                        <Label style={{ fontWeight: "bold" }}>System</Label>
                        <DropDownList
                          style={{ width: "165px" }}
                          data={enterpriseSystems}
                          name={`enterpriseSystems`}
                          textField="name"
                          dataItemKey="id"
                          value={selectedEnterpriseSystem}
                          defaultValue={selectedEnterpriseSystem}
                          onChange={handleEnterpriseSystemChange}
                          size={"small"}
                          className={"theme-form-input"}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: ".5rem"
                        }}>
                        <Label style={{ fontWeight: "bold" }}>MoT</Label>
                        <DropDownList
                          style={{ width: "136px" }}
                          data={picklists?.modeOfTransports ?? []}
                          name={`modeOfTransport`}
                          textField="name"
                          dataItemKey="id"
                          value={motSelected}
                          defaultValue={motSelected}
                          onChange={handleMotChange}
                          disabled={motDisabled}
                          size={"small"}
                          className={"theme-form-input"}
                        />
                      </div>
                    </div>
                    <div>
                      {pdfFile?.loading && <InlineLoadingPanel />}
                      {!pdfFile?.loading && (
                        <Button
                          icon={`${
                            pdfFile.showViewer ? "arrow-chevron-left" : "arrow-chevron-right"
                          } k-icon-lg`}
                          disabled={isLoading}
                          fillMode={"outline"}
                          themeColor={"tertiary"}
                          color="black"
                          className={pdfFile.showViewer ? "" : "show-document"}
                          onClick={showHideDocumentViewer}>
                          {getAddDocumentButtonText()}
                        </Button>
                      )}
                    </div>
                  </div>
                  <div
                    className="ticket-wrapper"
                    style={{ height: "calc(100% - 40px)", maxWidth: "850px" }}>
                    {tickets?.map(ticket => {
                      return (
                        <TicketForm
                          key={ticket.id}
                          activeTicket={ticket}
                          activeMovementGroup={activeMovementGroup}
                          onTicketChanged={handleTicketChanged}
                          onCopyTicket={onSplitTicket}
                          onDeleteTicket={handlelocalTicketDelete}
                          canSplitTicket={isNewTicket}
                          canDeleteTicket={(tickets?.length ?? 0) > 1}
                          pdfFile={pdfFile}></TicketForm>
                      );
                    })}
                  </div>
                </div>
                {pdfFile.showViewer && (
                  <div style={{ height: "100%" }}>
                    {!pdfFile.docFile && (
                      <div
                        style={{
                          margin: "50px 8px",
                          minWidth: "200px",
                          maxWidth: "400px"
                        }}>
                        <FileDropZone
                          accept={{ "application/pdf": [".pdf"] }}
                          onAccept={onAcceptFiles}
                          maxSize={MAX_FILE_SIZE} // PBI 2504803
                          maxFiles={1}
                          multiple={false}
                          disabled={!!pdfFile.docFile}
                          displayText="Drag 'n' drop or click to select pdf file"
                        />
                      </div>
                    )}
                    {pdfFile.docFile && (
                      <PdfComponent
                        pdfFileSelected={pdfFile.docFile}
                        onDocumentLoaded={onDocumentLoaded}></PdfComponent>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSplitModal && (
        <SplitTicketModal onClose={onCancelSplit} onSuccess={onSplitSuccess} />
      )}
      {volumeAllocationState.showModal &&
        volumeAllocationState.qtyToAllocate &&
        hasMovement && (
          <VolumeAllocationModal
            quantityToAllocate={volumeAllocationState.qtyToAllocate}
            onCancel={onCancelAllocationModal}
            onSave={onAllocationSave}
            sourceMovement={findPrimaryMovement(activeMovementGroup)}></VolumeAllocationModal>
        )}
      {showDeleteTicketConfirmation && (
        <Dialog title={"Delete Ticket"} onClose={onCancelDeleteTicket}>
          <div className="component-title" style={{ padding: "16px" }}>
            Are you sure you want to delete this ticket?
          </div>
          <DialogActionsBar>
            <Button icon="cancel" onClick={onCancelDeleteTicket}>
              Cancel
            </Button>
            <Button themeColor={"primary"} icon="check" onClick={onDeleteTicketConfirmed}>
              Delete
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {showZeroOutTicketConfirmation && (
        <Dialog title={"Zero Out Ticket"} onClose={onCancelZeroOutTicket}>
          <div className="component-title" style={{ padding: "16px" }}>
            Please confirm setting all volumes to 0.0000001?
          </div>
          <DialogActionsBar>
            <Button icon="cancel" onClick={onCancelZeroOutTicket}>
              Cancel
            </Button>
            <Button themeColor={"primary"} icon="check" onClick={onZeroOutTicketConfirmed}>
              Zero Out
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {showDeleteDocConfirmModal && (
        <Dialog title={"Delete Document"} onClose={onCancelDeleteDocument}>
          <div className="component-title" style={{ padding: "16px" }}>
            Are you sure you want to delete this document?
          </div>
          <DialogActionsBar>
            <Button icon="cancel" onClick={onCancelDeleteDocument}>
              Cancel
            </Button>
            <Button themeColor={"primary"} icon="check" onClick={onDeleteDocumentConfirmed}>
              Delete
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </Window>
  );
};
export default AddOrUpdateTicket;
