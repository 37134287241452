import { TDeal, TMovement, TMovementGroup, TShipCode } from "ticketing/ticketing.types";
import { isPhysicalDeal } from "ticketing/utils";

const isIdEqual = (id1: string | undefined | null, id2: string | undefined | null) =>
  id1 && id2 && id1 === id2;

export const filterShipCodes = (
  shipCodes?: TShipCode[],
  movement?: TMovement,
  deal?: TDeal
) => {
  if (isPhysicalDeal(deal)) {
    return (
      shipCodes?.filter(s =>
        s.maps?.find(
          m =>
            isIdEqual(m.facility?.id, movement?.titleTransferFacility?.id) &&
            isIdEqual(m.internalParty?.id, movement?.internalLegalEntity?.id) &&
            (isIdEqual(m.counterParty?.id, deal?.counterParty?.id) ||
              isIdEqual(m.counterParty?.id, deal?.counterParty?.parentLegalEntity?.id))
        )
      ) ?? []
    );
  }
  return shipCodes ?? [];
};

export const filterShipFromCodes = (
  shipCodes?: TShipCode[],
  movementGroup?: TMovementGroup | null
) => {
  if (movementGroup) {
    return movementGroup.movements
      .flatMap(movement => filterShipCodes(shipCodes, movement, movement?.recDeal))
      .filter((s1, i, arr) => arr.findIndex(s2 => s2.id === s1.id) === i);
  }
  return shipCodes ?? [];
};

export const filterShipToCodes = (
  shipCodes?: TShipCode[],
  movementGroup?: TMovementGroup | null
) => {
  if (movementGroup) {
    return movementGroup.movements
      .flatMap(movement => filterShipCodes(shipCodes, movement, movement?.delDeal))
      .filter((s1, i, arr) => arr.findIndex(s2 => s2.id === s1.id) === i)
      .toSorted((s1, s2) => s1.description.localeCompare(s2.description));
  }
  return shipCodes ?? [];
};
