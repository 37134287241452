import { FormField, Modal, RangeDatePicker, Variants } from "@sede-x/shell-ds-react-framework";
import dayjs from "dayjs";

import localeData from "dayjs/plugin/localeData";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import { TNotificationSearchCriteria } from "notification/models";
import { useState } from "react";

const NotificationSearchModal = ({
  searchCriteria,
  onClose,
  onSubmit
}: {
  searchCriteria: TNotificationSearchCriteria;
  onClose: () => void;
  onSubmit: (elm: TNotificationSearchCriteria) => void;
}) => {
  dayjs.extend(weekday);
  dayjs.extend(utc);
  dayjs.extend(localeData);
  const [localSearchCriteria, setLocalSearchCriteria] =
    useState<TNotificationSearchCriteria>(searchCriteria);

  return (
    <Modal
      title="Search Notifications"
      width={"600px"}
      height={"400px"}
      mask={true}
      open={true}
      maskClosable={false}
      onClose={onClose}
      actions={[
        {
          label: "Cancel",
          action: onClose,
          props: {
            variant: Variants.Outlined
          }
        },
        {
          label: "Search",
          action: () => {
            onSubmit(localSearchCriteria);
          }
        }
      ]}>
      <FormField size={"medium"} label="Period">
        <RangeDatePicker
          elevation={false}
          placeholder={["Start Date", "End Date"]}
          picker="date"
          format="DD MMM YYYY"
          id="date-range"
          size={"small"}
          allowClear={false}
          value={[dayjs(localSearchCriteria.fromDate), dayjs(localSearchCriteria.toDate)]}
          onChange={values => {
            setLocalSearchCriteria({
              fromDate: values?.[0]?.toISOString() ?? "",
              toDate: values?.[1]?.toISOString() ?? ""
            });
          }}
        />
      </FormField>
    </Modal>
  );
};

export default NotificationSearchModal;
