import { ApolloError, MutationFetchPolicy, useLazyQuery, useMutation } from "@apollo/client";
import { addMonths, firstDayOfMonth } from "@progress/kendo-date-math";
import { toString as toKString } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { MackUser } from "auth";
import { useMackAuth } from "auth/AuthenticationProvider";
import { loader } from "graphql.macro";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import GlobalHeader from "shared/components/GlobalHeader";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import LoadingPanel from "shared/components/LoadingPanel";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import {
  GqlResponse,
  TBatch,
  TLinkedTicket,
  TMovement,
  TTicket,
  TTicketSearchCriteria,
  TicketStatus
} from "ticketing/ticketing.types";
import { toSearchLikedTicketFilterInput, toSearchTicketFilterInput } from "ticketing/utils";
import EnterpriseSystemSelect from "../EnterpriseSystemSelect";
import SearchTicketsContainer from "./SearchTicketsContainer";
import TicketingGrid, { TGridTicket } from "./TicketingGrid";

const PADDING = 16;

const FETCH_POLICY_NO_CACHE = {
  fetchPolicy: "no-cache" as MutationFetchPolicy
};

type TMovementTickets = {
  id: string;
  version: number;
  enterpriseSystemCode: string;
  batch: TBatch;
  tickets: TLinkedTicket[];
  actualizationComplete: boolean;
};
type TicketsSearchResponse = GqlResponse<TTicket[], "ticketsFilterBy">;
type LinkedTicketsSearchResponse = GqlResponse<TMovementTickets[], "movements">;
type BulkUnlinkTicketResponse = GqlResponse<TMovement[], "movements">;
type AllocateVolumeResponse = GqlResponse<TMovement[], "bulkAllocateMovementVolume">;

const TICKETS_SEARCH = loader("../../ticketing-graphql/searchTickets.graphql");
const LINKED_TICKETS_SEARCH = loader(
  "../../ticketing-graphql/searchTicketsOfMovements.graphql"
);
const DELETE_TICKETS = loader("../../ticketing-graphql/deleteTicket.graphql");
const BULK_UNLINK_TICKETS = loader("../../ticketing-graphql/bulkUnlinkTickets.graphql");
const UPDATE_TICKETS = loader("../../ticketing-graphql/updateTickets.graphql");
const BULK_UPDATE_LINKED_TICKETS = loader(
  "../../ticketing-graphql/bulkUpdateLinkedTicketOfMovements.graphql"
);
const BULK_ALLOCATE_MOVEMENTS = loader(
  "../../ticketing-graphql/bulkAllocateMovementVolume.graphql"
);

const firstDayOfPrevMonth = () => firstDayOfMonth(addMonths(firstDayOfMonth(new Date()), -1));

const initSearchCriteria = (
  criteria?: TTicketSearchCriteria | null,
  user?: MackUser | null
): TTicketSearchCriteria => {
  return {
    startDate: criteria?.startDate ?? firstDayOfPrevMonth(),
    endDate: new Date(),
    batches: criteria?.batches ?? [],
    modeOfTransports: criteria?.modeOfTransports ?? [],
    ticketNumbers: criteria?.ticketNumbers,
    ticketStatus: TicketStatus.Unlinked,
    ticketSource: ["Manual"],
    lastModifiedBy: criteria?.lastModifiedBy ?? user?.mackUser?.id
  };
};

const transformTickets = (tickets: TTicket[] | TLinkedTicket[]) => {
  return tickets
    ?.map(t => ({
      ...t,
      startDate: toKString(new Date(t.startDate), "yyyy-MM-dd"),
      borderCrossingDate: t.borderCrossingDate
        ? toKString(new Date(t.borderCrossingDate), "yyyy-MM-dd")
        : "",
      lastModifiedDate: toKString(new Date(t.lastModifiedDate + "Z"), "yyyy-MM-dd hh:mm:ss"),
      grossVolume: t.volumes[0]?.grossVolume ?? 0,
      netVolume: t.volumes[0]?.netVolume ?? 0,
      railcars: t.railcars?.map(r => r.railcarNumber).join(","),
      volumeUnit: t.volumes[0]?.unitOfMeasure?.name ?? "",
      deliveryIds:
        (t as TLinkedTicket).movements?.map(m => m.enterpriseSystemCode).join(", ") ?? "",
      transitComplete: (t as TLinkedTicket).movements?.some(m => m.batch?.transitComplete)
    }))
    .sort((t1, t2) => +t2.id - +t1.id);
};

const transformMovementTickets = (movements: TMovementTickets[], ticketNumbers?: string) => {
  const allTickets = movements
    .filter(m => m.tickets?.length)
    .flatMap(m =>
      m.tickets
        .filter(t => !ticketNumbers || ticketNumbers?.includes(t.ticketNumber))
        .map(t => ({
          ...t,
          movements: [
            {
              id: m.id,
              version: m.version,
              enterpriseSystemCode: m.enterpriseSystemCode,
              batch: m.batch,
              actualizationComplete: m.actualizationComplete
            }
          ]
        }))
    );

  return allTickets.reduce<TLinkedTicket[]>((a, i) => {
    const existing = a.find(t => t.id === i.id);
    if (existing) {
      existing.movements?.push(...i.movements);
    } else {
      a.push(i);
    }
    return a;
  }, []);
};

type TDeleteTicketState = {
  showConfirmation: boolean;
  ticketIds?: string[];
};

type TUnlinkTicketState = {
  showConfirmation: boolean;
  input?: TGridTicket[];
};

type TZeroOutTicketState = {
  showConfirmation: boolean;
  input?: TGridTicket[];
};

/**
 *
 * @returns
 */
const ManageTickets = () => {
  const location = useLocation();

  const pathName = location.pathname;

  const { mackUser } = useMackAuth();
  const { picklists, isLoading: picklistsLoading, isReady: picklistsReady } = usePicklists();

  const [tickets, setTickets] = useState<TGridTicket[]>();
  const [ticketSearchCriteria, setTicketSearchCriteria] = useState<TTicketSearchCriteria>();

  const searchFormRef = useRef<HTMLDivElement>(null);
  const [searchFormOpen, setSearchFormOpen] = useState(true);
  const [searchCalled, setSearchCalled] = useState(false);

  const [deleteTicketState, setDeleteTicketState] = useState<TDeleteTicketState>({
    showConfirmation: false
  });

  const [unlinkTicketState, setUnlinkTicketState] = useState<TUnlinkTicketState>({
    showConfirmation: false
  });

  const [zeroOutTicketState, setZeroOutTicketState] = useState<TZeroOutTicketState>({
    showConfirmation: false
  });

  const [top, setTop] = useState<number>(0);

  const [searchTickets, { loading: searchTicketsLoading, error: searchTicketsError }] =
    useLazyQuery<TicketsSearchResponse>(TICKETS_SEARCH, {
      ...FETCH_POLICY_NO_CACHE,
      onCompleted: data => setTickets(transformTickets(data.ticketsFilterBy))
    });

  const [
    searchLinkedTickets,
    { loading: searchLinkedTicketsLoading, error: searchLinkedTicketsError }
  ] = useLazyQuery<LinkedTicketsSearchResponse>(LINKED_TICKETS_SEARCH, {
    ...FETCH_POLICY_NO_CACHE,
    onCompleted: data =>
      setTickets(
        transformTickets(
          transformMovementTickets(data.movements, ticketSearchCriteria?.ticketNumbers)
        )
      )
  });

  const [
    deleteTickets,
    { loading: deleteTicketsLoading, error: deleteTicketsError, reset: deleteTicketsReset }
  ] = useMutation(DELETE_TICKETS);

  const [
    bulkUnlinkTickets,
    {
      loading: loadingBulkUnlinkTickets,
      error: errorBulkUnlinkTickets,
      reset: bulkUnlinkTicketsReset
    }
  ] = useMutation<BulkUnlinkTicketResponse>(BULK_UNLINK_TICKETS);

  const [
    bulkAllocateMovementVolume,
    { loading: allocateLoading, error: allocateError, reset: allocateReset }
  ] = useMutation<AllocateVolumeResponse>(BULK_ALLOCATE_MOVEMENTS, {
    fetchPolicy: "no-cache"
  });

  const [
    updateTickets,
    { loading: updateTicketsLoading, error: updateTicketsError, reset: updateTicketsReset }
  ] = useMutation(UPDATE_TICKETS);

  const [
    bulkUpdateLinkedTickets,
    {
      loading: bulkUpdateLinkedTicketsLoading,
      error: bulkUpdateLinkedTicketsError,
      reset: bulkUpdateLinkedTicketsReset
    }
  ] = useMutation(BULK_UPDATE_LINKED_TICKETS);

  useEffect(() => {
    setTop(
      (searchFormRef?.current?.getBoundingClientRect().top ?? 0) +
        (searchFormRef?.current?.getBoundingClientRect().height ?? 0) +
        PADDING
    );
  }, [searchFormRef, searchFormOpen, searchCalled]);

  const getSearchCriteria = () => {
    return ticketSearchCriteria ?? initSearchCriteria(null, mackUser);
  };

  const getTicketsForSearch = useCallback(
    (criteria: TTicketSearchCriteria) => {
      if (criteria.deliveryIds?.trim()) {
        searchLinkedTickets({
          variables: {
            filter: toSearchLikedTicketFilterInput(criteria, picklists?.enterpriseSystemId!)
          }
        });
      } else {
        searchTickets({
          variables: {
            filter: toSearchTicketFilterInput(criteria)
          }
        });
      }
    },
    [searchTickets, searchLinkedTickets, picklists?.enterpriseSystemId]
  );

  const handleSearch = useCallback(
    (criteria: TTicketSearchCriteria) => {
      setSearchCalled(true);
      setTicketSearchCriteria(criteria);
      setTickets([]);
      getTicketsForSearch(criteria);
    },
    [getTicketsForSearch]
  );

  const onRefresh = useCallback(() => {
    if (ticketSearchCriteria) {
      getTicketsForSearch(ticketSearchCriteria);
    }
  }, [getTicketsForSearch, ticketSearchCriteria]);

  const resetErrors = useCallback(() => {
    deleteTicketsReset?.();
    bulkUnlinkTicketsReset?.();
    bulkUpdateLinkedTicketsReset?.();
    allocateReset?.();
    updateTicketsReset?.();
  }, [
    deleteTicketsReset,
    bulkUnlinkTicketsReset,
    bulkUpdateLinkedTicketsReset,
    allocateReset,
    updateTicketsReset
  ]);

  const onReset = useCallback(() => {
    setTicketSearchCriteria(undefined);
    resetErrors();
  }, [resetErrors]);

  // unlink
  const onUnlinkTickets = useCallback(
    (input: TGridTicket[]) => {
      resetErrors();
      setUnlinkTicketState({ showConfirmation: true, input });
    },
    [resetErrors]
  );

  const onCancelUnlinkTicket = useCallback(() => {
    setUnlinkTicketState({ showConfirmation: false });
  }, []);

  const saveAllocations = useCallback(
    (input: TGridTicket[], reason: string, onDone: () => void) => {
      const allocateVolumeInput = input
        .flatMap(t => t.movements)
        .filter((m): m is TMovement => Boolean(m))
        .filter(m => m.actualizationComplete)
        .map(srcMovement => ({
          srcId: srcMovement.id,
          version: srcMovement.version,
          volume: 0,
          reason,
          targets: [
            {
              movementId: srcMovement.id,
              version: srcMovement.version,
              volume: 0
            }
          ]
        }));
      if (allocateVolumeInput?.length) {
        bulkAllocateMovementVolume({
          variables: { input: allocateVolumeInput },
          onCompleted: () => onDone()
        });
      } else {
        onDone();
      }
    },
    [bulkAllocateMovementVolume]
  );

  const onUnlinkTicketsConfirmed = useCallback(() => {
    const input = unlinkTicketState.input;
    setUnlinkTicketState({ showConfirmation: false });
    if (input) {
      const bulkUnlinkInput = input.map(t => ({
        ticketIds: [t.id],
        movements: t.movements?.map(m => ({ movementId: m.id, version: m.version }))
      }));
      saveAllocations(input, "Ticketing Grid: Bulk unlink tickets", () => {
        bulkUnlinkTickets({
          variables: { bulkUnlinkInput },
          onCompleted: onRefresh
        });
      });
    }
  }, [bulkUnlinkTickets, unlinkTicketState.input, saveAllocations, onRefresh]);

  const onDeleteTickets = useCallback(
    (ticketIds: string[]) => {
      resetErrors();
      setDeleteTicketState({ showConfirmation: true, ticketIds });
    },
    [resetErrors]
  );

  const onCancelDeleteTicket = useCallback(() => {
    setDeleteTicketState({ showConfirmation: false });
  }, []);

  const onDeleteTicketConfirmed = useCallback(() => {
    const ticketIds = deleteTicketState.ticketIds;
    setDeleteTicketState({ showConfirmation: false });

    if (ticketIds) {
      deleteTickets({
        variables: { ids: ticketIds },
        onCompleted: onRefresh
      });
    }
  }, [deleteTickets, onRefresh, deleteTicketState.ticketIds]);

  // zero out
  const onZeroOut = useCallback(
    (input: TGridTicket[]) => {
      resetErrors();
      setZeroOutTicketState({ showConfirmation: true, input });
    },
    [resetErrors]
  );

  const onCancelZeroOutTicket = useCallback(() => {
    setZeroOutTicketState({ showConfirmation: false });
  }, []);

  const updateLinkedTickets = useCallback(
    (input: TGridTicket[]) => {
      const bulkUpdateInput = input.flatMap(t =>
        t.movements?.map(m => ({
          ticketId: t.id,
          volume: 0.0000001,
          movementId: m.id,
          version: m.version
        }))
      );
      saveAllocations(input, "Ticketing Grid: Bulk Zereout", () => {
        bulkUpdateLinkedTickets({
          variables: { input: bulkUpdateInput },
          onCompleted: onRefresh
        });
      });
    },
    [saveAllocations, bulkUpdateLinkedTickets, onRefresh]
  );

  const onZeroOutTicketConfirmed = useCallback(() => {
    const input = zeroOutTicketState.input;
    setZeroOutTicketState({ showConfirmation: false });
    if (input) {
      const ticketInput = input.map(t => ({
        id: t.id,
        version: t.version,
        volumes: t.volumes.map(v => ({
          id: v.id,
          netVolume: 0.0000001,
          grossVolume: 0.0000001
        }))
      }));

      updateTickets({
        variables: { tickets: ticketInput },
        onCompleted: () => updateLinkedTickets(input)
      });
    }
  }, [updateTickets, zeroOutTicketState.input, updateLinkedTickets]);

  if (picklistsLoading || !picklistsReady) {
    return <LoadingPanel />;
  }

  const loading = [
    searchTicketsLoading,
    searchLinkedTicketsLoading,
    deleteTicketsLoading,
    loadingBulkUnlinkTickets,
    allocateLoading,
    updateTicketsLoading,
    bulkUpdateLinkedTicketsLoading
  ].some(l => l);

  const errors = [
    searchTicketsError,
    searchLinkedTicketsError,
    allocateError,
    deleteTicketsError,
    errorBulkUnlinkTickets,
    updateTicketsError,
    bulkUpdateLinkedTicketsError
  ].filter((e): e is ApolloError => Boolean(e));

  return (
    <>
      {pathName === "/ticketing/manageTickets" && (
        <GlobalHeader
          pageName="Manage Tickets"
          buttonContent={[<EnterpriseSystemSelect key={1} />]}
        />
      )}

      <div id="Ticketing" className="tickets-page">
        <div ref={searchFormRef}>
          <SearchTicketsContainer
            searchCriteria={getSearchCriteria()}
            onSearch={handleSearch}
            open={searchFormOpen}
            onCollapsed={collapsed => setSearchFormOpen(collapsed)}
            onReset={onReset}
            loading={loading}
          />
        </div>
        {loading && <InlineLoadingPanel />}
        {!!errors.length && <ApolloErrorViewer error={errors} />}
        {deleteTicketState.showConfirmation && (
          <Dialog title={"Delete Ticket"} onClose={onCancelDeleteTicket}>
            <div className="component-title" style={{ padding: "16px" }}>
              Are you sure you want to delete selected tickets?
            </div>
            <DialogActionsBar>
              <Button icon="cancel" onClick={onCancelDeleteTicket}>
                Cancel
              </Button>
              <Button themeColor={"primary"} icon="check" onClick={onDeleteTicketConfirmed}>
                Delete
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
        {unlinkTicketState.showConfirmation && (
          <Dialog title={"Unlink Tickets"} onClose={onCancelDeleteTicket}>
            <div className="component-title" style={{ padding: "16px" }}>
              Are you sure you want to unlink selected tickets?
            </div>
            <DialogActionsBar>
              <Button icon="cancel" onClick={onCancelUnlinkTicket}>
                Cancel
              </Button>
              <Button themeColor={"primary"} icon="check" onClick={onUnlinkTicketsConfirmed}>
                Unlink
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
        {zeroOutTicketState.showConfirmation && (
          <Dialog title={"Zero Out Ticket"} onClose={onCancelZeroOutTicket}>
            <div className="component-title" style={{ padding: "16px" }}>
              Please confirm setting all volumes to 0.0000001?
            </div>
            <DialogActionsBar>
              <Button icon="cancel" onClick={onCancelZeroOutTicket}>
                Cancel
              </Button>
              <Button themeColor={"primary"} icon="check" onClick={onZeroOutTicketConfirmed}>
                Zero Out
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
        {searchCalled && (
          <div
            style={{
              height: `calc(100vh - ${top}px)`,
              boxSizing: "border-box"
            }}>
            <TicketingGrid
              tickets={tickets ?? []}
              onRefresh={onRefresh}
              loading={loading}
              onDelete={onDeleteTickets}
              onUnlink={onUnlinkTickets}
              onZeroOut={onZeroOut}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ManageTickets;

/*

*/
