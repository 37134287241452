import { firstDayOfMonth, lastDayOfMonth } from "@progress/kendo-date-math";
import { IForecastState } from "../../_redux/IReduxState";
import { IForecastMonthlySummary } from "../models";
import {
  FORECAST_BASE_PRODUCTS,
  FORECAST_BULK_EDIT_DAILY_DATA,
  FORECAST_BULK_EDIT_DETAILS_DATA,
  FORECAST_CLOSE_DETAILS,
  FORECAST_CRITERIA,
  FORECAST_DAILY,
  FORECAST_DATASTATE,
  FORECAST_DETAILS,
  FORECAST_DONE_EDIT,
  FORECAST_EDIT_BASE_DATA,
  FORECAST_EDIT_DAILY_DATA,
  FORECAST_EDIT_DETAILS_DATA,
  FORECAST_ENTER_EDIT,
  FORECAST_EXIT_EDIT,
  FORECAST_FAIL_EDIT,
  FORECAST_FILTER_OPTIONS,
  FORECAST_MONTHLY,
  FORECAST_PICKLISTS,
  FORECAST_SELECT_ALL_CHANGE,
  FORECAST_SELECT_DAILY_ROW,
  FORECAST_SELECT_DETAILS_ROW,
  FORECAST_SELECT_MONTHLY_ROW,
  FORECAST_SELECTED_FILTER,
  FORECAST_SELECTED_GROUP,
  FORECAST_SELECTION_CHANGE
} from "./forecastActionTypes";
const initialSummary: IForecastMonthlySummary = {
  Id: "Initial",
  monthName: "Initial",
  startDate: "2021-05-01",
  endDate: "2021-05-01",
  headerId: 1784,
  supplier: {
    id: 1000,
    name: "sopus",
  },
  customer: {
    id: 2007,
    code: "6",
    name: "Exchange",
    businessUnit: "Supply",
  },
  terminal: {
    id: 3228,
    name: "Y354",
  },
  modeOfTransport: {
    id: 6000,
    name: "10",
  },
  product: {
    id: 4109,
    name: "400007390",
  },
  unitOfMeasure: {
    id: 5000,
    name: "GAL",
  },
  forecastVolume: 789.6,
  approvedVolume: 662.62,
};

//TODO: remove all 'any' types - set up linting after complete refactor
const initState: IForecastState = {
  loading: false,
  isMonthlyDisplayMode: false,
  unitOfMeasure: "GAL",
  error: { message: "" },
  errorNotified: true,

  monthlySummary: {
    data: [initialSummary],
    // original: {},
    header: {},
    dataState: {},
    inEdit: false,
  },
  dailySummary: {
    data: [initialSummary],
    // data: null,
    // original: {},
    header: {},
    dataState: {},
    inEdit: false,
  },
  picklists: {
    customer: [],
    terminal: [],
    product: [],
    terminalGroup: [],
  },
  criteria: {
    startDate: firstDayOfMonth(new Date()),
    endDate: lastDayOfMonth(new Date()),
    customer: [],
    terminal: [],
    terminalGroup: [],
    finishedProduct: [],
    planningCycle: "",
    isMonthlyDisplayMode: false,
  },
};

export default function forecastReducer(
  state: IForecastState = initState,
  action: { type: any; payload: { isMonthlyDisplayMode: boolean } }
) {
  switch (action.type) {
    case "loading": {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case "logError": {
      return {
        ...state,
        error: action.payload,
        errorNotified: false,
      };
    }

    case "errorNotified": {
      return {
        ...state,
        errorNotified: true,
      };
    }

    case "forecastUOM": {
      return {
        ...state,
        //payload = forecast, update entire forecast folder
      };
    }

    case "forecastType": {
      return {
        ...state,
        isMonthlyDisplayMode: action.payload,
        criteria: {
          ...state.criteria,
          isMonthlyDisplayMode: action.payload,
        },
      };
    }

    //=====================================
    // grid criteria
    //=====================================
    case FORECAST_PICKLISTS: {
      return {
        ...state,
        picklists: action.payload,
      };
    }

    case FORECAST_CRITERIA: {
      const isMonthlyDisplayMode = action.payload.isMonthlyDisplayMode ?? false;
      return {
        ...state,
        criteria: action.payload,
        isMonthlyDisplayMode: isMonthlyDisplayMode,
        monthlySummary: isMonthlyDisplayMode ? state.monthlySummary : null,
        dailySummary: isMonthlyDisplayMode ? null : state.dailySummary,
      };
    }

    //=====================================
    // grid data fetched
    //=====================================
    case FORECAST_MONTHLY: {
      const initDataState = {
        skip: 0,
        take: 100,
      };
      return {
        ...state,
        isMonthlyDisplayMode: true,
        monthlySummary: {
          ...action.payload,
          dataState: initDataState,
        },
      };
    }

    case FORECAST_DAILY: {
      const initDataState = {
        skip: 0,
        take: 100,
      };
      return {
        ...state,
        isMonthlyDisplayMode: false,
        dailySummary: {
          ...action.payload,
          dataState: initDataState,
        },
      };
    }

    case FORECAST_DETAILS: {
      const isMonthlyDisplayMode = state.isMonthlyDisplayMode;
      const initDataState = {};
      if (isMonthlyDisplayMode) {
        return {
          ...state,
          monthlySummary: {
            ...state.monthlySummary,
            details: {
              ...action.payload,
              dataState: initDataState,
            },
          },
        };
      } else {
        return {
          ...state,
          dailySummary: {
            ...state.dailySummary,
            details: {
              ...action.payload,
              dataState: initDataState,
            },
          },
        };
      }
    }

    case FORECAST_BASE_PRODUCTS: {
      const isMonthlyDisplayMode = state.isMonthlyDisplayMode;
      if (isMonthlyDisplayMode) {
        return {
          ...state,
          monthlySummary: {
            ...state.monthlySummary,
            baseProduct: action.payload,
          },
        };
      } else {
        return {
          ...state,
          dailySummary: {
            ...state.dailySummary,
            baseProduct: action.payload,
          },
        };
      }
    }

    //=====================================
    // grid dataState on change
    //=====================================
    case FORECAST_DATASTATE: {
      const isMonthlyDisplayMode = state.isMonthlyDisplayMode;
      if (isMonthlyDisplayMode) {
        return {
          ...state,
          monthlySummary: action.payload,
        };
      } else {
        return {
          ...state,
          dailySummary: action.payload,
        };
      }
    }

    //=====================================
    // grid active rows
    //=====================================
    case FORECAST_SELECT_MONTHLY_ROW: {
      return {
        ...state,
        monthlySummary: {
          ...state.monthlySummary,
          data: action.payload,
          showDetails: true,
          showBaseProduct: false,
        },
      };
    }

    case FORECAST_SELECT_DAILY_ROW: {
      return {
        ...state,
        dailySummary: {
          ...state.dailySummary,
          data: action.payload,
          showBaseProduct: true,
        },
      };
    }

    case FORECAST_SELECT_DETAILS_ROW: {
      return {
        ...state,
        monthlySummary: {
          ...state.monthlySummary,
          data: action.payload,
          showBaseProduct: true,
        },
      };
    }

    case FORECAST_CLOSE_DETAILS: {
      return {
        ...state,
        monthlySummary: {
          ...state.monthlySummary,
          data: action.payload,
          showDetails: false,
          showBaseProduct: false,
        },
      };
    }

    case "closeBaseProduct": {
      const isMonthlyDisplayMode = state.isMonthlyDisplayMode;
      if (isMonthlyDisplayMode) {
        return {
          ...state,
          monthlySummary: {
            ...state.monthlySummary,
            data: action.payload,
            showBaseProduct: false,
          },
        };
      } else {
        return {
          ...state,
          dailySummary: {
            ...state.dailySummary,
            data: action.payload,
            showBaseProduct: false,
          },
        };
      }
    }

    //=====================================
    // grid cell editing
    //=====================================
    case FORECAST_ENTER_EDIT:
    case FORECAST_EXIT_EDIT: {
      if (state.isMonthlyDisplayMode) {
        return {
          ...state,
          monthlySummary: action.payload,
        };
      } else {
        return {
          ...state,
          dailySummary: action.payload,
        };
      }
    }

    case FORECAST_BULK_EDIT_DAILY_DATA:
    case FORECAST_EDIT_DAILY_DATA: {
      return {
        ...state,
        dailySummary: {
          ...state.dailySummary,
          data: action.payload,
          inEdit: true,
        },
      };
    }

    case FORECAST_BULK_EDIT_DETAILS_DATA:
    case FORECAST_EDIT_DETAILS_DATA: {
      return {
        ...state,
        monthlySummary: {
          ...state.monthlySummary,
          data: action.payload,
          inEdit: true,
        },
      };
    }

    case FORECAST_EDIT_BASE_DATA: {
      if (state.isMonthlyDisplayMode) {
        return {
          ...state,
          monthlySummary: {
            ...state.monthlySummary,
            data: action.payload,
            inEdit: true,
          },
        };
      } else {
        return {
          ...state,
          dailySummary: {
            ...state.dailySummary,
            data: action.payload,
            inEdit: true,
          },
        };
      }
    }

    case FORECAST_DONE_EDIT: {
      if (state.isMonthlyDisplayMode) {
        return {
          ...state,
          monthlySummary: {
            ...state.monthlySummary,
            data: action.payload,
            inEdit: false,
          },
        };
      } else {
        return {
          ...state,
          dailySummary: {
            ...state.dailySummary,
            data: action.payload,
            inEdit: false,
          },
        };
      }
    }

    case FORECAST_FAIL_EDIT: {
      if (state.isMonthlyDisplayMode) {
        return {
          ...state,
          monthlySummary: {
            ...state.monthlySummary,
            data: action.payload,
            inEdit: true,
          },
        };
      } else {
        return {
          ...state,
          dailySummary: {
            ...state.dailySummary,
            data: action.payload,
            inEdit: true,
          },
        };
      }
    }

    //=====================================
    // grid row selection
    //=====================================
    case FORECAST_SELECT_ALL_CHANGE:
    case FORECAST_SELECTION_CHANGE: {
      if (state.isMonthlyDisplayMode) {
        return {
          ...state,
          monthlySummary: {
            ...state.monthlySummary,
            data: action.payload,
          },
        };
      } else {
        return {
          ...state,
          dailySummary: {
            ...state.dailySummary,
            data: action.payload,
          },
        };
      }
    }

    //=====================================
    // grid filtering & grouping (side bar)
    //=====================================
    case FORECAST_FILTER_OPTIONS: {
      const isMonthlyDisplayMode = state.isMonthlyDisplayMode;
      if (isMonthlyDisplayMode) {
        return {
          ...state,
          monthlySummary: {
            ...state.monthlySummary,
            filterOptions: action.payload,
          },
        };
      } else {
        return {
          ...state,
          dailySummary: {
            ...state.dailySummary,
            filterOptions: action.payload,
          },
        };
      }
    }

    case FORECAST_SELECTED_FILTER: {
      const isMonthlyDisplayMode = state.isMonthlyDisplayMode;
      if (isMonthlyDisplayMode) {
        return {
          ...state,
          monthlySummary: {
            ...state.monthlySummary,
            selectedFilter: action.payload,
          },
        };
      } else {
        return {
          ...state,
          dailySummary: {
            ...state.dailySummary,
            selectedFilter: action.payload,
          },
        };
      }
    }

    case FORECAST_SELECTED_GROUP: {
      const isMonthlyDisplayMode = state.isMonthlyDisplayMode;
      if (isMonthlyDisplayMode) {
        return {
          ...state,
          monthlySummary: {
            ...state.monthlySummary,
            selectedGroup: action.payload,
          },
        };
      } else {
        return {
          ...state,
          dailySummary: {
            ...state.dailySummary,
            selectedGroup: action.payload,
          },
        };
      }
    }

    default:
      return state;
  }
}
