import { GridCellProps } from "@progress/kendo-react-grid";
import { useContext } from "react";
import { TPdfFile } from "../types";
import { BulkImportContext } from "../util";
import { DropDownListCell } from "./DropDownListCell";

export const PdfFileNameCell = (props: GridCellProps) => {
  const { fieldConfig, refData: allRefData } = useContext(BulkImportContext);
  const { field } = props;
  const fileNameFieldConfig = fieldConfig?.find(f => f.name === field);
  const lookupName = fileNameFieldConfig?.lookupName!;
  const data = allRefData?.[lookupName] as TPdfFile[];

  if (!fileNameFieldConfig) {
    return <td></td>;
  }

  return (
    <DropDownListCell
      {...props}
      data={data}
      fieldConfig={fileNameFieldConfig}
      loading={false}
    />
  );
};
