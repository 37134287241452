import Papa from "papaparse";
import { GlobalWorkerOptions, getDocument } from "pdfjs-dist";
import { useState } from "react";
import FileDropZone from "shared/components/FileDropZone";
import { MAX_FILE_SIZE, fromUSDateFormat } from "ticketing/utils";
import { DocumentViewer } from "./DocumentViewer";
import { TBulkImportFileDropZoneProps, TBulkImportTicket } from "./types";

GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const isPdfFile = (f: File) =>
  f.type === "application/pdf" || f.name.toLowerCase().endsWith(".pdf");
const isCsvFile = (f: File) => f.type === "text/csv" || f.name.toLowerCase().endsWith(".csv");
const shouldConvertToNumber = (field: string | number) => {
  const newfield = (field as string).toLowerCase();
  return (
    newfield.startsWith("volume") &&
    (newfield.endsWith("net") || newfield.endsWith("gross") || newfield.endsWith("temp"))
  );
};

//remove all non printable characters and spaces
const transformHeader = (header: string) => header.replace(/[^\x21-\x7E]+/g, "").toLowerCase();

const trasformFieldValue = (value: string, field: string | number) => {
  const newfield = (field as string).replaceAll(" ", "").toLowerCase();
  //remove all non printable characters
  const tValue = value.replace(/[^\x20-\x7E]+/g, "");

  if (
    newfield.startsWith("volume") &&
    (newfield.endsWith("net") || newfield.endsWith("gross") || newfield.endsWith("temp"))
  ) {
    return tValue.replace(/[\s',]/g, ""); //making sure the number fields dont have digit delimiter(comma) inside
  }
  switch (newfield) {
    case "ticketdate":
    case "bordercrossingdate":
      return fromUSDateFormat(tValue);
    case "batchname":
    case "legalentity":
    case "deliveryid":
    case "modeoftransport":
    case "carriername":
    case "productname":
    case "locationname":
    case "logisticsname":
    case "shipfromcode":
    case "shiptocode":
    case "filename":
      return { initial: tValue };
    default:
      break;
  }
  if (
    newfield.startsWith("volume") &&
    (newfield.endsWith("tempuom") || newfield.endsWith("uom"))
  ) {
    return { initial: tValue };
  }
  return tValue;
};

export const BulkImportFileDropZone = ({
  pdfFiles,
  onNewPdfFile,
  onNewTicketsData
}: TBulkImportFileDropZoneProps) => {
  const [showPdfFile, setShowPdfFile] = useState<File | null>();
  const MAX_FILES = 25;
  //on file drop
  const onAcceptFiles = (acceptedFiles: File[]) => {
    //make sure we dont process the same file again
    //should we notify user???
    acceptedFiles
      .filter(f => isPdfFile(f) && pdfFiles.findIndex(p => p.file.name === f.name) < 0)
      .forEach(f => processPdfFile(f));
    acceptedFiles.filter(f => isCsvFile(f)).forEach(f => parseCSVFile(f));
  };

  const processPdfFile = (pdfFile: File) => {
    pdfFile.arrayBuffer().then(buff => {
      getDocument(new Uint8Array(buff)).promise.then(p =>
        onNewPdfFile({
          file: pdfFile,
          numPages: p.numPages,
          name: pdfFile.name
        })
      );
    });
  };

  //parse csv file...
  const parseCSVFile = (csvFile: File) => {
    Papa.parse(csvFile, {
      complete: onParseCSVFileCompleted,
      header: true,
      skipEmptyLines: true,
      dynamicTyping: shouldConvertToNumber,
      transform: trasformFieldValue,
      transformHeader
    });
  };

  //on parse completed
  const onParseCSVFileCompleted = (result: Papa.ParseResult<TBulkImportTicket>) => {
    if (!result.meta.aborted) {
      onNewTicketsData(result.meta.fields, result.data);
    }
  };

  return (
    <div className="bulk-import-tickes-card-container">
      <div style={{ flexGrow: "2" }}>
        <FileDropZone
          accept={{
            "text/plain": [".csv"],
            "application/pdf": [".pdf"]
          }}
          onAccept={onAcceptFiles}
          maxFiles={MAX_FILES}
          maxSize={MAX_FILE_SIZE}
          multiple={true}
          displayText="Drag 'n' drop csv/pdf files here, or click to select csv/pdf file"
        />
      </div>
      {showPdfFile && (
        <DocumentViewer
          file={showPdfFile}
          onClose={() => setShowPdfFile(null)}></DocumentViewer>
      )}
      {pdfFiles.length > 0 && (
        <div className="ticket-files-list-wrap">
          <div className="k-notification-secondary ticket-files-list-header">
            <span>Selected PDF files</span>
          </div>
          <ul className="ticket-files-list">
            {pdfFiles.map(f => {
              return (
                <li key={f.file.name}>
                  <span
                    className="ticket-files-list-item-text"
                    onClick={() => setShowPdfFile(f.file)}>
                    <span>{f.file.name}</span>
                    <span
                      style={{
                        color: "white",
                        fontSize: "x-small"
                      }}>{` (${f.numPages})`}</span>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
