import { useQuery } from "@apollo/client";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { Badge, Flexbox, Label, Sizes } from "@sede-x/shell-ds-react-framework";
import { loader } from "graphql.macro";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { GqlResponse } from "types";
import { IAppState } from "../../_redux/IReduxState";
const FETCH_POLICY_NO_CACHE = { fetchPolicy: "no-cache" as MutationFetchPolicy };

const UNREAD_NOTIFICATONS = loader("../graphql/query-unread-notifications.graphql");

type TUnReadNotificationResponse = GqlResponse<number, "unreadNotifications">;
const MAX_NOTIFICATION_COUNT_DISPLAY = 99;

const NotificationBadge = () => {
  const { loading, data, error, refetch } = useQuery<TUnReadNotificationResponse>(
    UNREAD_NOTIFICATONS,
    FETCH_POLICY_NO_CACHE
  );

  const notificationState = useSelector((state: IAppState) => state.notification);
  useEffect(() => {
    if (notificationState?.resetBadge) {
      refetch();
    }
  }, [notificationState, refetch]);

  const messageCount = () => {
    if (error) {
      return "e+";
    }
    if (data?.unreadNotifications) {
      return data.unreadNotifications > MAX_NOTIFICATION_COUNT_DISPLAY
        ? "99+"
        : data.unreadNotifications;
    }
    return "";
  };
  return (
    <Flexbox gap="2px">
      <Label>Notifications</Label>
      {!loading && data?.unreadNotifications && (
        <Badge size={Sizes.Small}>{messageCount()}</Badge>
      )}
    </Flexbox>
  );
};

export default NotificationBadge;
