import { TMovementGroup } from "./ticketing.types";
import {
  MOVEMENT_INFO_FIELD_NAME_ACTUALIZED_BY,
  equalsIgnoreCase,
  isActualizedByInfoSet,
  isAutoActualizeInfoSet
} from "./utils";

export type TMovementValidationResult = {
  error?: boolean;
  warning?: boolean;
  message?: string;
};

const validateAutoActualized = (
  movementGroup: TMovementGroup
): TMovementValidationResult | undefined => {
  if (isAutoActualizeInfoSet(movementGroup)) {
    return { error: true, message: "Movement is set to Auto Actualize outside of MACk" };
  }
};

const validateActualizedBySet = (
  movementGroup: TMovementGroup
): TMovementValidationResult | undefined => {
  if (isActualizedByInfoSet(movementGroup)) {
    const actualizedBy = movementGroup?.activeMovement?.infoFields?.find(
      field => field && equalsIgnoreCase(field.name, MOVEMENT_INFO_FIELD_NAME_ACTUALIZED_BY)
    )?.value;
    if (actualizedBy) {
      return {
        error: true,
        message: `For Delivery ID#${movementGroup.activeMovement?.enterpriseSystemCode} "Actualized by" has been set to ${actualizedBy}.`
      };
    }
  }
};

const validateActualizedExternally = (
  movementGroup: TMovementGroup
): TMovementValidationResult | undefined => {
  if (movementGroup.movements.find(m => m.isActualizedExternally)) {
    return { error: true, message: "Movement is actualized externally" };
  }
};

//creating a new message to display formula adjustment pricing warning

const validateFormulaPrice = (
  movementGroup: TMovementGroup
): TMovementValidationResult | undefined => {
  if (
    movementGroup.movements.some(
      m => m.delDeal?.hasFormulaPricing || m.recDeal?.hasFormulaPricing
    )
  ) {
    return {
      warning: true,
      message:
        "Warning: This movement is based on formula pricing. Please update in 'Measures' section."
    };
  }
};

const movementValidations = [
  validateActualizedExternally,
  validateAutoActualized,
  validateActualizedBySet,
  validateFormulaPrice
];

export const validateMovementGroup = (movementGroup: TMovementGroup) => {
  return movementValidations
    .map(f => f?.(movementGroup))
    .filter((r): r is TMovementValidationResult => Boolean(r));
};
