import { gql, useLazyQuery } from "@apollo/client";
import { useState, useCallback } from "react";
import { GqlResponse, LongQueryOperatorInput, TShipCode } from "ticketing/ticketing.types";
import { toEqOrInQueryOperator } from "ticketing/utils";

export const GQL = gql`
  query shipCodesFilterBy($filter: ShipCodeFilterInput!) {
    shipCodes: shipCodesFilterBy(filter: $filter) {
      id
      code
      description
      # maps {
      #   counterParty {
      #     id
      #   }
      #   facility {
      #     id
      #   }
      #   internalParty {
      #     id
      #   }
      # }
    }
  }
`;

type Response = GqlResponse<TShipCode[], "shipCodes">;
type ShipCodeSearchParams = {
  shipCodeType: "SHIP_FROM" | "SHIP_TO";
  code?: string;
  internalPartyIds?: string[];
  counterPartyIds?: string[];
  facilityIds?: string[];
  completed?: (shipCodes: TShipCode[]) => void;
};
const sort = (shipCodes: TShipCode[]) =>
  shipCodes.slice().sort((sc1, sc2) => sc1.description.localeCompare(sc2.description));

const useSearchShipCodes = () => {
  const [searchResults, setSearchResults] = useState<TShipCode[]>([]);

  const [filter, { loading, error }] = useLazyQuery<Response>(GQL, {
    fetchPolicy: "no-cache",
    // onCompleted: (data) => setSearchResults(sort(data.shipCodes)),
  });

  const onSearchCompleted = useCallback(
    (shipCodes: TShipCode[], completed?: (shipCodes: TShipCode[]) => void) => {
      const sortedShipCodes = sort(shipCodes);
      setSearchResults(sortedShipCodes);
      completed?.(sortedShipCodes);
    },
    [setSearchResults]
  );

  const search = useCallback(
    ({ shipCodeType, code, internalPartyIds, counterPartyIds, facilityIds, completed }: ShipCodeSearchParams) => {
      filter({
        variables: {
          filter: {
            shipCodeType,
            code: code ? { regexIgnoreCase: `%${code}%` } : undefined,
            counterPartyId: toEqOrInQueryOperator<string, LongQueryOperatorInput>(counterPartyIds),
            facilityId: toEqOrInQueryOperator<string, LongQueryOperatorInput>(facilityIds),
            internalPartyId: toEqOrInQueryOperator<string, LongQueryOperatorInput>(internalPartyIds),
          },
        },
        onCompleted: (data) => onSearchCompleted(data.shipCodes, completed),
      });
    },
    [filter, onSearchCompleted]
  );

  const clear = useCallback(() => {
    setSearchResults([]);
  }, [setSearchResults]);

  return [search, searchResults, loading, error, clear] as const;
};

export default useSearchShipCodes;
