import { createContext } from "react";
import { TBatch, TMovement, TMovementGroup } from "ticketing/ticketing.types";

type MovementsMainContextProps = {
  onBatchSelected?: (batch: TBatch) => void;
  onBatchUpdated?: (batch: TBatch) => void;
  onMovementsUpdated?: (movements: TMovement[]) => void;
  onActiveMovementGroupChanged?: (movementGroup: TMovementGroup | null) => void;
};
export const MovementsMainContext = createContext<MovementsMainContextProps>({});
