import { Dialog } from "@progress/kendo-react-dialogs";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import type { DocumentCallback } from "react-pdf/dist/cjs/shared/types";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

export const DocumentViewer = (props: { file: File; onClose: () => void }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages: nextNumPages }: DocumentCallback): void {
    setNumPages(nextNumPages);
  }

  return (
    <Dialog
      id="pdfViewerDialog"
      style={{ padding: "4px 2px", color: "black" }}
      title={props.file.name}
      onClose={() => props.onClose()}>
      <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber || 1} width={600} scale={1} />
        <div className="page-controls">
          <button
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber(pageNumber - 1)}
            type="button"
            style={{ cursor: "pointer" }}
            aria-label="Previous page">
            ‹
          </button>
          <span>
            {pageNumber} of {numPages}
          </span>
          <button
            disabled={pageNumber >= numPages}
            onClick={() => setPageNumber(pageNumber + 1)}
            type="button"
            style={{ cursor: "pointer" }}
            aria-label="Next page">
            ›
          </button>
        </div>
      </Document>
    </Dialog>
  );
};
