import { ApolloError, useMutation } from "@apollo/client";
import {
  Drawer,
  FormField,
  Heading,
  Sentiments,
  Sizes,
  TextInput,
  TextTypes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { TTerminalsFilter } from "carbonIQ/carbonIQtypes";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import { loader } from "graphql.macro";
import { useState } from "react";
import LoadingPanel from "shared/components/LoadingPanel";

const ciAddNewTerminal = loader("../graphql/mutation-CarbonAddNewTerminal.graphql");

const ciUpdateTerminal = loader("../graphql/mutation-CarbonUpdateTerminal.graphql");

const FETCH_POLICY_NO_CACHE = "no-cache";

const AddOrUpdateTerminals = ({
  details,
  onClose,
  onSubmit
}: {
  details: TTerminalsFilter | null;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  const [open, setOpen] = useState(true);
  const handleOnClose = () => {
    onSubmit();
    onClose();
    setOpen(false);
  };

  const [loading, setLoading] = useState(false);

  // Add New Terminal
  const [addNewTerminal, { loading: addTerminalLoading, error: addTerminalError }] =
    useMutation(ciAddNewTerminal, {
      fetchPolicy: FETCH_POLICY_NO_CACHE,
      onCompleted: () => handleOnClose(),
      onError: () => {
        setLoading(false);
      }
    });

  // Update New Terminal
  const [updateTerminal, { loading: updateTerminalLoading, error: updateTerminalError }] =
    useMutation(ciUpdateTerminal, {
      fetchPolicy: FETCH_POLICY_NO_CACHE,
      onCompleted: () => handleOnClose(),
      onError: () => {
        setLoading(false);
      }
    });

  const isStateAdd: boolean = !details?.id;

  const [preFillTerminalDetails, setPreFillTerminalDetails] = useState<TTerminalsFilter | null>(
    isStateAdd ? null : details
  );

  const isLoading = [loading, updateTerminalLoading, addTerminalLoading].some(elm => elm);

  const onSubmitClick = () => {
    const tempdata = {
      displayName: preFillTerminalDetails?.displayName?.trim(),
      gsapCode: preFillTerminalDetails?.gsapCode?.trim(),
      gsapName: preFillTerminalDetails?.gsapName?.trim()
    };

    isStateAdd
      ? addNewTerminal({
          variables: {
            terminal: tempdata
          }
        })
      : updateTerminal({
          variables: {
            terminal: {
              ...tempdata,
              id: preFillTerminalDetails?.id,
              version: preFillTerminalDetails?.version
            }
          }
        });
  };

  const isError = [addTerminalError, updateTerminalError].some(e => e);
  const errors = [addTerminalError, updateTerminalError].filter((e): e is ApolloError =>
    Boolean(e)
  );
  return (
    <Drawer
      header={
        <Heading className="setting-form-heading" type={TextTypes.H2}>
          {(isStateAdd ? "Add New" : "Update") + " Terminals"}
        </Heading>
      }
      borders={false}
      closeButton={false}
      sticky
      mask={true}
      open={open}
      size={Sizes.Medium}
      onClose={() => {
        handleOnClose();
      }}
      actions={[
        {
          label: "Cancel",
          action: () => {
            handleOnClose();
          },
          props: {
            variant: Variants.Outlined
          }
        },
        {
          label: "Save",
          action: () => {
            setLoading(true);
            onSubmitClick();
          },
          props: {
            disabled:
              !preFillTerminalDetails?.displayName?.trim() ||
              !preFillTerminalDetails?.gsapCode?.trim() ||
              !preFillTerminalDetails?.gsapName?.trim()
          }
        }
      ]}>
      {isError && <CommonErrorComponent error={errors} />}

      {!isStateAdd && <p>Updating Terminal - Some Warrning message</p>}

      <form>
        <FormField
          size={"medium"}
          id="gsap-code-label"
          mandatory={true}
          label="Terminal Code"
          bottomLeftHelper={{
            content: !preFillTerminalDetails?.gsapCode ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              const tempObj = Object.create(preFillTerminalDetails);
              tempObj.gsapCode = event.target.value.trim();
              setPreFillTerminalDetails(tempObj);
            }}
            value={preFillTerminalDetails?.gsapCode ?? ""}
            invalid={!preFillTerminalDetails?.gsapCode}
          />
        </FormField>
        <FormField
          size={"medium"}
          id="gsap-name-label"
          label="Terminal Name"
          mandatory={true}
          bottomLeftHelper={{
            content: !preFillTerminalDetails?.gsapName ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              const tempObj = Object.create(preFillTerminalDetails);
              tempObj.gsapName = event.target.value;
              setPreFillTerminalDetails(tempObj);
            }}
            value={preFillTerminalDetails?.gsapName ?? ""}
            invalid={!preFillTerminalDetails?.gsapName}
          />
        </FormField>

        <FormField
          size={"medium"}
          id="display-name-label"
          label="Display Name"
          mandatory={true}
          bottomLeftHelper={{
            content: !preFillTerminalDetails?.displayName ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              const tempObj = Object.create(preFillTerminalDetails);
              tempObj.displayName = event.target.value;
              setPreFillTerminalDetails(tempObj);
            }}
            value={preFillTerminalDetails?.displayName ?? ""}
            invalid={!preFillTerminalDetails?.displayName}
          />
        </FormField>
      </form>

      {isLoading && <LoadingPanel />}
    </Drawer>
  );
};

export default AddOrUpdateTerminals;
