//import React from 'react'

import { toString as toKString } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { useCallback, useContext, useState } from "react";
import { TMovement, TMovementGroup } from "ticketing/ticketing.types";
import { equalsIgnoreCase } from "ticketing/utils";
import MovementAdditionalDetailsContainer from "./MovementAdditionalDetailsContainer";
import { MovementChildDetails } from "./MovementChildDetails";
import { MovementChildHeader } from "./MovementChildGroupHeader";
import { MovementsMainContext } from "./utils";

export const MovementDetails = ({
  movement,
  movementGroup,
  activeMovementGroup,
  isActiveMovement
}: {
  movement: TMovement;
  movementGroup: TMovementGroup;
  activeMovementGroup: TMovementGroup | null;
  isActiveMovement: boolean;
}) => {
  const { onActiveMovementGroupChanged } = useContext(MovementsMainContext);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const [showChildren, setShowChildren] = useState(false);
  const radarAlert = "Radar Alert";
  const hasAlerts = movement.alerts.some(alert => !equalsIgnoreCase(alert.code, radarAlert));
  const hasChildren = (movement.children?.length ?? 0) > 0;

  const onActiveMovementChanged = useCallback(
    (aMovement: TMovement) => {
      if (activeMovementGroup?.activeMovement.id === aMovement.id) {
        if (activeMovementGroup.activeChild) {
          return onActiveMovementGroupChanged?.({
            ...movementGroup,
            activeChild: null
          });
        }
        return onActiveMovementGroupChanged?.(null);
      }

      return onActiveMovementGroupChanged?.({
        ...movementGroup,
        activeMovement: aMovement
      });
    },
    [
      movementGroup,
      activeMovementGroup?.activeMovement.id,
      activeMovementGroup?.activeChild,
      onActiveMovementGroupChanged
    ]
  );

  const onActiveChildChanged = (child: Partial<TMovement>) => {
    onActiveMovementGroupChanged?.({
      ...movementGroup,
      activeMovement: movement,
      activeChild:
        activeMovementGroup === movementGroup && movementGroup.activeChild === child
          ? null
          : child
    });
  };

  const handleShowChildren = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (
      showChildren &&
      activeMovementGroup?.activeMovement.id === movement.id &&
      activeMovementGroup.activeChild
    ) {
      onActiveMovementGroupChanged?.({
        ...movementGroup,
        activeChild: null
      });
    }
    setShowChildren(!showChildren);
    event?.stopPropagation();
  };
  const componentStyle = hasAlerts ? { color: "#DC143C" } : {};
  return (
    <>
      <div
        className="movement-detail-container"
        role="none"
        onClick={e => {
          onActiveMovementChanged(movement);
          e.stopPropagation();
        }}
        style={componentStyle}>
        <Button
          icon="round-corners"
          fillMode={"flat"}
          className={
            isActiveMovement && activeMovementGroup?.activeChild == null
              ? "color-yellow"
              : "color-grey"
          }></Button>
        <Label className="detail-label">{toKString(movement.startDate, "yyyy-MM-dd")}</Label>
        <Label className="detail-label">{movement.product?.name}</Label>
        <Label className="prefix-text">at</Label>
        <Label className="detail-label-wide">{movement.titleTransferFacility?.name}</Label>
        <Label className="prefix-text">Via</Label>
        <Label className="detail-label">{movement.activityType?.name}</Label>
        <Label className="prefix-text">From</Label>
        <Label className="detail-label-narrow">{movement.recDeal?.contractNumber}</Label>
        <Label className="prefix-text">To</Label>
        <Label className="detail-label-narrow">{movement.delDeal?.contractNumber}</Label>
        <Label className="detail-label">{movement.incoterm?.name}</Label>
        <div style={{ display: "flex" }}>
          {hasChildren && (
            <Button
              icon={showChildren ? "minus-outline" : "plus-outline"}
              fillMode={"flat"}
              onClick={handleShowChildren}></Button>
          )}
          <Button
            icon="info"
            style={{ color: "blue" }}
            fillMode={"flat"}
            onClick={e => {
              setShowAdditionalDetails(true);
              e.stopPropagation();
            }}></Button>
        </div>
      </div>
      {showAdditionalDetails && (
        <MovementAdditionalDetailsContainer
          movement={movement}
          onClose={() => setShowAdditionalDetails(false)}></MovementAdditionalDetailsContainer>
      )}
      {showChildren &&
        movement.children
          ?.sort((child1, child2) =>
            (child1.enterpriseSystemCode ?? "").localeCompare(child2.enterpriseSystemCode ?? "")
          )
          ?.map(child => (
            <div
              style={{ margin: "8px 0 2px 24px" }}
              className="movement-grp-wrapper"
              key={child.id}>
              <MovementChildHeader childMovement={child} />
              <MovementChildDetails
                childMovement={child}
                isActiveChild={activeMovementGroup?.activeChild === child}
                onActiveChildChanged={onActiveChildChanged}
              />
            </div>
          ))}
    </>
  );
};
