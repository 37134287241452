import {
  Button,
  Dropdown,
  Menu,
  MenuItem,
  Positions,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { ChevronDown } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";

const EnterpriseSystemSelect = () => {
  const { enterpriseSystems, selectedEnterpriseSystem, setEnterpriseSystem } = usePicklists();
  const overlay = (
    <Menu>
      {enterpriseSystems?.map(elm => (
        <MenuItem
          key={elm.id}
          onClick={() => {
            elm && setEnterpriseSystem?.(elm);
          }}>
          {elm.name}
        </MenuItem>
      ))}
    </Menu>
  );
  return (
    <Dropdown overlay={overlay} trigger={["click"]}>
      <Button
        variant={Variants.Transparent}
        icon={<ChevronDown />}
        iconPosition={Positions.Right}>
        {selectedEnterpriseSystem?.name}
      </Button>
    </Dropdown>
  );
};

export default EnterpriseSystemSelect;
