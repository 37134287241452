import { GridCellProps } from "@progress/kendo-react-grid";
import {
  TBatch,
  TCarrier,
  TMovement,
  TMovementGroup,
  TShipCode,
  TTicket
} from "ticketing/ticketing.types";

export type TRefData = {
  id: number | string;
  name: string;
};

export const enum BULK_IMPORT_FIELDS {
  BATCH_FIELD_NAME = "batchname",
  DELIVERY_ID_FIELD_NAME = "deliveryid",
  MOT_FIELD_NAME = "modeoftransport",
  CARRIER_FIELD_NAME = "carriername",
  PRODUCT_FIELD_NAME = "productname",
  FACILITY_FIELD_NAME = "locationname",
  LOGISTICS_FIELD_NAME = "logisticsname",
  SHIP_FROM_CODE_FIELD_NAME = "shipfromcode",
  SHIP_TO_CODE_FIELD_NAME = "shiptocode",
  TICKET_NUMBER_FIELD_NAME = "ticketnumber",
  TICKET_DATE_FIELD_NAME = "ticketdate",
  RAILCARS_FIELD_NAME = "railcars",
  PO_FIELD_NAME = "ponumber",
  BORDER_CROSSING_DATE_FIELD_NAME = "bordercrossingdate",
  INVOICE_COMMENTS_FIELD_NAME = "comments",
  TMS_BOL_NUMBER = "tmsbol",
  PDF_FILE_FIELD_NAME = "filename",
  PDF_FILE_RANGE_FIELD_NAME = "pagerange",
  VOLUME_GROUP_FIELD_NAME = "volume1"
}

export type TBulkImportTicketDeliveryField = {
  initial?: string | null;
  value?: TMovementGroup | null;
};
export type TBulkImportTicketRefField = {
  initial?: string | null;
  value?: TRefData | null;
};
export type TBulkImportTicketCarrierField = {
  initial?: string | null;
  value?: TCarrier;
};
export type TBulkImportTicketShipCodeField = {
  initial?: string | null;
  value?: TShipCode | null;
};
export type TBulkImportTicketPdfFileField = {
  initial?: string | null;
  value?: TPdfFile | null;
};

export type TBulkImportTicket = {
  batchname: TBulkImportTicketRefField | null;
  bordercrossingdate: string | Date;
  carriername: TBulkImportTicketCarrierField | null;
  comments?: string;
  tmsbol?: string;
  deliveryid: TBulkImportTicketDeliveryField | null;
  errors?: string[];
  filename: TBulkImportTicketPdfFileField | null;
  inEdit?: string | null;
  KEY: string;
  locationname: TBulkImportTicketRefField | null;
  logisticsname: TBulkImportTicketRefField | null;
  modeoftransport: TBulkImportTicketRefField | null;
  pagerange?: string;
  ponumber?: string;
  productname: TBulkImportTicketRefField | null;
  railcars?: string;
  shipfromcode: TBulkImportTicketShipCodeField | null;
  shiptocode: TBulkImportTicketShipCodeField | null;
  ticketdate: string | Date;
  ticketnumber: string;
  volume1gross: number;
  volume1net: number;
  volume1temp: number;
  volume1tempuom: string;
  volume1uom: string;
  [key: string]: any;
};

type TRefDataType = TBatch[] | TRefData[] | TShipCode[] | TPdfFile[] | TMovement[];
export type TAllRefData = {
  batches?: TBatch[];
  movements?: TMovement[];
  products?: TRefData[];
  carriers?: TRefData[];
  facilities?: TRefData[];
  fileNames?: TPdfFile[];
  [key: string]: TRefDataType | undefined;
};

export type TFieldConfig = {
  name: string;
  title: string;
  dataType: string;
  width?: string;
  isRefData?: boolean;
  isPicklist?: boolean;
  cell?: React.ComponentType<GridCellProps>;
  lookupName?: string;
  typeAhead?: boolean;
  isRequired?: boolean;
  resizable?: boolean;
  children?: TFieldConfig[];
};

export type TTicketSaveResult = TTicket &
  Record<string, unknown> & {
    document?: TRefData;
    pageNumbers?: string;
    bulkImportTicketRef?: string;
  };

export type TPdfFile = {
  file: File;
  numPages: number;
  name: string;
};

export type TBulkImportFileDropZoneProps = {
  pdfFiles: TPdfFile[];
  onNewPdfFile: (file: TPdfFile) => void;
  onNewTicketsData: (fields?: string[], data?: TBulkImportTicket[]) => void;
};
