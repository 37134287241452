import { KeyValue } from "@progress/kendo-react-form";
import { IForecastSearchCriteria } from "../../forecast/models";
import { INotificationSearchCriteria } from "../../notification/models";

const emailRegex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);

export const emailValidator = (value: string) =>
  emailRegex.test(value) ? "" : "You have entered an invalid email address!";

export const requiredValidator = (value: string) =>
  value ? "" : "This field is required";

export const phoneValidator = (value: string) =>
  value && phoneRegex.test(value) ? "" : "Please enter a valid phone number.";

export const optionalPhoneValidator = (value: string) =>
  !value || phoneRegex.test(value) ? "" : "Please enter a valid phone number.";


export const dateRangeValidator = (
  values: IForecastSearchCriteria | INotificationSearchCriteria
): KeyValue<string> => {
  return !values.startDate ||
    !values.endDate ||
    new Date(values.startDate.toDateString()) >
    new Date(values.endDate.toDateString())
    ? {
      VALIDATION_SUMMARY: "Start date can not be after End date.",
      startDate: "*Invalid Start Date",
      endDate: "*Invalid End date.",
    }
    : {};
};
