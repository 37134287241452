import { BaseTable, Modal, Sizes } from "@sede-x/shell-ds-react-framework";
import { ColumnDef, getSortedRowModel, SortingState } from "@tanstack/react-table";
import { IMACkRole, IMACkUser } from "auth";
import { useState } from "react";
import ColumnText from "shared/components/basetable/cells/ColumnText";
import NoDataTableImage from "shared/components/basetable/NoDataTableImage";

type TUsersOfRole = { role: IMACkRole; onClose: () => void };

const gridColumns: ColumnDef<IMACkUser>[] = [
  {
    header: "ID",
    accessorKey: "id",
    enableSorting: true,
    enableResizing: false,
    cell: arg => <ColumnText>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "First Name",
    accessorKey: "firstName",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Last Name",
    accessorKey: "lastName",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Status",
    accessorKey: "status",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText>{arg.getValue() as string}</ColumnText>
  }
];

const UsersOfRole = ({ role, onClose }: TUsersOfRole) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const tableOptions = {
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false
  };

  return (
    <Modal
      open={true}
      title={`Users of Role: ${role.name}`}
      size={Sizes.Large}
      onClose={onClose}
      width={"780px"}>
      <BaseTable
        className="carboniq-data-table"
        columns={gridColumns}
        data={role.users ?? []}
        noFooter={true}
        tableOptions={tableOptions}
        useColumnsSizing={true}
        maxHeight={400}
        stickyHeader={true}
        emptyStateProps={{ image: <NoDataTableImage /> }}
      />
    </Modal>
  );
};

export default UsersOfRole;
