import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { GRID_COL_INDEX_ATTRIBUTE, GridCellProps, GridDetailRowProps } from "@progress/kendo-react-grid";
import { TBulkImportTicket } from "../types";

interface StatusCellProps extends GridCellProps {
  expandChange(event: TBulkImportTicket): void;
}

export const DetailComponent = (props: GridDetailRowProps) => {
  const errors = props.dataItem["errors"];
  if (!errors) {
    return null;
  }
  return (
    <ul className="k-error-colored">
      {errors.map((error: string) => {
        return (
          <li key={error} className="k-text-error">
            {error}
          </li>
        );
      })}
    </ul>
  );
};

export const StatusCell = (props: StatusCellProps) => {
  const value = props.dataItem["errors"];
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  const className = `k-icon ${
    value?.length ? "k-link bt-i-error" : "k-i-check-circle k-i-checkmark-circle k-color-success"
  }`;

  return (
    <td
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
      {...navigationAttributes}
    >
      {value && value.length === 0 ? (
        <span className={className}></span>
      ) : (
        <strong onClick={() => props.expandChange(props.dataItem)} tabIndex={-1}>
          <span className={className}></span>
        </strong>
      )}
    </td>
  );
};
