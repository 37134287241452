import { Button, Sentiments, Variants } from "@sede-x/shell-ds-react-framework";
import { FileText } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { CellContext, RowData } from "@tanstack/react-table";
import dayjs from "dayjs";
import ColumnText from "shared/components/basetable/cells/ColumnText";

type TButtonClickHandler<TData> = (val: TData) => void;

export const TextCell = <TData extends RowData>(arg: CellContext<TData, unknown>) => (
  <ColumnText>{arg.getValue() as string}</ColumnText>
);

export const DateCell = <TData extends RowData>(arg: CellContext<TData, unknown>) => {
  const dateValue = arg.getValue() ? new Date((arg.getValue() as string) + "Z") : undefined;
  return <ColumnText>{dayjs(dateValue).format("MM/DD/YYYY HH:mm:ss")}</ColumnText>;
};

export const makeButtonCell =
  <TData extends RowData>(onclick: TButtonClickHandler<TData>) =>
  (arg: CellContext<TData, unknown>) => {
    const val = arg.getValue() as string;
    return (
      <span>
        {val && (
          <Button
            icon={<FileText />}
            iconOnly={true}
            size="small"
            sentiment={Sentiments.Positive}
            variant={Variants.Transparent}
            onClick={() => onclick(arg.row.original)}></Button>
        )}
      </span>
    );
  };
