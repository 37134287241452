import { GridCellProps } from "@progress/kendo-react-grid";
import { useContext } from "react";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import { TPicklists } from "ticketing/ticketing.types";
import { TRefData } from "../types";
import { BulkImportContext } from "../util";
import { DropDownListCell } from "./DropDownListCell";

export const PicklistCell = (props: GridCellProps) => {
  const { fieldConfig } = useContext(BulkImportContext);
  const { field } = props;
  const { picklists } = usePicklists();
  const picklistConfig = fieldConfig
    ?.flatMap(f => [f, ...(f.children ?? [])])
    .find(f => f.name === field);
  const lookupName = picklistConfig?.lookupName!;
  const data = picklists?.[lookupName as keyof TPicklists] as unknown;

  if (!picklistConfig) {
    return <td></td>;
  }

  return <DropDownListCell {...props} data={data as TRefData[]} fieldConfig={picklistConfig} />;
};
