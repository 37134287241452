import {
  Button,
  FormField,
  Modal,
  RangeDatePicker,
  TextInput,
  Variants
} from "@sede-x/shell-ds-react-framework";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { TInboundContractSearchCriteria } from "./tracker.types";

export const InboundContractSearch = ({
  open,
  searchCriteria,
  onClose,
  onSubmit
}: {
  open: boolean;
  searchCriteria: TInboundContractSearchCriteria;
  onClose: () => void;
  onSubmit: (elm: TInboundContractSearchCriteria) => void;
}) => {
  const [localSearchCriteria, setLocalSearchCriteria] =
    useState<TInboundContractSearchCriteria>(searchCriteria);

  const disabledDate = (current: Dayjs) =>
    current
      ? current.isBefore(new Date("2024-08-01"), "date") || current.isAfter(new Date(), "date")
      : false;
  return (
    <Modal
      title="Search inbound contract updae request"
      width={"800px"}
      height={"600px"}
      mask={true}
      open={open}
      onClose={onClose}>
      <div>
        <FormField size={"medium"} label="Requested between">
          <RangeDatePicker
            picker="date"
            format="MM/DD/YYYY"
            allowClear={true}
            disabledDate={disabledDate}
            autoFocus
            value={[dayjs(localSearchCriteria.startDate), dayjs(localSearchCriteria.endDate)]}
            onChange={values => {
              setLocalSearchCriteria(c => ({
                ...c,
                startDate: values?.[0]?.toISOString() ?? "",
                endDate: values?.[1]?.toISOString() ?? ""
              }));
            }}
          />
        </FormField>
        <FormField size={"medium"} id="contractIdInput" label="Contract Id">
          <TextInput
            placeholder="Comma seperated contract ids"
            value={localSearchCriteria.contractId}
            onChange={e =>
              setLocalSearchCriteria(c => ({
                ...c,
                contractId: e.target.value
              }))
            }
          />
        </FormField>
        <FormField size={"medium"} id="contractNumberInput" label="Contract Number">
          <TextInput
            placeholder="Comma seperated contract numbers"
            value={localSearchCriteria.contractNumber}
            onChange={e =>
              setLocalSearchCriteria(c => ({
                ...c,
                contractNumber: e.target.value
              }))
            }
          />
        </FormField>
        <div style={{ paddingBottom: "120px" }}></div>
        <div className="from-button-wrap">
          <Button variant={Variants.Outlined} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" onClick={() => onSubmit(localSearchCriteria)}>
            Search
          </Button>
        </div>
      </div>
    </Modal>
  );
};
