import { ApolloError } from "@apollo/client";
import { Icon } from "@progress/kendo-react-common";
import { Popup } from "@progress/kendo-react-popup";
import { MouseEvent, useContext, useEffect, useRef, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import { TMovementGroup } from "ticketing/ticketing.types";
import { equalsIgnoreCase } from "ticketing/utils";
import MovementGroup from "./MovementGroup";
import "./movements.css";
import { MovementStatusFilter } from "./MovementStatusFilter";
import { MovementsMainContext } from "./utils";

type MovementGroupContainerProps = {
  movementGroups?: TMovementGroup[] | null;
  activeMovementGroup: TMovementGroup | null;
  loading?: boolean;
  error?: ApolloError | null;
};

const EXCLUDED_MOVEMENT_STATUSES = ["Approved", "Cancelled", "Confirmed"];

/**
 *
 * @param param0
 * @returns
 */
const MovementGroupContainer = ({
  movementGroups,
  activeMovementGroup,
  loading,
  error
}: MovementGroupContainerProps) => {
  const { onActiveMovementGroupChanged } = useContext(MovementsMainContext);
  const { picklists } = usePicklists();
  const anchor = useRef(null);
  const [showFilter, setShowFilter] = useState(false);

  const [filteredStatuses, setFilteredStatuses] = useState(() =>
    picklists?.movementStatuses
      ?.filter(s => !EXCLUDED_MOVEMENT_STATUSES.some(ex => equalsIgnoreCase(ex, s.name)))
      ?.map(s => ({
        id: s.id,
        name: s.name,
        selected: true
      }))
      ?.sort((s1, s2) => s1.id - s2.id)
  );

  const onMovementStatusFilterChanged = (id: number, selected: boolean) => {
    setFilteredStatuses(statuses =>
      statuses?.map(s => (id === 0 || s.id === id ? { ...s, selected } : s))
    );
  };

  const toggleMovementStatusFilter = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setShowFilter(!showFilter);
  };

  const handleBodyClick = (e: Event) => {
    //@ts-ignore
    if (!e.target?.closest?.(".movement-status-filter")) {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    if (
      activeMovementGroup &&
      !filteredStatuses
        ?.filter(s => s.selected)
        ?.map(s => s.id)
        ?.includes(activeMovementGroup.activeMovement.status.id)
    ) {
      onActiveMovementGroupChanged?.(null);
    }
  }, [filteredStatuses, activeMovementGroup, onActiveMovementGroupChanged]);

  useEffect(() => {
    document.body.addEventListener("click", handleBodyClick);
    return () => document.body.removeEventListener("click", handleBodyClick);
  }, []);

  const filteredMovementStatusIds = filteredStatuses?.filter(s => s.selected).map(s => s.id);
  const filteredMovemenentGroups = filteredMovementStatusIds
    ? movementGroups?.filter(m =>
        filteredMovementStatusIds.includes(m.activeMovement.status.id)
      )
    : movementGroups;
  return (
    <>
      <div
        className="container-component-title"
        style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <span>Movements</span>
        {(movementGroups?.length ?? 0) > 0 && (
          <div ref={anchor} onClick={toggleMovementStatusFilter}>
            <Icon
              name="filter"
              themeColor="primary"
              className="k-cursor-pointer k-icon-sm"></Icon>
          </div>
        )}
        <Popup
          anchor={anchor.current}
          show={showFilter}
          popupClass={"popup-content"}
          anchorAlign={{ horizontal: "right", vertical: "bottom" }}
          popupAlign={{ horizontal: "right", vertical: "top" }}>
          <MovementStatusFilter
            selectedStatuses={filteredStatuses}
            onChanged={onMovementStatusFilterChanged}
            onDone={() => setShowFilter(false)}
          />
        </Popup>
      </div>

      <div className="movement-batch-container-wrapper">
        {loading && <InlineLoadingPanel />}
        {error && <ApolloErrorViewer error={error} />}
        {(filteredMovemenentGroups?.length ?? 0) === 0 && (
          <div>
            <span className="availabel-tkt-container-wrapper">No data available.</span>
          </div>
        )}
        {filteredMovemenentGroups?.map(m => (
          <MovementGroup
            key={`${m.groupId}`}
            movementGroup={m}
            activeMovementGroup={activeMovementGroup}
          />
        ))}
      </div>
    </>
  );
};

export default MovementGroupContainer;
