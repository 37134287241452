import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";

type TSelectedMovementStatus = {
  id: number;
  name: string;
  selected: boolean;
}
type MovementStatusFilterProps = {
  selectedStatuses?: TSelectedMovementStatus[]
  onChanged: (id: number, selected: boolean) => void;
  onDone: () => void;
}
export const MovementStatusFilter = (
  { selectedStatuses, onChanged, onDone }: MovementStatusFilterProps
) => {


  return (
    <div style={{ minWidth: "200px" }} className="movement-status-filter">
      <div style={{ padding: "5px", backgroundColor: "#333", color: "#e7e7e7" }}>
        <Label>Movement Status</Label>
      </div>
      <div className="k-p-2">
        <div className="k-pl-2 k-pt-1">
          <Checkbox
            checked={!selectedStatuses?.find(s => !s.selected)}
            label={"All"}
            style={{ color: "black" }}
            labelClassName="k-color-black k-ml-2"
            onChange={e => onChanged(0, e.value)}
          />
        </div>
        {selectedStatuses?.map(s =>
          <div key={s.id} className="k-pl-2 k-pt-1">
            <Checkbox
              checked={s.selected}
              label={s.name}
              value={s.id}
              style={{ color: "black" }}
              labelClassName="k-color-black k-ml-2"
              onChange={e => onChanged(+(e.target.element?.value ?? 0), e.value)} />
          </div>)
        }
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", padding: "5px", backgroundColor: "rgba(0,0,0, 0.1)" }}>
        <Button themeColor={"primary"} onClick={onDone}>Done</Button>
      </div>
    </div>
  )
}
