import { ApolloError, useMutation, useQuery } from "@apollo/client";
import {
  Badge,
  Flexbox,
  Pagination,
  Sentiments,
  StickyTypeCell,
  Table,
  TBody,
  TD as Td,
  TH as Th,
  THead,
  TR as Tr,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  AddCircleSolid,
  MailClosedSolid,
  MailOpenSolid,
  MinusCircleSolid,
  Search
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  Row,
  useReactTable
} from "@tanstack/react-table";
import { actionCreator } from "_redux/actionCreator";
import dayjs from "dayjs";
import { loader } from "graphql.macro";
import parse from "html-react-parser";
import { INotification, TNotificationSearchCriteria } from "notification/models";
import { RESET_NOTIFICATION_BADGE } from "notification/state/notificationActionTypes";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import ColumnText from "shared/components/basetable/cells/ColumnText";
import GlobalHeader from "shared/components/GlobalHeader";
import LoadingPanel from "shared/components/LoadingPanel";
import { GqlResponse } from "types";
import NotificationSearchModal from "./NotificationSearchModal";

const GET_NOTIFICATIONS = loader("../graphql/query-notifications.graphql");
const MARK_NOTIFICATION_AS_READ = loader("../graphql/mutation-mark-notification-read.graphql");
const ROWS_PER_PAGE = 10;
const PADDING = 80;

type TNotificationsResponse = GqlResponse<INotification[], "notificationsFilterBy">;
type TNotificationReadResponse = GqlResponse<INotification, "markAsReadOrUnRead">;

const dayJSFormat = (args: string) => {
  return dayjs(args).format("MM/DD/YY hh:mm:ss A");
};

const gridColumns: ColumnDef<INotification>[] = [
  {
    id: "expander",
    header: () => null,
    cell: ({ row }) => (
      <Flexbox gap={"1rem"}>
        {row.getIsExpanded() ? <MinusCircleSolid /> : <AddCircleSolid />}
        {row.original.read ? <MailOpenSolid /> : <MailClosedSolid />}
      </Flexbox>
    )
  },

  {
    header: "ID",
    accessorKey: "id",
    enableSorting: true,
    enableResizing: false,
    cell: arg => <ColumnText width={"70px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Source",
    accessorKey: "notifier.serviceName",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText width={"200px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Title",
    accessorKey: "title",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <ColumnText width={"200px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Send Date",
    accessorKey: "createdDate",
    enableResizing: false,
    enableSorting: true,
    cell: arg => (
      <ColumnText width={"200px"}>{dayJSFormat(arg.getValue() as string)}</ColumnText>
    )
  }
];

const NotificationMessage = ({ row }: { row: Row<INotification> }) => {
  return <span>{parse(row.original.message)}</span>;
};

const Notification = () => {
  const dispatch = useDispatch();
  const [searchCriteria, setSearchCriteria] = useState<TNotificationSearchCriteria>({
    fromDate: dayjs().add(-1, "week").startOf("day").toISOString(),
    toDate: dayjs().endOf("day").toISOString()
  });
  const { loading: nLoading, error: nError } = useQuery<TNotificationsResponse>(
    GET_NOTIFICATIONS,
    {
      onCompleted: data => setNotifications(data?.notificationsFilterBy),
      variables: searchCriteria
    }
  );
  const [markNotificationAsRead, { loading: rLoading, error: rError }] =
    useMutation<TNotificationReadResponse>(MARK_NOTIFICATION_AS_READ, {
      onCompleted: data => {
        setNotifications(ns =>
          ns?.map(n => (n.id === data.markAsReadOrUnRead.id ? data.markAsReadOrUnRead : n))
        );
        //update badge
        dispatch(actionCreator(RESET_NOTIFICATION_BADGE, { resetBadge: true }));
      }
    });
  const [notifications, setNotifications] = useState<INotification[]>();
  const [searchOpen, setSearchOpen] = useState<boolean>(false);

  const globalHeaderRef = useRef<HTMLDivElement>(null);
  const [tableTop, setTableTop] = useState<number>(0);

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });

  const showOrHideMessage = useCallback(
    (row: Row<INotification>) => {
      row.getToggleExpandedHandler()();
      if (row.original && !row.original.read) {
        markNotificationAsRead({
          variables: { notificationId: row.original.id.toString(), isRead: true }
        });
      }
    },
    [markNotificationAsRead]
  );

  const table = useReactTable<INotification>({
    data: notifications ?? [],
    columns: gridColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableRowSelection: false,
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination
    },
    onPaginationChange: setPagination
  });

  const handleSearchSubmit = (data: TNotificationSearchCriteria) => {
    setSearchOpen(!searchOpen);
    setSearchCriteria(data);
  };

  const onPageChange = useCallback(
    (current: number, changedPageSize: number): void => {
      table.setPageSize(changedPageSize);
      table.setPageIndex(current - 1);
    },
    [table]
  );

  const SearchButton = useMemo(() => {
    return (
      <Badge
        key={1}
        icon={<Search />}
        sentiment={Sentiments.Information}
        variant={Variants.Filled}
        onClick={() => setSearchOpen(!searchOpen)}
      />
    );
  }, [searchOpen]);

  useEffect(() => {
    const { innerHeight } = window;
    setTableTop(
      innerHeight -
        (globalHeaderRef?.current?.getBoundingClientRect().top ?? 0) -
        (globalHeaderRef?.current?.getBoundingClientRect().height ?? 0) -
        PADDING
    );
  }, [globalHeaderRef]);
  const isLoading = [nLoading, rLoading].some(Boolean);
  const errors = [nError, rError].filter((e): e is ApolloError => Boolean(e));

  return (
    <>
      <GlobalHeader pageName="Notifications" buttonContent={[SearchButton]} />

      {isLoading && <LoadingPanel />}

      {nError && <ApolloErrorViewer error={errors} />}

      <div style={{ width: "100%", height: `${tableTop}px`, overflowY: "auto" }}>
        <Table className="carboniq-data-table">
          <THead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr
                key={headerGroup.id}
                depth={headerGroup.depth}
                sticky
                stickyType={StickyTypeCell.Header}>
                {headerGroup.headers.map(header => (
                  <Th key={header.id} colSpan={header.colSpan} width={header.getSize()}>
                    {header.isPlaceholder ? null : (
                      <Flexbox gap={8} alignItems="center">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </Flexbox>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </THead>
          <TBody>
            {table.getRowModel().rows.map(row => (
              <Fragment key={row.id}>
                <Tr
                  key={row.id}
                  onClick={() => showOrHideMessage(row)}
                  style={{ cursor: "pointer" }}>
                  {row.getVisibleCells().map(cell => (
                    <Td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                </Tr>
                {row.getIsExpanded() && (
                  <Tr key={`${row.original.id}-message`}>
                    <Td colSpan={row.getVisibleCells().length}>
                      {NotificationMessage({ row })}
                    </Td>
                  </Tr>
                )}
              </Fragment>
            ))}
          </TBody>
        </Table>
        <div>
          <Pagination
            total={table.getFilteredRowModel().rows.length}
            pageSize={table.getState().pagination.pageSize}
            onChange={onPageChange}
          />
        </div>
      </div>

      {searchOpen && (
        <NotificationSearchModal
          searchCriteria={searchCriteria}
          onClose={() => setSearchOpen(!searchOpen)}
          onSubmit={handleSearchSubmit}
        />
      )}
    </>
  );
};
export default Notification;
