import { ReactComponent as Lighthouse } from "shared/assets/lighthouse.svg";

const EmptyTable = () => {
  return (
    <div className="some-random-class-name" style={{ textAlign: "center" }}>
      <Lighthouse />

      <p style={{ textAlign: "center" }}>
        <strong>No data Found</strong>
      </p>
      <p style={{ textAlign: "center" }}>
        <span>Try adjusting your search to find what you're looking for.</span>
      </p>
    </div>
  );
};

export default EmptyTable;
