import { State as DataState } from "@progress/kendo-data-query";
import axios, { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import { actionCreator } from "../_redux/actionCreator";
import { getAuthHeader } from "../auth";
import { convertToISODate } from "../shared/algorithms";
import { Page } from "../shared/api/models";
import store from "../store";

import {
  ForecastBatchImport,
  ICustomer,
  IForecastAutoExportSettings,
  IForecastBase,
  IForecastDailySummary,
  IForecastMonthlySummary,
  IForecastSearchCriteria,
  IForecastSummary,
  IGSEData,
  IProduct,
  ITerminal,
  ITerminalGroup,
} from "./models";
import { convertVolumeToGAL } from "./util/dataConvertor";
const dispatch = store.dispatch;

const forecastApiClient = axios.create({
  baseURL:
    (process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_GATEWAY_URI
      : window._env_.REACT_APP_GATEWAY_URI) + "/api",
  timeout: 5000,
  headers: { "Content-Type": "application/json" },
});

forecastApiClient.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = getAuthHeader();
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const getCancelTokenSource = () => axios.CancelToken.source();

export const fetchPicklists = async () => {
  try {
    const [customerResponse, terminalResponse, productResponse] =
      await Promise.all([
        await forecastApiClient.get<ICustomer[]>("forecast/customers"),
        await forecastApiClient.get<ITerminal[]>("forecast/terminals"),
        await forecastApiClient.get<IProduct[]>("forecast/products"),
      ]);

    const terminalGroup: ITerminalGroup[] = [];
    terminalResponse.data.forEach((t) => {
      if (!t.group1) {
        t.group1 = { id: 0, version: 0, name: "Unknown" };
      }
      if (!terminalGroup.find((g) => g.id === t.group1!.id)) {
        terminalGroup.push(t.group1);
      }
    });
    return {
      customer: sortByName(customerResponse.data),
      terminal: sortByName(terminalResponse.data),
      product: sortByName(productResponse.data),
      terminalGroup: sortByName(terminalGroup),
    };
  } catch (error) {
    return error;
  }
};
const sortByName = (
  data: ICustomer[] | ITerminal[] | IProduct[] | ITerminalGroup[]
) => data?.sort((c1, c2) => c1.name.localeCompare(c2.name));

const getSearchParams = (query: IForecastSearchCriteria) => {
  const params = new URLSearchParams();
  params.append("startDate", convertToISODate(query.startDate));
  params.append("endDate", convertToISODate(query.endDate));

  if ((query.customer?.length ?? 0) > 0) {
    params.append("customer", query.customer!.map((item) => item.id).join());
  }
  if ((query.terminalGroup?.length ?? 0) > 0) {
    params.append(
      "terminalGroup",
      query.terminalGroup!.map((item) => item.id).join()
    );
  }
  if ((query.terminal?.length ?? 0) > 0) {
    params.append("terminal", query.terminal!.map((item) => item.id).join());
  }
  if ((query.finishedProduct?.length ?? 0) > 0) {
    params.append(
      "finishedProduct",
      query.finishedProduct!.map((item) => item.id).join()
    );
  }
  if (query.planningCycle) {
    params.append("planningCycle", query.planningCycle);
  }
  if (query.staleOnDate) {
    params.append("staleOnDate", convertToISODate(query.staleOnDate));
  }
  return params;
};

export const fetchForecastMonthlyData = async (
  query: IForecastSearchCriteria
) => {
  const params = getSearchParams(query);
  params.append("size", "100000");
  params.append("page", "0");
  const config = { params, timeout: 300000 };
  return forecastApiClient.get("forecast/summary/monthly", config);
};

export const fetchForecastDailyData = async (
  query: IForecastSearchCriteria
): Promise<IForecastSummary> => {
  const params = getSearchParams(query);
  params.append("size", "100000");
  params.append("page", "0");
  const config = { params, timeout: 300000 };
  return forecastApiClient.get("forecast/summary/daily", config);
};

export const fetchForecastDetailsData = async (
  summary: IForecastMonthlySummary,
  staleOnDate?: Date
) => {
  const params = new URLSearchParams();
  params.append("startDate", summary.startDate);
  params.append("endDate", summary.endDate);
  params.append("headerId", summary.headerId.toString());
  if (staleOnDate) {
    params.append("staleOnDate", convertToISODate(staleOnDate));
  }

  return forecastApiClient.get("forecast/details", { params });
};

export const fetchForecastExport = async (
  query: IForecastSearchCriteria,
  dataState: DataState
) => {
  const params = getSearchParams(query);

  if (dataState && dataState.take! > 0 && dataState.skip! >= 0) {
    params.append("size", dataState.take!.toString());
    params.append("page", (dataState.skip! / dataState.take!).toString());
  }
  dataState.sort?.forEach((s) => {
    params.append("sort", `${s.field},${s.dir}`);
  });

  const response = await forecastApiClient.get("/forecast/export", { params });
  return response.data as unknown as Page<IGSEData>;
};

export const sendToEndur = async (query: IForecastSearchCriteria) => {
  const data = {
    startDate: convertToISODate(query.startDate),
    endDate: convertToISODate(query.endDate),
  };
  const response = await forecastApiClient.put(
    "/forecast/export/sendToEndur",
    data
  );
  return response.data;
};

export const downloadFile = async (
  query: IForecastSearchCriteria,
  options: { onDownloadProgress: (progressEvent: AxiosProgressEvent) => void }
) => {
  const params = getSearchParams(query);
  const config = {
    params,
    ...options,
    timeout: 300000,
    responseType: "blob" as "json",
  };
  return forecastApiClient.get("/forecast/export/csv/download", config);
};

export const getAutoExportSettings = async () => {
  const response = await forecastApiClient.get<IForecastAutoExportSettings>(
    "/forecast/export/settings"
  );
  return response.data;
};

export const saveAutoExportSettings = async (
  s: IForecastAutoExportSettings
) => {
  const response = await forecastApiClient.put<IForecastAutoExportSettings>(
    "/forecast/export/settings",
    {
      cronExpression: s.cronExpression,
      startAt: s.startAt,
      plusMonths: s.plusMonths,
      zoneId: s.zoneId,
      incremental: s.incremental,
    }
  );
  return response.data;
};

export const uploadTicketDocument = async (
  fileToUpload: File,
  options: AxiosRequestConfig
) => {
  const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();
  formData.append("file", fileToUpload);

  const config = { ...headers, ...options, timeout: 60_000 };
  const response = await forecastApiClient.post(
    "/ticket/document",
    formData,
    config
  );
  return response.data;
};

export const uploadFiles = async (
  filesToUpload: File[],
  options: AxiosRequestConfig
) => {
  const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();
  filesToUpload.forEach((file) => {
    formData.append("file", file);
  });

  const config = { ...headers, ...options, timeout: 300000 };
  const response = await forecastApiClient.post<ForecastBatchImport>(
    "/forecast/import",
    formData,
    config
  );
  return response.data;
};

export const getFile = async (documentId: string) => {
  return forecastApiClient.get(`/ticket/document/${documentId}`, {
    responseType: "blob",
    timeout: 30_000,
  });
};

export const getCarbonIQFile = async (documentPath: string) => {
  return forecastApiClient.get(documentPath, { responseType: "blob" });
};

export const getBatchStatus = async (batchId: string) => {
  const response = await forecastApiClient.get<ForecastBatchImport>(
    "/forecast/import/" + batchId
  );
  return response.data;
};

export const fetchForecastBaseProductData = async (
  summary: IForecastDailySummary
) => {
  const params = new URLSearchParams();
  params.append("startDate", summary.startDate);
  params.append("endDate", summary.endDate);
  params.append("headerId", summary.headerId.toString());
  params.append("groupId", summary.groupId.toString());

  try {
    return await forecastApiClient.get("/forecast/base", { params });
  } catch (error) {
    dispatch(actionCreator("logError", error));
    return error;
  }
};

export const updateBaseVolume = async (data: IForecastBase[][]) => {
  const payload = data.map((i) =>
    i.map((b) => ({
      id: b.id,
      approvedVolume: convertVolumeToGAL(b),
      approvedVolumePercent: b.approvedVolumePercent,
    }))
  );

  try {
    return await forecastApiClient.put("/forecast/base/volume", payload);
  } catch (error) {
    dispatch(actionCreator("logError", error));
    return error;
  }
};

export const updateDetailsVolume = async (data: IForecastDailySummary[]) => {
  const payload = data
    .filter((s) => s.edited)
    .flatMap((s) => ({
      headerId: s.headerId,
      groupId: s.groupId,
      approvedVolume: convertVolumeToGAL(s),
    }));

  try {
    return await forecastApiClient.put("/forecast/details/volume", payload);
  } catch (error) {
    dispatch(actionCreator("logError", error));
    return error;
  }
};
