import { IForecastState } from "../../_redux/IReduxState";
import { actionCreator } from "../../_redux/actionCreator";
import * as enums from "../../shared/enums";
import store from "../../store";
import { IForecastDailySummary, IForecastMonthlySummary } from "../models";
import { FORECAST_SELECT_ALL_CHANGE } from "../state/forecastActionTypes";
import * as api from "./apiHandler";
import * as editor from "./gridEditor";

const dispatch = store.dispatch;

export const selectionChange = (
  event: { dataItem: { selected: boolean } },
  data: any
) => {
  event.dataItem.selected = !event.dataItem.selected;
  dispatch(actionCreator("selectionChange", data));
};

export const headerSelectionChange = (
  event: { syntheticEvent: { target: { checked: any } } },
  data: any
) => {
  const checked = event.syntheticEvent.target.checked;
  data.forEach((item: any) => {
    item.selected = checked;
  });
  dispatch(actionCreator(FORECAST_SELECT_ALL_CHANGE, data));
};

//======================================
// switch active row & data fetching
//======================================

export const onSummaryRowSelect = (
  dataItem: IForecastMonthlySummary | IForecastDailySummary
) => {
  let forecast: IForecastState = store.getState().forecast;
  if (forecast.isMonthlyDisplayMode) {
    onMonthlyRowSelect(dataItem);
  } else {
    onDailyRowSelect(dataItem as IForecastDailySummary);
  }
};

export const onMonthlyRowSelect = async (dataItem: IForecastMonthlySummary) => {
  let forecast: IForecastState = store.getState().forecast;
  let monthlyData:
    | IForecastMonthlySummary[]
    | IForecastDailySummary[]
    | never[] = forecast?.monthlySummary?.data;

  if (!dataItem.details) {
    // if details not exists, fetch it
    let details = await api.fetchForecastDetails(
      dataItem,
      forecast.criteria.staleOnDate
    );

    if (details?.data?.length > 0) {
      editor.setActiveRow(monthlyData, dataItem);
      dataItem.details = details;

      dispatch(actionCreator("selectMonthlyRow", monthlyData));
    } else {
      // TODO error handling
    }
  } else {
    editor.setActiveRow(monthlyData, dataItem);
    dispatch(actionCreator("selectMonthlyRow", monthlyData));
  }
};

export const onDailyRowSelect = async (dataItem: IForecastDailySummary) => {
  let forecast: IForecastState = store.getState().forecast;
  let dailyData: IForecastMonthlySummary[] | IForecastDailySummary[] | never[] =
    forecast?.dailySummary?.data;

  if (!dataItem.base) {
    // if base not exists, fetch it
    let base: any = await api.fetchForecastBaseProduct(dataItem);

    if (base.data) {
      editor.setActiveRow(dailyData, dataItem);
      dataItem.base = base;

      // if the active row has been editted, recalculate base volumes
      if (dataItem.edited) {
        editor.updateBaseProductVolumes(
          dataItem,
          dataItem[enums.FORECAST_APPROVED_VOLUME]
        );
      }

      dispatch(actionCreator("selectDailyRow", dailyData));
    } else {
      // TODO error handling
    }
  } else {
    editor.setActiveRow(dailyData, dataItem);
    dispatch(actionCreator("selectDailyRow", dailyData));
  }
};

export const onDetailsRowSelect = async (dataItem: IForecastDailySummary) => {
  let forecast: IForecastState = store.getState().forecast;
  let monthlyData = forecast?.monthlySummary?.data;
  let activeMonthlyRow = editor.activeRow(forecast.monthlySummary);
  let detailsData = activeMonthlyRow.details.data;

  if (!dataItem.base) {
    // if base not exists, fetch it
    let result: any = await api.fetchForecastBaseProduct(dataItem);

    if (result.data) {
      editor.setActiveRow(detailsData, dataItem);
      dataItem.base = result;

      // if the active row has been editted, recalculate base volumes
      if (dataItem.edited) {
        editor.updateBaseProductVolumes(
          dataItem,
          dataItem[enums.FORECAST_APPROVED_VOLUME]
        );
      }

      dispatch(actionCreator("selectDetailsRow", monthlyData));
    } else {
      // TODO error handling
    }
  } else {
    editor.setActiveRow(detailsData, dataItem);
    dispatch(actionCreator("selectDetailsRow", monthlyData));
  }
};

export const onBaseProductRowSelect = async (
  data: IForecastMonthlySummary[] | IForecastDailySummary[],
  dataItem: IForecastMonthlySummary | IForecastDailySummary
) => {
  editor.setActiveRow(data, dataItem);
};
