import { composeWithDevTools } from "@redux-devtools/extension";

import { createStore } from "redux";
import rootReducer from "./_redux";

const store = createStore(rootReducer, composeWithDevTools());
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
