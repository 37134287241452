import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useState } from "react";

const MIN_NUMBER_OF_COPIES = 2;
const SplitTicketModal = ({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: (copies: number) => void;
}) => {
  const [numberOfTickets, setNumberOfTickets] = useState(MIN_NUMBER_OF_COPIES);
  const handleOnChange = (event: NumericTextBoxChangeEvent) => {
    setNumberOfTickets(event.target.value ?? 0);
  };

  return (
    <Dialog onClose={onClose} title={"Copy Ticket"}>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          margin: ".5rem",
          alignItems: "center",
        }}
      >
        <Label style={{ color: "whitesmoke" }}>Number of copies:</Label>
        <NumericTextBox
          min={2}
          onChange={handleOnChange}
          value={numberOfTickets}
          style={{ maxWidth: "144px" }}
        />
      </div>
      <DialogActionsBar>
        <Button icon="cancel" onClick={onClose}>
          Cancel
        </Button>
        <Button
          themeColor={"primary"}
          icon="check"
          onClick={() => onSuccess(numberOfTickets - 1)}
        >
          Copy
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default SplitTicketModal;
