import { Button } from "@progress/kendo-react-buttons";
import {
  GridColumn as Column,
  Grid,
  GridCellProps,
  GridDetailRowProps
} from "@progress/kendo-react-grid";
import { LabelProps, ProgressBar } from "@progress/kendo-react-progressbars";
import { AxiosProgressEvent, CancelTokenSource } from "axios";
import { useState } from "react";
import GlobalHeader from "shared/components/GlobalHeader";
import FileDropZone from "../shared/components/FileDropZone";
import GridActionBar from "./../shared/components/grid/GridActionBar";
import "./ForecastImport.css";
import { getBatchStatus, getCancelTokenSource, uploadFiles } from "./api";
import { ForecastImportResult } from "./models";

export interface IUploadFile {
  file: File;
  status: UploadStatus;
  result?: ForecastImportResult;
}

enum UploadStatus {
  New,
  Uploading,
  Uploaded,
  Cancelled,
  Error
}

let cancelTokenSource: CancelTokenSource;

const EmptyCell = () => <td></td>;

const DetailComponent = (props: GridDetailRowProps) => {
  const dataItem = props.dataItem;
  return (
    <div className="forecast-import-detail-grid">
      <Grid data={dataItem.result.logs} sortable={false}>
        <Column field="message" title="ERROR" width="160px" className="k-error-colored" />
        <Column field="gseData.planningCycle" title="#PLANNING CYCLE" width="80px" />
        <Column field="gseData.startDate" title="FORECAST START DATE" width="80px" />
        <Column field="gseData.endDate" title="FORECAST END DATE" width="80px" />
        <Column field="gseData.forecastType" title="FORECAST TYPE" width="80px" />
        <Column field="gseData.gsapOrgClass" title="GSAP ORG CLASS" width="64px" />
        <Column field="gseData.businessUnit" title="BUSINESS UNIT" width="96px" />
        <Column field="gseData.orgClass" title="ORG CLASS" width="96px" />
        <Column field="gseData.gsapContractNumber" title="GSAP CONTRACT NUM" width="96px" />
        <Column field="gseData.gsapMOT" title="GSAP MOT MOD" width="48px" />
        <Column field="gseData.gsapFacility" title="GSAP PROPERTY NUM" width="96px" />
        <Column field="gseData.gsapProductCode" title="GSAP PRODUCT CODE" width="96px" />
        <Column field="gseData.volume" title="VOLUME" width="96px" />
        <Column field="gseData.gsapUOM" title="GSAP UOM" width="64px" />
        <Column
          field="gseData.gsapFinishedProduct"
          title="GSAP PRODUCT CODE FINISHED"
          width="96px"
        />
        <Column field="gseData.volumeFinished" title="VOLUME FINISHED" width="80px" />
        <Column field="gseData.gsapUOMFinished" title="GSAP UOM FINISHED" width="64px" />
        <Column field="gseData.tmeSceVrsDte" title="TME SCE VRS DTE" width="96px" />
        <Column field="gseData.srcPhySysIsc" title="SRC PHY SYS ISC" />
      </Grid>
    </div>
  );
};

const progressLabel = (lProps: LabelProps) => <strong>{lProps.value}% Uploading</strong>;

const ForecastImport = () => {
  const [files, setFiles] = useState<IUploadFile[]>([]);

  const [uploadInfo, setUploadInfo] = useState({
    status: UploadStatus.New,
    progress: 0,
    error: ""
  });
  const UNINDENT_TWO_PLACES = 100;

  const onAcceptFiles = (acceptedFiles: File[]) => {
    setFiles(curr => {
      const validAcceptedFiles = acceptedFiles
        .filter(f => curr.findIndex(f2 => f2.file.name === f.name) === -1)
        .map(f => ({ file: f, status: UploadStatus.New } as IUploadFile));
      return [...curr, ...validAcceptedFiles];
    });
    setUploadInfo({
      ...uploadInfo,
      status: UploadStatus.New,
      progress: 0,
      error: ""
    });
  };

  const hasData = () => (files?.length ?? 0) > 0;
  const isUploading = () => uploadInfo.status === UploadStatus.Uploading;
  const isUploaded = () => uploadInfo.status === UploadStatus.Uploaded;

  const onRemove = (file: IUploadFile) => setFiles(files.filter(item => item !== file));
  const onRefresh = async (file: IUploadFile) => {
    try {
      if (file.result) {
        const result = await getBatchStatus(file.result.batchId);
        setFiles(curr => {
          return curr.map(f => {
            const u = result.imports.find(r => r.fileName === f.file.name);
            return u ? { ...f, status: UploadStatus.Uploaded, result: u } : f;
          });
        });
        // const uploadedFiles = result.imports.map(job => {
        //     let uploadFile = files.find(f => f.file.name === job.fileName);
        //     return ({ file: uploadFile?.file, status: UploadStatus.Uploaded, result: job } as IUploadFile);
        // });
        // setFiles([...files, ...uploadedFiles]);
      }
    } catch (error) {
      // console.log(error);
      //do nothing
    }
  };

  const onCancel = () => cancelTokenSource.cancel("Operation canceled by the user.");

  const onUpload = async () => {
    try {
      cancelTokenSource = getCancelTokenSource();
      setUploadInfo({ status: UploadStatus.Uploading, progress: 0, error: "" });

      const options = {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const { loaded, total } = progressEvent;
          setUploadInfo({
            ...uploadInfo,
            progress: Math.floor((loaded * UNINDENT_TWO_PLACES) / (total ?? 1)),
            status: UploadStatus.Uploading,
            error: ""
          });
        },
        cancelToken: cancelTokenSource.token
      };

      const result = await uploadFiles(
        files.filter(f => f.status === UploadStatus.New).map(f => f.file),
        options
      );
      const uploadedFiles = result.imports.map(job => {
        const uploadFile = files.find(f => f.file.name === job.fileName);
        return {
          file: uploadFile?.file,
          status: UploadStatus.Uploaded,
          result: job
        } as IUploadFile;
      });
      setFiles(uploadedFiles);
      setUploadInfo({ ...uploadInfo, status: UploadStatus.Uploaded });
    } catch (error) {
      const errorStatus = error.response
        ? error.response.data?.errors?.[0] ?? "Error: Unknown Error"
        : "Error: Network Error, please try later";
      setUploadInfo({
        ...uploadInfo,
        status: UploadStatus.Error,
        error: errorStatus
      });
    }
  };

  const CustomCell = (props: GridCellProps) => {
    return (
      <td>
        {!isUploading() &&
          (props.dataItem.status === UploadStatus.Uploaded ? (
            <span
              onClick={() => onRefresh(props.dataItem)}
              className={
                "k-icon k-i-refresh k-cursor-pointer k-pl-1 k-pr-1 k-color-primary"
              }></span>
          ) : (
            <span
              onClick={() => onRemove(props.dataItem)}
              className={
                "k-icon k-i-delete k-cursor-pointer k-pl-1 k-pr-1 k-color-primary"
              }></span>
          ))}
      </td>
    );
  };

  const ViewErrorsCell = (props: GridCellProps) => {
    return (
      <td>
        {props.dataItem.status === UploadStatus.Uploaded &&
          (props.dataItem.result.failed > 0 ? (
            <div
              title="View details"
              className="k-cursor-pointer k-pl-1 k-pr-1"
              onClick={() => {
                props.dataItem.showDetails = !props.dataItem.showDetails;
                setUploadInfo(info => {
                  return { ...info };
                }); /*Dummy state update to force rendering */
              }}>
              <span>{props.dataItem.result.failed}</span>
              <span className={"k-icon k-i-detail-section "}></span>
            </div>
          ) : (
            <span>{props.dataItem.result.failed}</span>
          ))}
      </td>
    );
  };

  return (
    <div id="ForecastImport" className="forecast-page">
      <GlobalHeader pageName="Forecast Import" />

      <div className="search-wrapper">
        <div className={"card-container"}>
          <GridActionBar label="Forecast Import" />
          <div style={{ maxWidth: "800px", margin: "auto", padding: "1rem" }}>
            <FileDropZone
              accept={{
                "text/plain": [".csv"],
                "application/vnd.ms-excel": []
              }}
              onAccept={onAcceptFiles}
              disabled={isUploading()}
            />
          </div>
        </div>
      </div>
      {uploadInfo.error && (
        <div className={"card-message form-error"}>
          <span>{uploadInfo.error}</span>
        </div>
      )}
      {hasData() && (
        <div className={"content-wrapper"}>
          <div className={"grid-wrapper"}>
            <div className={"grid-container"}>
              <div className={"card-container"}>
                <Grid data={files} expandField={"showDetails"} detail={DetailComponent}>
                  {/* dummy column to hide k-hierarchy-cell */}
                  <Column width="0" cell={EmptyCell}></Column>
                  <Column field="file.name" title="File Name" />
                  <Column field="file.size" title="File Size" />
                  <Column
                    field="file.lastModifiedDate"
                    title="Last Modified"
                    format="{0:dd-MMM-yy HH:mm:ss}"
                    width="132px"
                  />
                  <Column field="result.processed" title="Processed" />
                  <Column field="result.succeeded" title="Succeeded" />
                  <Column field="result.failed" title="Failed" cell={ViewErrorsCell} />
                  <Column field="result.startedAt" title="Started At" width="144px" />
                  <Column field="result.finishedAt" title="Finished At" width="144px" />
                  <Column field="result.elapsedTime" title="Duration" width="96px" />
                  <Column width="24px" cell={CustomCell} />
                </Grid>
              </div>
            </div>
          </div>
          <div className="forecast-upload-status">
            {isUploading() && (
              <ProgressBar
                style={{ flex: "1 1" }}
                labelPlacement="center"
                value={uploadInfo.progress}
                label={progressLabel}
              />
            )}
            {!isUploading() && !isUploaded() && (
              <Button className="k-primary theme-btn-yellow" type="button" onClick={onUpload}>
                Import
              </Button>
            )}
            {isUploading() && (
              <Button className="k-primary theme-btn-yellow" type="button" onClick={onCancel}>
                Cancel
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default ForecastImport;
