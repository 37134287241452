// Forecast grid columns
export const FORECAST_APPROVED_VOLUME = "approvedVolume";
export const FORECAST_APPROVED_PERCENTAGE = "approvedVolumePercent";
export const FORECAST_VOLUME = "forecastVolume";
export const FORECAST_AGGREGATED_APPROVED_VOLUME_PERCENT =
  "aggregatedApprovedVolumePercent";

// Forecast messages
export const FORECAST_UPDATE_SUCCEEDED =
  "Your changes were saved successfully.";
export const FORECAST_UPDATE_FAILED = "One or more items failed to update.";
export const FORECAST_BASE_PERCENTAGE_MISSING =
  "Approved Volume Percentage for each base product is 0%, please edit base product instead.";
export const FORECAST_BULK_VOLUME_EDIT_MISSING_PERCENTAGE =
  "One or more items have missing approved volume percentage(s). You have to edit base product instead.";
