import { useState } from "react";

import { Dialog } from "@progress/kendo-react-dialogs";
import "./movements.css";

import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab
} from "@progress/kendo-react-layout";
import { TMovementGroup } from "ticketing/ticketing.types";
import { MovementAlerts } from "./MovementAlerts";
import { MovementDeliveryEvents } from "./MovementDeliveryEvents";
import { MovementMeasures } from "./MovementMeasures";

const MovementAspectContainer = ({
  movementGroup,
  onClose,
  editDisabled
}: {
  movementGroup: TMovementGroup;
  onClose: () => void;
  editDisabled: boolean;
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const onActiveTabChanged = (e: TabStripSelectEventArguments) => {
    setActiveTab(e.selected);
  };

  // DEV NOTES: for the code block below
  // in the case of a double play - since all the delivery events must be the same
  // we only show the delivery events for the selected leg
  // when a change is made we need to mimic the change for the other legs

  const handleClose = () => {
    onClose();
  };

  //DEV NOTES - PLEASE READ
  /**
   Bit of a unique situation here - while a movement group has more than one leg/movement/nom - 
   the delivery events for each of these MUST be the same
   So the prop passed to the delivery event details component is the attribute
   deliveryEvents of only the FIRST movement
   ? can we assume that all the del event types for a movement are unique, or is the combination of event type and event date is unique - YES - confirmed with Jose
   */
  return (
    <Dialog
      onClose={handleClose}
      title={`Delivery ID: ${movementGroup.movements.at(0)?.enterpriseSystemCode}`}
      width={600}
      height={400}>
      <TabStrip
        selected={activeTab}
        onSelect={onActiveTabChanged}
        keepTabsMounted={true}
        className="movement-aspect-container">
        <TabStripTab title="Delivery Events" contentClassName="tab-content">
          <MovementDeliveryEvents
            movementGroup={movementGroup}
            onClose={onClose}
            editDisabled={editDisabled}
          />
        </TabStripTab>
        <TabStripTab title="Measures" contentClassName="tab-content">
          <MovementMeasures
            movementGroup={movementGroup}
            onClose={onClose}
            editDisabled={editDisabled}
          />
        </TabStripTab>
        <TabStripTab title="Alerts" contentClassName="tab-content">
          <MovementAlerts movementGroup={movementGroup} onClose={onClose} />
        </TabStripTab>
      </TabStrip>
    </Dialog>
  );
};

export default MovementAspectContainer;
