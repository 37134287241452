import {
  Badge,
  BadgeSentiment,
  Button,
  Flexbox,
  Sizes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  BlockRight,
  Check,
  CrossCircle,
  InfoCircle
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

const MAX_NOTIFICATION_DISPLAY_TIME_MS = 10000;

type TNotificicationProps = {
  message: string;
  sentiment: BadgeSentiment;
  autoCloseAfterMs?: number;
  autoCloseable?: boolean;
  onClose?: () => void;
};

const NotificationIcon = ({ sentiment }: { sentiment: BadgeSentiment }) => {
  // forcefilly added if statement to avoid Sonarqube warning, prefered using nested terniry operator
  if (sentiment === "negative") {
    return <BlockRight />;
  }
  if (sentiment === "positive") {
    return <Check />;
  }
  return <InfoCircle />;
};

export const NotificationGroup = ({ children }: { children: ReactNode }) => {
  return (
    <Flexbox flexDirection={"column"} gap={".5rem"} className="notification-wrapper">
      {children}
    </Flexbox>
  );
};

export const Notification = ({
  message,
  sentiment,
  autoCloseAfterMs = MAX_NOTIFICATION_DISPLAY_TIME_MS,
  autoCloseable = true,
  onClose
}: TNotificicationProps) => {
  const [showNotification, setShowNotification] = useState(true);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const onCloseNotification = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setShowNotification(false);
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    if (autoCloseable) {
      timeoutRef.current = setTimeout(onCloseNotification, autoCloseAfterMs);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [autoCloseable, onCloseNotification, autoCloseAfterMs]);

  if (!showNotification) {
    return <></>;
  }

  return (
    <Badge
      style={{ padding: "15px" }}
      size={Sizes.Large}
      sentiment={sentiment}
      variant={Variants.Filled}
      icon={<NotificationIcon sentiment={sentiment} />}>
      <Flexbox>
        <span className="notification-title">{message}</span>
        <Button
          size={Sizes.ExtraSmall}
          icon={<CrossCircle />}
          iconOnly
          variant={Variants.Transparent}
          onClick={onCloseNotification}
        />
      </Flexbox>
    </Badge>
  );
};
