import { EmptyState, Sizes } from "@sede-x/shell-ds-react-framework";

import { ReactComponent as Lighthouse } from "shared/assets/lighthouse.svg";

const NotFoundPage = () => {
  return (
    <div className="container-404">
      <EmptyState
        title="404 - Page Not Found"
        description="Oops! It looks like the page you’re trying to reach doesn’t exist or has been moved. Please check the URL for any errors or return to the homepage. If you continue to encounter this issue, feel free to contact us for assistance. We’re here to help!"
        size={Sizes.Large}
        image={<Lighthouse />}
      />
    </div>
  );
};

export default NotFoundPage;
