import { Sizes, Text } from "@sede-x/shell-ds-react-framework";
import { ReactNode } from "react";

const ColumnText = (props: { children: ReactNode; width?: string; onClick?: () => void }) => {
  return (
    <Text size={Sizes.Small} style={{ width: props.width }} onClick={props.onClick}>
      {props.children}
    </Text>
  );
};

export default ColumnText;
