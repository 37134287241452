import { Dialog } from "@progress/kendo-react-dialogs";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Error as ErrorLabel } from "@progress/kendo-react-labels";

import { FORECAST_APPROVED_VOLUME } from "../../enums";

const minValueValidator = (value: number): string =>
  value >= 0 ? "" : "The value must be 0 or higher";

const NonNegativeNumericInput = (fieldRenderProps: {
  [x: string]: any;
  validationMessage: string;
  visited: boolean;
}) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <NumericTextBox {...others} />
      {visited && validationMessage && (
        <ErrorLabel>{validationMessage}</ErrorLabel>
      )}
    </div>
  );
};

export const GridBulkEditor = (props: {
  onClose: any;
  onUpdate: any;
  onCancel: any;
}) => {
  const { onClose, onUpdate, onCancel } = props;

  return (
    <Dialog title={`Bulk Edit Approved Volume`} onClose={onClose}>
      <Form
        onSubmit={onUpdate}
        render={(formRenderProps) => (
          <FormElement style={{ maxWidth: 650 }}>
            <fieldset className={"k-form-fieldset"}>
              <div className="mb-3">
                <Field
                  name={"approvedVolume"}
                  component={NonNegativeNumericInput}
                  label={FORECAST_APPROVED_VOLUME}
                  validator={minValueValidator}
                />
              </div>
            </fieldset>

            <div className="k-form-buttons">
              <button
                type={"submit"}
                className="k-button k-primary theme-btn-green"
                disabled={!formRenderProps.allowSubmit}
              >
                Update
              </button>
              <button
                type={"submit"}
                className="k-button theme-btn-red"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
};
export default GridBulkEditor;
