import { useMutation, useQuery } from "@apollo/client";
import { getter } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridItemChangeEvent,
  GridNoRecords,
  GridToolbar
} from "@progress/kendo-react-grid";
import { loader } from "graphql.macro";
import { useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import {
  GqlResponse,
  MovementDeliveryEventStatus,
  Picklists,
  TDeal,
  TMovement,
  TMovementGroup
} from "ticketing/ticketing.types";
import { fromISODateString, isPhysicalDeal, toISODateString } from "ticketing/utils";

type DropDownListCellProps = GridCellProps & {
  data?: Picklists.PDeliveryEventType[] | string;
};

const DropDownListCell = (props: DropDownListCellProps) => {
  const { ariaColumnIndex, columnIndex, dataItem, field, data } = props;
  const isInEdit = dataItem.inEdit;
  const valueGetter = field && getter(field);
  const value = valueGetter ? valueGetter(dataItem) : "";
  const bindData = typeof data === "string" ? dataItem.statuses : data;
  const onChange = (e: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        value: e.value,
        syntheticEvent: e.syntheticEvent
      });
    }
  };

  // const defaultRendering = (
  return (
    <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex} role={"gridcell"}>
      {isInEdit ? (
        <div>
          <DropDownList
            data={bindData}
            defaultValue={value}
            onChange={onChange}
            textField={"name"}
          />
        </div>
      ) : (
        <span>{value.name}</span>
      )}
    </td>
  );
};
const getCell = (data?: Picklists.PDeliveryEventType[] | string) => {
  return (props: GridCellProps) => <DropDownListCell {...props} data={data} />;
};

const QUERY_MOVEMENT_DELIVERY_EVENTS = loader(
  "../../ticketing-graphql/deliveryEventsOfMovement.graphql"
);

const UPDATE_MOVEMENT_DELIVERY_EVENTS = loader(
  "../../ticketing-graphql/updateDeliveryEventsOfMovements.graphql"
);

type Response = GqlResponse<Partial<TMovement[]>, "movements">;

type TMovementDeliveryEventId = {
  id?: string;
  version?: number;
  movementId?: string;
  movementVersion?: number;
};

type TMovementDeliveryEventGroup = {
  id: string;
  deliveryEventType?: Picklists.PDeliveryEventType;
  deliveryEventDate?: Date;
  deliveryEventStatus?: TDeliveryEventStaus;
  movementDeliveryEventId?: [TMovementDeliveryEventId];
  statuses: TDeliveryEventStaus[];
  inEdit: boolean;
};

type TDeliveryEventUpdate = {
  id: string;
  version: number;
  deliveryEventTypeId: number;
  deliveryEventDate: string;
  deliveryEventStatus: MovementDeliveryEventStatus;
};

type TDeliveryEventInputUpdate = {
  id: string;
  version: number;
  deliveryEvents: TDeliveryEventUpdate[];
};

const isRequiredForPricing = (deal: TDeal, id: number) =>
  deal.deliveryEvents.find(d => d.deliveryEventType?.id === id)?.requiredForPricing ?? false;

type TDeliveryEventStaus = {
  id: string;
  name: string;
};
const defaultDeliveryEventStauses: TDeliveryEventStaus[] = [
  { id: "ESTIMATED", name: "UNLINKED" }
];
const finalDeliveryEventStauses: TDeliveryEventStaus[] = defaultDeliveryEventStauses.concat({
  id: "FINAL",
  name: "FINAL"
});

const getStatuses = (m: TMovement, id: number): TDeliveryEventStaus[] =>
  (isPhysicalDeal(m.delDeal) && isRequiredForPricing(m.delDeal, id)) ||
  (isPhysicalDeal(m.recDeal) && isRequiredForPricing(m.recDeal, id))
    ? finalDeliveryEventStauses
    : defaultDeliveryEventStauses;

const transformInput = (movements: Partial<TMovement[]>): TMovementDeliveryEventGroup[] => {
  //flatten all delivery events from all sides of the movement
  const deliveryEvents = movements.flatMap(md =>
    md?.deliveryEvents.map(de => ({
      ...de,
      deliveryEventDate: fromISODateString(de.deliveryEventDate),
      deliveryEventStatus: finalDeliveryEventStauses.find(
        s => s.id === de.deliveryEventStatus.toString()
      ),
      movementId: md.id,
      movementVersion: md.version,
      statuses: getStatuses(md, de.deliveryEventType?.id!)
    }))
  );

  //group delivery events by delivery event type id
  const delivertEventGroups = deliveryEvents.reduce<
    Record<string, TMovementDeliveryEventGroup>
  >((group, item) => {
    const deliveryEventTypeId: string = `Id:${item!.deliveryEventType!.id}`;
    if (group[deliveryEventTypeId] == null) {
      group[deliveryEventTypeId] = {
        id: deliveryEventTypeId,
        deliveryEventType: item?.deliveryEventType,
        deliveryEventStatus: item?.deliveryEventStatus,
        deliveryEventDate: item?.deliveryEventDate,
        statuses: item?.statuses!,
        movementDeliveryEventId: [
          {
            id: item?.id,
            version: item?.version,
            movementId: item?.movementId,
            movementVersion: item?.movementVersion
          }
        ],
        inEdit: true
      };
      return group;
    }
    group[deliveryEventTypeId].movementDeliveryEventId?.push({
      id: item?.id,
      version: item?.version,
      movementId: item?.movementId,
      movementVersion: item?.movementVersion
    });

    return group;
  }, {});
  return Object.values(delivertEventGroups);
};

const transformOutput = (movementDeliveryEvents: TMovementDeliveryEventGroup[]) => {
  //flatten to TMovementDeliveryEventGroup
  const movementDeliveryEventUpdate = movementDeliveryEvents?.flatMap(d =>
    d.movementDeliveryEventId?.map(md => ({
      id: md.movementId,
      version: md.movementVersion,
      deliveryEvents: {
        id: md.id!,
        version: md.version!,
        deliveryEventTypeId: d.deliveryEventType?.id!,
        deliveryEventDate: toISODateString(d.deliveryEventDate!),
        deliveryEventStatus: d.deliveryEventStatus
          ?.id! as unknown as MovementDeliveryEventStatus
      }
    }))
  );

  //group them by movement id
  const movementDeliveryEventUpdateGroup = movementDeliveryEventUpdate?.reduce<
    Record<string, TDeliveryEventInputUpdate>
  >((movementIdGroup, item) => {
    if (movementIdGroup[item!.id!] == null) {
      movementIdGroup[item!.id!] = {
        id: item?.id!,
        version: item?.version!,
        deliveryEvents: [item?.deliveryEvents!]
      };
      return movementIdGroup;
    }
    movementIdGroup[item!.id!].deliveryEvents.push(item?.deliveryEvents!);
    return movementIdGroup;
  }, {});

  return Object.values(movementDeliveryEventUpdateGroup);
};

type MovementDeliveryEventsProps = {
  movementGroup: TMovementGroup;
  editDisabled: boolean;
  onClose: () => void;
};
/**
 *
 * @param param0
 * @returns
 */
export const MovementDeliveryEvents = ({
  movementGroup,
  editDisabled,
  onClose
}: MovementDeliveryEventsProps) => {
  const { picklists } = usePicklists();
  const [movementDeliveryEvents, setMovementDeliveryEvents] =
    useState<TMovementDeliveryEventGroup[]>();
  const { loading, error, refetch } = useQuery<Response>(QUERY_MOVEMENT_DELIVERY_EVENTS, {
    fetchPolicy: "no-cache",
    variables: { ids: movementGroup.movements.map(m => m.id) },
    onCompleted: data => onCompleted(data.movements)
  });

  const [updateDeliveryEvents, { loading: loadingDeliveryEvents, error: errorDeliveryEvents }] =
    useMutation<Response>(UPDATE_MOVEMENT_DELIVERY_EVENTS, {
      onCompleted: data => {
        onCompleted(data.movements);
      }
    });

  const onCompleted = (movements: Partial<TMovement[]>) => {
    setMovementDeliveryEvents(transformInput(movements));
  };

  const onReset = () => {
    refetch({ ids: movementGroup.movements.map(m => m.id) });
  };

  const itemChange = (e: GridItemChangeEvent) => {
    setMovementDeliveryEvents(measures =>
      measures?.map(m => {
        if (m.id === e.dataItem.id) {
          return { ...m, [e.field!]: e.value };
        }
        return m;
      })
    );
  };

  const onSave = () => {
    if (movementDeliveryEvents) {
      const deliveryEvents = transformOutput(movementDeliveryEvents);
      updateDeliveryEvents({
        variables: {
          deliveryEvents
        }
      });
    }
  };

  const onSaveAndClose = () => {
    onSave();
    onClose();
  };
  if (loading || loadingDeliveryEvents) {
    return <InlineLoadingPanel />;
  }
  if (error) {
    return <ApolloErrorViewer error={error} />;
  }
  if (errorDeliveryEvents) {
    return <ApolloErrorViewer error={errorDeliveryEvents} />;
  }
  return (
    <div className={"content-wrapper"}>
      <div className={"grid-wrapper"}>
        <div className={"card-container"}>
          <Grid
            data={movementDeliveryEvents}
            resizable
            dataItemKey="code"
            editField="inEdit"
            onItemChange={itemChange}>
            <GridToolbar>
              <div style={{ flexGrow: "1" }}>
                <Button
                  disabled={editDisabled}
                  onClick={onReset}
                  style={{ backgroundColor: "#ffc600" }}
                  className="theme-btn-yellow">
                  Reset
                </Button>
              </div>
              <Button
                onClick={onSave}
                disabled={editDisabled || (movementDeliveryEvents?.length ?? 0) <= 0}
                className="theme-btn-green">
                Save
              </Button>
              <Button
                onClick={onSaveAndClose}
                disabled={editDisabled || (movementDeliveryEvents?.length ?? 0) <= 0}
                className="theme-btn-green">
                Save & Close
              </Button>
              <Button themeColor={"primary"} onClick={onClose} className="theme-btn-red">
                Close
              </Button>
            </GridToolbar>
            <GridNoRecords>
              <div style={{ color: "gray" }}>There is no data available</div>
            </GridNoRecords>
            <GridColumn
              title="Delivery Event Type"
              field="deliveryEventType"
              cell={getCell(picklists?.deliveryEventTypes)}
            />
            <GridColumn title="Delivery Event Date" field="deliveryEventDate" editor="date" />
            <GridColumn title="status" field="deliveryEventStatus" cell={getCell("STATUS")} />
          </Grid>
        </div>
      </div>
    </div>
  );
};
