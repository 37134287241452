import { useMutation } from "@apollo/client";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { loader } from "graphql.macro";
import { useContext, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import VolumeAllocationModal from "ticketing/components/movements/VolumeAllocationModal";
import AddOrUpdateTicket from "ticketing/components/tickets/AddOrUpdateTicket";
import {
  GqlResponse,
  TAllocationMovement,
  TLinkedTicket,
  TMovement,
  TMovementGroup,
  TTicket,
  TValidatedTicket
} from "ticketing/ticketing.types";
import {
  buildMovementAllocationsPayload,
  findPrimaryMovement,
  hasCreditRedLine,
  isActualizedByInfoSet,
  isAutoActualizeInfoSet,
  isPlannedMovement,
  isValidMOT,
  truncateTo
} from "ticketing/utils";
import AvailableTicketContainer from "./AvailableTicketContainer";
import LinkedTicketContainer from "./LinkedTicketContainer";
import RevisionsTicket from "./RevisionsTicket";
import "./movements.css";
import { MovementsMainContext } from "./utils";

const FETCH_POLICY_NO_CACHE = {
  fetchPolicy: "no-cache" as MutationFetchPolicy
};

type UnlinkTicketResponse = GqlResponse<TMovement[], "movements">;
type Response = GqlResponse<TMovement[], "movements">;
type LinkTicketResponse = GqlResponse<TMovement[], "movements">;
type UpdateMovementsResponse = GqlResponse<TMovement[], "updateMovements">;
type TEditTicketState = {
  showEditTicketForm: boolean;
  movementGroup?: TMovementGroup | null;
  ticket?: TTicket;
};

const LINK_TICKETS = loader("../../ticketing-graphql/linkTicketsToMovements.graphql");
const BULK_UNLINK_TICKETS = loader(
  "../../ticketing-graphql/bulkUnlinkTicketsFromMovements.graphql"
);
const ALLOCATE_MOVEMENTS = loader("../../ticketing-graphql/allocateMovementVolume.graphql");
const UPDATE_MOVEMENTS = loader("../../ticketing-graphql/updateMovements.graphql");

type TVolumeAllocationState = {
  showModal: boolean;
  tickets?: TLinkedTicket[] | TValidatedTicket[];
  action?: "link" | "unlink";
  qtyToAllocate?: number;
};

const calcVolumeToAllocateOnLinkTicket = (
  ticket: TValidatedTicket,
  activeMovement?: TMovement
) => {
  if (
    activeMovement &&
    ticket.matchingVolume &&
    isValidMOT(activeMovement.batch.modeOfTransport.name)
  ) {
    const ticketQuantity = ticket.matchingVolume.netVolume;
    const { scheduledQuantity, actualizedQuantity } = activeMovement;
    const availabeQuantity = Math.max(scheduledQuantity - (actualizedQuantity ?? 0), 0);

    //if actualization is already complete,
    //then entire volume of the ticket should be allocated to another movement
    //else ticket volume - remaining quantity(scheduled - actualized)
    return activeMovement.actualizationComplete
      ? -1 * ticketQuantity
      : Math.min(availabeQuantity - ticketQuantity, 0);
  }
  return 0;
};

const calcVolumeToAllocateOnUnLinkTicket = (
  tickets: TLinkedTicket[],
  activeMovement?: TMovement
) => {
  if (activeMovement && isValidMOT(activeMovement.batch.modeOfTransport.name)) {
    const ticketQuantity = tickets.reduce((a, b) => +a + +b.actualizedVolume, 0);
    //if actualization is already complete,
    //then entire volume of the ticket should be allocated to another movement
    return truncateTo(activeMovement.actualizationComplete ? ticketQuantity : 0, 4);
  }
  return 0;
};

/**
 *
 * @param param0
 * @returns
 */
const TicketContainer = ({
  activeMovementGroup
}: {
  activeMovementGroup: TMovementGroup | null;
}) => {
  const { onMovementsUpdated } = useContext(MovementsMainContext);
  const [refreshTickets, setRefreshTickets] = useState<number>();
  const [volumeAllocationState, setVolumeAllocationState] = useState<TVolumeAllocationState>({
    showModal: false
  });

  const [editTicketState, setEditTicketState] = useState<TEditTicketState>({
    showEditTicketForm: false
  });

  const [showTicketRevisions, setShowTicketRevisions] = useState<boolean>(false);
  const [revisonTicketId, setRevisonTicketId] = useState<string>();

  const [linkTicket, { loading: linkTicketLoading, error: linkTicketError }] =
    useMutation<LinkTicketResponse>(LINK_TICKETS, FETCH_POLICY_NO_CACHE);

  const [unlinkTickets, { loading: unlinkTicketsLoading, error: unlinkTicketsError }] =
    useMutation<UnlinkTicketResponse>(BULK_UNLINK_TICKETS, FETCH_POLICY_NO_CACHE);

  const [allocateMovementVolume, { loading: allocateLoading, error: allocateError }] =
    useMutation<Response>(ALLOCATE_MOVEMENTS, FETCH_POLICY_NO_CACHE);

  const [updateMovements, { loading: loadingUpdateMovements, error: errorUpdateMovements }] =
    useMutation<UpdateMovementsResponse>(UPDATE_MOVEMENTS, FETCH_POLICY_NO_CACHE);

  const onCancelAllocationModal = () => {
    setVolumeAllocationState({ showModal: false });
  };

  const onAllocationSave = (allocationMovements: TAllocationMovement[] | null) => {
    setVolumeAllocationState({ ...volumeAllocationState, showModal: false });

    const onSuccess = (movements: TMovement[]) => {
      onMovementsUpdated?.(movements);
      refresh();
    };

    switch (volumeAllocationState.action) {
      case "link":
        doLinkTicket(volumeAllocationState.tickets?.at(0)!, movements => {
          saveAllocations(allocationMovements, "Link Ticket", alcMovements => {
            //complete actualization if not actualized
            completeActualization(updatedMovements => {
              onSuccess([...(updatedMovements ?? movements), ...(alcMovements ?? [])]);
            });
          });
        });
        break;
      case "unlink":
        doUnlinkTickets(volumeAllocationState.tickets as TLinkedTicket[], movements => {
          saveAllocations(allocationMovements, "Unlink Ticket", alcMovements => {
            onMovementsUpdated?.([...movements, ...(alcMovements ?? [])]);
            refresh();
          });
        });
        break;
      default:
        break;
    }
  };

  const completeActualization = (onComplete: (movements?: TMovement[]) => void) => {
    //complete actualization if not actualized
    if (!activeMovementGroup?.activeMovement.actualizationComplete) {
      updateMovements({
        variables: {
          movements: activeMovementGroup?.movements.map(m => ({
            id: m.id,
            version: m.version,
            actualizationComplete: true
          }))
        },
        onCompleted: data => onComplete(data.updateMovements)
      });
    } else {
      onComplete();
    }
  };

  const saveAllocations = (
    allocationMovements: TAllocationMovement[] | null,
    reason: string,
    onSaveCompleted: (movements?: TMovement[]) => void
  ) => {
    if (activeMovementGroup) {
      const allocateVolumeInput = buildMovementAllocationsPayload(
        activeMovementGroup?.activeMovement,
        allocationMovements,
        reason
      );

      allocateMovementVolume({
        ...allocateVolumeInput,
        onCompleted: data => onSaveCompleted(data.movements)
      });
    } else {
      //save with out allocating
      onSaveCompleted();
    }
  };

  const handleLinkTicket = (ticket: TValidatedTicket) => {
    const volume = truncateTo(
      calcVolumeToAllocateOnLinkTicket(ticket, activeMovementGroup?.activeMovement),
      4
    );
    if (volume !== 0) {
      setVolumeAllocationState({
        showModal: true,
        action: "link",
        qtyToAllocate: volume,
        tickets: [ticket]
      });
    } else {
      doLinkTicket(ticket, m => {
        onMovementsUpdated?.(m);
        refresh();
      });
    }
  };

  const handleUnlinkTickets = (tickets: TLinkedTicket[]) => {
    const volume = calcVolumeToAllocateOnUnLinkTicket(
      tickets,
      activeMovementGroup?.activeMovement
    );
    if (volume !== 0) {
      setVolumeAllocationState({
        showModal: true,
        action: "unlink",
        qtyToAllocate: volume,
        tickets
      });
      return;
    }
    doUnlinkTickets(tickets, m => {
      onMovementsUpdated?.(m);
      refresh();
    });
  };

  const doLinkTicket = (
    ticket: TValidatedTicket,
    onSaveCompleted: (data: TMovement[]) => void
  ) => {
    linkTicket({
      variables: {
        movements: activeMovementGroup?.movements.map(m => ({
          movementId: m.id,
          version: m.version
        })),
        tickets: [{ ticketId: ticket.id, volume: ticket.matchingVolume?.netVolume }]
      },
      onCompleted: data => onSaveCompleted(data.movements)
    });
  };

  const doUnlinkTickets = (
    tickets: TLinkedTicket[],
    onSaveCompleted: (data: TMovement[]) => void
  ) => {
    unlinkTickets({
      variables: {
        input: [
          {
            movements: activeMovementGroup?.movements.map(m => ({
              movementId: m.id,
              version: m.version
            })),
            ticketIds: tickets.map(t => t.id)
          }
        ]
      },
      onCompleted: data => onSaveCompleted(data.movements)
    });
  };

  const refresh = () => {
    //refersh both linked/unlinked tickets and notify parent
    setRefreshTickets(new Date().getTime());
  };

  const onShowTicketRevisions = (revisionTicketId: string) => {
    setRevisonTicketId(revisionTicketId);
    setShowTicketRevisions(true);
  };

  const onEditAvailableTicket = (ticket: TTicket) => {
    setEditTicketState({ showEditTicketForm: true, ticket });
  };

  const onEditLinkedTicket = (ticket: TTicket) => {
    setEditTicketState({
      showEditTicketForm: true,
      ticket,
      movementGroup: activeMovementGroup
    });
  };

  const onEditTicketCancel = () => {
    setEditTicketState({ showEditTicketForm: false });
  };

  const onEditTicketSuccess = (movements?: TMovement[]) => {
    setEditTicketState({ showEditTicketForm: false });
    if (movements && Array.isArray(movements)) {
      onMovementsUpdated?.(movements);
      refresh();
    }
  };
  const onDeleteTicket = () => {
    setEditTicketState({ showEditTicketForm: false });
    refresh();
  };

  const hideTicketRevisions = () => setShowTicketRevisions(false);
  const isTransitComplete =
    activeMovementGroup?.movements?.at(0)?.batch.transitComplete ?? false;
  const isAutoActualized = isAutoActualizeInfoSet(activeMovementGroup) ?? false;
  const hasActualizedBy = isActualizedByInfoSet(activeMovementGroup) ?? false;

  const isActualizedExternally =
    activeMovementGroup?.movements.find(md => md.isActualizedExternally === true) !== undefined;

  const isLoading = [
    linkTicketLoading,
    unlinkTicketsLoading,
    loadingUpdateMovements,
    allocateLoading
  ].some(l => l);

  const disableLinkUnlinkTickets = [
    isLoading,
    !!activeMovementGroup?.activeChild,
    hasCreditRedLine(activeMovementGroup),
    isPlannedMovement(activeMovementGroup),
    isTransitComplete,
    isAutoActualized,
    hasActualizedBy,
    isActualizedExternally
  ].some(r => r);

  return (
    <div style={{ height: "100%", overflowY: "auto" }}>
      <div>
        {showTicketRevisions && revisonTicketId && (
          <RevisionsTicket
            ticketId={revisonTicketId}
            handleCancel={hideTicketRevisions}></RevisionsTicket>
        )}

        {editTicketState.showEditTicketForm && (
          <AddOrUpdateTicket
            onCancel={onEditTicketCancel}
            onSuccess={onEditTicketSuccess}
            onDelete={onDeleteTicket}
            activeMovementGroup={editTicketState.movementGroup}
            activeTicket={editTicketState.ticket}
          />
        )}
        {activeMovementGroup &&
          volumeAllocationState.showModal &&
          volumeAllocationState.qtyToAllocate && (
            <VolumeAllocationModal
              quantityToAllocate={volumeAllocationState.qtyToAllocate}
              onCancel={onCancelAllocationModal}
              onSave={onAllocationSave}
              sourceMovement={findPrimaryMovement(activeMovementGroup)}></VolumeAllocationModal>
          )}

        <div>
          <LinkedTicketContainer
            activeMovementGroup={activeMovementGroup}
            onUnlinkTickets={handleUnlinkTickets}
            onShowTicketRevisions={onShowTicketRevisions}
            disableLinks={disableLinkUnlinkTickets}
            onEditTicket={onEditLinkedTicket}
            refreshTickets={refreshTickets}
          />
        </div>
        <div style={{ backgroundColor: "#a09d9d", minHeight: "5px" }}>
          {isLoading && <InlineLoadingPanel />}
          {errorUpdateMovements && (
            <ApolloErrorViewer error={errorUpdateMovements}></ApolloErrorViewer>
          )}
          {linkTicketError && <ApolloErrorViewer error={linkTicketError}></ApolloErrorViewer>}
          {unlinkTicketsError && (
            <ApolloErrorViewer error={unlinkTicketsError}></ApolloErrorViewer>
          )}
          {allocateError && <ApolloErrorViewer error={allocateError}></ApolloErrorViewer>}
        </div>
        {(activeMovementGroup?.activeChild == null || !disableLinkUnlinkTickets) && (
          <div style={{ backgroundColor: "var(--nav-bg)" }}>
            <AvailableTicketContainer
              activeMovementGroup={activeMovementGroup}
              disableLinks={disableLinkUnlinkTickets}
              onLinkTicket={handleLinkTicket}
              onShowTicketRevisions={onShowTicketRevisions}
              onEditTicket={onEditAvailableTicket}
              refreshTickets={refreshTickets}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketContainer;
