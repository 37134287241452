import { GridCellProps } from "@progress/kendo-react-grid";
import { useContext } from "react";
import useSearchFacilities from "ticketing/hooks/useSearchFacilities";
import { TRefData } from "../types";
import { BulkImportContext } from "../util";
import { DropDownListCell } from "./DropDownListCell";

export const FacilityCell = (props: GridCellProps) => {
  const { fieldConfig, refData: allRefData } = useContext(BulkImportContext);
  const { field } = props;
  /* eslint-disable no-unused-vars */
  const [search, searchResults, loading, , clear] = useSearchFacilities();
  /* eslint-enable no-unused-vars */
  const facilityFieldConfig = fieldConfig?.find(f => f.name === field);
  const lookupName = facilityFieldConfig?.lookupName!;

  const getData = () => {
    return searchResults?.length ? searchResults : (allRefData?.[lookupName] as TRefData[]);
  };

  if (!facilityFieldConfig) {
    return <td></td>;
  }

  return (
    <DropDownListCell
      {...props}
      data={getData()}
      fieldConfig={facilityFieldConfig}
      loading={loading}
      onSearch={search}
      onDone={() => clear()}
    />
  );
};
