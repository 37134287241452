import { EmptyState, Sizes } from "@sede-x/shell-ds-react-framework";

import bulb from "shared/assets/bulb.png";

const AccessRestricted = () => {
  return (
    <div className="container-404">
      <EmptyState
        title="Access Restricted"
        description="Please note that access to this page is reserved for authorized personnel only. If you believe you should have access, please contact the site administrator for assistance. We appreciate your understanding and cooperation. Thank you."
        size={Sizes.Large}
        image={<img src={bulb} alt="Bulb" />}
      />
    </div>
  );
};

export default AccessRestricted;
