import { Button } from "@progress/kendo-react-buttons";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  InputSuffix,
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import {
  Picklists,
  TTicketInput,
  TVolumeInput,
} from "ticketing/ticketing.types";
import { isMotRailOrTruck } from "ticketing/utils";

const TemperatureSuffix =
  (
    data?: Picklists.TTemperatureUOM[],
    value?: Picklists.TTemperatureUOM,
    onChange?: (event: DropDownListChangeEvent) => void
  ) =>
    () =>
    (
      <InputSuffix>
        <DropDownList
          id="temperatureUnitOfMeasure"
          name="temperatureUnitOfMeasure"
          data={data}
          value={value}
          textField="shortName"
          onChange={onChange}
          size={"small"}
          required
        />
      </InputSuffix>
    );

type TicketVolumeFormProps = {
  activeTicket: TTicketInput;
  volume: TVolumeInput;
  canDelete: boolean;
  onVolumeChange: (
    volume: TVolumeInput,
    change: {
      [key: string]:
      | number
      | Picklists.TUnitOfMeasure
      | Picklists.TTemperatureUOM;
    }
  ) => void;
  onCopyVolume: (volume: TVolumeInput) => void;
  onDeleteVolume: (volume: TVolumeInput) => void;
};

export const TicketVolumeForm = ({
  activeTicket,
  volume,
  canDelete,
  onVolumeChange,
  onCopyVolume,
  onDeleteVolume,
}: TicketVolumeFormProps) => {
  const { picklists } = usePicklists();

  const onInputChange = (
    e: DropDownListChangeEvent | NumericTextBoxChangeEvent
  ) => {
    if (e.target.name) {
      if (e.target.name === "grossVolume" || e.target.name === "netVolume") {
        e.value = Math.abs(e.value);
      }
      onVolumeChange(volume, { [e.target.name]: e.value });
    }
  };

  const isGrossVolumeValid = !isMotRailOrTruck(activeTicket.modeOfTransport?.name) || !!volume.grossVolume;

  const uomClassFilter = volume.volumeType
    ? [volume.volumeType]
    : ["Volume", "Mass"];

  const unitOfMeasures = picklists?.unitOfMeasures?.filter((u) =>
    uomClassFilter.includes(u.unitOfMeasureClass.name)
  );

  const disableAdd =
    !volume.unitOfMeasure ||
    !volume.temperature ||
    !volume.temperatureUnitOfMeasure ||
    !volume.netVolume;

  return (
    <>
      <DropDownList
        data={unitOfMeasures}
        name={`unitOfMeasure`}
        textField="name"
        dataItemKey="id"
        value={volume.unitOfMeasure}
        onChange={onInputChange}
        size={"small"}
        required
        className={"theme-form-input"}
      />
      <NumericTextBox
        id="temperature"
        name="temperature"
        disabled={volume.isDefault}
        min={0}
        value={volume.temperature}
        onChange={onInputChange}
        spinners={false}
        suffix={TemperatureSuffix(
          picklists?.temperatureUOMs,
          volume.temperatureUnitOfMeasure,
          onInputChange
        )}
        size={"small"}
        required
        className={"theme-form-input"}
      />
      <NumericTextBox
        min={0}
        id="netVolume"
        name="netVolume"
        format={{ maximumFractionDigits: 7 }}
        value={volume.netVolume}
        onChange={onInputChange}
        size={"small"}
        spinners={false}
        required
        className={"theme-form-input"}
      />
      <NumericTextBox
        min={0}
        id="grossVolume"
        name="grossVolume"
        format={{ maximumFractionDigits: 7 }}
        value={volume.grossVolume}
        onChange={onInputChange}
        size={"small"}
        spinners={false}
        valid={isGrossVolumeValid}
        className={"theme-form-input"}
      />
      <Button
        icon="plus"
        fillMode={"flat"}
        title={"Copy Volume"}
        disabled={disableAdd}
        style={{ color: `${disableAdd ? "gray" : "green"}` }}
        onClick={() => onCopyVolume(volume)}
      ></Button>
      {!volume.isDefault && (
        <Button
          icon="delete"
          fillMode={"flat"}
          title={"Delete Volume"}
          themeColor={"primary"}
          onClick={() => onDeleteVolume(volume)}
          disabled={!canDelete}
        ></Button>
      )}
      {volume.isDefault && <span></span>}
    </>
  );
};
