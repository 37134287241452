import { useQuery } from "@apollo/client";
import { toString as toKString } from "@progress/kendo-intl";
import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { Dialog, Window } from "@progress/kendo-react-dialogs";
import {
  GridColumn as Column,
  Grid,
  GridCellProps,
  GridNoRecords,
  GridPageChangeEvent
} from "@progress/kendo-react-grid";
import calculateSize from "calculate-size";
import { loader } from "graphql.macro";
import { useCallback, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import { GqlResponse, TTicketRevision } from "ticketing/ticketing.types";
import LoadingPanel from "../../../shared/components/LoadingPanel";
import RevisionsTicketMovements from "./RevisionsTicketMovements";
import RevisionsTicketRailcars from "./RevisionsTicketRailcars";
import RevisionsTicketVolumes from "./RevisionsTicketVolumes";

// pass the font properties based on the application
const CalculateSizeOptions = {
  font: "Segoe UI",
  fontSize: "14px"
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

type Response = GqlResponse<TTicketRevision[], "ticketRevisions">;

const query = loader("../../ticketing-graphql/ticketRevisions.graphql");

const getHyperLinkCell = (text: string, onClick: (revisionNumber: string) => void) => {
  return (props: GridCellProps) => (
    <td className="k-command-cell" width={"80px"}>
      <Button
        themeColor={"warning"}
        fillMode={"link"}
        className="k-button theme-btn-yellow"
        onClick={() => onClick(props.dataItem?.revisionNumber)}>
        {text}
      </Button>
    </td>
  );
};

const columns = [
  { title: "Ticket ID", field: "ticketNumber" },
  { title: "Revision #", field: "revisionNumber" },
  { title: "Ticket Date", field: "startDate", format: "{0:MM/dd/yy}" },
  { title: "Update Date", field: "lastModifiedDate", format: "{0:MM/dd/yy HH:mm:ss}" },
  { title: "Updated By", field: "lastModifiedBy" },
  { title: "Batch", field: "batch.name" },
  { title: "Ship From", field: "shipFromCode.description" },
  { title: "Ship To", field: "shipToCode.description" },
  { title: "Product", field: "product.name" },
  { title: "Carrier", field: "carrier.name" },
  { title: "Border Crossing Date", field: "borderCrossingDate" },
  { title: "Comments", field: "invoiceComment" },
  { title: "PO Number", field: "poNumber" }
];

const PADDING = 16;
type TTicketRevisionTransformed = Omit<TTicketRevision, "lastModifiedBy"> & {
  lastModifiedBy?: string;
};

const transformData = (ticketRevisions: TTicketRevision[]) => {
  return ticketRevisions
    ?.map(ticket => ({
      ...ticket,
      lastModifiedDate: new Date(ticket?.lastModifiedDate + "Z"),
      startDate: new Date(ticket?.startDate + "Z"),
      lastModifiedBy: [ticket.lastModifiedBy.lastName, ticket.lastModifiedBy.firstName]
        .filter(Boolean)
        .join(", ")
    }))
    .sort((t1, t2) => t2.lastModifiedDate.getTime() - t1.lastModifiedDate.getTime());
};

/**
 *
 * @param param0
 * @returns
 */
const RevisionsTicket = ({
  ticketId,
  handleCancel
}: {
  ticketId: string;
  handleCancel: () => void;
}) => {
  const [revisions, setRevisions] = useState<TTicketRevisionTransformed[]>();
  const { loading, error } = useQuery<Response>(query, {
    fetchPolicy: "no-cache",
    variables: { id: ticketId },
    onCompleted: data => setRevisions(transformData(data.ticketRevisions))
  });

  const [revisionNumber, setRevisionNumber] = useState<string>();
  const [showMovements, setShowMovements] = useState<boolean>(false);
  const [showVolumes, setShowVolumes] = useState<boolean>(false);
  const [showRailcars, setShowRailcars] = useState<boolean>(false);

  const handleTicketMovementRevisions = (revNumber: string) => {
    setRevisionNumber(revNumber);
    setShowMovements(true);
  };
  const handleTicketVolumeRevisions = (revNumber: string) => {
    setRevisionNumber(revNumber);
    setShowVolumes(true);
  };
  const handleTicketsRailcarsRevisions = (revNumber: string) => {
    setRevisionNumber(revNumber);
    setShowRailcars(true);
  };
  const hideMovementRevisions = () => setShowMovements(false);
  const hideVolumeRevisions = () => setShowVolumes(false);
  const hideRailcars = () => setShowRailcars(false);

  const [page, setPage] = useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState<number | string | undefined>();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.syntheticEvent as PagerTargetEvent;
    const take = event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }

    setPage({
      ...event.page,
      take
    });
  };

  const calculateWidth = useCallback(
    (field: string, title: string, format?: string) => {
      let maxWidth = calculateSize(title, CalculateSizeOptions).width + PADDING;
      if (revisions && field) {
        revisions.forEach(item => {
          const valueGetter = getter(field);
          const value = valueGetter(item);
          const formattedValue =
            value instanceof Date && format ? toKString(value, format) : value;
          const size = calculateSize(formattedValue, CalculateSizeOptions).width + PADDING;
          if (size > maxWidth) {
            maxWidth = size;
          }
        });
      }
      return maxWidth;
    },
    [revisions]
  );

  return (
    <Window
      title={`Ticket Revisions (System ID: ${ticketId})`}
      initialHeight={300}
      onClose={handleCancel}
      initialWidth={1080}
      modal>
      <div className={"content-wrapper"} style={{ height: "100%" }}>
        <div className={"grid-wrapper"} style={{ height: "100%" }}>
          <div className={"card-container"} style={{ height: "100%" }}>
            {loading && <LoadingPanel />}
            {error && <ApolloErrorViewer error={error} />}
            <Grid
              style={{ height: "100%" }}
              data={revisions?.slice(page.skip, page.take + page.skip)}
              resizable
              skip={page.skip}
              take={page.take}
              total={revisions?.length}
              pageable={{
                buttonCount: 4,
                pageSizes: [5, 10, 25, 50],
                pageSizeValue
              }}
              onPageChange={pageChange}
              dataItemKey="revisionNumber">
              <GridNoRecords>
                <div style={{ color: "gray" }}>There is no data available</div>
              </GridNoRecords>
              {columns.map(column => (
                <Column
                  key={column.field}
                  minResizableWidth={96}
                  {...column}
                  headerClassName={"k-justify-content-center"}
                  width={calculateWidth(column.field, column.title, column.format)}></Column>
              ))}
              <Column
                cell={getHyperLinkCell("Movements", handleTicketMovementRevisions)}
                width="90px"
              />
              <Column
                cell={getHyperLinkCell("Volumes", handleTicketVolumeRevisions)}
                width="72px"
              />
              <Column
                cell={getHyperLinkCell("Railcars", handleTicketsRailcarsRevisions)}
                width="72px"
              />
            </Grid>
          </div>
        </div>
      </div>
      {showMovements && revisionNumber && (
        <Dialog title={"Ticket Revision MovementIds"} onClose={hideMovementRevisions}>
          <RevisionsTicketMovements
            ticketId={ticketId}
            revisionNumber={revisionNumber}></RevisionsTicketMovements>
        </Dialog>
      )}
      {showVolumes && revisionNumber && (
        <Dialog title={"Ticket Revision Volumes"} onClose={hideVolumeRevisions}>
          <RevisionsTicketVolumes
            ticketId={ticketId}
            revisionNumber={revisionNumber}></RevisionsTicketVolumes>
        </Dialog>
      )}
      {showRailcars && revisionNumber && (
        <Dialog title={"Ticket Revision Railcars"} onClose={hideRailcars}>
          <RevisionsTicketRailcars
            ticketId={ticketId}
            revisionNumber={revisionNumber}></RevisionsTicketRailcars>
        </Dialog>
      )}
    </Window>
  );
};

export default RevisionsTicket;
