import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  MultiColumnComboBox
} from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useContext, useRef } from "react";
import useSearchBatches from "ticketing/hooks/useSearchBatches";
import { TBatch } from "ticketing/ticketing.types";
import { equalsIgnoreCase } from "ticketing/utils";
import { BulkImportContext } from "../util";

const columns = [
  { field: "name", header: "Name", width: "180px" },
  { field: "logisticsSystem.name", header: "Logistics System", width: "180px" }
];

const delay = 500;
const MIN_FILTER_LENGTH = 3;
export const BatchCell = (props: GridCellProps) => {
  const { fieldConfig: allFieldConfig, refData: allRefData } = useContext(BulkImportContext);
  const [search, searchResults, loading, , clear] = useSearchBatches();
  const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;

  const isInEdit = field === dataItem.inEdit;
  const batchValue = field && dataItem[field];
  const isValid = batchValue != null;
  const fieldConfig = allFieldConfig?.find(f => f.name === field);
  const lookupName = fieldConfig?.lookupName!;
  const typeAhead = fieldConfig?.typeAhead;
  const timeout = useRef<ReturnType<typeof setTimeout> | null>();
  const navigationAttributes = useTableKeyboardNavigation(props.id);

  const getData = () => {
    return searchResults?.length
      ? searchResults
      : (allRefData?.[lookupName] as TBatch[])?.filter(b =>
          equalsIgnoreCase(b.modeOfTransport.name, dataItem?.modeoftransport?.initial)
        );
  };

  const handleFilterChange = async (e: ComboBoxFilterChangeEvent) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    const filterValue = e.filter?.value?.trim();
    if (typeAhead && (filterValue.length ?? 0) >= MIN_FILTER_LENGTH) {
      timeout.current = setTimeout(() => {
        search(filterValue);
      }, delay);
    }
  };

  const onValueChange = (e: ComboBoxChangeEvent) => {
    clear();
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        value: { ...batchValue, value: e.target.value },
        syntheticEvent: e.syntheticEvent
      });
    }
  };

  if (!fieldConfig) {
    return <td></td>;
  }

  const defaultRendering = (
    <td
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      role={"gridcell"}
      {...navigationAttributes}>
      {isInEdit ? (
        <div>
          <MultiColumnComboBox
            data={getData()}
            dataItemKey="id"
            columns={columns}
            textField={"name"}
            filterable={true}
            onFilterChange={handleFilterChange}
            style={{ width: "100%" }}
            loading={loading}
            onChange={onValueChange}
            clearButton={true}
            placeholder="Please select ..."
            value={batchValue?.value}
            size={"small"}
          />
        </div>
      ) : (
        <span>
          {!isValid && fieldConfig?.isRequired && (
            <span className="k-icon-xs k-icon bt-i-warning k-color-error"></span>
          )}
          {!isValid && !fieldConfig?.isRequired && batchValue && (
            <span className="k-icon-xs k-icon bt-i-warning k-color-warning"></span>
          )}
          <span>{batchValue?.value?.name ?? batchValue?.intial ?? ""}</span>
        </span>
      )}
    </td>
  );

  return render?.(defaultRendering, props) ?? <td></td>;
};
