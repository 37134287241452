import { ApolloError, useMutation } from "@apollo/client";
import {
  Drawer,
  FormField,
  Heading,
  Select,
  Sentiments,
  Sizes,
  TextInput,
  TextTypes,
  Toggle,
  Tree,
  TreeNode,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { loader } from "graphql.macro";
import { useMemo, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import LoadingPanel from "shared/components/LoadingPanel";
import { GqlResponse } from "types";
import { IMACkRoleView, IMACkUser } from "../../auth";
import { TUserLegalEntity } from "../types";
import "../user.css";

const ADD_USER = loader("../graphql/mutation-add-user.graphql");
const UPDATE_USER = loader("../graphql/mutation-update-user.graphql");

type TAddUpdateUserResponse = GqlResponse<IMACkUser, "user">;

type TInitialValues = Partial<IMACkUser> & {
  active?: boolean;
  defaultLegalEntity?: TUserLegalEntity;
};

const AddOrUpdateUser = (props: {
  user: IMACkUser;
  roles: IMACkRoleView[];
  legalEntities: TUserLegalEntity[];
  onEditDone: (done: IMACkUser | null) => void;
  onClose: () => void;
}) => {
  const { user, roles, legalEntities, onEditDone, onClose } = props;
  const [addUser, { loading: aLoading, error: aError, reset: aReset }] =
    useMutation<TAddUpdateUserResponse>(ADD_USER, {
      onCompleted: data => onEditDone(data.user)
    });

  const [updateUser, { loading: uLoading, error: uError, reset: uReset }] =
    useMutation<TAddUpdateUserResponse>(UPDATE_USER, {
      onCompleted: data => onEditDone(data.user)
    });

  const [treeState, setTreeState] = useState(
    roles.map(p => ({
      key: String(p.id),
      title: p.name,
      isChecked: user.roles?.some(r => String(r.id) === String(p.id))
    }))
  );

  const errors = useMemo(
    () => [aError, uError].filter((e): e is ApolloError => Boolean(e)),
    [aError, uError]
  );

  const [initialValues, setInitialValues] = useState<TInitialValues>({
    id: user.id,
    firstName: user.firstName,
    middleName: user.middleName ?? "",
    lastName: user.lastName,
    mobile: user.mobile ?? "",
    email: user.email,
    active: user.status === "ACTIVE",
    defaultLegalEntityId: user.defaultLegalEntityId,
    defaultLegalEntity: legalEntities.find(e => e.id === user.defaultLegalEntityId)
  });

  const onSubmitClick = () => {
    const modifiedUser = {
      ...initialValues,
      id: initialValues.id?.trim(),
      firstName: initialValues.firstName?.trim(),
      middleName: initialValues.middleName?.trim(),
      lastName: initialValues.lastName?.trim(),
      mobile: initialValues.mobile?.trim(),
      email: initialValues.email?.trim(),
      defaultLegalEntityId: initialValues.defaultLegalEntity?.id ?? null,
      roles: treeState.filter(r => r.isChecked).map(r => parseInt(r.key, 10)),
      status: initialValues.active ? "ACTIVE" : "DISABLED"
    };
    delete modifiedUser.active;
    delete modifiedUser.defaultLegalEntity;

    if (!user.id) {
      addUser({
        variables: {
          user: modifiedUser
        },
        onCompleted: data => onEditDone(data.user)
      });
    } else {
      updateUser({
        variables: {
          user: {
            ...modifiedUser,
            version: user.version
          }
        },
        onCompleted: data => onEditDone(data.user)
      });
    }
  };

  const onCheckChanged = (event: TreeNode) => {
    const isChecked = event.isChecked === "indeterminate" ? false : event.isChecked;
    setTreeState(s =>
      s.map(r => (r.key === event.key ? { ...r, isChecked: isChecked as boolean } : r))
    );
  };
  const onValueChanged = (fieldName: string, value: unknown) => {
    setInitialValues(v => ({ ...v, [fieldName]: value }));
  };

  const isLoading = [aLoading, uLoading].some(elm => elm);
  const hasError = errors.length > 0;
  const reset = () => [aReset, uReset].map(fn => fn?.());

  return (
    <Drawer
      header={
        <>
          <Heading className="setting-form-heading" type={TextTypes.H2}>
            {user.id ? "Edit User" : "Add User"}
          </Heading>
          {hasError && <ApolloErrorViewer error={errors} onClose={reset} />}
        </>
      }
      borders={false}
      closeButton={false}
      sticky
      mask={true}
      open={true}
      maskClosable={false}
      size={Sizes.Medium}
      onClose={onClose}
      actions={[
        {
          label: "Cancel",
          action: onClose,
          props: {
            variant: Variants.Outlined
          }
        },
        {
          label: "Save",
          action: onSubmitClick
        }
      ]}>
      {isLoading && <LoadingPanel />}
      <form className="setting-form-container">
        <FormField
          size={"small"}
          id="user-id-label"
          mandatory={true}
          label="User Id"
          bottomLeftHelper={{
            content: !initialValues?.id ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            size={Sizes.Small}
            disabled={!!user.id}
            readOnly={!!user.id}
            onChange={event => {
              if (!user.id) {
                setInitialValues({
                  ...initialValues,
                  id: event.target.value.trim()
                });
              }
            }}
            value={initialValues?.id ?? ""}
            invalid={!initialValues?.id}
          />
        </FormField>

        <FormField
          size={"small"}
          id="firstName-label"
          mandatory={true}
          label="First Name"
          bottomLeftHelper={{
            content: !initialValues?.firstName ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            size={Sizes.Small}
            onChange={event => onValueChanged("firstName", event.target.value)}
            value={initialValues?.firstName ?? ""}
            invalid={!initialValues?.firstName}
          />
        </FormField>

        <FormField size={"small"} id="middleName-label" label="Middle Name">
          <TextInput
            size={Sizes.Small}
            onChange={event => onValueChanged("middleName", event.target.value)}
            value={initialValues?.middleName ?? ""}
          />
        </FormField>

        <FormField
          size={"small"}
          id="lastName-label"
          mandatory={true}
          label="Last Name"
          bottomLeftHelper={{
            content: !initialValues?.lastName ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            size={Sizes.Small}
            onChange={event => onValueChanged("lastName", event.target.value.trim())}
            value={initialValues?.lastName ?? ""}
            invalid={!initialValues?.lastName}
          />
        </FormField>

        <FormField
          size={"small"}
          id="email-label"
          mandatory={true}
          label="Email"
          bottomLeftHelper={{
            content: !initialValues?.email ? <b>Field can't be blank.</b> : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            size={Sizes.Small}
            onChange={event => onValueChanged("email", event.target.value.trim())}
            value={initialValues?.email ?? ""}
            invalid={!initialValues?.email}
          />
        </FormField>

        <FormField size={"small"} id="mobile-label" label="Phone">
          <TextInput
            size={Sizes.Small}
            onChange={event => onValueChanged("mobile", event.target.value.trim())}
            value={initialValues?.mobile ?? ""}
          />
        </FormField>

        <FormField size={"small"} id="legal-entity-label" label="Legal Entity">
          <Select
            options={legalEntities?.map((elm: TUserLegalEntity) => ({
              key: elm.id,
              label: elm.name,
              value: elm.name
            }))}
            size={"small"}
            id="legal-entity-input"
            placeholder="Select a legal entity"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            labelInValue={true}
            value={initialValues?.defaultLegalEntity?.name ?? undefined}
            onChange={data =>
              onValueChanged(
                "defaultLegalEntity",
                data ? { id: data.key, name: data.value } : null
              )
            }
          />
        </FormField>

        <FormField size={"medium"} id="role-status-label" label="Active">
          <Toggle
            size={Sizes.Large}
            checked={initialValues?.active}
            onChange={() => onValueChanged("active", !initialValues?.active)}
          />
        </FormField>

        <FormField size={"medium"} id="role-privilleges-label" label="Roles">
          <div>
            <Tree
              treeData={treeState || []}
              isCheckable
              size={Sizes.Small}
              onCheck={onCheckChanged}
            />
          </div>
        </FormField>
      </form>
    </Drawer>
  );
};
export default AddOrUpdateUser;
