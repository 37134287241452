import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { process, State } from "@progress/kendo-data-query";
import {
  Splitter,
  SplitterOnChangeEvent,
  SplitterPaneProps,
} from "@progress/kendo-react-layout";

import LoadingPanel from "../LoadingPanel";
import MessageBar from "../MessageBar";
import GridBaseProduct from "./GridBaseProduct";
import GridItemDetails from "./GridItemDetails";
import GridSummary from "./GridSummary";
import GridToolBar from "./GridToolBar";

import { actionCreator } from "../../../_redux/actionCreator";
import { IAppState } from "../../../_redux/IReduxState";
import {
  FORECAST_CLOSE_BASE_PRODUCT,
  FORECAST_CLOSE_DETAILS,
  FORECAST_DATASTATE,
} from "../../../forecast/state/forecastActionTypes";
import * as editor from "../../../forecast/util/gridEditor";

const GridWrapper = (props: { isSearchOpen: any }) => {
  const dispatch = useDispatch();

  // local state
  const [isSearchOpen, setSearchOpen] = useState(props.isSearchOpen);

  useEffect(() => {
    setSearchOpen(props.isSearchOpen);
  }, [props.isSearchOpen]);

  //=================================
  // state & data
  //=================================
  const forecast = useSelector((state: IAppState) => state.forecast);
  const isMonthly = forecast.isMonthlyDisplayMode;

  const summary = isMonthly ? forecast.monthlySummary : forecast.dailySummary;
  const data = summary?.data;

  const showDetails = summary.showDetails ?? false;
  const showBaseProduct = summary.showBaseProduct ?? false;
  const loading = forecast.loading;

  // current active rows
  const activeMonthlyRow = editor.activeRow(forecast.monthlySummary);
  const activeDailyRow = editor.activeRow(forecast.dailySummary);
  const activeDetailsRow = activeMonthlyRow
    ? editor.activeRow(activeMonthlyRow.details)
    : null;

  //console.log('@ active rows', activeMonthlyRow, activeDailyRow, activeDetailsRow)

  // details & base data
  const detailsData = isMonthly
    ? activeMonthlyRow && activeMonthlyRow.details
      ? activeMonthlyRow.details.data
      : []
    : [];

  const baseData = isMonthly
    ? activeDetailsRow && activeMonthlyRow.base
      ? activeDetailsRow.base.data
      : []
    : activeDailyRow && activeDailyRow.base
      ? activeDailyRow.base.data
      : [];

  //console.log('@ detail/base data', detailsData, baseData)

  //=================================
  // splitters
  //=================================
  const [panes, setPanes] = useState<SplitterPaneProps[]>([{ size: "40%", collapsible: true }, {}]);
  // Details & Base Product Splitter
  const [detailsPanes, setDetailsPanes] = useState<SplitterPaneProps[]>([
    { size: "62%", collapsible: true },
    {},
  ]);

  //======================================
  // dataState on change
  //======================================
  const createDataState = (gridData: unknown[], dataState: State) => {
    const result = process(gridData, dataState);
    return {
      result,
      dataState,
    };
  };

  const onSummaryStateChange = (event: { dataState: State }) => {
    const newState = createDataState(data, event.dataState);
    summary.result = newState.result;
    summary.dataState = newState.dataState;
    dispatch(actionCreator(FORECAST_DATASTATE, summary));
  };

  const onDetailsStateChange = (event: { dataState: State }) => {
    const newState = createDataState(detailsData, event.dataState);
    const activeRow = activeMonthlyRow;
    activeRow.details.result = newState.result;
    activeRow.details.dataState = newState.dataState;
    dispatch(actionCreator(FORECAST_DATASTATE, summary));
  };

  const onBaseStateChange = (event: { dataState: State }) => {
    const newState = createDataState(baseData, event.dataState);
    const activeRow = isMonthly ? activeDetailsRow : activeDailyRow;
    activeRow.base.result = newState.result;
    activeRow.base.dataState = newState.dataState;
    dispatch(actionCreator(FORECAST_DATASTATE, summary));
  };

  //======================================
  // grid on close
  //======================================
  const onDetailsClose = () => {
    if (activeMonthlyRow) { activeMonthlyRow.active = false; }
    dispatch(
      actionCreator(FORECAST_CLOSE_DETAILS, forecast.monthlySummary.data)
    );
  };

  const onBaseClose = () => {
    if (forecast.isMonthlyDisplayMode) {
      if (activeDetailsRow) { activeDetailsRow.active = false; }
      dispatch(
        actionCreator(FORECAST_CLOSE_BASE_PRODUCT, forecast.monthlySummary.data)
      );
    } else {
      if (activeDailyRow) { activeDailyRow.active = false; }
      dispatch(
        actionCreator(FORECAST_CLOSE_BASE_PRODUCT, forecast.dailySummary.data)
      );
    }
  };

  //======================================
  // splitter panes on change
  //======================================
  const onPanesChange = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };

  const onDetailsPanesChange = (e: SplitterOnChangeEvent) => {
    setDetailsPanes(e.newState);
  };

  const getSummaryGridStyle = () => {
    return { height: `calc(100vh - ${isSearchOpen ? "546px" : "190px"})` };
  };
  const getDetailsGridStyle = () => {
    return {
      height: `calc((100vh - ${isSearchOpen ? "546px" : "190px"})*${showBaseProduct ? ".6" : "1"
        })`,
    };
  };

  //=================================
  // Forecast Grid Components
  //=================================
  const ForecastSummary = (
    <GridSummary
      gridStyle={getSummaryGridStyle()}
      onDataStateChange={onSummaryStateChange}
    />
  );

  const ForecastDetails = (
    <GridItemDetails
      gridStyle={getDetailsGridStyle()}
      onDataStateChange={onDetailsStateChange}
      onClose={onDetailsClose}
    />
  );

  const ForecastBaseProduct = (
    <GridBaseProduct
      onDataStateChange={onBaseStateChange}
      onClose={onBaseClose}
    />
  );
  //=================================
  // Render
  //=================================
  return (
    <div className={"content-wrapper"}>
      <GridToolBar />

      <div className={"grid-wrapper"}>
        <div className={"grid-container"}>
          {loading && <LoadingPanel />}
          {showDetails || showBaseProduct ? (
            <Splitter panes={panes} onChange={onPanesChange}>
              {ForecastSummary}
              {showDetails && showBaseProduct ? (
                <Splitter
                  panes={detailsPanes}
                  orientation={"vertical"}
                  onChange={onDetailsPanesChange}
                >
                  {ForecastDetails}
                  {ForecastBaseProduct}
                </Splitter>
              ) : showDetails ? (
                ForecastDetails
              ) : (
                ForecastBaseProduct
              )}
            </Splitter>
          ) : (
            ForecastSummary
          )}
        </div>
      </div>

    </div>
  );
};
export default GridWrapper;
