import { GridCustomCellProps, GridRowProps } from "@progress/kendo-react-grid";
import React, { ReactNode } from "react";
import { TBulkImportTicket } from "../types";

interface CellRenderProps {
  originalProps: GridCustomCellProps;
  td: React.ReactElement<HTMLTableCellElement> | null;
  enterEdit: (dataItem: TBulkImportTicket, field: string | undefined) => void;
  editField: string | undefined;
}

interface RowRenderProps {
  originalProps: GridRowProps;
  tr: React.ReactElement<HTMLTableRowElement>;
  exitEdit: () => void;
  editField: string | undefined;
  onContextMenu: (e: React.MouseEvent, dataItem: TBulkImportTicket) => void
}

export const CellRender = (props: CellRenderProps) => {
  const dataItem = props.originalProps.dataItem;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField ?? ""];
  const additionalProps =
    cellField && cellField === inEditField
      ? {
        ref: (td: HTMLTableCellElement) => {
          const input = td?.querySelector("input");
          const activeElement = document.activeElement;

          if (!input || !activeElement || input === activeElement || !activeElement.contains(input)) {
            return;
          }

          if (input.type === "checkbox") {
            input.focus();
          } else {
            input.select();
          }
        },
      }
      : {
        onClick: () => {
          props.enterEdit(dataItem, cellField);
        },
      };

  const clonedProps = { ...props.td?.props, ...additionalProps };
  const childNodes = props.td?.props.children;
  return React.cloneElement(props.td ?? <td></td>, clonedProps, childNodes as ReactNode);
};

export const RowRender = (props: RowRenderProps) => {
  const dataItem = props.originalProps.dataItem;
  const blurTimeout = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const inEditField = props.editField && props.originalProps.dataItem[props.editField];
  if (!inEditField && dataItem["errors"] && dataItem["errors"].length > 0) {
    Object.assign(props.tr.props.style, { color: "rgb(232, 59, 70)" });
  }
  const trProps = {
    ...props.tr.props,

    onFocus: () => {
      if (blurTimeout.current) {
        clearTimeout(blurTimeout.current);
      }
    },
    onBlur: () => {
      const activeElement = document.activeElement;
      if (activeElement && activeElement.className.indexOf("k-calendar") < 0) {
        blurTimeout.current = setTimeout(() => {
          props.exitEdit();
        });
      }
    },
    onContextMenu: (e: React.MouseEvent) => {
      e.preventDefault();
      props?.onContextMenu(e, dataItem);
    },
  };
  const childNodes = props.tr.props.children;
  return React.cloneElement(props.tr, { ...trProps }, childNodes as ReactNode);
};
