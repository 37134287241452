import {
  Button,
  FormField,
  Modal,
  Select,
  TextInput,
  Variants
} from "@sede-x/shell-ds-react-framework";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { useEffect, useState } from "react";
import { CIQOption, TCILookup, TWeightedAverageFilter } from "../carbonIQtypes";

const WeightedAverageLookup = ({
  onClose,
  onSubmit,
  selectedWeightedAverageSearchFilter,
  weightedAverageData,
  terminals,
  components
}: {
  onClose: () => void;
  onSubmit: (data: TWeightedAverageFilter) => void;
  selectedWeightedAverageSearchFilter: any;
  weightedAverageData: any[];
  terminals: TCILookup[];
  components: TCILookup[];
}) => {
  const [open, setOpen] = useState(true);
  const handleOnClose = () => {
    if (onClose) onClose();
    setOpen(false);
  };

  dayjs.extend(weekday);
  dayjs.extend(localeData);
  const [terminalsList, setTerminalsList] = useState<any[]>([]);
  const [componentsList, setComponentsList] = useState<any[]>([]);

  useEffect(() => {
    setTerminalsList(
      terminals.map(terminal => ({
        value: terminal.id.toString(), //'value` of Option should not use number type when `mode` is `tags` or `combobox`.
        label: terminal.displayName,
        key: terminal.id
      }))
    );

    setComponentsList(
      components.map(component => ({
        value: component.id.toString(), //'value` of Option should not use number type when `mode` is `tags` or `combobox`.
        label: component.name,
        key: component.id
      }))
    );
  }, [components, terminals]);

  const [selectedComponents, setSelectedComponents] = useState<any[]>([]);
  const [selectedTerminals, setSelectedTerminals] = useState<any[]>([]);
  const [selectedYears, setSelectedYears] = useState<string>("");

  return (
    <Modal
      title="Search for WeightedAverage(s)"
      width={"700px"}
      height={"600px"}
      mask={true}
      open={open}
      onClose={() => {
        handleOnClose();
      }}>
      <form
        onSubmit={event => {
          event.preventDefault();
          onSubmit({
            components: selectedComponents,
            terminals: selectedTerminals,
            years: selectedYears.split(",")
          });
          handleOnClose();
        }}>
        <FormField size={"medium"} id="component-name-label" label="Component">
          <Select
            options={componentsList}
            size={"medium"}
            id="component-name-input"
            placeholder="Select a Component"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            mode="tags"
            labelInValue={true}
            onChange={data => {
              setSelectedComponents(
                data
                  .map((elm: CIQOption) =>
                    componentsList.find(item => item.value === elm.value)
                  )
                  .filter(function (elm: CIQOption) {
                    // used filter to remove undefined elements, which don't exists in list
                    return elm !== undefined;
                  })
              );
            }}
          />
        </FormField>
        <FormField size={"medium"} id="terminal-name-label" label="Terminal">
          <Select
            options={terminalsList}
            size={"medium"}
            id="terminal-name-input"
            placeholder="Select a Terminal"
            optionLabelProp="label"
            filterOption={true}
            optionFilterProp="label"
            mode="tags"
            labelInValue={true}
            onChange={data => {
              setSelectedTerminals(
                data
                  .map((elm: CIQOption) => terminalsList.find(item => item.value === elm.value))
                  .filter(function (elm: CIQOption) {
                    // used filter to remove undefined elements, which don't exists in list
                    return elm !== undefined;
                  })
              );
            }}
          />
        </FormField>
        <FormField size={"medium"} id="year-label" label="Year">
          <TextInput
            onChange={event => {
              setSelectedYears(event.target.value.trim());
            }}
            value={selectedYears ?? ""}
          />
        </FormField>

        <div style={{ paddingBottom: "200px" }}></div>
        <div className="from-button-wrap">
          <Button
            variant={Variants.Outlined}
            onClick={() => {
              handleOnClose();
            }}>
            Cancel
          </Button>
          <Button type="submit">Search</Button>
        </div>
      </form>
    </Modal>
  );
};

export default WeightedAverageLookup;
