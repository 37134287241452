import { ApolloError, useLazyQuery } from "@apollo/client";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { addDays } from "@progress/kendo-date-math";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import { loader } from "graphql.macro";
import { useEffect, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import { validateTicket } from "ticketing/components/tickets/validations";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import useValidationConfigs from "ticketing/hooks/useValidationConfigs";
import {
  GqlResponse,
  TBatchSearchCriteria,
  TMovement,
  TMovementGroup,
  TPicklists,
  TTicket,
  TTicketValidationResult,
  TUserQueryCode,
  TValidatedTicket,
  TValidationsConfig,
  TicketStatus
} from "ticketing/ticketing.types";
import {
  DAYS_IN_WEEK,
  isPhysicalDeal,
  toAvailableTicketFilterInput,
  toTicketFilterInput
} from "ticketing/utils";
import { AvailableTicket } from "./AvailableTicket";
import SearchAvailableTicketsContainer from "./SearchAvailableTicketsContainer";
import "./movements.css";
import { filterShipCodes } from "./shipcode-filter";
import { findMatchingVolume } from "./volume_util";

const initSearchCriteria = (movement: TMovement): TBatchSearchCriteria => {
  return {
    startDate: movement.startDate as Date,
    endDate: addDays(movement.startDate as Date, DAYS_IN_WEEK),
    batches: movement.batch ? Array.of(movement.batch) : undefined,
    logisticsSystems: movement.logisticsSystem
      ? Array.of({
          id: movement.logisticsSystem.id,
          name: movement.logisticsSystem.name
        })
      : undefined,
    modeOfTransports: movement.batch.modeOfTransport
      ? Array.of({
          id: movement.batch.modeOfTransport.id,
          name: movement.batch.modeOfTransport.name
        })
      : undefined,
    ticketStatus: TicketStatus.Unlinked,
    shipFromCodes: [],
    shipToCodes: []
  };
};

const transformAvailableTickets = (
  movementGroup: TMovementGroup,
  tickets: TTicket[],
  picklists?: TPicklists,
  validationConfigs?: TValidationsConfig[],
  userQueryCodes?: TUserQueryCode[]
): TValidatedTicket[] => {
  const movement = movementGroup?.activeMovement;
  const shipFromCodes = isPhysicalDeal(movement?.recDeal)
    ? filterShipCodes(picklists?.shipFrom, movement, movement?.recDeal)
    : [];
  const shipToCodes = isPhysicalDeal(movement?.delDeal)
    ? filterShipCodes(picklists?.shipTo, movement, movement?.delDeal)
    : [];

  const transformedTickets = tickets
    ?.map(t => ({ ...t, startDate: new Date(t.startDate) }))
    .sort((t1, t2) => +t2.id - +t1.id);

  return transformedTickets.map(ticket => ({
    ...ticket,
    validationResults: validateTicket(
      movementGroup,
      ticket,
      picklists?.temperatureUOMs!,
      shipFromCodes,
      shipToCodes,
      validationConfigs!,
      userQueryCodes!
    ) as [TTicketValidationResult],
    matchingVolume: findMatchingVolume(ticket, movementGroup, picklists?.uomConversions)
  }));
};

const TICKETS_SEARCH = loader("../../ticketing-graphql/searchAvailableTickets.graphql");
const FETCH_POLICY_NO_CACHE = {
  fetchPolicy: "no-cache" as MutationFetchPolicy
};
type AvailableTicketResponse = GqlResponse<TTicket[], "ticketsFilterBy">;

/***
 *
 */
const AvailableTicketContainer = ({
  activeMovementGroup,
  disableLinks,
  onShowTicketRevisions,
  onEditTicket,
  onLinkTicket,
  refreshTickets
}: {
  activeMovementGroup?: TMovementGroup | null;
  disableLinks: boolean;
  onShowTicketRevisions: (ticketId: string) => void;
  onEditTicket: (ticket: TTicket) => void;
  onLinkTicket: (ticket: TValidatedTicket) => void;
  refreshTickets?: number;
}) => {
  const { picklists } = usePicklists();
  const [searchCriteria, setSearchCriteria] = useState<TBatchSearchCriteria | null>();
  const [availableTickets, setAvailableTickets] = useState<TValidatedTicket[]>();
  const [showAvailableTicketsFilter, setShowAvailableTicketsFilter] = useState(false);

  const [searchForAvailableTickets, { loading: tLoading, error: tError }] =
    useLazyQuery<AvailableTicketResponse>(TICKETS_SEARCH, FETCH_POLICY_NO_CACHE);

  const {
    loading: vLoading,
    error: vError,
    validationConfigs,
    userQueryCodes
  } = useValidationConfigs();

  useEffect(() => {
    setAvailableTickets([]);
    //refresh availabe tickets
    if (activeMovementGroup) {
      const filter = searchCriteria
        ? toTicketFilterInput(searchCriteria)
        : toAvailableTicketFilterInput(activeMovementGroup.activeMovement);

      searchForAvailableTickets({
        variables: { filter },
        onCompleted: data => {
          setAvailableTickets(
            transformAvailableTickets(
              activeMovementGroup,
              data.ticketsFilterBy,
              picklists,
              validationConfigs,
              userQueryCodes
            )
          );
        }
      });
    }
  }, [
    activeMovementGroup,
    refreshTickets,
    picklists,
    searchCriteria,
    searchForAvailableTickets,
    validationConfigs,
    userQueryCodes
  ]);

  const handleFilter = (visible: boolean) => {
    if (visible) {
      //2659979
      setSearchCriteria(initSearchCriteria(activeMovementGroup!.movements.at(0)!));
    }
    setShowAvailableTicketsFilter(visible);
  };

  const onAdvancedSearch = (c: TBatchSearchCriteria) => {
    setAvailableTickets([]);
    setShowAvailableTicketsFilter(false);
    setSearchCriteria(c);
  };
  const loading = [tLoading, vLoading].some(Boolean);
  const errors = [tError, vError].filter((e): e is ApolloError => Boolean(e));
  return (
    <>
      {showAvailableTicketsFilter && searchCriteria && (
        <SearchAvailableTicketsContainer
          searchCriteria={searchCriteria}
          onClose={() => handleFilter(false)}
          onSearch={onAdvancedSearch}
        />
      )}

      <div style={{ backgroundColor: "#a09d9d" }}>
        <div className="container-component-title" style={{ display: "flex" }}>
          <Label
            style={{
              display: "flex",
              alignItems: "center",
              flexGrow: "1"
            }}>{`Available Tickets (Count: ${availableTickets?.length ?? 0})`}</Label>
          <Button
            fillMode="flat"
            themeColor={"primary"}
            title="View Filters"
            icon="filter"
            onClick={() => handleFilter(true)}
            hidden={!activeMovementGroup}></Button>
        </div>
      </div>
      {loading && <InlineLoadingPanel />}
      {errors?.length > 0 && <ApolloErrorViewer error={errors} />}
      {!loading && (availableTickets?.length ?? 0) === 0 && (
        <div>
          <span className="availabel-tkt-container-wrapper">No data available.</span>
        </div>
      )}
      {availableTickets?.map(at => {
        return (
          <AvailableTicket
            key={at.id}
            ticket={at}
            activeMovementGroup={activeMovementGroup}
            disableLinks={disableLinks}
            onShowTicketRevisions={onShowTicketRevisions}
            onLinkTicket={onLinkTicket}
            onEditTicket={onEditTicket}
          />
        );
      })}
    </>
  );
};

export default AvailableTicketContainer;
