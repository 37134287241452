import { ApolloError, useQuery } from "@apollo/client";
import { MutationFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import {
  Badge,
  BaseTable,
  Button,
  Sentiments,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  CrossCircle,
  Database,
  FilePdfOutline,
  MailSendSolid,
  Search
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  ColumnDef,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import { MackPrivilege } from "auth";
import { useMackAuth } from "auth/AuthenticationProvider";
import { ROWS_PER_PAGE } from "carbonIQ/CarbonIQConstants";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import dayjs from "dayjs";
import { getCarbonIQFile } from "forecast/api";
import { loader } from "graphql.macro";
import { useCallback, useState } from "react";
import { LongQueryOperatorInput } from "ticketing/ticketing.types";
import { toEqOrInQueryOperator } from "ticketing/utils";
import { GqlResponse } from "types";

import ColumnText from "shared/components/basetable/cells/ColumnText";
import NoDataTableImage from "shared/components/basetable/NoDataTableImage";
import GlobalHeader from "shared/components/GlobalHeader";
import LoadingPanel from "shared/components/LoadingPanel";
import {
  CIQOption,
  TErrorType,
  TSavingLetterFilterData,
  TSavingLetterSearchData
} from "../carbonIQtypes";
import CustomerSearch from "./CustomerSearch";
import SendEmail from "./SendEmail";
import ShowDetails from "./ShowDetails";

const carbonSavingsDocumentsFilter = loader("../graphql/carbonSavingsDocuments.graphql");
const FETCH_POLICY_NO_CACHE = {
  fetchPolicy: "no-cache" as MutationFetchPolicy
};

type TcarbonSavingsDocumentsFilterResponse = GqlResponse<
  TSavingLetterFilterData[],
  "carbonSavingsDocumentsFilterBy"
>;

const dayJSFormat = (args: string) => {
  return dayjs(args).format("MMM[, ] YYYY");
};

const gridColumns: ColumnDef<TSavingLetterFilterData>[] = [
  {
    header: "Customer GSAP Name",
    accessorKey: "customer.gsapName",
    enableSorting: true,
    size: 300,
    cell: arg => <ColumnText width={"300px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Display Name",
    accessorKey: "customer.displayName",
    size: 300,
    enableSorting: true,
    cell: arg => <ColumnText width={"300px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Period",
    accessorKey: "month",
    size: 150,
    enableSorting: true,
    cell: arg => (
      <ColumnText width={"200px"}>{dayJSFormat(arg.getValue() as string)}</ColumnText>
    )
  }
];

const gridColumnsGenerate = (
  sendMailClickcallback: (arg: {
    row: {
      original: Partial<TSavingLetterFilterData>;
    };
  }) => void,
  fetchPDFClickcallback: (arg: string) => void,
  moreDetailsClickcallback: (arg: {
    row: {
      original: Partial<TSavingLetterFilterData>;
    };
  }) => void,
  hasSendCISavingsCertificatePrivilege: boolean | undefined = false,
  hasViewCISavingsCertificatePrivilege: boolean | undefined = false
) => {
  const additionalGridColumn: ColumnDef<TSavingLetterFilterData> = {
    header: "",
    accessorKey: "id",
    size: 300,
    enableSorting: false,
    cell: arg => (
      <div className="cta-button-wrapper">
        {hasSendCISavingsCertificatePrivilege && (
          <span>
            <Button title="Send Email" onClick={() => sendMailClickcallback(arg)}>
              <MailSendSolid />
              Send
            </Button>
          </span>
        )}
        {hasViewCISavingsCertificatePrivilege && (
          <span>
            <Button
              variant={Variants.Outlined}
              title="View Report"
              onClick={() => fetchPDFClickcallback(arg.getValue() as string)}>
              <FilePdfOutline />
            </Button>
          </span>
        )}
        <span>
          <Button
            variant={Variants.Outlined}
            title="More Details"
            onClick={() => moreDetailsClickcallback(arg)}>
            <Database />
          </Button>
        </span>
      </div>
    )
  };
  return [...gridColumns, additionalGridColumn];
};

const CarbonIQSavingsLetter = () => {
  const [showErrors, setShowErrors] = useState<TErrorType>();
  const [loading, setLoading] = useState(false);
  const { mackUser } = useMackAuth();
  const hasViewCISavingsCertificatePrivilege = mackUser?.hasPrivilege(
    MackPrivilege.ViewCISavingsCertificate
  );
  const hasSendCISavingsCertificatePrivilege = mackUser?.hasPrivilege(
    MackPrivilege.SendCISavingsCertificate
  );

  //Fetching Filtered Carbon IQ document data
  const {
    loading: lCIQSearchDocument,
    error: eCIQSearchDocument,
    data: filterDataList,
    refetch
  } = useQuery<TcarbonSavingsDocumentsFilterResponse>(carbonSavingsDocumentsFilter, {
    ...FETCH_POLICY_NO_CACHE,
    onCompleted: () => {
      setPagination({
        pageIndex: 0,
        pageSize: ROWS_PER_PAGE
      });
    },
    onError: () => {
      setLoading(false);
    }
  });

  type TempType = {
    customerId?: LongQueryOperatorInput | string | string[];
    month?: { bw: { start?: string | null; end?: string | null } };
  };

  const filterCIQsearchGQL = (data: TSavingLetterSearchData) => {
    const tempfilter = {} as TempType;
    if (data?.selectedCustomerData && data.selectedCustomerData.length > 0) {
      tempfilter["customerId"] = toEqOrInQueryOperator<string, LongQueryOperatorInput>(
        data?.selectedCustomerData?.map(elm => elm.key as string)
      );
    }
    if (data?.startMonth) {
      tempfilter["month"] = {
        bw: { start: data.startMonth, end: data.endMonth }
      };
    }
    return Object.keys(tempfilter).length ? tempfilter : null;
  };

  const fetchReportPDF = (fileID: string) => {
    setLoading(true);
    getCarbonIQFile("/carboniq/document/" + fileID)
      .then(response => {
        const fileName = response.headers["content-disposition"]?.replace(
          `attachment;filename=${fileID}-`,
          ""
        );
        const fileRef = new File([response.data], fileName, {
          type: "application/pdf"
        });
        const fileURL = URL.createObjectURL(fileRef);
        const pdfWindow = window.open();
        if (pdfWindow) {
          pdfWindow.location.href = fileURL;
        }
        setLoading(false);
      })
      .catch(error => {
        setShowErrors(new ApolloError({ errorMessage: JSON.stringify(error.toJSON()) }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = gridColumnsGenerate(
    arg => {
      setSendEmailOpenData(arg.row.original as TSavingLetterFilterData);
      setSendEmailOpen(!sendEmailOpen);
    },

    arg => {
      fetchReportPDF(arg);
    },
    arg => {
      setShowDetailsData(arg.row.original as TSavingLetterFilterData);
      setShowDetailsOpen(!showDetailsOpen);
    },

    hasSendCISavingsCertificatePrivilege,
    hasViewCISavingsCertificatePrivilege
  );

  const [sorting, setSorting] = useState<SortingState>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });

  const tableOptions = {
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false
  };

  const [searchOpen, setSearchOpen] = useState(false);
  const [sendEmailOpen, setSendEmailOpen] = useState(false);
  const [sendEmailOpenData, setSendEmailOpenData] = useState({});
  const [showDetailsOpen, setShowDetailsOpen] = useState(false);
  const [showDetailsData, setShowDetailsData] = useState<TSavingLetterFilterData>();
  const [selectedSearchFilter, setSelectedSearchFilter] =
    useState<TSavingLetterSearchData>(null);

  const handleSearchSubmit = (data: TSavingLetterSearchData) => {
    const tempFilterData = filterCIQsearchGQL(data);
    if (!!tempFilterData) {
      setSelectedSearchFilter(data);
      refetch({
        filter: tempFilterData
      });
    }
  };

  const handleSearchReset = useCallback(() => {
    setSelectedSearchFilter(null);
    refetch({
      filter: null
    });
  }, [refetch]);

  const isLoading = [lCIQSearchDocument, loading].some(elm => elm);
  const isError = [eCIQSearchDocument, showErrors].some(e => e);
  const errors = [eCIQSearchDocument, showErrors].filter((e): e is ApolloError => Boolean(e));
  return (
    <>
      {isError && <CommonErrorComponent error={errors} />}

      {isLoading && <LoadingPanel />}

      <GlobalHeader
        pageName="Savings Letters"
        filterButtonContent={[
          selectedSearchFilter && Object.keys(selectedSearchFilter).length > 0 && (
            <span className="customer-Search-current-filter">
              <span className="customer-Search-current-filter-txt">
                {selectedSearchFilter?.startMonth
                  ? dayJSFormat(selectedSearchFilter?.startMonth)
                  : ""}

                {selectedSearchFilter?.endMonth
                  ? ` - to - ${dayJSFormat(selectedSearchFilter?.endMonth)}  `
                  : ""}

                {selectedSearchFilter?.selectedCustomerData
                  ?.map((elm: CIQOption) => elm.value)
                  .join(", ")}
              </span>
              <button className="customer-Search-reset" onClick={handleSearchReset}>
                <CrossCircle />
                Clear Filter
              </button>
            </span>
          )
        ]}
        descriptiontxt="Please use this screen to View the letters generated for each customer, and also the details on how the final numbers were derived. Once satisfied, you can send email to the customer directly."
        buttonContent={[
          <Badge
            key={1}
            icon={<Search />}
            sentiment={Sentiments.Information}
            variant={Variants.Filled}
            onClick={() => setSearchOpen(!searchOpen)}
          />
        ]}
      />

      <BaseTable
        columns={columns}
        data={filterDataList?.carbonSavingsDocumentsFilterBy}
        className="carboniq-data-table"
        tableOptions={tableOptions}
        emptyStateProps={{ image: <NoDataTableImage /> }}
      />

      {searchOpen && (
        <CustomerSearch
          onClose={() => setSearchOpen(!searchOpen)}
          onSubmit={(data: TSavingLetterSearchData) => {
            handleSearchSubmit(data);
          }}
        />
      )}

      {sendEmailOpen && (
        <SendEmail
          onClose={() => {
            setSendEmailOpenData({});
            setSendEmailOpen(!sendEmailOpen);
          }}
          onSubmit={() => {}}
          userData={sendEmailOpenData}
        />
      )}

      {showDetailsOpen && (
        <ShowDetails
          onClose={() => setShowDetailsOpen(!showDetailsOpen)}
          details={showDetailsData}
        />
      )}
    </>
  );
};

export default CarbonIQSavingsLetter;
