import { Loader } from "@progress/kendo-react-indicators";
import { AxiosProgressEvent, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { downloadFile } from "../../../forecast/api";
import { IForecastSearchCriteria } from "../../../forecast/models";

export interface IDownloadFile {
  query: IForecastSearchCriteria;
  downloadId?: number;
}

const Downloader = ({
  files = [],
  remove,
}: {
  files: IDownloadFile[];
  remove: (e: number) => void;
}) => {
  return (
    <div className="downloader">
      <div className="card" style={{ backgroundColor: "white" }}>
        <div className="card-header">File Downloader</div>
        <ul className="list-group list-group-flush">
          {files.map((file) => (
            <DownloadItem
              key={file.downloadId}
              removeFile={() => remove(file.downloadId!)}
              {...file}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

const DownloadItem = ({
  query,
  removeFile,
}: {
  query: IForecastSearchCriteria;
  removeFile: () => void;
}) => {
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
    error: "",
  });
  const UNINDENT_TWO_PLACES = 100;
  const TWO_SECONDS = 2000;
  const FIVE_SECONDS = 5000;
  const ONE_KILOBYTE = 1024;
  const TWO_PRECISION = 2;
  useEffect(() => {
    const options = {
      onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
        const { loaded, total } = progressEvent;

        setDownloadInfo({
          progress: Math.floor((loaded * UNINDENT_TWO_PLACES) / (total ?? 1)),
          loaded,
          total: total ?? 0,
          completed: false,
          error: "",
        });
      },
    };

    downloadFile(query, options)
      .then(function (response: AxiosResponse) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
          })
        );
        let filename: string = response.headers["content-disposition"];
        filename = filename.substring(filename.lastIndexOf("=") + 1);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        setDownloadInfo((info) => ({
          ...info,
          completed: true,
        }));

        setTimeout(() => removeFile(), TWO_SECONDS);
      })
      .catch((error) => {
        if (error?.response?.data?.type?.toLowerCase().indexOf("json") !== -1) {
          const reader = new FileReader();
          reader.onload = function () {
            const backendError = JSON.parse(reader.result as string);
            setDownloadInfo((info) => ({
              ...info,
              completed: false,
              error: backendError?.errors?.join(";"),
            }));
          };
          reader.readAsText(error.response.data);
        } else {
          setDownloadInfo((info) => ({
            ...info,
            completed: false,
            error: error.message ?? error,
          }));
        }
        setTimeout(() => removeFile(), FIVE_SECONDS);
      });
  }, [query, removeFile]);

  const formatBytes = (bytes: number) =>
    `${(bytes / (ONE_KILOBYTE * ONE_KILOBYTE)).toFixed(TWO_PRECISION)} MB`;

  return (
    <li className="list-group-item">
      <div className="row">
        <div className="col-12 d-flex">
          <div className="d-inline font-weight-bold text-truncate">
            {"Downloading...."}
          </div>
          <div className="d-inline ml-2">
            <small>
              {downloadInfo.loaded > 0 && (
                <>
                  <span className="k-text-success">
                    {formatBytes(downloadInfo.loaded)}
                  </span>
                </>
              )}
              {downloadInfo.loaded === 0 && <>Initializing...</>}
            </small>
          </div>
          <div className="d-inline ml-2 ml-auto">
            {downloadInfo.completed && (
              <span className="text-success">Completed</span>
            )}
            {downloadInfo.error && (
              <>
                <span className="k-text-error">{downloadInfo.error}</span>
              </>
            )}
          </div>
        </div>
        <div className="col-12 mt-2">
          <Loader size="small" type={"pulsing"} />
        </div>
      </div>
    </li>
  );
};

export default Downloader;
