import { Tooltip } from "@progress/kendo-react-tooltip";

export type TGridAction = {
  icon?: string;
  title?: string;
  click?: () => void;
};
const GridActionBar = (props: {
  label?: string;
  actions?: TGridAction[];
  component?: React.ReactNode;
  onClick?: () => void;
}) => {
  const { label, actions, component, onClick } = props;

  const Actions = actions?.map(action => {
    const icon = action.icon + " card-title-icon" || "";
    return (
      <span
        key={action.icon}
        className={icon}
        title={action.title ?? ""}
        onClick={action?.click}
      />
    );
  });

  return (
    <div className={"card-title k-cursor-pointer"} onClick={onClick}>
      <div className={"card-title-label"}>{label}</div>
      <div className={"card-title-icon-group"}>
        {component ?? <div />}
        <Tooltip anchorElement="target" parentTitle={true}>
          {Actions}
        </Tooltip>
      </div>
    </div>
  );
};
export default GridActionBar;
