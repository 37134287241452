import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  MultiColumnComboBox
} from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useContext, useState } from "react";

import { filterShipCodes } from "ticketing/components/movements/shipcode-filter";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import { TShipCode } from "ticketing/ticketing.types";
import { BULK_IMPORT_FIELDS } from "../types";
import { BulkImportContext } from "../util";

const columns = [
  { field: "code", header: "Code", width: "100px" },
  { field: "description", header: "Description", width: "300px" }
];

const MIN_FILTER_LENGTH = 3;
export const ShipCodeCell = (props: GridCellProps) => {
  const { picklists } = usePicklists();
  const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
  const navigationAttributes = useTableKeyboardNavigation(props.id);

  const { fieldConfig: allFieldConfig } = useContext(BulkImportContext);
  const fieldConfig = allFieldConfig?.find(f => f.name === field);
  const isInEdit = field === dataItem.inEdit;
  const shipCodeFieldValue = field && dataItem[field];
  const isValid = shipCodeFieldValue != null;
  const shipCodes =
    field === BULK_IMPORT_FIELDS.SHIP_FROM_CODE_FIELD_NAME
      ? picklists?.shipFrom
      : picklists?.shipTo;

  const movement = dataItem["deliveryid"]?.value?.activeMovement;
  const deal =
    field === BULK_IMPORT_FIELDS.SHIP_FROM_CODE_FIELD_NAME
      ? movement?.recDeal
      : movement?.delDeal;

  const [data, setData] = useState<TShipCode[] | undefined>();

  const handleFilterChange = async (e: ComboBoxFilterChangeEvent) => {
    const filterValue = e.filter?.value?.trim();
    if ((filterValue.length ?? 0) > MIN_FILTER_LENGTH) {
      setData(
        filterShipCodes(shipCodes, movement, deal).filter(s => s.code.includes(filterValue))
      );
    }
  };

  const onValueChange = (e: ComboBoxChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        value: { ...shipCodeFieldValue, value: e.target.value },
        syntheticEvent: e.syntheticEvent
      });
    }
  };

  const defaultRendering = (
    <td
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      role={"gridcell"}
      {...navigationAttributes}>
      {isInEdit ? (
        <div>
          <MultiColumnComboBox
            id="mccShipCode"
            data={data}
            dataItemKey="id"
            columns={columns}
            textField={"code"}
            filterable={true}
            onFilterChange={handleFilterChange}
            style={{ width: "100%" }}
            onChange={onValueChange}
            clearButton={true}
            placeholder="Please select ..."
            value={shipCodeFieldValue?.value}
            size={"small"}
          />
        </div>
      ) : (
        <span>
          {!isValid && fieldConfig?.isRequired && (
            <span className="k-icon-xs k-icon bt-i-warning k-color-error"></span>
          )}
          {!isValid && !fieldConfig?.isRequired && shipCodeFieldValue && (
            <span className="k-icon-xs k-icon bt-i-warning k-color-warning"></span>
          )}
          <span>{shipCodeFieldValue?.value?.code ?? shipCodeFieldValue?.intial ?? ""}</span>
        </span>
      )}
    </td>
  );

  return render?.(defaultRendering, props) ?? <td></td>;
};
