import {
  Flexbox,
  Pagination,
  StickyTypeCell,
  Table,
  TBody,
  TD as Td,
  Text,
  TH as Th,
  THead,
  TR as Tr
} from "@sede-x/shell-ds-react-framework";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from "@tanstack/react-table";
import { useCallback, useEffect, useRef, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import GlobalHeader from "shared/components/GlobalHeader";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import useValidationConfigs from "ticketing/hooks/useValidationConfigs";
import { TValidationsConfig } from "ticketing/ticketing.types";
import EnterpriseSystemSelect from "../EnterpriseSystemSelect";
import ShowOpsValidationDetails from "./ShowOpsValidationDetails";

const ROWS_PER_PAGE = 10;
const PADDING = 80;

type TShowValidationConfigDetails = { view: boolean; data?: TValidationsConfig };
const gridColumns: ColumnDef<TValidationsConfig>[] = [
  {
    header: "Code",
    accessorKey: "code",
    enableSorting: true,
    enableResizing: false,
    cell: arg => <Text>{arg.getValue() as string}</Text>
  },
  {
    header: "Object",
    accessorKey: "objectTypeName",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <Text>{arg.getValue() as string}</Text>
  },

  {
    header: "Active",
    accessorKey: "active",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <Text>{arg.getValue() === 1 ? "Yes" : "No"}</Text>
  },

  {
    header: "MACk Active",
    accessorKey: "mackActive",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <Text>{arg.getValue() === 1 ? "Yes" : "No"}</Text>
  },
  {
    header: "Legal Entity Id",
    accessorKey: "legalEntityId",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <Text>{arg.getValue() as string}</Text>
  },

  {
    header: "Validation Type",
    accessorKey: "validationTypeName",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <Text>{arg.getValue() as string}</Text>
  },
  {
    header: "MACk Validation Type",
    accessorKey: "mackValidationType",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <Text>{arg.getValue() as string}</Text>
  },

  {
    header: "Redline",
    accessorKey: "redlineCategory",
    enableResizing: false,
    enableSorting: true,
    cell: arg => <Text>{arg.getValue() as string}</Text>
  }
];

const OpsValidation = () => {
  const { loading, error, validationConfigs } = useValidationConfigs();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });

  const [showDetails, setShowDetails] = useState<TShowValidationConfigDetails>({
    view: false
  });

  const globalHeaderRef = useRef<HTMLDivElement>(null);
  const [tableTop, setTableTop] = useState<number>(0);

  const onRowClick = useCallback((arg: TValidationsConfig) => {
    setShowDetails({ view: true, data: arg });
  }, []);

  const table = useReactTable<TValidationsConfig>({
    data: validationConfigs ?? [],
    columns: gridColumns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: false,
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel()
  });

  const onPageChange = useCallback(
    (current: number, changedPageSize: number): void => {
      table.setPageSize(changedPageSize);
      table.setPageIndex(current - 1);
    },
    [table]
  );

  useEffect(() => {
    const { innerHeight } = window;
    setTableTop(
      innerHeight -
        (globalHeaderRef?.current?.getBoundingClientRect().top ?? 0) -
        (globalHeaderRef?.current?.getBoundingClientRect().height ?? 0) -
        PADDING
    );
  }, [globalHeaderRef]);

  if (loading) {
    return <InlineLoadingPanel />;
  }

  if (error) {
    return <ApolloErrorViewer error={error} />;
  }

  return (
    <>
      <GlobalHeader
        pageName="Ops Validation"
        buttonContent={[<EnterpriseSystemSelect key={1} />]}
      />

      <div style={{ width: "100%", height: `${tableTop}px`, overflowY: "auto" }}>
        <Table className="carboniq-data-table">
          <THead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr
                key={headerGroup.id}
                depth={headerGroup.depth}
                sticky
                stickyType={StickyTypeCell.Header}>
                {headerGroup.headers.map(header => (
                  <Th key={header.id} colSpan={header.colSpan} width={header.getSize()}>
                    {header.isPlaceholder ? null : (
                      <Flexbox alignItems="center">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </Flexbox>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </THead>
          <TBody>
            {table.getRowModel().rows.map(row => (
              <Tr
                key={row.id}
                onClick={() => onRowClick(row.original)}
                style={{ cursor: "pointer" }}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </TBody>
        </Table>
        <div>
          <Pagination
            total={table.getFilteredRowModel().rows.length}
            pageSize={table.getState().pagination.pageSize}
            onChange={onPageChange}
          />
        </div>
      </div>

      {showDetails.view && showDetails.data && (
        <ShowOpsValidationDetails
          onClose={() => setShowDetails({ view: false })}
          details={showDetails.data}
        />
      )}
    </>
  );
};
export default OpsValidation;
