import { IntlService } from "@progress/kendo-react-intl";
import {
  Picklists,
  TPicklists,
  TTicketInput,
  TUserQueryCode,
  TValidationsConfig,
  TVolumeInput
} from "ticketing/ticketing.types";
import { isStorageDeal, isValidPageRange, maxDates, minDates } from "ticketing/utils";
import { filterShipFromCodes, filterShipToCodes } from "../movements/shipcode-filter";
import { validateTicketInput } from "../tickets/validations";
import { TBulkImportTicket, TRefData } from "./types";

const intlService = new IntlService("en");
const TH_RULE_START = 20;

const getNumberWithOrdinal = (n: number) => {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n.toString() + (s[(v - TH_RULE_START) % 10] || s[v] || s[0]);
};

export const isValidDate = (input: string | Date): boolean =>
  (input instanceof Date && !isNaN(input.getTime())) ||
  intlService.parseDate(input as string, "MM/dd/yyyy") !== null;

const isEmptyVolume = (t: TBulkImportTicket, seq: string) => {
  const isTempNull = !t[`volume${seq}temp`] && !t[`volume${seq}tempuom`].value;

  return (
    !t[`volume${seq}net`] &&
    !t[`volume${seq}gross`] &&
    !t[`volume${seq}uom`].value &&
    isTempNull
  );
};

const hasSameTemperature = (t: TBulkImportTicket, srcSeq: string, destSeq: string) => {
  return (
    t[`volume${srcSeq}temp`] === t[`volume${destSeq}temp`] &&
    t[`volume${srcSeq}tempuom`] === t[`volume${destSeq}tempuom`]
  );
};

const isRail = (t: TBulkImportTicket): boolean =>
  !!t.modeoftransport && (t.modeoftransport?.value as TRefData)?.name === "Rail";

const NUM_DECIMAL_DIGITS = 2;

const validateVolumeConversion = (
  t: TBulkImportTicket,
  srcSeq: string,
  destSeq: string,
  uomConversions?: Picklists.TUomConversion[]
) => {
  const errors = [];
  //if all dest volumes are empty...ignore
  if (isEmptyVolume(t, destSeq)) {
    return null;
  }
  errors.push(...validateTicketVolume(t, destSeq));

  //if all fields have valid data, then check if temps match...
  //ignore conversion check if temperatures are not equal
  if (hasSameTemperature(t, srcSeq, destSeq)) {
    const volumeSrcuom = t[`volume${srcSeq}uom`].value;
    const volumeDestuom = t[`volume${destSeq}uom`].value;
    if (
      volumeSrcuom &&
      volumeDestuom &&
      volumeSrcuom?.unitOfMeasureClass.name === volumeDestuom?.unitOfMeasureClass.name
    ) {
      //not same class...ignore
      const conversion = uomConversions?.find(
        c =>
          c.srcUnitOfMeasure.name === volumeSrcuom?.name &&
          c.destUnitOfMeasure.name === volumeDestuom?.name
      );
      if (
        !conversion?.factor ||
        (conversion.factor * t[`volume${srcSeq}net`]).toFixed(NUM_DECIMAL_DIGITS) !==
          t[`volume${destSeq}net`].toFixed(NUM_DECIMAL_DIGITS) ||
        (conversion.factor * t[`volume${srcSeq}gross`]).toFixed(NUM_DECIMAL_DIGITS) !==
          t[`volume${destSeq}gross`].toFixed(NUM_DECIMAL_DIGITS)
      ) {
        errors.push(`Invalid volume conversion, Group: ${destSeq}`);
      }
    }
  }
  return errors;
};

const TICKET_NUMBER_VALID_CHARS = /^([a-zA-Z0-9 _-]+)$/;
const FLOAT = /^\s*-?(\d+\.?|\.\d+|\d+\.\d+)([eE][-+]?\d+)?\s*$/;

const isTruckOrRail = (t: TBulkImportTicket): boolean =>
  !!t.modeoftransport &&
  ((t.modeoftransport?.value as TRefData)?.name === "Rail" ||
    (t.modeoftransport?.value as TRefData)?.name === "Truck");

const isValidVolume = (t: TBulkImportTicket, f: string): boolean => t[f] && FLOAT.test(t[f]);

export const validateTicketVolume = (t: TBulkImportTicket, seq: string) => {
  const errors = [];
  if (!isValidVolume(t, `volume${seq}net`)) {
    errors.push(`Invalid ${getNumberWithOrdinal(+seq)} net volume`);
  }

  if (!isTruckOrRail(t) && !isValidVolume(t, `volume${seq}gross`)) {
    errors.push(`Invalid ${getNumberWithOrdinal(+seq)} gross volume`);
  }

  if (!t[`volume${seq}uom`]?.value) {
    errors.push(`Invalid ${getNumberWithOrdinal(+seq)} volume's Unit of Measure`);
  }

  if (!isValidVolume(t, `volume${seq}temp`)) {
    errors.push(`Invalid ${getNumberWithOrdinal(+seq)} volume's temperature`);
  }
  if (!t[`volume${seq}tempuom`]?.value) {
    errors.push(`Invalid ${getNumberWithOrdinal(+seq)} volume's temperature Unit of Measure`);
  }

  return errors;
};

const validateTicketNumber = (t: TBulkImportTicket) => {
  if (!t.ticketnumber || !TICKET_NUMBER_VALID_CHARS.test(t.ticketnumber)) {
    return "Invalid Ticket Number";
  }
  return null;
};

const validateTicketDate = (t: TBulkImportTicket) => {
  if (!isValidDate(t.ticketdate)) {
    return "Invalid Ticket Date, valid format: MM/dd/yyyy";
  }
  return null;
};

const validateModeOfTransport = (t: TBulkImportTicket) => {
  if (!t.modeoftransport?.value) {
    return "Invalid Mode of Transport";
  }
  return null;
};

const validateRail = (t: TBulkImportTicket) => {
  if (isRail(t)) {
    if (!t.railcars || t.railcars?.trim().length === 0) {
      return "Mode of Transport `Rail` requires railcars";
    }
    if (
      t.railcars
        .trim()
        .split(",")
        .filter(r => r.trim().length === 0).length > 0
    ) {
      return "Invalid railcar, plese check your delimiter";
    }
  }
  return null;
};

const validatePDFFile = (t: TBulkImportTicket) => {
  if ((t.filename?.value && !t.pagerange) || (!t.filename?.value && t.pagerange)) {
    return "Invalid file name or page range";
  }
  return null;
};

const validatePageRange = (t: TBulkImportTicket) => {
  if (
    t.filename?.value &&
    t.pagerange &&
    !isValidPageRange(t.pagerange, t.filename.value.numPages)
  ) {
    return "Not a valid page range";
  }
  return null;
};

const validateBorderCrossingDate = (t: TBulkImportTicket) => {
  if (t.bordercrossingdate && !isValidDate(t.bordercrossingdate)) {
    return "Invalid Border Crossing Date, valid format: MM/dd/yyyy";
  }
  return null;
};

const validateTicketVolumes = (
  t: TBulkImportTicket,
  volumeGroups: string[],
  uomConversions?: Picklists.TUomConversion[]
) => {
  return [
    ...validateTicketVolume(t, "1"),
    ...volumeGroups
      .filter(s => s !== "1")
      .flatMap(seq => validateVolumeConversion(t, "1", seq, uomConversions))
  ];
};

const validateStorageDates = (ticket: TBulkImportTicket) => {
  const temp = [ticket?.deliveryid?.value?.activeMovement];
  const storageDeals = temp
    .flatMap(m => [m?.recDeal, m?.delDeal].filter(Boolean))
    .filter(d => isStorageDeal(d))
    .flatMap(d => ({
      startDate: d?.commitment?.startDate,
      endDate: d?.commitment?.endDate
    }));

  const [contractStartDate, contractEndDate] = storageDeals.reduce<(Date | undefined)[]>(
    (acc, val) => {
      acc[0] = minDates(acc[0], val.startDate as Date | undefined);
      acc[1] = maxDates(acc[1], val.endDate as Date | undefined);
      return acc;
    },
    []
  );

  if (
    (contractStartDate && contractStartDate > ticket.startDate) ||
    (contractEndDate && contractEndDate < ticket.startDate)
  ) {
    return "Ticket is Outside of storage contract dates";
    // {
    //   error: true,
    //   message: "Ticket is Outside of storage contract dates", //~~ for 2622751 use similar logic
    // };
  }
  return null;
};

const ticketValidations = [
  validateStorageDates,
  validateTicketNumber,
  validateTicketDate,
  validateModeOfTransport,
  validateRail,
  validatePDFFile,
  validatePageRange,
  validateBorderCrossingDate
];
export const validateBulkImportTicket = (
  t: TBulkImportTicket,
  volumeGroups: string[],
  picklists?: TPicklists,
  validationConfigs?: TValidationsConfig[],
  userQueryCodes?: TUserQueryCode[],
  tickets?: TBulkImportTicket[]
) => {
  return [
    ...ticketValidations.map(f => f?.(t)).filter(Boolean),
    ...validateTicketVolumes(t, volumeGroups, picklists?.uomConversions),
    ...getLinkedTicketValidations(
      t,
      volumeGroups,
      picklists,
      validationConfigs,
      userQueryCodes,
      tickets
    )
  ].filter((m): m is string => Boolean(m));
};

const getLinkedTicketValidations = (
  bulkticket: TBulkImportTicket,
  volumeGroups: string[],
  picklists?: TPicklists,
  validationConfigs?: TValidationsConfig[],
  userQueryCodes?: TUserQueryCode[],
  tickets?: TBulkImportTicket[]
) => {
  if (bulkticket.deliveryid?.value) {
    const ticketInput: TTicketInput = tranformBulkTicketToGenerticTicket(
      bulkticket,
      volumeGroups
    );
    const movementGroup = bulkticket.deliveryid.value;
    const shipFromCodes = filterShipFromCodes(picklists?.shipFrom, movementGroup);
    const shipToCodes = filterShipToCodes(picklists?.shipTo, movementGroup);
    //get tickets in this batch for the same delivery id
    const unsavedTickets = tickets
      ?.filter(
        ticket =>
          bulkticket.KEY !== ticket.KEY &&
          bulkticket.deliveryid?.value?.groupId === ticket?.deliveryid?.value?.groupId
      )
      .map(ticket => tranformBulkTicketToGenerticTicket(ticket, volumeGroups));

    const validatedTicket = {
      ...ticketInput,
      validationResults: validateTicketInput(
        ticketInput,
        movementGroup,
        null,
        validationConfigs!,
        userQueryCodes!,
        shipFromCodes,
        shipToCodes,
        unsavedTickets
      )
    };
    if (!ticketInput.volumes.length) {
      validatedTicket.validationResults.push({
        error: true,
        message: "No valid volumes found"
      });
    }

    if (validatedTicket.validationResults && validatedTicket.validationResults.length > 0) {
      return validatedTicket.validationResults.filter(r => r?.error).map(r => r?.message);
    }
  }
  return [];
};

const tranformBulkTicketToGenerticTicket = (
  bulkTicket: TBulkImportTicket,
  volumeGroups: string[]
): TTicketInput => {
  return {
    id: bulkTicket.ticketnumber,
    version: 1,
    status: "UNLINKED",
    source: "MANUAL",
    ticketNumber: bulkTicket.ticketnumber,
    startDate: bulkTicket.ticketdate as Date,
    isNew: true,
    collapsed: true,
    volumes: transformBulkVolumeToGenericVolume(bulkTicket, volumeGroups),
    shipFromCode: bulkTicket.shipfromcode?.value,
    shipToCode: bulkTicket.shiptocode?.value,
    railcars: bulkTicket.railcars,
    carrier: bulkTicket.carriername?.value,
    carrierScacCode: bulkTicket.carriername?.value?.scac,
    modeOfTransport: bulkTicket.modeoftransport?.value as Picklists.TModeOfTransport
  };
};

const transformBulkVolumeToGenericVolume = (
  t: TBulkImportTicket,
  volumeGroups: string[]
): TVolumeInput[] => {
  return volumeGroups
    .filter(v => validateTicketVolume(t, v)?.length === 0)
    .map((v, i) => ({
      id: i.toString(),
      sequence: i + 1,
      netVolume: t[`volume${v}net`] ?? 0,
      grossVolume: t[`volume${v}gross`] ?? 0,
      unitOfMeasure: t[`volume${v}uom`]?.value,
      temperature: t[`volume${v}temp`],
      temperatureUnitOfMeasure: t[`volume${v}tempuom`]?.value,
      isNew: true
    }));
};
