import { useState } from "react";

import "./movements.css";

import { TMovement, TMovementGroup } from "ticketing/ticketing.types";
import { MovementDetails } from "./MovementDetails";
import { MovementGroupHeader } from "./MovementGroupHeader";

const MovementGroup = ({
  movementGroup,
  activeMovementGroup
}: {
  movementGroup: TMovementGroup;
  activeMovementGroup: TMovementGroup | null;
}) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const visibleMovements = showAll ? movementGroup.movements : [movementGroup.activeMovement];

  const isActiveMovement = (movement: TMovement) => {
    return (
      movementGroup.groupId === activeMovementGroup?.groupId &&
      activeMovementGroup?.activeMovement.id === movement.id
    );
  };
  return (
    <div className="k-m-1 k-p-1 movement-grp-wrapper" role="none">
      <MovementGroupHeader
        movementGroup={movementGroup}
        showOrHideAll={b => setShowAll(b)}
        activeMovementGroup={activeMovementGroup}
      />
      {visibleMovements?.map(md => {
        return (
          <MovementDetails
            movement={md}
            movementGroup={movementGroup}
            activeMovementGroup={activeMovementGroup}
            key={`${md.id}-${md.groupId}`}
            isActiveMovement={isActiveMovement(md)}
          />
        );
      })}
    </div>
  );
};

export default MovementGroup;
