import { ApolloError, useMutation } from "@apollo/client";
import {
  Drawer,
  FormField,
  Heading,
  Sentiments,
  Sizes,
  TextInput,
  TextTypes,
  Toggle,
  Tree,
  TreeNode,
  Variants
} from "@sede-x/shell-ds-react-framework";
import { IMACkPrivilege, IMACkRole } from "auth";
import { loader } from "graphql.macro";
import { useCallback, useMemo, useState } from "react";
import { ApolloErrorViewer } from "shared/components/ApolloErrorViewer";
import LoadingPanel from "shared/components/LoadingPanel";
import "../user.css";

const ADD_ROLE = loader("../graphql/mutation-add-role.graphql");
const UPDATE_ROLE = loader("../graphql/mutation-update-role.graphql");

interface IInitialValues {
  name: string;
  description: string;
  active: boolean;
  privileges: TreeNode[];
}

const buildPrivilegesTree = (privileges: IMACkPrivilege[], role: IMACkRole) => {
  const getLeafNodes = (serviceName: string, groupName: string) => {
    return privileges
      .filter(p => p.serviceName === serviceName && p.group === groupName)
      .map(p => ({
        key: `${serviceName}_${groupName}_${p.privilegeId}`,
        title: p.name,
        expanded: false,
        isChecked: !!role.privileges?.some(
          privilege => privilege.privilegeId === p.privilegeId
        ),
        isLeaf: true
      }));
  };

  const getGroupNodes = (serviceName: string) => {
    return [
      ...new Set(privileges.filter(p => p.serviceName === serviceName).map(p => p.group))
    ].map(g => ({
      key: `${serviceName}_${g}`,
      title: g,
      expanded: false,
      isLeaf: false,
      children: getLeafNodes(serviceName, g)
    }));
  };

  return [...new Set(privileges.map(p => p.serviceName))].map(s => ({
    key: `${s}`,
    title: s,
    expanded: true,
    isLeaf: false,
    children: getGroupNodes(s)
  }));
};

const AddOrUpdateRole = ({
  role,
  privileges,
  onEditDone,
  onClose
}: {
  role: IMACkRole;
  privileges: IMACkPrivilege[];
  onEditDone: (done: IMACkRole | null) => void;
  onClose: () => void;
}) => {
  const [addRole, { loading: aLoading, error: aError, reset: aReset }] = useMutation(ADD_ROLE, {
    onCompleted: data => onEditDone(data?.role ?? null)
  });
  const [updateRole, { loading: uLoading, error: uError, reset: uReset }] = useMutation(
    UPDATE_ROLE,
    { onCompleted: data => onEditDone(data?.role ?? null) }
  );

  const [initialValues, setInitialValues] = useState<IInitialValues>({
    name: role.name,
    description: role.description ?? "",
    active: role.active,
    privileges: buildPrivilegesTree(privileges, role)
  });

  //Keep this useState seperate from initial
  const [checkedPrivileges, setCheckedPrivileges] = useState<TreeNode[]>(
    initialValues.privileges
  );

  const errors = useMemo(
    () => [aError, uError].filter((e): e is ApolloError => Boolean(e)),
    [aError, uError]
  );

  const onCheckChanged = (_node: TreeNode, treeData?: TreeNode[]) => {
    setCheckedPrivileges(treeData!);
  };

  const onSubmitClick = useCallback(() => {
    const copy = checkedPrivileges.flatMap(d => d.children?.flatMap(p => p.children));

    const assignedPrivileges = privileges
      .filter(p =>
        copy.some(c => c?.key === `${p.serviceName}_${p.group}_${p.privilegeId}` && c.isChecked)
      )
      .map(p => ({
        privilegeId: p.privilegeId,
        name: p.name,
        group: p.group,
        serviceName: p.serviceName
      }));

    const modifiedRole = { ...initialValues, privileges: assignedPrivileges };

    if (role.id) {
      updateRole({
        variables: {
          role: {
            ...modifiedRole,
            id: role.id,
            version: role.version
          }
        }
      });
    } else {
      addRole({ variables: { role: modifiedRole } });
    }
  }, [addRole, updateRole, initialValues, checkedPrivileges, role, privileges]);

  const isLoading = [aLoading, uLoading].some(Boolean);
  const hasError = errors.length > 0;
  const reset = () => [aReset, uReset].map(fn => fn?.());

  return (
    <Drawer
      header={
        <>
          <Heading type={TextTypes.H2}>{role.id ? "Edit Role" : "Add Role"}</Heading>
          {hasError && <ApolloErrorViewer error={errors} onClose={reset} />}
        </>
      }
      borders={false}
      closeButton={false}
      sticky
      size={Sizes.Medium}
      open={true}
      mask={true}
      maskClosable={false}
      onClose={onClose}
      actions={[
        {
          label: "Cancel",
          action: onClose,
          props: {
            variant: Variants.Outlined
          }
        },
        {
          label: "Save",
          action: onSubmitClick
        }
      ]}>
      {isLoading && <LoadingPanel />}

      <div className={""}>
        <form className="setting-form-container">
          <FormField
            size={"medium"}
            id="name-label"
            mandatory={true}
            label="Name"
            bottomLeftHelper={{
              content: !initialValues?.name ? <b>Field can't be blank.</b> : "",
              sentiment: Sentiments.Negative
            }}>
            <TextInput
              size={Sizes.Medium}
              onChange={event => {
                setInitialValues({
                  ...initialValues,
                  name: event.target.value
                });
              }}
              value={initialValues?.name ?? ""}
              invalid={!initialValues?.name}
            />
          </FormField>

          <FormField size={"medium"} label="Description">
            <TextInput
              size={Sizes.Medium}
              onChange={event => {
                setInitialValues({
                  ...initialValues,
                  description: event.target.value
                });
              }}
              value={initialValues?.description ?? ""}
            />
          </FormField>

          <FormField size={"medium"} label="Role Status">
            <Toggle
              size={Sizes.Large}
              checked={initialValues?.active}
              onChange={() => {
                setInitialValues({
                  ...initialValues,
                  active: !initialValues?.active
                });
              }}
            />
          </FormField>
          <div>
            <Tree
              treeData={initialValues.privileges}
              isCheckable
              size={Sizes.Small}
              onCheck={(node, treeData) => {
                onCheckChanged(node, treeData);
              }}
            />
          </div>
        </form>
      </div>
    </Drawer>
  );
};
export default AddOrUpdateRole;
