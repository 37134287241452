import { useLazyQuery } from "@apollo/client";
import { toString as toKString } from "@progress/kendo-intl";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  MultiColumnComboBox
} from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { loader } from "graphql.macro";
import React, { useContext, useState } from "react";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import { GqlResponse, TMovement, TMovementGroup } from "ticketing/ticketing.types";
import {
  firstDayOfPrevMonth,
  getCancelledMovementStatusId,
  lastDayOfNextMonth,
  toISODateString,
  transformMovements
} from "ticketing/utils";
import { BulkImportContext } from "../util";

type MovementSearchResponse = GqlResponse<TMovement[], "movementsFilterBy">;

const MOVEMENTS_SEARCH = loader("../../../ticketing-graphql/movementSearch.graphql");

const columns = [
  { field: "enterpriseSystemCode", header: "Delivery Id", width: "100px" },
  { field: "scheduleDate", header: "Start Date", width: "100px" },
  { field: "status.name", header: "Status", width: "100px" },
  { field: "scheduledQuantity", header: "Scheduled Quantity", width: "200px" }
];

const KEYSTROKE_DELAY = 500;
const MIN_FILTER_LENGTH = 5;

const getStartDate = (startDate?: Date | null) => {
  return firstDayOfPrevMonth(!startDate ? new Date() : startDate);
};

const getEndDate = (endDate?: Date | null) => {
  return lastDayOfNextMonth(!endDate ? new Date() : endDate);
};

const localTransformMovements = (movements: TMovement[]) => {
  const localCopy = transformMovements(movements);
  localCopy.forEach(m =>
    Object.assign(m, { scheduleDate: toKString(m.startDate, "MM/dd/yyyy") })
  );
  return localCopy;
};

export const DeliveryIdCell = (props: GridCellProps) => {
  const [searchMovements, { loading }] = useLazyQuery<MovementSearchResponse>(
    MOVEMENTS_SEARCH,
    {
      fetchPolicy: "no-cache",
      onCompleted: data => setMovements(localTransformMovements(data.movementsFilterBy))
    }
  );
  const { picklists } = usePicklists();
  const [movements, setMovements] = useState<TMovement[]>();
  const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
  const { fieldConfig: allFieldConfig, refData: allRefData } = useContext(BulkImportContext);
  const fieldConfig = allFieldConfig?.find(f => f.name === field);
  const isInEdit = field === dataItem.inEdit;
  const deliveryIdValue = field && dataItem[field];
  const isValid = deliveryIdValue?.value != null;
  const refMovements = allRefData?.movements;
  const timeout = React.useRef<ReturnType<typeof setTimeout> | null>();
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  const typeAhead = fieldConfig?.typeAhead;

  const onValueChange = (e: ComboBoxChangeEvent) => {
    const activeMovement = getData()?.find(m => m.id === e.value?.id);

    const group: TMovementGroup | null =
      e.value && activeMovement
        ? {
          groupId: e.value.groupId,
          movements: getData()?.filter(m => m.groupId === e.value.groupId)!,
          activeMovement
        }
        : null;

    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        value: { value: group, initial: e.value == null ? null : deliveryIdValue.initial },
        syntheticEvent: e.syntheticEvent
      });
    }
    setMovements([]);
  };

  const handleFilterChange = async (e: ComboBoxFilterChangeEvent) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    const filterValue = e.filter?.value?.trim();
    if (typeAhead && (filterValue.length ?? 0) > MIN_FILTER_LENGTH) {
      timeout.current = setTimeout(() => {
        searchMovements({
          variables: {
            movementsFilter: {
              startDate: { goe: toISODateString(getStartDate(dataItem.ticketdate)) },
              endDate: { loe: toISODateString(getEndDate(dataItem.ticketdate)) },
              enterpriseSystemId: picklists?.enterpriseSystemId,
              enterpriseSystemCode: { eq: filterValue },
              statusId: { ne: getCancelledMovementStatusId(picklists?.movementStatuses) }
            }
          }
        });
      }, KEYSTROKE_DELAY);
    }
  };

  const getData = () => ((movements?.length ?? 0) === 0 ? refMovements : movements);

  const defaultRendering = (
    <td
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      role={"gridcell"}
      {...navigationAttributes}>
      {isInEdit ? (
        <div>
          <MultiColumnComboBox
            data={getData()}
            dataItemKey="id"
            columns={columns}
            textField={"enterpriseSystemCode"}
            filterable={true}
            onFilterChange={handleFilterChange}
            style={{ width: "100%" }}
            loading={loading}
            onChange={onValueChange}
            clearButton={true}
            placeholder="Please select ..."
            value={
              deliveryIdValue?.value?.activeMovement ?? {
                enterpriseSystemCode: deliveryIdValue?.initial
              }
            }
            size={"small"}
            // onBlur={onBlur}
            popupSettings={{ popupClass: "bulk-import-multi-column" }}
          />
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center", alignContent: "center" }}>
          {!isValid && fieldConfig?.isRequired && (
            <span className="k-icon-xs k-icon bt-i-warning k-color-error"></span>
          )}
          {!isValid && !fieldConfig?.isRequired && deliveryIdValue?.initial && (
            <span
              className="k-icon-xs k-icon bt-i-warning k-color-warning"
              title="Invalid Delivery Id"></span>
          )}
          <span style={{ flexGrow: "1" }}>
            {deliveryIdValue?.value?.activeMovement?.enterpriseSystemCode ??
              deliveryIdValue?.initial ??
              ""}
          </span>
        </div>
      )}
    </td>
  );

  return render?.(defaultRendering, props) ?? <td></td>;
};
