import { memo } from "react";
import { TBatch } from "ticketing/ticketing.types";
import BatchComponent from "./BatchComponent";
import "./movements.css";

type BatchListViewProps = {
  batches?: TBatch[] | null;
  selectedBatch?: TBatch | null;
};

const BatchContainer = ({ batches, selectedBatch }: BatchListViewProps) => {
  return (
    <>
      <div className="container-component-title">Batch</div>
      <div className="movement-batch-container-wrapper">
        {(batches?.length ?? 0) === 0 && (
          <div>
            <span className="availabel-tkt-container-wrapper">No data available.</span>
          </div>
        )}
        {batches && batches.length > 0 && (
          <div>
            {batches.map(batch => (
              <BatchComponent
                key={batch.id}
                batch={batch}
                isSelected={selectedBatch?.id === batch.id}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(BatchContainer);
