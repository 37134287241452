import { useMutation } from "@apollo/client";
import {
  Checkbox,
  Drawer,
  FormField,
  Heading,
  Label,
  Sentiments,
  Sizes,
  TextInput,
  TextTypes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import { loader } from "graphql.macro";
import { useState } from "react";
import LoadingPanel from "shared/components/LoadingPanel";
import { TCICustomer, TUpdateCICustomer } from "../carbonIQtypes";

const updateCustomerMutation = loader("./../graphql/mutation-update-customer.graphql");

const UpdateCustomer = ({
  details,
  onClose,
  onSubmit
}: {
  details: Partial<TCICustomer> | null;
  onClose: () => void;
  onSubmit: (data: TUpdateCICustomer) => void;
}) => {
  const emailRegex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);

  const [open, setOpen] = useState(true);
  const handleOnClose = (data?: TUpdateCICustomer) => {
    if (data) {
      onSubmit(data as TUpdateCICustomer);
    }
    onClose();
    setOpen(false);
  };
  const [loading, setLoading] = useState(false);

  // Update Customer
  const [updateCustomer, { loading: updateCustomerLoading, error: updateCustomerError }] =
    useMutation(updateCustomerMutation, {
      fetchPolicy: "no-cache",
      onCompleted: data => handleOnClose(data),
      onError: () => {
        handleOnClose();
      }
    });

  const [customerName, setCustomerName] = useState<string | undefined>(
    details?.displayName ?? ""
  );
  const [customerEmail, setCustomerEmail] = useState<string | undefined>(details?.email ?? "");
  const [keyAccountEmail, setKeyAccountEmail] = useState<string | undefined>(
    details?.managerEmail ?? ""
  );
  const [shouldGenerateLetters, setShouldGenerateLetters] = useState<boolean | undefined>(
    details?.shouldGenerateLetters
  );

  const disableSave =
    !customerName ||
    !emailRegex.test(customerEmail as string) ||
    !emailRegex.test(keyAccountEmail as string);

  const isLoading = [loading, updateCustomerLoading].some(elm => elm);

  const onSubmitClick = () => {
    setLoading(true);
    const tempdata = {
      id: details?.id,
      version: details?.version,
      email: customerEmail,
      managerEmail: keyAccountEmail,
      displayName: customerName,
      shouldGenerateLetters
    };
    updateCustomer({
      variables: {
        customer: {
          ...tempdata,
          id: details?.id,
          version: details?.version
        }
      }
    });
  };

  return (
    <Drawer
      header={
        <Heading className="setting-form-heading" type={TextTypes.H2}>
          Update Customer
        </Heading>
      }
      borders={false}
      closeButton={false}
      sticky
      mask={true}
      open={open}
      size={Sizes.Medium}
      onClose={() => {
        handleOnClose();
      }}
      actions={[
        {
          label: "Cancel",
          action: () => {
            handleOnClose();
          },
          props: {
            variant: Variants.Outlined
          }
        },
        {
          label: "Save",
          action: () => {
            setLoading(true);
            onSubmitClick();
          },
          props: {
            disabled: disableSave
          }
        }
      ]}>
      {updateCustomerError && <CommonErrorComponent error={updateCustomerError} />}
      <form>
        <FormField size={"medium"} id="customer-name-label" label="Customer ID">
          <Label>{details?.gsapName}</Label>
        </FormField>
        <FormField
          size={"medium"}
          id="customer-name-label"
          label="Customer Name *"
          bottomLeftHelper={{
            content: customerName === "" ? "Field can't be blank." : "",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              setCustomerName(event.target.value.trim());
            }}
            invalid={customerName === ""}
            value={customerName}
            required
          />
        </FormField>
        <FormField
          size={"medium"}
          id="customer-email-label"
          label="Customer Email"
          bottomLeftHelper={{
            content:
              customerEmail && emailRegex.test(customerEmail)
                ? ""
                : "You have entered an invalid email address!",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              setCustomerEmail(event.target.value.trim());
            }}
            invalid={!emailRegex.test(customerEmail as string)}
            value={customerEmail}
            required
          />
        </FormField>
        <FormField
          size={"medium"}
          id="manager-email-label"
          label="Key A/C Mgr Email"
          bottomLeftHelper={{
            content:
              customerEmail && emailRegex.test(keyAccountEmail as string)
                ? ""
                : "You have entered an invalid email address!",
            sentiment: Sentiments.Negative
          }}>
          <TextInput
            onChange={event => {
              setKeyAccountEmail(event.target.value.trim());
            }}
            invalid={!emailRegex.test(keyAccountEmail as string)}
            value={keyAccountEmail}
            required
          />
        </FormField>
        <FormField size={"medium"} id="shouldGenerate-letters-label">
          <Checkbox
            label="Generate Letters?"
            checked={shouldGenerateLetters}
            size="small"
            mirrored={true}
            onChange={() => {
              setShouldGenerateLetters(!shouldGenerateLetters);
            }}
          />
        </FormField>
      </form>
      {isLoading && <LoadingPanel />}
    </Drawer>
  );
};

export default UpdateCustomer;
