import { ApolloError, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { GqlResponse } from "types";
import { IMACkUser, MackUser } from ".";

export interface IAuthenticationContext {
  mackUser?: MackUser | null;
  isLoading?: boolean;
  ssoSignInError?: Error;
  mackSignInError?: ApolloError;
}
export const AuthenticationContext = createContext<IAuthenticationContext>({});

const SIGN_IN_USER = loader("./graphql/mutation-sign-in-user.graphql");

type TSignInResponse = GqlResponse<IMACkUser, "user">;

export const AuthenticationProvider = (props: { children: React.ReactNode }) => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const [mackUser, setMackUser] = useState<MackUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const [signInUser, { error: mackSignInError }] = useMutation<TSignInResponse>(SIGN_IN_USER, {
    onCompleted: data => {
      if (data?.user?.status === "ACTIVE") {
        setMackUser(new MackUser(data.user));
        setIsLoading(false);
      }
    },
    onError: () => setIsLoading(false)
  });

  const providerValue = useMemo(
    () => ({
      mackUser,
      isLoading,
      ssoSignInError: auth.error,
      mackSignInError
    }),
    [mackUser, isLoading, mackSignInError, auth.error]
  );

  const isAuthComplete = !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading;

  useEffect(() => {
    if (!hasAuthParams() && isAuthComplete && !hasTriedSignin) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin, isAuthComplete]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      signInUser();
    }
  }, [auth.isAuthenticated, auth.events, signInUser]);

  return (
    <AuthenticationContext.Provider value={providerValue}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};

export function useMackAuth() {
  const context = useContext<IAuthenticationContext>(AuthenticationContext);
  if (!context) {
    throw new Error("Invalid authentication context");
  }
  return context;
}
