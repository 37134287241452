import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn, GridNoRecords, GridToolbar } from "@progress/kendo-react-grid";
import { TMovement } from "ticketing/ticketing.types";

type MovementInfoFieldsProps = {
  movement: TMovement;
  onClose: () => void;
};

export const MovementInfoFields = ({ movement, onClose }: MovementInfoFieldsProps) => {
  const fields = movement.infoFields;
  return (
    <div className={"content-wrapper"}>
      <div className={"grid-wrapper"}>
        <div className={"card-container"}>
          <Grid
            data={fields}
            dataItemKey="name"
            style={{ maxHeight: "296px", overflowY: "auto", margin: "2px" }}>
            <GridToolbar>
              <Button
                themeColor={"primary"}
                onClick={onClose}
                icon="close"
                className="theme-btn-red">
                Close
              </Button>
            </GridToolbar>
            <GridNoRecords>There is no data available</GridNoRecords>
            <GridColumn title="Name" field="name" width={125} />
            <GridColumn title="Value" field="value" width={450} />
          </Grid>
        </div>
      </div>
    </div>
  );
};
