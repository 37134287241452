import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

interface IDialogProps {
  title: string;
  text: string;
  details?: string[];
  onClose: () => void;
}

const GridDialog = (props: Partial<IDialogProps>) => {
  const { title, text, details, onClose } = props;
  return (
    <Dialog title={title}>
      <p style={{ margin: "25px", textAlign: "center" }}>{text}</p>
      {details && (
        <ul>
          {props.details!.map((detail) => (
            <li key={detail}>{detail}</li>
          ))}
        </ul>
      )}

      <DialogActionsBar>
        <Button onClick={onClose}>
          OK
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};
export default GridDialog;
