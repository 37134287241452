import { Badge } from "@sede-x/shell-ds-react-framework";

export const EnvBadge = () => {
  const environment =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_ENVIRONMENT
      : window._env_.REACT_APP_ENVIRONMENT;

  const envName = environment !== "PRODUCTION" ? environment : "";

  return (
    <>
      {envName && envName.length > 0 && (
        <Badge className={"env-badge-label " + envName?.toLowerCase()}>{envName}</Badge>
      )}
    </>
  );
};
