import { toString as toKString } from "@progress/kendo-intl";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useContext } from "react";
import { BulkImportContext } from "../util";
import { isValidDate } from "../validations";

export const DateCell = (props: GridCellProps) => {
  const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
  const { fieldConfig: allFieldConfig } = useContext(BulkImportContext);
  const fieldConfig = allFieldConfig?.find(f => f.name === field);
  const isInEdit = field === dataItem.inEdit;
  const value = field && dataItem[field] ? dataItem[field] : "";
  const isValid = isValidDate(value);
  const navigationAttributes = useTableKeyboardNavigation(props.id);

  const onChange = (e: DatePickerChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value
      });
    }
  };
  const defaultRendering = (
    <td
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
      role={"gridcell"}
      {...navigationAttributes}>
      {isInEdit ? (
        <div className="k-font-size-xs">
          <DatePicker
            name={field}
            defaultValue={isValid ? value : null}
            format="MM/dd/yyyy"
            onChange={onChange}
            size={"small"}
            className="bulk-import-cell-input"
          />
        </div>
      ) : (
        <span>
          {!isValid && fieldConfig?.isRequired && (
            <span className="k-icon-xs k-icon bt-i-warning k-color-error"></span>
          )}
          {!!value && !isValid && (
            <span className="k-icon-xs k-icon bt-i-warning k-color-warning"></span>
          )}
          <span>{isValid ? toKString(value, "MM/dd/yyyy") : value}</span>
        </span>
      )}
    </td>
  );

  return render?.(defaultRendering, props) ?? <td></td>;
};
