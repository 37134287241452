import { Button } from "@progress/kendo-react-buttons";
import {
  GridColumn as Column,
  Grid,
  GridCellProps,
  GridCustomCellProps,
  GridItemChangeEvent,
  GridRowProps,
  GridToolbar
} from "@progress/kendo-react-grid";
import { useRef, useState } from "react";

import { ContextMenu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import InlineLoadingPanel from "shared/components/InlineLoadingPanel";
import { DetailComponent, StatusCell } from "./cells/StatusCell";
import { CellRender, RowRender } from "./cells/cellRender";
import { TBulkImportTicket, TFieldConfig } from "./types";

const getStatusCell =
  (expandChange: (dataItem: TBulkImportTicket) => void) => (props: GridCellProps) =>
    <StatusCell {...props} expandChange={expandChange} />;

type TBulkImportTicketGridProp = {
  tickets: TBulkImportTicket[];
  columns: TFieldConfig[];
  loading: boolean;
  onSave: () => void;
  onReset: () => void;
  exitEdit: () => void;
  enterEdit: (ticket: TBulkImportTicket, field?: string) => void;
  onTicketUpdated: (event: GridItemChangeEvent) => void;
  expandChange: (ticket: TBulkImportTicket) => void;
  onDeleteTicket: (index: number) => void;
};

const EXPAND_FIELD = "expanded";
const EDIT_FIELD = "inEdit";
const DEFAULT_TOP = 260;
const DEFAULT_PADDING = 24;

/**
 *
 * @param param0
 * @returns
 */
export const BulkImportTicketGrid = ({
  tickets,
  columns,
  loading,
  onSave,
  onReset,
  exitEdit,
  enterEdit,
  onTicketUpdated,
  expandChange,
  onDeleteTicket
}: TBulkImportTicketGridProp) => {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [dataItemIndex, setDataItemIndex] = useState(0);
  const contextMenuOffset = useRef({ left: 0, top: 0 });
  const divRef = useRef<HTMLDivElement>(null);
  const top = (divRef.current?.getBoundingClientRect().y ?? DEFAULT_TOP) + DEFAULT_PADDING;

  const handleContextMenuOpen = (e: React.MouseEvent, dataItem: TBulkImportTicket) => {
    e.preventDefault();

    setDataItemIndex(tickets.findIndex(t => t.KEY === dataItem.KEY));

    contextMenuOffset.current = { left: e.pageX, top: e.pageY };
    setShowContextMenu(true);
  };

  const handleCloseMenu = () => {
    setShowContextMenu(false);
  };

  const handleOnSelect = (e: MenuSelectEvent) => {
    if (e.item.data.action === "deleteRow") {
      onDeleteTicket(dataItemIndex);
    }
    setShowContextMenu(false);
  };

  const customCellRender = (
    td: React.ReactElement<HTMLTableCellElement> | null,
    props: GridCustomCellProps
  ) => (
    <CellRender originalProps={props} td={td} enterEdit={enterEdit} editField={EDIT_FIELD} />
  );

  const customRowRender = (
    tr: React.ReactElement<HTMLTableRowElement>,
    props: GridRowProps
  ) => (
    <RowRender
      originalProps={props}
      tr={tr}
      exitEdit={exitEdit}
      editField={EDIT_FIELD}
      onContextMenu={handleContextMenuOpen}
    />
  );
  const validTicketCount = tickets?.filter(t => !t.errors?.length).length ?? 0;
  const canSave = validTicketCount > 0;

  return (
    <div className={"content-wrapper"} ref={divRef}>
      <div className={"grid-wrapper"}>
        <div className={"grid-container"}>
          <div className={"card-container"}>
            <Grid
              data={tickets}
              resizable={true}
              reorderable={true}
              rowHeight={30}
              style={{ height: `calc(100vh - ${top}px)` }}
              cellRender={customCellRender}
              rowRender={customRowRender}
              onItemChange={onTicketUpdated}
              dataItemKey={"KEY"}
              editField={EDIT_FIELD}
              expandField={EXPAND_FIELD}
              detail={DetailComponent}
              navigatable={true}>
              <GridToolbar>
                {loading && <InlineLoadingPanel />}
                <span className="k-text-light">Number of Records: {tickets?.length}</span>
                <span className="k-color-primary">| Valid Records: {validTicketCount}</span>
                <Button
                  title="Save"
                  className="bulk-tickets-cta-btn"
                  disabled={!canSave || loading}
                  icon={"save"}
                  onClick={onSave}
                  themeColor={"primary"}>
                  Save
                </Button>
                <Button
                  title="Cancel"
                  className="bulk-tickets-cta-btn"
                  icon={"cancel"}
                  onClick={onReset}
                  disabled={loading}>
                  Cancel
                </Button>
              </GridToolbar>
              <Column
                field={EXPAND_FIELD}
                title=" "
                width="32px"
                editable={false}
                cell={getStatusCell(expandChange)}
                resizable={false}
              />
              {columns.map(field => (
                <Column
                  key={field.name}
                  field={field.name}
                  headerClassName={"k-justify-content-center"}
                  title={field.title}
                  resizable={field.resizable}
                  width={field.width}
                  minResizableWidth={64}
                  cell={field.cell}>
                  {field.children?.map(c => (
                    <Column
                      field={c.name}
                      key={c.name}
                      headerClassName={"k-justify-content-center"}
                      className={"k-color-primary"}
                      title={c.title}
                      resizable={c.resizable}
                      width={c.width}
                      minResizableWidth={64}
                      cell={c.cell}
                    />
                  ))}
                </Column>
              ))}
            </Grid>
            <ContextMenu
              show={showContextMenu}
              offset={contextMenuOffset.current}
              onSelect={handleOnSelect}
              onClose={handleCloseMenu}>
              <MenuItem text="Delete row" data={{ action: "deleteRow" }} icon="delete" />
            </ContextMenu>
          </div>
        </div>
      </div>
    </div>
  );
};
