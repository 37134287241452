import { useQuery } from "@apollo/client";
import {
  Badge,
  BaseTable,
  Button,
  Sentiments,
  Sizes,
  Variants
} from "@sede-x/shell-ds-react-framework";
import {
  AddSquare,
  EditOutlined
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import {
  ColumnDef,
  SortingState,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import { MackPrivilege } from "auth";
import { useMackAuth } from "auth/AuthenticationProvider";
import { ROWS_PER_PAGE } from "carbonIQ/CarbonIQConstants";
import { TComponentsFilter } from "carbonIQ/carbonIQtypes";
import CommonErrorComponent from "carbonIQ/commonErrorComponent";
import { loader } from "graphql.macro";
import { useState } from "react";
import ColumnText from "shared/components/basetable/cells/ColumnText";
import NoDataTableImage from "shared/components/basetable/NoDataTableImage";
import GlobalHeader from "shared/components/GlobalHeader";
import LoadingPanel from "shared/components/LoadingPanel";
import { GqlResponse } from "types";
import AddOrUpdateBlendComponents from "./addOrUpdateBlendComponents";

const CarbonGenericProductsList = loader("../graphql/lookupComponents.graphql");

type TComponentsFilterResponse = GqlResponse<TComponentsFilter[], "ciComponentsFilterBy">;

const FETCH_POLICY_NO_CACHE = "no-cache";

const gridColumns: ColumnDef<TComponentsFilter>[] = [
  {
    header: "Component Name",
    accessorKey: "name",
    enableSorting: false,
    size: 300,
    cell: arg => <ColumnText width={"300px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Energy Density",
    accessorKey: "energyDensity",
    size: 500,
    enableSorting: false,
    cell: arg => <ColumnText width={"300px"}>{arg.getValue() as string}</ColumnText>
  },

  {
    header: "Renewable",
    accessorKey: "isRenewable",
    size: 500,
    enableSorting: false,
    cell: arg => (
      <ColumnText width={"300px"}>{(arg.getValue() as boolean) ? "Yes" : "No"}</ColumnText>
    )
  }
];

const gridColumnsGenerate = (
  clickcallback: (arg: {
    row: {
      original: Partial<TComponentsFilter>;
    };
  }) => void,
  editSelected: number | undefined,
  hasEditPermission: boolean | undefined = false
) => {
  if (!hasEditPermission) {
    return gridColumns;
  } else {
    const additionalGridColumn = {
      header: "",
      accessorKey: "components",
      cell: (arg: {
        row: {
          original: TComponentsFilter;
        };
      }) => (
        <Button
          className={arg.row.original.id === editSelected ? "editedRow" : ""}
          title="Edit"
          size={Sizes.Small}
          onClick={() => clickcallback(arg)}>
          <EditOutlined />
          Edit
        </Button>
      ),
      size: 40,
      enableSorting: false
    };
    return [...gridColumns, additionalGridColumn];
  }
};

const BlendComponents = () => {
  const { mackUser } = useMackAuth();

  const hasAddBlendcomponentsPrivilege = mackUser?.hasPrivilege(MackPrivilege.AddCIComponent);

  const hasModifyBlendcomponentsPrivilege = mackUser?.hasPrivilege(
    MackPrivilege.ModifyCIComponent
  );

  const [editSelected, setEditSelected] = useState<number>();

  const {
    loading: lCIQDocument,
    error: eCIQDocument,
    data,
    refetch
  } = useQuery<TComponentsFilterResponse>(CarbonGenericProductsList, {
    fetchPolicy: FETCH_POLICY_NO_CACHE
  });

  const [addOrUpdateOpen, setAddOrUpdateOpen] = useState(false);
  const [updateOpenProductDetails, setUpdateOpenProductDetails] =
    useState<TComponentsFilter | null>(null);

  const columns = gridColumnsGenerate(
    arg => {
      setEditSelected(arg.row.original.id);
      setUpdateOpenProductDetails(arg.row.original as TComponentsFilter);
      setAddOrUpdateOpen(true as boolean);
    },
    editSelected,
    hasModifyBlendcomponentsPrivilege
  );

  const [sorting, setSorting] = useState<SortingState>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: ROWS_PER_PAGE //default page size
  });

  const tableOptions = {
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false
  };

  const handlAddOrUpdateSubmit = () => {
    refetch();
    setAddOrUpdateOpen(false);
  };

  const handleAddNewProductClick = () => {
    setUpdateOpenProductDetails(null);
    setAddOrUpdateOpen(true);
  };

  const isLoading = [lCIQDocument].some(elm => elm);
  const isError = [eCIQDocument].some(e => e);

  return (
    <>
      {isError && <CommonErrorComponent error={eCIQDocument} />}

      {isLoading && <LoadingPanel />}

      <GlobalHeader
        pageName="Blend Components"
        descriptiontxt="Please use this screen to add Components and their energy densities in order to enable the correct savings calculation."
        buttonContent={[
          hasAddBlendcomponentsPrivilege && (
            <Badge
              key={1}
              icon={<AddSquare />}
              sentiment={Sentiments.Information}
              variant={Variants.Filled}
              onClick={() => handleAddNewProductClick()}>
              Add New Component
            </Badge>
          )
        ]}
      />

      <BaseTable
        columns={columns}
        data={data?.ciComponentsFilterBy}
        className="carboniq-data-table"
        tableOptions={tableOptions}
        key={"blendComponents-table-key"}
        emptyStateProps={{ image: <NoDataTableImage /> }}
      />

      {addOrUpdateOpen && (
        <AddOrUpdateBlendComponents
          onClose={() => setAddOrUpdateOpen(!addOrUpdateOpen)}
          onSubmit={() => handlAddOrUpdateSubmit()}
          details={updateOpenProductDetails}
        />
      )}
    </>
  );
};

export default BlendComponents;
