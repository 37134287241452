import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Checkbox, NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { ReCron, Tab } from "@sbzen/re-cron";
import { useEffect, useState } from "react";
import {
  getAutoExportSettings,
  saveAutoExportSettings,
} from "../../../forecast/api";
import LoadingPanel from "../LoadingPanel";
import {
  ForecastExportPeriod,
  IForecastAutoExportSettings,
} from "./../../../forecast/models";
import "./index.scss";

const CronDialog = (props: {
  onClose: (e: boolean) => void;
  onError: (e: string) => void;
}) => {
  const [settings, setSettings] = useState<IForecastAutoExportSettings | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initSettings = async () => {
      setLoading(true);
      try {
        const data = await getAutoExportSettings();
        setSettings({
          ...data,
          cronExpression: data.cronExpression ?? "0 0 6 * * *",
          startAt: data.startAt ?? data.availablePeriods[0].name,
          zoneId: data.zoneId ?? data.availableZones[0],
          plusMonths: data.plusMonths ?? 0,
          incremental: data.incremental,
        });
      } catch (error) {
        props.onError(
          "Error retrieving settings: " +
          (error.response?.data?.errors?.[0] ?? error.message)
        );
      } finally {
        setLoading(false);
      }
    };
    initSettings();
  }, [props]);

  const getExportPeriod = () =>
    settings?.availablePeriods.find((p) => p.name === settings.startAt);

  const setCron = (cronValue: string) => {
    setSettings({ ...settings as IForecastAutoExportSettings, cronExpression: cronValue });
  };
  const onStartChange = (e: DropDownListChangeEvent) => {
    setSettings({
      ...settings as IForecastAutoExportSettings,
      startAt: (e.value as ForecastExportPeriod).name,
    });
  };
  const onPlusMonthsChange = (e: NumericTextBoxChangeEvent) => {
    setSettings({ ...settings as IForecastAutoExportSettings, plusMonths: e.value });
  };
  const onZoneChange = (e: DropDownListChangeEvent) => {
    setSettings({ ...settings as IForecastAutoExportSettings, zoneId: e.value });
  };
  const onCancel = () => props.onClose(false);
  const onSave = async () => {
    try {
      await saveAutoExportSettings(settings!);
      props.onClose(true);
    } catch (error) {
      props.onError(`Error saving settings: ${error.response?.data?.errors?.[0] ??
        error.message}`
      );
    }
  };

  return (
    <>
      {loading && <LoadingPanel />}
      {settings && (
        <Dialog
          title={"Auto Export Settings"}
          onClose={onCancel}
          width="480px"
          height="368px"
          className="forecast-export-settings"
        >
          <div>
            <div>
              {/* <div className="k-pb-1">Run at:</div> */}
              <ReCron
                tabs={[Tab.HOURS, Tab.DAY, Tab.MONTH]}
                value={settings?.cronExpression!}
                onChange={setCron}
                cssClassPrefix="cron-"
              />
            </div>
            <div className="k-d-flex-row k-align-items-end k-justify-content-between k-mt-1 k-p-1 payload">
              <div className="start-date-of k-mr-1">
                <DropDownList
                  data={settings.availablePeriods}
                  textField="caption"
                  dataItemKey="name"
                  title="Start Date"
                  label="Start Of"
                  onChange={onStartChange}
                  value={getExportPeriod()}
                />
              </div>
              <div className="plus-months k-mr-1">
                <NumericTextBox
                  title="Number of Months"
                  placeholder="Months"
                  label="+Months"
                  value={settings.plusMonths}
                  onChange={onPlusMonthsChange}
                  min={0}
                ></NumericTextBox>
              </div>
              <div className="time-zone">
                <DropDownList
                  data={settings.availableZones}
                  label="Time Zone"
                  title="Time Zone"
                  onChange={onZoneChange}
                  value={settings.zoneId}
                />
              </div>
            </div>
            <div className="k-pt-1">
              <Checkbox
                label={"Only modified data since last export"}
                className="k-p-1"
                defaultChecked={settings.incremental}
                onChange={(event) =>
                  setSettings({ ...settings, incremental: event.value })
                }
              />
            </div>
          </div>
          <DialogActionsBar>
            <Button
              icon="cancel"
              onClick={onCancel}
              className={"theme-btn-red"}
            >
              Cancel
            </Button>
            <Button
              themeColor={"primary"}
              icon="save"
              onClick={onSave}
              className={"theme-btn-yellow"}
            >
              Save
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};
export default CronDialog;
