import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { process } from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuProps,
  GridItemChangeEvent,
  GridRowClickEvent,
  GridSelectionChangeEvent,
} from "@progress/kendo-react-grid";

import GridActionBar from "./GridActionBar";

import { IAppState } from "../../../_redux/IReduxState";
import { actionCreator } from "../../../_redux/actionCreator";
import {
  IForecastDailySummary,
  IForecastSummary,
} from "../../../forecast/models";
import { FORECAST_SELECT_ALL_CHANGE } from "../../../forecast/state/forecastActionTypes";
import {
  validateValue, activeRow, enterEdit as enterEditor,
  itemTouched, itemOnChange
} from "../../../forecast/util/gridEditor";
import { rowRender, cellRender} from "../../../forecast/util/gridRenderer";
import { onDetailsRowSelect } from "../../../forecast/util/gridSelector";
import { sumOfValues } from "../../algorithms";
import { FORECAST_AGGREGATED_APPROVED_VOLUME_PERCENT, FORECAST_APPROVED_VOLUME, FORECAST_BASE_PERCENTAGE_MISSING } from "shared/enums";

interface IGridItemDetails {
  gridStyle?: any;
  onDataStateChange?: any;
  onClose?: any;
}

const GridItemDetails = (props: IGridItemDetails) => {
  const { onDataStateChange, onClose } = props;

  const dispatch = useDispatch();

  // redux store
  const forecast = useSelector((state: IAppState) => state.forecast);
  const summary: IForecastSummary = forecast.monthlySummary;

  const activeSummary = activeRow(summary);
  const details = activeSummary.details;
  const data = details.data;
  const result = details.result ? details.result.data : details.data;

  const dataState = (details?.dataState) || {};

  const selectionChange = (event: GridSelectionChangeEvent) => {
    event.dataItem.selected = !event.dataItem.selected;
    dispatch(actionCreator("selectionChange", summary.data));
  };

  const headerSelectionChange = (event: any) => {
    const checked = event.syntheticEvent.target.checked;
    data.forEach((item: any) => {
      item.selected = checked;
    });
    dispatch(actionCreator(FORECAST_SELECT_ALL_CHANGE, summary.data));
  };

  const onRowSelect = (e: GridRowClickEvent) => {
    onDetailsRowSelect(e.dataItem);
  };

  //========================
  const enterEdit = (dataItem: IForecastDailySummary, field: string) => {
    exitEdit(data);

    if (field === FORECAST_APPROVED_VOLUME) {
      // when showBaseProduct is enabled but base has not been fetched,
      // fetch base and reset active row
      if (summary.showBaseProduct) {
        onDetailsRowSelect(dataItem);
      }

      // set clicked data item inEdit and re-apply the dataState
      enterEditor(data, dataItem, field);
      details.result = process(details.data, dataState);
      dispatch(actionCreator("enterEdit", summary));
    }
  };

  const exitEdit = (dataItem: { inEdit: string }) => {
    dataItem.inEdit = "";
    dispatch(actionCreator("exitEdit", summary));
  };

  const onItemChange = (event: GridItemChangeEvent) => {
    const value = validateValue(event.value);

    // 1. if base % is 0, pop up message
    // 2. if base % is normal,
    //      . set new detail item volume
    //      . set each base volume = new detail volume * %
    //      . set new monthly volume

    // base % total === 0
    if (
      event.dataItem[FORECAST_AGGREGATED_APPROVED_VOLUME_PERCENT] === 0
    ) {
      const msg = FORECAST_BASE_PERCENTAGE_MISSING;
      dispatch(actionCreator("logError", { message: msg }));
    } else {
      // change detail and base volumes
      itemOnChange(event.dataItem, value);

      // update corresponded monthly volume
      const field = event.dataItem.inEdit;
      const newDetailsVolumeTotal = sumOfValues(data, field);
      itemTouched(activeSummary, newDetailsVolumeTotal);

      dispatch(actionCreator("editDetailsData", summary.data));
    }
  };

  const onCellRender = (
    tdElement: React.DetailedReactHTMLElement<any, HTMLElement>,
    cellProps: { dataItem: any; field: any }
  ) => {
    return cellRender(tdElement, cellProps, enterEdit, exitEdit);
  };

  const actions = [
    {
      icon: "k-icon k-i-close",
      title: "Close",
      click: onClose,
    },
  ];

  const ColumnMenuCheckboxFilter = (props: GridColumnMenuProps) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter {...props} data={data} expanded={true} />
      </div>
    );
  };

  const DetailsGrid = () => {
    return (
      <Grid
        data={result}
        {...dataState}
        onDataStateChange={onDataStateChange}
        sortable={true}
        resizable
        reorderable
        pageable={false}
        onRowClick={onRowSelect}
        onRowDoubleClick={onRowSelect}
        selectedField="selected"
        onSelectionChange={selectionChange}
        onHeaderSelectionChange={headerSelectionChange}
        editField="inEdit"
        onItemChange={onItemChange}
        cellRender={onCellRender}
        rowRender={rowRender}
        style={props.gridStyle}
      >
        <GridColumn
          field="selected"
          width="50px"
          headerSelectionValue={data.every(
            (dataItem: any) => dataItem.selected === true
          )}
        />

        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="startDate"
          title="Start Date"
          width="120px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="endDate"
          title="End Date"
          width="120px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="product.name"
          title="Finished Product"
          width="170px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="approvedVolume"
          title="Approved Volume"
          width="170px"
          format="{0:#,##.##}"
          editable={true}
          editor="numeric"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="forecastVolume"
          title="Forecast Volume"
          width="170px"
          format="{0:#,##.##}"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="unitOfMeasure.name"
          title="UoM"
          width="100px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="contractNum"
          title="Contract Num"
          width="150px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="supplier.name"
          title="Supplier"
          width="100px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="customer.name"
          title="Customer"
          width="250px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="terminal.name"
          title="Terminal"
          width="120px"
        />
        <GridColumn
          columnMenu={ColumnMenuCheckboxFilter}
          field="modeOfTransport.name"
          title="Mode of Transport"
          width="170px"
        />
      </Grid>
    );
  };

  return (
    <div className="card-container">
      <GridActionBar label="Details" actions={actions} />
      <DetailsGrid />
    </div>
  );
};
export default GridItemDetails;
