import { ApolloError, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { useEffect, useState } from "react";
import { usePicklists } from "ticketing/contexts/picklists/PicklistContextProvider";
import { TUserQueryCode, TValidationsConfig } from "ticketing/ticketing.types";

const lookupTicketValidationsConfig = loader(
  "../ticketing-graphql/lookupTicketValidationsConfig.graphqls"
);

type GqlResponseTicketValidationsConfig = {
  ticketValidationConfig: TValidationsConfig[];
  userQueryCodes: TUserQueryCode[];
};

const FETCH_POLICY_CACHE_FIRST = "cache-first";

const TICKET_VALIDATIONS_CONFIG_KEY = "TICKET_VALIDATIONS_CONFIG";
const startOfToday = new Date(new Date().setHours(0, 0, 0, 0)).getTime();

const useValidationConfigs = () => {
  const [validationConfigs, setValidationConfigs] = useState<TValidationsConfig[]>([]);
  const [userQueryCodes, setUserQueryCodes] = useState<TUserQueryCode[]>([]);

  const [loadTicketValidationsConfig, setLoadTicketValidationsConfig] = useState(false);
  const [error, setError] = useState<ApolloError>();
  const [loading, setLoading] = useState(true);
  const { picklists } = usePicklists();

  useQuery<GqlResponseTicketValidationsConfig>(lookupTicketValidationsConfig, {
    fetchPolicy: FETCH_POLICY_CACHE_FIRST,
    skip: !loadTicketValidationsConfig,
    variables: { enterpriseSystemId: picklists?.enterpriseSystemId },
    onCompleted: data => {
      const ticketValidationsConfig = data.ticketValidationConfig;
      const uQc = data.userQueryCodes;
      window.localStorage.setItem(
        TICKET_VALIDATIONS_CONFIG_KEY,
        JSON.stringify({
          lastFetchedDay: startOfToday,
          ticketValidationsConfig,
          userQueryCodes: uQc
        })
      );
      setValidationConfigs(ticketValidationsConfig);
      setUserQueryCodes(uQc);
      setLoadTicketValidationsConfig(false);
      setLoading(false);
    },
    onError: e => {
      setError(e);
      setLoading(false);
    }
  });

  useEffect(() => {
    const val = window.localStorage.getItem(TICKET_VALIDATIONS_CONFIG_KEY);
    if (val != null) {
      const validationsConfigState = JSON.parse(val);
      if (
        validationsConfigState != null &&
        validationsConfigState.constructor === Object &&
        Object.keys(validationsConfigState).length > 0 &&
        validationsConfigState.lastFetchedDay === startOfToday
      ) {
        setValidationConfigs(validationsConfigState.ticketValidationsConfig);
        setUserQueryCodes(validationsConfigState.userQueryCodes);
        setLoading(false);
        return;
      }
    }
    setLoadTicketValidationsConfig(true);
  }, []);
  return { loading, error, validationConfigs, userQueryCodes };
};

export default useValidationConfigs;
